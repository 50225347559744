import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired when a field is rendered in the grid.
 * Returning anything will embed that result in the grid cell for the field.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.grid.cell::render"
 * @type {GridRenderFieldEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 *          Null if rendering should be passed to either Flotiq or other plugins.
 *          Renderable result if plugin wants to replace the default renderer.
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name GridRenderFieldEvent
 *
 * @property {string} accessor Full path to the field within the object
 * @property {*} data Field value
 * @property {string} inputType Field input type
 * @property {object} contentObject An entire object that is being rendered
 * @property {object} contentType Content type that includes the field
 * @property {string} contentTypeName Content type api name
 */
export class GridRenderFieldEvent extends IFlotiqPluginEvent {
  accessor;
  data;
  inputType;
  contentObject;
  contentTypeName;
  contentType;
}
