import { useCallback } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import { CaretDownIcon } from '../../images/shapes';

const Panel = ({
  title,
  children,
  onChange,
  open,
  helpText,
  width,
  isCollapsable,
  additionalTitleWrapClasses,
  additionalTitleClasses,
  additionalChildrenClasses,
  additionalContainerClasses,
  additionalChildrenContainerClasses,
  additionalIconClasses,
  testId,
  id,
}) => {
  const onPanelOpen = useCallback(() => {
    if (onChange && isCollapsable) {
      onChange();
    }
  }, [onChange, isCollapsable]);

  return (
    <Disclosure defaultOpen={open}>
      {({ open }) => (
        <div
          style={{ width: width }}
          className={twMerge(
            'px-7 py-5 rounded-lg bg-white dark:bg-slate-950 relative h-fit',
            additionalContainerClasses,
          )}
          id={id}
          {...getTestProps(testId, `panel-container`)}
        >
          <Disclosure.Button
            onClick={onPanelOpen}
            disabled={!isCollapsable}
            as="div"
            className={twMerge(
              'flex items-center justify-between',
              isCollapsable && 'cursor-pointer',
              additionalTitleClasses,
            )}
            {...getTestProps(testId, `panel-open`)}
          >
            <div
              className={twMerge(
                'flex justify-center items-center',
                additionalTitleWrapClasses,
              )}
            >
              <span
                className={twMerge(
                  'mr-2 font-bold text-base dark:text-white',
                  additionalTitleClasses,
                )}
                {...getTestProps(testId, `panel-title`)}
              >
                {title}
              </span>
              {helpText}
            </div>
            {isCollapsable && (
              <CaretDownIcon
                className={twMerge(
                  'w-3 min-w-[0.75rem] transition-all duration-300 ease-in-out dark:text-white',
                  additionalIconClasses,
                  open && 'rotate-180',
                )}
                {...getTestProps(testId, `panel-open-icon`)}
              />
            )}
          </Disclosure.Button>
          <Transition
            show={open}
            className={additionalChildrenContainerClasses}
            enter="transition-all duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition-all duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel
              {...getTestProps(testId, `panel-content-container`)}
              className={twMerge(
                'overflow-visible pt-4 dark:text-white',
                additionalChildrenClasses,
              )}
            >
              {children}
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
};
export default Panel;

Panel.propTypes = {
  /**
   * Test id for Panel
   */
  testId: PropTypes.string,
  /**
   * Panel title
   */
  title: PropTypes.node,
  /**
   * Panel children
   */
  children: PropTypes.node,
  /**
   * Callback run on panel opens
   */
  onChange: PropTypes.func,
  /**
   * Boolean for panel to render open
   */
  open: PropTypes.bool,
  /**
   * Boolean for blocking panel to open
   */
  isCollapsable: PropTypes.bool,
  /**
   * Node with panel toolitp
   */
  helpText: PropTypes.node,
  /**
   * Panel width
   */
  width: PropTypes.string,
  /**
   * Additional classes for children
   */
  additionalChildrenClasses: PropTypes.string,
  /**
   * Additional classes for panel container
   */
  additionalPanelContainerClasses: PropTypes.string,
  /**
   * Additional classes for panel title
   */
  additionalTitleClasses: PropTypes.string,
  /**
   * Additional classes for panel container
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Additional classes for children container
   */
  additionalChildrenContainerClasses: PropTypes.string,
  /**
   * Additional classes for panel icon
   */
  additionalIconClasses: PropTypes.string,
  /**
   * Additional classes for panel title container
   */
  additionalTitleWrapClasses: PropTypes.string,
  /**
   * Panel id
   */
  id: PropTypes.string,
};

Panel.defaultProps = {
  testId: '',
  title: '',
  children: '',
  open: false,
  isCollapsable: true,
  width: 'auto',
  onChange: /* istanbul ignore next */ () => {},
  helpText: '',
  additionalChildrenClasses: '',
  additionalPanelContainerClasses: '',
  additionalTitleClasses: '',
  additionalIconClasses: '',
  additionalContainerClasses: '',
  additionalChildrenContainerClasses: '',
  additionalTitleWrapClasses: '',
  id: '',
};
