import { useTranslation } from 'react-i18next';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import Panel from '../Panel/Panel';
import Button from '../Button/Button';
import { ArrowUpRightIcon } from '../../images/shapes';

const PluginInfo = ({ plugin }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="text-xl font-bold mb-2">
        <span className="break-words">
          {plugin.displayName || plugin.name || plugin.id}
        </span>
        <span className="float-right text-base font-normal font-mono">
          {plugin.version || (
            <span className="italic">{t('Plugins.UI.UnknownVersion')}</span>
          )}
        </span>
      </div>
      <p className="break-words">
        {plugin.description || (
          <span className="italic break-words">
            {t('Plugins.UI.UnknownDescription')}
          </span>
        )}
      </p>
      <div className="overflow-hidden clear-both">
        {plugin.repository && (
          <Button
            buttonSize="sm"
            buttonColor="borderless"
            iconImage={<ArrowUpRightIcon className="w-3 h-3" />}
            as="a"
            target="_blank"
            rel="noreferrer"
            href={plugin.repository}
            additionalClasses="xl:float-left whitespace-nowrap mt-4 w-fit px-0 underline hover:no-underline"
          >
            {t('Plugins.UI.GoToRepository')}
          </Button>
        )}
        {plugin.permissions?.length > 0 && (
          <details>
            <summary className="xl:float-right leading-none cursor-pointer mt-4 mb-2 text-sm">
              {t('Plugins.UI.DataAccess')}
            </summary>

            <div className="mt-2 clear-both block float-none align-middle">
              {plugin.permissions.map((permission) => (
                <div
                  key={permission.ctdName + '|' + permission.type}
                  className="grid grid-cols-2 border-t border-gray py-2 animate-popoverfadein"
                >
                  <div>{t(`Plugins.UI.Type.${permission.type}`)}</div>
                  <div>{permission.ctdName || t('Plugins.UI.AllCTD')}</div>
                  <div className="col-span-2 italic">
                    {['Read', 'Create', 'Update', 'Delete']
                      .filter((perm) => permission[`can${perm}`])
                      .map((perm) => t(`Global.Permission.${perm}`))
                      .join(', ')}
                  </div>
                </div>
              ))}
            </div>
          </details>
        )}
      </div>
    </>
  );
};

const LoadedUIPluginsPanel = () => {
  const { t } = useTranslation();

  return (
    <Panel
      title={t('Plugins.UI.Title')}
      additionalChildrenClasses="space-y-6 dark:text-gray-200"
      isCollapsable={false}
      open
    >
      {!Object.keys(FlotiqPlugins.getLoadedPlugins()).length
        ? t('Plugins.UI.EmptyData')
        : Object.entries(FlotiqPlugins.getLoadedPlugins()).map(
            ([id, handler]) => (
              <div key={id}>
                <PluginInfo plugin={handler.plugin} />
              </div>
            ),
          )}
    </Panel>
  );
};

export default LoadedUIPluginsPanel;
