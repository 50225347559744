import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import StatusBar from '../StatusBar/StatusBar';
import {
  getTestProps,
  resizeColumn,
  setInitialGridOptions,
} from '../../lib/helpers';
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';
import { useTranslation } from 'react-i18next';
import { getDefinedColumns } from './definedColumns';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import DataGrid from '../DataGrid/DataGrid';
import Loader from '../Loader/Loader';
import NewMediaContext from '../../contexts/NewMediaContext';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import useActionsColumn from '../DataGrid/useActions';
import UserContext from '../../contexts/UserContext';
import { RolePermissions } from '../../lib/rolePermissions';

const OPTIONS_KEY = 'cms.media-library-grid-state';

const MediaDataGrid = ({
  media,
  isLoading,
  pagination,
  limit,
  reloadMedia,
  order,
  handleOrder,
  selectedMedia,
  selectMedia,
  onTagEdit,
  onTagClick,
  onVariantCreate,
  testId,
}) => {
  const { t } = useTranslation();
  const { newMedia, setNewMedia } = useContext(NewMediaContext);
  const { buildUrlWithSpace } = useSelectedSpace();
  const mediaGridContainer = useRef();
  const [columns, setColumns] = useState([]);
  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const { permissions } = useContext(UserContext);

  const canUpdate = permissions.canCo(
    '_media',
    RolePermissions.PERMISSIONS_TYPES.UPDATE,
  );

  const handleInitialGridOptions = useCallback((cols, minWidth) => {
    setInitialGridOptions(
      cols,
      minWidth,
      mediaGridContainer.current.offsetWidth - 100,
      setGridOptions,
      setColumns,
      OPTIONS_KEY,
    );
  }, []);

  useEffect(() => {
    const cols = [];

    const gridOptionsByID = gridOptions?.reduce((acc, elem) => {
      acc[elem.colId] = elem;
      return acc;
    }, {});

    const definiedColumns = getDefinedColumns(
      gridOptionsByID,
      onTagEdit,
      onTagClick,
      onVariantCreate,
      canUpdate,
      t,
      testId,
    );

    Object.keys(definiedColumns).forEach((accessor) => {
      cols.push(definiedColumns[accessor]);
    });

    if (!gridOptions) {
      handleInitialGridOptions(cols, 200);
    } else {
      setColumns(cols);
    }
  }, [
    t,
    handleInitialGridOptions,
    gridOptions,
    testId,
    onTagEdit,
    onTagClick,
    onVariantCreate,
    buildUrlWithSpace,
    canUpdate,
  ]);

  const actionColumn = useActionsColumn('media', ['edit'], [], '_media', false);

  const allColumns = useMemo(
    () => [actionColumn, ...columns],
    [actionColumn, columns],
  );

  const handleColumnResize = useCallback(
    (columnWidth, dataKey) =>
      resizeColumn(
        columnWidth,
        dataKey,
        gridOptions,
        setGridOptions,
        OPTIONS_KEY,
      ),
    [gridOptions, setGridOptions],
  );

  const onSortColumn = useCallback(
    (field, order) => {
      handleOrder('order_by', field);
      handleOrder('order_direction', order);
    },
    [handleOrder],
  );

  const debouncedOnColumnResize = useDebounceCallback(handleColumnResize, 100);

  const handleResetGrid = useCallback(() => {
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
    setNewMedia?.([]);
  }, [setNewMedia]);

  const mediaDict = useMemo(
    () =>
      media.reduce((acc, currendMedia) => {
        acc[currendMedia.id] = currendMedia;
        return acc;
      }, {}),
    [media],
  );

  const handleCheck = useCallback(
    (ids) => {
      const idsSet = new Set(ids);
      Object.keys(selectedMedia).forEach((selectedId) => {
        if (!idsSet.has(selectedId)) selectMedia({ id: selectedId });
      });

      const selectedIds = new Set(Object.keys(selectedMedia));
      ids.forEach((idToSelect) => {
        if (!selectedIds.has(idToSelect) && mediaDict[idToSelect])
          selectMedia(mediaDict[idToSelect]);
      });
    },
    [mediaDict, selectMedia, selectedMedia],
  );

  const reloadData = useCallback(() => {
    reloadMedia();
    setNewMedia?.([]);
  }, [reloadMedia, setNewMedia]);

  const isNewMediaAdding = useMemo(
    () => newMedia?.findIndex((media) => !Array.isArray(media)) > -1,
    [newMedia],
  );

  const newMediaToShow = useMemo(
    () =>
      (newMedia || [])
        .filter((media) => Array.isArray(media) && !media[1])
        .map((media) => media[0]),
    [newMedia],
  );

  return (
    <div
      className="h-[calc(100vh-300px)] md:h-[calc(100vh-400px)]"
      ref={mediaGridContainer}
    >
      <DataGrid
        columns={allColumns}
        data={[...newMediaToShow, ...media]}
        isLoading={isNewMediaAdding || isLoading}
        loadingIcon={<Loader size={'small'} type={'spinner-grid'} />}
        onSortColumn={onSortColumn}
        sort={order?.order_by}
        sortOrder={order?.order_direction}
        statusBar={
          <StatusBar
            rows={pagination.count + newMediaToShow.length}
            handleDataUpdate={reloadData}
            resultsFrom={(pagination.current_page - 1) * limit + 1}
            resultsTo={
              (pagination.current_page - 1) * limit +
              pagination.count +
              newMediaToShow.length
            }
            resultsTotalCount={pagination.total_count + newMediaToShow.length}
            handleResetGrid={handleResetGrid}
            {...getTestProps(testId, 'statusbar', 'testId')}
          />
        }
        noDataInfoText={t('Users.FiltersEmptyResult')}
        additionalClasses="bg-white dark:bg-gray-900"
        rowHeight={70}
        onResize={debouncedOnColumnResize}
        autoHeight={false}
        checkedRows={Object.keys(selectedMedia)}
        onCheckRows={handleCheck}
        hasSelectColumn
        fillHeight
        {...getTestProps(testId, 'grid', 'testId')}
      />
    </div>
  );
};

export default MediaDataGrid;

MediaDataGrid.propTypes = {
  /**
   * Media data
   */
  media: PropTypes.array,
  /**
   * If media are loading
   */
  isLoading: PropTypes.bool,
  /**
   * On upload callback
   */
  pagination: PropTypes.object,
  /**
   * Current media limit on page
   */
  limit: PropTypes.number,
  /**
   * On reload data callback
   */
  reloadMedia: PropTypes.func,
  /**
   * Current order
   */
  order: PropTypes.shape({
    order_by: PropTypes.string,
    order_direction: PropTypes.string,
  }),
  /**
   * On order change callback
   */
  handleOrder: PropTypes.func,
  /**
   * Selected media
   */
  selectedMedia: PropTypes.object,
  /**
   * Callback for selecting media
   */
  selectMedia: PropTypes.func,
  /**
   * On tag edit callback
   */
  onTagEdit: PropTypes.func,
  /**
   * On tag click callbakc
   */
  onTagClick: PropTypes.func,
  /**
   *   Test id for user media subpage
   */
  testId: PropTypes.string,
};

MediaDataGrid.defaultProps = {
  media: [],
  isLoading: false,
  pagination: {},
  limit: 12,
  reloadMedia: /* istanbul ignore next */ () => null,
  handleOrder: /* istanbul ignore next */ () => null,
  selectedMedia: {},
  selectMedia: /* istanbul ignore next */ () => null,
  onTagEdit: /* istanbul ignore next */ () => null,
  onTagClick: /* istanbul ignore next */ () => null,
  testId: '',
};
