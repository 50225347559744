import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * Expected resposne fron event that will generate form
 *
 * @typedef {object} SchemaEventResponse
 * @memberof FlotiqPlugins.Events
 * @property {object} schema Schema object compatible with Flotiq API. 
 *            All passed schema gets nonCtdSchema value set on true.
 * @property {object} options Additional options passed to settings form
 * @property {FlotiqPlugins.Form.onValidate} options.onValidate
 * @property {FlotiqPlugins.Form.onSubmit} options.onSubmit
 * @property {boolean} options.disbaledBuildInValidation If build in validation should be disabled.
 *            If not provided on form submission, the validation has to be handled by onValidate option.
 */

/**
 * An event that is fired on manage button click.
 * Returning object will be used to generate manage form for plugin settings.
 *
 * A plugin will **not** receive this event when manage button is clicked for any other plugin.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.plugins.manage::form-schema"
 * @type {PluginsManageFormSchemaEvent}
 * @returns {null|SchemaEventResponse}
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name PluginsManageFormSchemaEvent
 *
 * @property {object} plugin Plugin data
 * @property {array} contentTypes Content types data
 * @property {object} modalInstance Settings modal instance with resolve method
 */

export class PluginsManageFormSchemaEvent extends IFlotiqPluginEvent {
  plugin;
  contentTypes;
  modalInstance;
}
