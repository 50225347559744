import { useCallback, useContext, useMemo } from 'react';
import AppContext from '../contexts/AppContext';

const useSelectedSpace = () => {
  const { appContext } = useContext(AppContext);
  const space = useMemo(() => appContext?.space, [appContext]);
  const spaceSlug = useMemo(() => appContext?.spaceSlug, [appContext]);
  const buildUrlWithSpace = useCallback(
    (url) => `/s/${spaceSlug}/${url}`,
    [spaceSlug],
  );
  return { space, buildUrlWithSpace };
};

export default useSelectedSpace;
