import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// :: Helpers
import { getTestProps } from '../../lib/helpers';

// :: Images
import rightArrowIcon from '../../images/icons-library/carets/caret_right_blue-icon.svg';

const Breadcrumbs = ({
  breadcrumbs,
  rightArrowIconSrc,
  additionalClasses,
  additionalBreadcrumbClasses,
  testId,
}) => {
  const renderBreadcrumb = useCallback((breadcrumb) => {
    const { children, disabled, additionalClasses, label, link, onClick } =
      breadcrumb;
    const labelTitle = label?.length > 30 ? label : '';

    if (children) return children;
    if (disabled)
      return (
        <p
          className={twMerge(
            'text-slate-400 dark:text-gray-200',
            additionalClasses || '',
          )}
          title={labelTitle}
        >
          {label}
        </p>
      );
    return (
      <Link
        className={twMerge(additionalClasses || '')}
        to={link}
        title={labelTitle}
        onClick={onClick}
      >
        {label}
      </Link>
    );
  }, []);

  return (
    <div
      className={twMerge(
        'flex p-3 text-gray-700 whitespace-nowrap border-slate-800',
        'max-w-[calc(100vw-94px)] sm:max-w-[calc(100vw-114px)] md:max-w-[calc(100vw-430px)]',
        'lg:max-w-[calc(100vw-430px)]',
        additionalClasses,
      )}
      {...getTestProps(testId, 'wrapper')}
    >
      {breadcrumbs?.map((breadcrumb) => (
        <div
          key={breadcrumb.key}
          className={twMerge(
            'pr-5 last:!pr-0 flex items-center bg-contain bg-no-repeat bg-right last:!bg-none text-xs',
            'dark:!text-zinc-600 dark:last:!text-gray-200 truncate min-w-8',
            additionalBreadcrumbClasses,
          )}
          style={{
            backgroundImage: `url(${rightArrowIconSrc || rightArrowIcon})`,
            backgroundSize: '1.1rem',
          }}
          {...getTestProps(testId, breadcrumb.key)}
        >
          {renderBreadcrumb(breadcrumb)}
        </div>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  /**
   * Your breadcrumbs list with all needed attributes
   */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      label: PropTypes.node,
      disabled: PropTypes.bool,
      key: PropTypes.string.isRequired,
      additionalClasses: PropTypes.string,
    }),
  ),
  /**
   * Breadcrumb icon image src
   */
  rightArrowIconSrc: PropTypes.string,
  /**
   * Breadcrumbs additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Single Breadcrumb additional CSS classes
   */
  additionalBreadcrumbClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  breadcrumbs: [],
  rightArrowIconSrc: rightArrowIcon,
  additionalClasses: '',
  additionalBreadcrumbClasses: '',
  testId: '',
};

export default Breadcrumbs;
