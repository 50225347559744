import moment from 'moment';

// Components
import DataGridActionMenu from '../DataGridActionMenu/DataGridActionMenu';
import StatusPill from '../../StatusPill/StatusPill';
import CheckCircle from '../../CheckCircle/CheckCircle';
import Button from '../../Button/Button';
import LinkButton from '../../LinkButton/LinkButton';

// Images
import { LinkIcon } from '../../../images/shapes';
import { getTestProps } from '../../../lib/helpers';

const ActionButtons = ({ actions }) => (
  <>
    {actions?.map((action) => {
      const ButtonComponent = action.link ? LinkButton : Button;
      return (
        <ButtonComponent
          key={action.key}
          title={action.label}
          buttonSize="sm"
          iconImage={action.icon}
          buttonColor="borderless"
          link={action.link}
          disabled={action.disabled}
          additionalClasses="w-fit !px-1.5 !py-2 hover:opacity-50"
          additionalIconClasses="min-w-3.5 w-3.5"
          {...(action.link ? { link: action.link } : {})}
          {...(action.onClick ? { onClick: action.onClick } : {})}
        />
      );
    })}
  </>
);

export const actionsRenderer = (
  rowData,
  actions,
  mobileActions,
  testId = '',
) => {
  return mobileActions.length > 0 ? (
    <>
      <div className="hidden lg:flex items-center justify-start">
        <ActionButtons actions={actions} />
      </div>
      <div className="lg:hidden flex items-center justify-start h-full overflow-visible">
        <DataGridActionMenu
          menuItems={mobileActions}
          rowData={rowData}
          ignoreIcons
          {...getTestProps(testId, 'mobile-actions', 'testId')}
        />
      </div>
    </>
  ) : (
    <div className="flex items-center justify-start">
      <ActionButtons actions={actions} />
    </div>
  );
};

export const relationRenderer = (data, handleGetRelations) => {
  if (!data || !data.length > 0) return null;
  return (
    <div
      className="bg-blue rounded-lg px-2 py-0.5 inline-flex items-center text-white cursor-pointer"
      onClick={() => handleGetRelations(data)}
      data-testid="relation-renderer"
    >
      <LinkIcon className="w-3.5 h-3.5 mr-1" />
      {data.length}
    </div>
  );
};

export const geoRenderer = (data) => {
  if (!data) return null;
  return (
    <span className="inline-block h-full w-full truncate">
      {`${data.lat || '?'}, ${data.lon || '?'}`}
    </span>
  );
};

export const pillRenderer = (data, color = 'blue') => {
  if (!data) return null;
  return Array.isArray(data) ? (
    <div className="w-full flex gap-1">
      {data.slice(0, 3).map((d) => (
        <StatusPill
          status={d}
          containerClasses="text-xs lg:text-sm lg:py-1.5 lg:px-3 lg:h-7 py-0.5 px-1 h-fit truncate"
          color={color}
          key={d}
        />
      ))}
      {data.length > 3 && (
        <div
          className="bg-blue px-2 py-0.5 text-white rounded-lg flex ml-1 justify-center items-center"
          data-testid="object-renderer-counter"
        >
          +{data.length - 3}
        </div>
      )}
    </div>
  ) : (
    <div className="inline-block truncate w-full">
      <StatusPill
        status={data}
        containerClasses="text-xs lg:text-sm lg:py-1.5 lg:px-3 lg:h-7 py-0.5 px-1 h-fit truncate"
        color={color}
      />
    </div>
  );
};

export const webhookActionsRenderer = (data, color = 'blue', testId = '') => {
  if (!data) return null;
  return (
    <div className="flex flex-nowrap truncate w-full">
      {data?.map((el) => {
        if (!el.action) return null;
        return (
          <StatusPill
            key={el.action}
            status={el.action}
            containerClasses="text-xs lg:text-sm lg:py-1.5 lg:px-3 lg:h-7 py-0.5 px-1 h-fit truncate ml-1"
            color={color}
            testId={testId ? `${testId}-status-pill` : ''}
          />
        );
      })}
    </div>
  );
};

export const checkboxRenderer = (data) => {
  return defaultRenderer(
    typeof data !== 'undefined' ? <CheckCircle checked={!!data} /> : null,
  );
};

export const defaultRenderer = (data) => {
  return <span className="inline-block h-full w-full truncate">{data}</span>;
};

export const dateRenderer = (date) => {
  if (date) return moment(date).format(date?.includes('T') ? 'L LT' : 'L');
  return '';
};

/**
 * Extract elements from list column type
 * @param data object with column data
 * @param items object with column config
 * @returns {array|null}
 */
const extractListElements = (data, items) => {
  if (data == null) return null;
  const [order, propertiesConfig] = [items.order, items.propertiesConfig];
  const firstData = data[0];
  return order.map((element) => {
    const childItems = propertiesConfig[element]?.items;
    if (childItems != null) {
      return extractListElements(firstData?.[element], childItems);
    }
    if (
      ['text', 'number', 'email'].includes(propertiesConfig[element].inputType)
    ) {
      return data[0]?.[element];
    } else if (propertiesConfig[element].inputType === 'geo') {
      return `${data[0]?.[element]?.lat || '?'}, ${
        data[0]?.[element]?.lon || '?'
      }`;
    }
    return null;
  });
};

export const objectRenderer = (data, items) => {
  if (data == null || items == null) return null;
  const listElements = extractListElements(data, items)
    .flat()
    .filter((element) => element);
  return (
    <div className="flex items-center">
      {defaultRenderer(listElements.join(', '))}
      {data.length >= 2 && (
        <div
          className="bg-blue px-2 py-0.5 text-white rounded-lg flex ml-1 justify-center items-center"
          data-testid="object-renderer-counter"
        >
          +{data.length - 1}
        </div>
      )}
    </div>
  );
};
