import { useCallback, useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getTestProps } from '../../lib/helpers';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader/Loader';
import UserContext from '../../contexts/UserContext';
import { RolePermissions } from '../../lib/rolePermissions';

const FileButton = ({
  onUpload,
  multiple,
  disabled,
  label,
  onBlur,
  buttonSize,
  additionalClasses,
  testId,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef();
  const [isUploading, setIsUploading] = useState(false);

  const { permissions } = useContext(UserContext);

  const canCreate = permissions.canCo(
    '_media',
    RolePermissions.PERMISSIONS_TYPES.CREATE,
  );

  const handleUpload = useCallback(
    async (e) => {
      setIsUploading(true);
      await onUpload(e.target.files);
      if (inputRef.current?.value) inputRef.current.value = null;
      setIsUploading(false);
    },
    [onUpload],
  );

  return (
    <Button
      as="label"
      buttonSize={buttonSize}
      onBlur={onBlur}
      iconImage={
        isUploading ? <Loader type="spinner-grid" size="tiny" /> : null
      }
      disabled={isUploading || disabled || !canCreate}
      additionalClasses={additionalClasses}
      {...getTestProps(testId, 'add-media', 'testId')}
    >
      <>
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          onChange={handleUpload}
          multiple={multiple}
          disabled={isUploading || disabled}
          {...getTestProps(testId, 'file-input')}
        />
        {label ? label : t('Media.AddFile')}
      </>
    </Button>
  );
};

export default FileButton;

FileButton.propTypes = {
  /**
   * On upload callback
   */
  onUpload: PropTypes.func,
  /**
   * If file selection is multiple
   */
  multiple: PropTypes.bool,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Button label
   */
  label: PropTypes.node,
  /**
   * On blur callback
   */
  onBlur: PropTypes.func,
  /**
   * Additional button classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Button test id
   */
  testId: PropTypes.string,
};

FileButton.defaultProps = {
  onUpload: /* istanbul ignore next */ () => null,
  multiple: false,
  disabled: false,
  label: '',
  onBlur: /* istanbul ignore next */ () => null,
  buttonSize: 'xs',
  additionalClasses: '',
  testId: '',
};
