import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// :: Components
import Dropdown from '../Dropdown/Dropdown';
import Loader from '../Loader/Loader';

const HeaderFilterDatasource = ({
  id,
  value,
  options,
  handleFilters,
  dropdownLeftPosition,
  testId,
  disabled,
  extraOptions,
  filterDatasourceFetch,
}) => {
  const { t } = useTranslation();
  const [currentOptions, setCurrentOptions] = useState(options);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleOnChange = (e) => {
    if (e.target.value) {
      setCurrentOptions([
        { value: e.target.value, label: e.option?.label || e.target.value },
        ...defaultOptions.filter((el) => el.value !== e.target.value),
      ]);
    } else {
      setCurrentOptions(defaultOptions);
    }
    handleFilters?.(id, e);
  };

  const filterCallback = useCallback(
    async (query, options, setIsLoading, currentValue) => {
      setIsLoading(true);

      let resOptions = options;

      if (query !== currentValue && filterDatasourceFetch) {
        const isInitOnEmpty = query === '_initOnEmpty';
        const isInitOnEmptyFirst = isInitOnEmpty && !defaultOptions?.length;

        if (isInitOnEmpty) {
          if (isInitOnEmptyFirst) {
            resOptions = await filterDatasourceFetch();
            if (!resOptions?.length) {
              setIsLoading(false);
              return [];
            }

            // Case: add default options on init fetch
            setDefaultOptions(resOptions);
          } else if (!value) {
            // Case: show default options instead another fetch
            resOptions = defaultOptions;
          }
        } else if (!isInitOnEmpty) {
          resOptions = await filterDatasourceFetch(query);
        }
      }

      setIsLoading(false);

      return resOptions || [];
    },
    [defaultOptions, filterDatasourceFetch, value],
  );
  return (
    <Dropdown
      testId={testId}
      onChange={handleOnChange}
      name={id}
      options={currentOptions}
      value={value}
      nullable={true}
      loadingIcon={
        <div className="h-10 overflow-hidden flex justify-center items-center">
          <Loader type="spinner-grid" size="tiny" />
        </div>
      }
      renderEmpty={() => (
        <div className="p-2 text-slate-400/80 text-sm text-center">
          {t('Global.NoData')}
        </div>
      )}
      emptyOptions={
        <div className="p-2 text-slate-400/80 text-sm text-center">
          {t('Global.NoData')}
        </div>
      }
      filterCallback={filterCallback}
      filterCallbackOnEmpty={true}
      disabled={disabled}
      debounceTime={500}
      additionalContainerClasses={'h-10'}
      additionalClasses={'relative w-full flex flex-col'}
      additionalDropdownClasses={`w-auto min-w-full top-12 ${
        dropdownLeftPosition ? 'left-0' : 'right-0'
      }`}
      extraOptions={extraOptions}
    />
  );
};

export default HeaderFilterDatasource;

HeaderFilterDatasource.propTypes = {
  /**
   * HeaderFilterDatasource id
   */
  id: PropTypes.string,
  /**
   * HeaderFilterDatasource handle filter function
   */
  handleFilters: PropTypes.func,
  /**
   * HeaderFilterDatasource value
   */
  value: PropTypes.string,
  /**
   * HeaderFilterDatasource position of the dropdown if is going from left
   */
  dropdownLeftPosition: PropTypes.bool,
  /**
   * Extra filter options
   */
  extraOptions: PropTypes.array,
  /**
   * HeaderFilterDatasource data test id
   */
  testId: PropTypes.string,
  /**
   * HeaderFilterDatasource validation
   */
  validation: PropTypes.shape({
    relationContenttype: PropTypes.string,
    relationMultiple: PropTypes.bool,
  }),
  /**
   * HeaderFilterDatasource callback to get filtered data from
   */
  filterDatasourceFetch: PropTypes.func,
};

HeaderFilterDatasource.defaultProps = {
  id: '',
  handleFilters: undefined,
  value: '',
  dropdownLeftPosition: false,
  testId: '',
  options: [],
  validation: {},
};
