import { useCallback, useContext, useMemo, useReducer } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './permissions-table.css';

// :: Images
import {
  CaretRightIcon,
  DeleteIcon,
  GithubColorIcon,
  GithubColorIconWhite,
  InformationSignIcon,
  PlusIcon,
  WarningTriangleRedIcon,
} from '../../../images/shapes';

// :: Hooks
import useDarkMode from '../../../hooks/useDarkMode';

// :: Context
import UserContext from '../../../contexts/UserContext';

// :: Lib
import { formatErrorToString, getTestProps } from '../../../lib/helpers';
import FlotiqPlugins from '../../../lib/flotiq-plugins/flotiqPluginsRegistry';

// :: Components
import Button from '../../../components/Button/Button';
import Switch from '../../../components/Switch/Switch';
import Tooltip from '../../../components/Tooltip/Tooltip';

const PluginItem = ({
  plugin,
  manifest,
  openManageModal,
  enablePlugin,
  newPluginVersion,
  disabled,
  onDelete,
  onUpdate,
  isNew,
  limitReachedInfo,
  testId,
}) => {
  const { t } = useTranslation();
  const [darkMode] = useDarkMode();
  const [opened, open] = useReducer((opened) => {
    return !opened;
  }, false);

  const { permissions } = useContext(UserContext);

  const { canDelete, canUpdate, canCreate } = useMemo(
    () => permissions.getCoPermissions('_plugin_settings') || {},
    [permissions],
  );

  const pluginErrors = useMemo(() => {
    const errors = FlotiqPlugins.getPluginErrors(plugin.id);
    return errors ? formatErrorToString(errors) : '';
  }, [plugin.id]);

  const handleDelete = useCallback(() => {
    onDelete(plugin);
  }, [onDelete, plugin]);

  const handleUpdate = useCallback(() => {
    onUpdate(plugin, newPluginVersion);
  }, [newPluginVersion, onUpdate, plugin]);

  const hasManageEvent = FlotiqPlugins.hasPluginOneOfEvents(plugin.id, [
    'flotiq.plugins.manage::render',
    'flotiq.plugins.manage::form-schema',
  ]);

  const enableSwitch = useMemo(() => {
    const blocked = !plugin.enabled && !!limitReachedInfo;

    const switchButton = (
      <Switch
        name={plugin.name + '-enabled'}
        checked={plugin.enabled || false}
        onChange={async (e) =>
          await enablePlugin(plugin, false, e.target.value)
        }
        disabled={!canUpdate || blocked || disabled}
        additionalClasses="justify-items-start"
        {...getTestProps(testId, `enabled`, 'testId')}
      />
    );

    if (!canUpdate)
      return (
        <Tooltip
          tooltip={t('Global.NoAccess')}
          additionalClasses="inline-block font-normal"
          tooltipPlacement="topRight"
        >
          {switchButton}
        </Tooltip>
      );

    if (blocked)
      return (
        <Tooltip
          tooltip={limitReachedInfo}
          additionalClasses="inline-block font-normal"
          tooltipPlacement="topRight"
        >
          {switchButton}
        </Tooltip>
      );
    return switchButton;
  }, [canUpdate, disabled, enablePlugin, limitReachedInfo, plugin, t, testId]);

  const permissionsBlock = useMemo(() => {
    if (!opened) return null;
    return (
      <div className="mt-1 md:mt-3 border-t">
        <p className="mt-1 md:mt-3 mb-2 md:ml-3 dark:text-white">
          {t('Plugins.UI.AccessDescription')}
        </p>
        <table
          className="permissions-table overflow-x-overlay scrollbar-sm"
          {...getTestProps(testId, 'permissions')}
        >
          <thead>
            <tr>
              <th>{t('Plugins.UI.AccessType')}</th>
              <th>{t('Plugins.UI.ContentTypeDefinition')}</th>
              <th>{t('Plugins.UI.Operations')}</th>
            </tr>
          </thead>
          <tbody>
            {manifest.permissions.map((permission) => (
              <tr key={permission.ctdName + '|' + permission.type}>
                <td>{t(`Plugins.UI.Type.${permission.type}`)}</td>
                <td>{permission.ctdName || t('Plugins.UI.AllCTD')}</td>
                <td>
                  {['Read', 'Create', 'Update', 'Delete']
                    .filter((perm) => permission[`can${perm}`])
                    .map((perm) => t(`Global.Permission.${perm}`))
                    .join(', ')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }, [manifest.permissions, opened, t, testId]);

  return (
    <div className="rounded-lg bg-white dark:bg-slate-950 p-3 md:p-5 my-2">
      <div
        key={plugin.name}
        className={twMerge(
          'flex flex-col sm:flex-row sm:items-center justify-between gap-2',
        )}
      >
        <div className="flex flex-col gap-2">
          <span className="flex gap-2 items-center text-xl font-bold dark:text-white">
            {plugin.name}{' '}
            {plugin.version && (
              <span className="flex gap-2 items-center">
                v{plugin.version}
                {newPluginVersion && (
                  <Tooltip
                    tooltip={t('Plugins.NewVersionTooltip', {
                      currentVersion: plugin.version,
                      newVersion: newPluginVersion.version,
                    })}
                    additionalClasses="font-normal"
                    {...getTestProps(testId, `new-version`, 'testId')}
                  >
                    <Button
                      onClick={handleUpdate}
                      buttonSize="sm"
                      buttonColor="borderless"
                      additionalClasses="px-0 text-slate-400 dark:text-slate-400"
                      iconImage={
                        <InformationSignIcon className="text-blue w-4" />
                      }
                      disabled={!canUpdate}
                      {...getTestProps(testId, `update-plugin`, 'testId')}
                    >
                      {t('Plugins.NewVersionUpdateAction')}
                    </Button>
                  </Tooltip>
                )}
              </span>
            )}
            {pluginErrors && (
              <Tooltip
                tooltip={pluginErrors}
                additionalClasses="font-normal"
                {...getTestProps(testId, `warning`, 'testId')}
              >
                <WarningTriangleRedIcon className="w-4 min-w-4" />
              </Tooltip>
            )}
          </span>
          <span className="dark:text-white">{plugin.description || ''}</span>
          {(manifest.repository || manifest.permissions?.length > 0) && (
            <div className="flex items-between sm:items-start gap-8 mt-1 md:mt-3">
              {manifest.repository && (
                <Button
                  buttonSize="sm"
                  buttonColor="borderless"
                  iconImage={
                    darkMode ? (
                      <GithubColorIconWhite className="w-6" />
                    ) : (
                      <GithubColorIcon className="w-6" />
                    )
                  }
                  iconPosition="start"
                  as="a"
                  target="_blank"
                  rel="noreferrer"
                  href={manifest.repository}
                  additionalClasses="w-fit 2xl:text-sm"
                  additionalIconClasses="mr-1 mb-0.5"
                  noPaddings
                  {...getTestProps(testId, `repository`, 'testId')}
                >
                  {t('Plugins.UI.Details')}
                </Button>
              )}
              {manifest.permissions?.length > 0 && (
                <Button
                  onClick={open}
                  buttonSize="sm"
                  buttonColor="borderless"
                  iconImage={
                    <CaretRightIcon
                      className={twMerge('w-1.5', opened && 'rotate-90')}
                    />
                  }
                  iconPosition="start"
                  additionalClasses="w-fit 2xl:text-sm"
                  additionalIconClasses="mr-0.5"
                  noPaddings
                  {...getTestProps(testId, `open-permissions`, 'testId')}
                >
                  {t('Plugins.UI.DataAccess')}
                </Button>
              )}
            </div>
          )}
          <div className="sm:hidden">{permissionsBlock}</div>
        </div>
        <div className="flex gap-4 mx-auto sm:mx-0 sm:ml-auto items-center">
          {plugin?.enabled && hasManageEvent && canUpdate && (
            <Button
              onClick={() => openManageModal(plugin)}
              buttonSize="sm"
              color="blueBordered"
              additionalClasses="whitespace-nowrap ml-2 w-fit"
              {...getTestProps(testId, `manage`, 'testId')}
            >
              {t('Plugins.Manage')}
            </Button>
          )}
          {!isNew && enableSwitch}
          {isNew && canCreate && (
            <Tooltip
              tooltip={limitReachedInfo || t('Plugins.AddPlugin')}
              additionalClasses="inline-block font-normal"
              tooltipPlacement="topRight"
            >
              <Button
                iconImage={
                  <PlusIcon className="h-3.5 text-indigo-750 dark:text-gray-200 opacity-50 hover:opacity-100" />
                }
                buttonColor="borderless"
                additionalClasses="w-fit"
                onClick={() => enablePlugin(plugin)}
                disabled={!!limitReachedInfo || disabled}
                noPaddings
                {...getTestProps(testId, `add`, 'testId')}
              />
            </Tooltip>
          )}
          {!isNew && onDelete && canDelete && (
            <Tooltip
              tooltip={t('Plugins.DeletePlugin')}
              additionalClasses="inline-block font-normal"
              tooltipPlacement="topRight"
            >
              <Button
                iconImage={
                  <DeleteIcon className="h-3.5 text-indigo-750 dark:text-gray-200 opacity-50 hover:opacity-100" />
                }
                buttonColor="borderless"
                additionalClasses="w-fit"
                onClick={handleDelete}
                disabled={disabled}
                noPaddings
                {...getTestProps(testId, `delete`, 'testId')}
              />
            </Tooltip>
          )}
        </div>
      </div>
      <div className="hidden sm:block">{permissionsBlock}</div>
    </div>
  );
};

export default PluginItem;

PluginItem.propTypes = {
  /**
   * Plugin data
   */
  plugin: PropTypes.object.isRequired,
  /**
   * Plugin manifest data
   */
  manifest: PropTypes.object,
  /**
   * On manage button click callback
   */
  openManageModal: PropTypes.func.isRequired,
  /**
   * On enable switch change callback
   */
  enablePlugin: PropTypes.func.isRequired,
  /**
   * New version from plugins library
   */
  newPluginVersion: PropTypes.object,
  /**
   * If any actions for plugins are not allowed
   */
  disabled: PropTypes.bool,
  /**
   * On plugin delete callback
   */
  onDelete: PropTypes.func,
  /**
   * Called when user wants to update plugin
   */
  onUpdate: PropTypes.func,
  /**
   * If plugin is not added to user plugins yet
   */
  isNew: PropTypes.bool,
  /**
   * Limit reached tooltip
   */
  limitReachedInfo: PropTypes.node,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

PluginItem.defaultProps = {
  manifest: {},
  disabled: false,
  onDelete: /* istanbul ignore next */ () => null,
  onUpdate: /* istanbul ignore next */ () => null,
  isNew: false,
  limitReachedInfo: '',
  testId: '',
};
