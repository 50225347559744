import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs';
import { HouseIcon } from '../../../images/shapes';
import useSelectedSpace from '../../../hooks/useSelectedSpace';
import { TopbarContext } from '../Topbar';

const TopbarBreadcrumbs = ({ title, parentTitle, parentLink }) => {
  const { buildUrlWithSpace } = useSelectedSpace();
  const { pageTitle } = useContext(TopbarContext);

  const breadcrumbs = useMemo(
    () => [
      {
        label: <HouseIcon className="w-3 text-blue" />,
        link: buildUrlWithSpace(''),
        additionalClasses: 'text-slate-400 text-center shrink-0',
        key: 'home',
      },
      ...(parentTitle
        ? [
            {
              label: parentTitle,
              link: parentLink,
              additionalClasses: 'text-slate-400 truncate text-center',
              key: 'parent',
              disabled: !parentLink,
            },
          ]
        : []),
      {
        label: title || pageTitle,
        additionalClasses: 'text-zinc-600 truncate',
        disabled: true,
        key: 'form',
      },
    ],
    [buildUrlWithSpace, parentTitle, parentLink, title, pageTitle],
  );

  return (
    <Breadcrumbs breadcrumbs={breadcrumbs} additionalClasses="p-0 mt-0.5" />
  );
};

export default TopbarBreadcrumbs;

TopbarBreadcrumbs.propTypes = {
  /**
   * Page title
   */
  title: PropTypes.node,
  /**
   * Parent page title
   */
  parentTitle: PropTypes.node,
  /**
   * Link to parent page
   */
  parentLink: PropTypes.string,
};

TopbarBreadcrumbs.defaultProps = {
  title: '',
};
