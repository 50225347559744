import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

// :: Components
import Input from '../../../components/Input/Input';
import Tooltip from '../../../components/Tooltip/Tooltip';
import Button from '../../../components/Button/Button';
import Loader from '../../../components/Loader/Loader';
import PermissionsField from '../../../components/PermissionField/PermissionField';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

// :: Icons
import {
  ReloadIcon,
  CopyIcon,
  BookIcon,
  DeleteIcon,
  QrCodeIcon,
} from '../../../images/shapes';

const ApiKeyItem = ({
  data,
  options,
  optionsFilterCallback,
  removable,
  editableName,
  rules,
  generateSchema,
  onGetSchema,
  onRegenerate,
  onDelete,
  onShowQRCode,
  onSave,
  isLoading,
  testId,
}) => {
  const { t } = useTranslation();
  const { name, apiKey, id, permissions } = data;
  const [currentName, setCurrentName] = useState(name);
  const [currentPermissions, setCurrentPermissions] = useState({
    permissions: permissions,
  });

  useEffect(() => {
    setCurrentPermissions({
      permissions: permissions,
    });
  }, [permissions]);

  const apiOptions = useMemo(() => {
    return [
      {
        id: `${id}-copy`,
        icon: (
          <CopyToClipboard
            text={apiKey}
            onCopy={() => toast.success(t('ApiKeys.CopiedApiKey'))}
            {...getTestProps(testId, 'copy-to-clipboard')}
          >
            <CopyIcon
              className={'h-5 w-5 group-hover:opacity-50 dark:text-gray-150'}
            />
          </CopyToClipboard>
        ),
        label: t('ApiKeys.CopyToClipboard'),
      },
      {
        id: `${id}-schema`,
        icon: (
          <BookIcon
            className={twMerge(
              'h-5 w-5 group-hover:opacity-50 dark:text-gray-150',
              !generateSchema && 'text-red',
            )}
          />
        ),
        onClick: () => onGetSchema(currentName, apiKey, generateSchema),
        tooltip: generateSchema
          ? t('ApiKeys.GetAPIDoc')
          : t('ApiKeys.LimitsSchema'),
        label: t('ApiKeys.GetSchema'),
      },
      {
        id: `${id}-remove`,
        icon: (
          <DeleteIcon
            className={'h-5 w-5 group-hover:opacity-50 dark:text-gray-150'}
          />
        ),
        onClick: () => onDelete(id, currentName),
        tooltip: removable
          ? t('Global.Remove')
          : t('ApiKeys.CannotRemoveApplicationKey'),
        label: t('Global.Remove'),
        disabled: !removable,
      },
      {
        id: `${id}-qr`,
        icon: (
          <QrCodeIcon
            className={'h-5 w-5 group-hover:opacity-50 dark:text-gray-150'}
          />
        ),
        onClick: () => onShowQRCode(currentName, apiKey),
        label: t('ApiKeys.ShowQRCode'),
      },
      {
        id: `${id}-regenerate`,
        icon: (
          <ReloadIcon
            className={'h-5 w-5 group-hover:opacity-50 dark:text-gray-150'}
          />
        ),
        onClick: () => onRegenerate(currentName, id),
        label: t('ApiKeys.RegenerateApiKey'),
      },
    ];
  }, [
    currentName,
    id,
    apiKey,
    onGetSchema,
    onDelete,
    onShowQRCode,
    onRegenerate,
    generateSchema,
    removable,
    t,
    testId,
  ]);

  const ruleColumn = useMemo(() => {
    return ['Read', 'Create', 'Update', 'Delete'].map((perm) => ({
      id: `${id}-can${perm}`,
      name: `can${perm}`,
      label: t(`Global.Permission.${perm}`),
    }));
  }, [id, t]);

  return (
    <Formik
      initialValues={currentPermissions}
      validationSchema={yup.object().shape({
        permissions: yup.array().of(
          yup.object().shape({
            contentTypeDefinition: yup.object().shape({
              id: yup.mixed().required(t('Form.FormErrorNotBlank')),
            }),
          }),
        ),
      })}
      onSubmit={async (values) => {
        onSave?.(id, currentName, apiKey, values.permissions);
      }}
    >
      {({ values }) => (
        <Form>
          <div
            className={twMerge(
              'rounded-lg bg-white dark:bg-slate-950 relative',
              'my-2 border dark:border-0 flex-col items-center',
            )}
            {...getTestProps(testId, 'container')}
          >
            {isLoading && (
              <div
                className={twMerge(
                  'h-full w-full z-10 absolute top-0 left-0',
                  'overflow-hidden flex justify-center items-center',
                )}
                {...getTestProps(testId, 'loader')}
              >
                <Loader type="spinner-grid" size="big" />
              </div>
            )}

            <div
              className={twMerge(
                isLoading && 'opacity-50 selection-none pointer-events-none',
              )}
            >
              <div
                className={
                  'flex flex-col xl:flex-row w-full items-center p-5 xl:border-b dark:border-0'
                }
              >
                {/* Section: Name */}
                <Input
                  value={currentName}
                  onChange={(e) => setCurrentName(e.target.value)}
                  additionalClasses={twMerge(
                    'mr-2 mb-2 xl:mb-0',
                    !editableName && 'cursor-not-allowed',
                  )}
                  additionalInputClasses={twMerge(
                    !editableName && 'pointer-events-none',
                  )}
                  {...getTestProps(testId, 'input-name', 'testId')}
                />
                {/* Section: Api Key */}

                <Input
                  value={apiKey || t('ApiKeys.SaveApiToGenerateToken')}
                  type={'text'}
                  additionalClasses={'mr-2 sm:min-w-[250px]  mb-2 xl:mb-0'}
                  readOnly
                  disabled={!apiKey}
                  notSensitiveToPasswordManager={apiKey ? true : false}
                  additionalInputClasses={'text-sm sm:text-base'}
                  {...getTestProps(testId, `${id}-input-api-key`, 'testId')}
                />
                {apiKey ? (
                  <>
                    <div className="flex flex-row flex-wrap xl:flex-nowrap justify-around">
                      {/* Section: Api Options */}
                      {apiOptions.map((item) => (
                        <Tooltip
                          key={item.id}
                          tooltip={item.tooltip || item.label || ''}
                          tooltipPlacement={'topCenter'}
                          additionalClasses={
                            'group p-2 min-w-[80px] xl:min-w-0 flex justify-center'
                          }
                        >
                          <Button
                            onClick={item.onClick}
                            iconImage={item.icon}
                            buttonColor="borderless"
                            iconPosition="top"
                            additionalClasses={'justify-center'}
                            disabled={item.disabled}
                            noPaddings
                            additionalChildrenClasses={twMerge(
                              'text-sm whitespace-nowrap',
                              'flex xl:hidden dark:text-gray-150',
                            )}
                            {...getTestProps(testId, `${item.id}-option`)}
                          >
                            {item.label}
                          </Button>
                        </Tooltip>
                      ))}
                    </div>
                  </>
                ) : (
                  <div className="flex flex-row flex-wrap xl:flex-nowrap justify-around">
                    <Button
                      onClick={() => onDelete?.(id, name, 'unsaved')}
                      iconImage={
                        <DeleteIcon className={'w-5 h-5 dark:text-gray-150'} />
                      }
                      buttonColor="borderless"
                      iconPosition="top"
                      additionalClasses={'px-0 justify-center'}
                      additionalChildrenClasses={twMerge(
                        'text-sm whitespace-nowrap',
                        'flex xl:hidden',
                      )}
                      {...getTestProps(testId, `${id}-remove-unsaved`)}
                    >
                      {t('Global.Delete')}
                    </Button>
                  </div>
                )}
              </div>

              {rules && (
                <PermissionsField
                  permissions={values?.permissions}
                  ruleColumn={ruleColumn}
                  options={options}
                  optionsFilterCallback={optionsFilterCallback}
                  renderButtons={(push) => (
                    <div className="flex flex-row w-full p-5 border-t dark:border-slate-800">
                      <Button
                        onClick={() =>
                          push({
                            canCreate: false,
                            canRead: false,
                            canUpdate: false,
                            canDelete: false,
                            contentTypeDefinition: { id: '' },
                          })
                        }
                        buttonSize="sm"
                        {...getTestProps(testId, `${id}-button-add-rule`)}
                      >
                        {t('ApiKeys.AddRule')}
                      </Button>

                      <Button
                        type="submit"
                        buttonSize="sm"
                        additionalClasses={'ml-auto'}
                        {...getTestProps(testId, `${id}-button-save`)}
                      >
                        {t('Global.Save')}
                      </Button>
                    </div>
                  )}
                  {...getTestProps(testId, data.id, 'testId')}
                />
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ApiKeyItem;

ApiKeyItem.propTypes = {
  /**
   *  Current options like name, api key, permisionss
   */
  data: PropTypes.shape({
    apiKey: PropTypes.string,
    canCreate: PropTypes.bool,
    canDelete: PropTypes.bool,
    canRead: PropTypes.bool,
    canUpdate: PropTypes.bool,
    global: PropTypes.bool,
    id: PropTypes.string,
    name: PropTypes.string,
    permissions: PropTypes.any,
  }),
  /**
   * If current api key could be removed
   */
  removable: PropTypes.bool,
  /**
   * If input name could be edit
   */
  editableName: PropTypes.bool,
  /**
   *  Show section with rules
   */
  rules: PropTypes.bool,
  /**
   * Remove Api Key handler
   */
  onRemoveApiKey: PropTypes.func,
  /**
   * Options
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  /**
   * Options filter
   */
  optionsFilterCallback: PropTypes.func,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
  /**
   * Allow to generate schema
   */
  generateSchema: PropTypes.bool,
};

ApiKeyItem.defaultProps = {
  testId: '',
  rules: true,
  token: true,
  options: [],
  optionsFilterCallback: undefined,
  data: {},
  removable: false,
  editableName: false,
  onRemoveApiKey: undefined,
  generateSchema: false,
  isLoading: false,
};
