import { createContext } from 'react';
import PropTypes from 'prop-types';

export const ContentTypeFormContext = createContext({});

ContentTypeFormContext.propTypes = {
  value: PropTypes.shape({
    /**
     * Content type before editing
     */
    oldCtd: PropTypes.shape({}),
    /**
     * Function to set form dirty
     */
    setDirty: PropTypes.func,
    /**
     * Is form disabled
     */
    disabled: PropTypes.bool,
    /**
     * If ctd is editing
     */
    isEditing: PropTypes.bool,
    /**
     * On media upload callback
     */
    onMediaUpload: PropTypes.func,
    /**
     * Errors for ctd properties
     */
    propertiesErrors: PropTypes.shape({}),
  }).isRequired,
};

export default ContentTypeFormContext;
