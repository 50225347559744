export const allPreferences = {
  isOverridden: false,
  navigation: {
    favorites: true,
    media: true,
    tags: true,
    content: true,
    definitionBuilder: true,
    webhooks: true,
    plugins: true,
    starters: true,
    apiKeys: true,
    apiDocs: true,
    userRoles: true,
  },
  dashboard: {
    packages_grid: true,
  },
};

export const nonDevPreferences = {
  isOverridden: false,
  navigation: {
    favorites: true,
    media: true,
    tags: true,
    content: true,
    definitionBuilder: true,
    webhooks: false,
    plugins: true,
    starters: false,
    apiKeys: false,
    apiDocs: false,
    userRoles: true,
  },
  dashboard: {
    packages_grid: false,
  },
};

export const isViewOverridden = (userLs) =>
  !!userLs?.data?.config?.viewPreferences?.isOverridden;

export const getUserPreferences = (userLs) => {
  const nonDevUser =
    userLs?.data?.workType && userLs.data.workType !== 'Developer';

  const isOverridden = isViewOverridden(userLs);
  if (isOverridden) return allPreferences;
  return (
    userLs?.data?.config?.viewPreferences ||
    (nonDevUser ? nonDevPreferences : allPreferences)
  );
};

export const hasHiddenData = (userLs) =>
  Object.values(userLs?.data?.config?.viewPreferences?.navigation || {}).filter(
    (show) => !show,
  ).length > 0;
