import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import useLocalStorageState from 'use-local-storage-state';

// :: Form
import RegisterForm from '../../form/RegisterForm/RegisterForm';

// :: Hoc
import Authentication from '../../hoc/Authentication/Authentication';

// :: Images
import { AppSumoLogo } from '../../images/shapes';

// :: Components
import AnnouncementComponent from '../../components/Announcement/AnnouncementComponent';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { postLogin, postRegisterAppSumo } from '../../lib/flotiq-client';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

const RegisterAppSumo = ({ testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toastRef = useRef(null);
  const [isTokenValid, setIsTokenValid] = useState(null);
  const announcement = process.env.REACT_APP_ANNOUNCEMENT.split(',').join(',');
  const [darkMode] = useDarkMode();
  const [, setUser] = useLocalStorageState('cms.user');

  useEffect(() => {
    if (toastRef.current) {
      toast.remove(toastRef.current);
      toastRef.current = null;
    }
    if (isTokenValid === false) {
      toastRef.current = toast.error(t('AppSumo.TokenNotValid'));
    }
  }, [isTokenValid, t]);

  const encodedToken = useMemo(() => {
    const tokenParam = searchParams.get('activationToken');
    if (!tokenParam) {
      setIsTokenValid(false);
      return '';
    }
    try {
      return window.atob(tokenParam);
    } catch {
      setIsTokenValid(false);
      return '';
    }
  }, [searchParams]);

  const activationTokenArray = useMemo(
    () => encodedToken.split(':'),
    [encodedToken],
  );
  const email = useMemo(() => activationTokenArray[0], [activationTokenArray]);
  const token = useMemo(() => activationTokenArray[1], [activationTokenArray]);

  const handleLogin = useCallback(
    async (plainPassword, email) => {
      try {
        const { body, status } = await postLogin(plainPassword, email);
        checkResponseStatus(body, status);
        setUser(body);
        navigate('/');
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message
              ? error.message
              : t('Global.ErrorInvalidLoginOrPassword'),
          );
        }
      }
    },
    [setUser, navigate, t],
  );

  const onSubmit = useCallback(
    async (values) => {
      const userData = { ...values };
      delete userData.permission;
      try {
        const { body, status } = await postRegisterAppSumo(token, userData);
        checkResponseStatus(body, status);
        toast.success(t('AppSumo.CreatedAccount'));
        await handleLogin(values.plainPassword, values.email);
        return null;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return {
            general: t('Form.CommunicationErrorMessage'),
            ...error.errors,
          };
        }
        toast.error(
          error.message ? error.message : t('AppSumo.CoudntCreateAccount'),
        );
        return {
          general: t('AppSumo.CoudntCreateAccount'),
          ...error.errors,
        };
      }
    },
    [t, handleLogin, token],
  );

  return (
    <>
      <Helmet>
        <title>{t('Global.RegisterToFlotiq')} - AppSumo</title>
      </Helmet>
      <AnnouncementComponent
        additionalClasses="h-16"
        announcement={announcement}
      />
      <Authentication
        headerText={t('AppSumo.WelcomeSumoLing')}
        paragraphText={t('Global.AccountAlmostReady')}
        additionalLogo={
          <>
            <p className="text-sm text-gray-500">with</p>
            <Link to="https://appsumo.com" target="_blank" rel="noreferrer">
              <AppSumoLogo
                className="h-5 m-6 dark:invert"
                {...getTestProps(testId, 'appsumo-logo')}
              />
            </Link>
          </>
        }
        logoLink={process.env.REACT_APP_FLOTIQ_PAGE_URL}
        additionalClasses={twMerge(
          announcement && 'pt-16',
          darkMode && 'dark:bg-gray-900',
        )}
        {...getTestProps(testId, 'authentication', 'testId')}
      >
        <RegisterForm
          onSubmit={onSubmit}
          prefilledEmail={email}
          {...getTestProps(testId, 'form', 'testId')}
        />
      </Authentication>
    </>
  );
};

export default RegisterAppSumo;

RegisterAppSumo.propTypes = {
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

RegisterAppSumo.defaultProps = {
  testId: '',
};
