import {
  createContext,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext,
} from 'react';
import { WarningIcon, WarningTriangleRedIcon } from '../images/shapes';
import i18next from '../i18n';
import Loader from '../components/Loader/Loader';
import ModalPanel from '../components/Modal/ModalPanel/ModalPanel';
import Modal from '../components/Modal/Modal';

let modalFnInstance;
export const getModal = () => modalFnInstance;

export const ModalContext = createContext({});
export const ModalInstanceContext = createContext({});

const textRender = {
  warning: (text) => (
    <div className="inline-flex text-red font-bold text-3xl items-center">
      <WarningTriangleRedIcon className="h-5 mr-2.5" />
      {text}
    </div>
  ),
  info: (text) => (
    <div className="inline-flex text-blue font-bold text-3xl items-center">
      <WarningIcon className="h-5 mr-2.5" />
      {text}
    </div>
  ),
  simple: (text) => (
    <div className="inline-flex font-bold text-3xl items-center">{text}</div>
  ),
};

const ModalInstance = ({ modalConfig, onClose }) => {
  const finalButtons = useMemo(
    () =>
      (modalConfig.buttons || []).map((button) => ({
        ...button,
        onClick: (e) => onClose(modalConfig, e, button),
      })),
    [modalConfig, onClose],
  );

  return (
    <ModalPanel
      onClose={(e) => onClose(modalConfig, e)}
      buttons={finalButtons}
      title={modalConfig.title}
      content={modalConfig.content}
      size={modalConfig.size}
      hideClose={modalConfig.hideClose}
      dialogAdditionalClasses={modalConfig.dialogAdditionalClasses}
      contentAdditionalClasses={modalConfig.contentAdditionalClasses}
      testId={modalConfig.testId}
    />
  );
};

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState({});
  const modalPromises = useRef({});

  const cleanupModal = useCallback(async (id) => {
    setModals((oldModals) => {
      const newModals = { ...oldModals };
      delete newModals[id];
      return newModals;
    });
    delete modalPromises.current[id];
  }, []);

  const resolveModal = useCallback(
    async (id, result) => {
      if (modalPromises.current[id]) modalPromises.current[id].resolve(result);
      cleanupModal(id);
    },
    [cleanupModal],
  );

  const modal = useMemo(() => {
    const memoFn = (options) => {
      const {
        id = Date.now(),
        buttons = [],
        title = '',
        content = '',
        size = '',
        hideClose = false,
        dialogAdditionalClasses = '',
        contentAdditionalClasses = '',
        testId = '',
      } = options;

      const modalPromiseData = modalPromises.current[id] || {};

      if (!modalPromiseData.promise) {
        modalPromiseData.promise = new Promise((resolve, reject) => {
          modalPromiseData.resolve = resolve;
          modalPromiseData.reject = reject;
        });

        modalPromises.current[id] = modalPromiseData;
      }

      setModals((oldModals) => ({
        ...oldModals,
        [id]: {
          id,
          buttons: buttons.length ? buttons : oldModals[id]?.buttons,
          title: title || oldModals[id]?.title,
          content: content || oldModals[id]?.content,
          size: size || oldModals[id]?.size,
          hideClose: hideClose || oldModals[id]?.hideClose,
          dialogAdditionalClasses:
            dialogAdditionalClasses || oldModals[id]?.dialogAdditionalClasses,
          contentAdditionalClasses:
            contentAdditionalClasses || oldModals[id]?.contentAdditionalClasses,
          testId: testId || oldModals[id]?.testId,
        },
      }));

      return modalPromiseData.promise;
    };

    memoFn.isOpen = (id) => !!modalPromises.current[id];
    memoFn.close = (id, result = null) => resolveModal(id, result);

    memoFn.confirmation = (
      content,
      text = '',
      trueLabel = '',
      falseLabel = '',
      type = 'warning',
      options = {},
    ) =>
      memoFn({
        title: textRender[type](text || i18next.t('Global.Warning')),
        content,
        buttons: [
          {
            key: 'ok',
            label: trueLabel || i18next.t('Global.Ok'),
            result: true,
          },
          {
            key: 'cancel',
            label: falseLabel || i18next.t('Global.Cancel'),
            result: false,
            color: 'grayBordered',
          },
        ],
        ...options,
      });

    memoFn.delete = (
      content,
      id = null,
      onDeleteClick = () => true,
      onCancelClick = () => false,
      options = {},
    ) =>
      memoFn({
        id,
        title: textRender['warning'](i18next.t('Global.Warning')),
        content,
        buttons: [
          {
            key: 'delete',
            label: i18next.t('Global.Delete'),
            onClick: onDeleteClick,
          },
          {
            key: 'cancel',
            label: i18next.t('Global.Cancel'),
            onClick: onCancelClick,
            color: 'grayBordered',
          },
        ],
        ...options,
      });

    memoFn.deleting = (id, options = {}) =>
      memoFn({
        id,
        buttons: [
          {
            key: 'deleting',
            label: i18next.t('Global.Delete'),
            iconImage: <Loader size="small" type="spinner-grid" />,
            disabled: true,
          },
          {
            key: 'cancel',
            label: i18next.t('Global.Cancel'),
            disabled: true,
          },
        ],
        hideClose: true,
        ...options,
      });

    memoFn.info = (text, content, id = null, options = {}) =>
      memoFn({
        id,
        title: textRender['info'](text),
        content,
        buttons: [
          {
            key: 'ok',
            label: i18next.t('Global.Ok'),
            result: true,
          },
        ],
        ...options,
      });

    modalFnInstance = memoFn;
    return memoFn;
  }, [resolveModal]);

  const onClose = useCallback(
    async (modalConfig, event, button = null) => {
      try {
        const buttonResult = button ? button.result : null;
        const result = button?.onClick
          ? await button.onClick(event)
          : buttonResult;
        resolveModal(modalConfig.id, result);
      } catch (e) {
        modalPromises.current[modalConfig.id].reject(e);
        cleanupModal(modalConfig.id);
      }
    },
    [resolveModal, cleanupModal],
  );

  const modalProviderValue = useCallback(
    (modalConfig) => ({
      resolve: (value) => resolveModal(modalConfig.id, value),
      promise: modalPromises.current[modalConfig.id]?.promise,
    }),
    [resolveModal],
  );

  return (
    <ModalContext.Provider value={modal}>
      {children}
      <Modal open={Object.values(modals).length > 0}>
        {Object.values(modals).map((modalConfig) => (
          <ModalInstanceContext.Provider
            key={modalConfig.id}
            value={modalProviderValue(modalConfig)}
          >
            <ModalInstance modalConfig={modalConfig} onClose={onClose} />
          </ModalInstanceContext.Provider>
        ))}
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModals = () => useContext(ModalContext);
