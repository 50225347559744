import { useMemo } from 'react';
import { getCtdFeaturedImage } from '../../lib/helpers';
import { useContentObjects, useContentTypes } from '.';

const DEFAULT_OPTIONS = {};

/**
 * Get featured images dictionary where the key is content type name and the value is featured media URL
 *
 * @param {string|Array<string>} contentTypes
 * @param {object} defaultOptions
 * @returns {{
 *    images: Object.<string, string>,
 *    isLoading: boolean,
 *    errors: array | null,
 *    reload: function,
 *  }|[
 *   data: Object.<string, string>,
 *   isLoading: boolean,
 *   errors: array | null,
 *   reload: function,
 * ]}
 */
export const useFeaturedImages = (contentTypes, options = DEFAULT_OPTIONS) => {
  const filteredTypes = useMemo(
    () => contentTypes?.filter((ctd) => ctd) || [],
    [contentTypes],
  );

  const contentTypeStrings = useMemo(
    () => filteredTypes.filter((ctd) => typeof ctd === 'string') || [],
    [filteredTypes],
  );

  const contentTypesByNameOptions = useMemo(
    () => [
      {
        names: contentTypeStrings,
        limit: contentTypeStrings.length,
      },
      {
        ...options,
        pause: options?.pause || !contentTypeStrings.length,
      },
    ],
    [contentTypeStrings, options],
  );

  const {
    data: contentTypesByName,
    isLoading: ctdsLoading,
    errors: ctdsErrors,
    reload,
  } = useContentTypes(...contentTypesByNameOptions);

  const contentTypesDict = useMemo(() => {
    const apiContentTypesDict = (contentTypesByName || []).reduce(
      (acc, ctd) => {
        acc[ctd.name] = ctd;
        return acc;
      },
      {},
    );

    return filteredTypes.reduce((acc, ctd) => {
      const contentTypeData =
        typeof ctd === 'string'
          ? apiContentTypesDict[ctd] || { name: ctd }
          : ctd;

      acc[contentTypeData.name] = {
        contentTypeData,
        featuredMediaId:
          contentTypeData?.featuredImage?.[0]?.dataUrl?.match(/[^/]+$/)?.[0],
      };
      return acc;
    }, {});
  }, [contentTypesByName, filteredTypes]);

  const imageIds = useMemo(
    () =>
      [
        ...new Set(
          Object.values(contentTypesDict || {}).map(
            ({ featuredMediaId }) => featuredMediaId,
          ),
        ),
      ].filter((id) => id),
    [contentTypesDict],
  );

  const mediaParams = useMemo(
    () => [
      {
        ids: imageIds,
        limit: imageIds.length,
      },
      { ...options, pause: options?.pause || !imageIds.length },
    ],
    [imageIds, options],
  );

  const {
    data: mediaByIds,
    isLoading: mediaLoading,
    errors: mediaErrors,
  } = useContentObjects('_media', ...mediaParams);

  const imagesDict = useMemo(
    () =>
      mediaByIds.reduce((acc, media) => {
        acc[media.id] = media;
        return acc;
      }, {}),
    [mediaByIds],
  );

  const images = useMemo(
    () =>
      Object.entries(contentTypesDict).reduce(
        (acc, [contentTypeName, { featuredMediaId }]) => {
          acc[contentTypeName] = getCtdFeaturedImage(
            contentTypeName,
            imagesDict[featuredMediaId],
          );
          return acc;
        },
        {},
      ),
    [contentTypesDict, imagesDict],
  );

  const isLoading =
    (ctdsLoading && contentTypeStrings.length > 0) ||
    (mediaLoading && imageIds.length > 0);

  const errors = useMemo(() => {
    const err = [];
    if (mediaErrors) err.push(mediaErrors);
    if (ctdsErrors) err.push(ctdsErrors);
    return err.length ? err : null;
  }, [ctdsErrors, mediaErrors]);

  const result = [images, isLoading, errors, reload];
  Object.assign(result, {
    images,
    isLoading,
    errors,
    reload,
  });
  return result;
};
