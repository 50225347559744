import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Components
import { SectionUserMenu } from './SectionUserMenu';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import { AvatarIcon, CaretLeftIcon, CaretDownIcon } from '../../images/shapes';

export const SectionToggleSidebar = ({ onClick, isOpen, testId }) => {
  return (
    <div
      className={twMerge(
        'absolute -top-3 sm:top-0 -right-8 left-auto sm:right-auto h-full w-8 sm:w-4 mb-safe sm:mb-0',
        'justify-center items-center cursor-pointer flex z-10 text-white',
        'bg-blue sm:bg-white dark:sm:dark:bg-slate-950 rounded-r-xl sm:rounded-r-2xl',
        isOpen && 'top-0',
      )}
      onClick={onClick}
      {...getTestProps(testId)}
    >
      <CaretLeftIcon
        className={twMerge(
          'h-5 sm:h-3 text-white dark:text-white sm:text-indigo-950',
          'transition-all',
          'ease-linear',
          'duration-normal',
          'hover:opacity-50',
          'select-none',
          !isOpen && 'rotate-180',
        )}
        {...getTestProps(testId, 'icon')}
      />
    </div>
  );
};

export const SectionUserProfile = ({
  avatar,
  name,
  email,
  menuItem,
  onClick,
  isOpen,
  isModalOnClose,
  isModalOnCloseOutside,
  handleSidebarOpen,
  sizeClose,
  height,
  additionalUserContainerClasses,
  additionalUserNameClasses,
  additionalUserEmailClasses,
  additionalUserMenuContainerClasses,
  additionalUserMenuClasses,
  hasEventOnCloseOutside,
  testId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const validWhenModal = isModalOnClose ? true : isOpen;

  useEffect(() => {
    if (!isOpen) {
      setIsModalOpen(false);
    }
  }, [isOpen]);

  const handleOpen = () => {
    if (!isOpen && handleSidebarOpen && !isModalOnCloseOutside) {
      handleSidebarOpen();
    }

    setIsModalOpen((prevState) => !prevState);
  };

  return (
    <div
      className={twMerge(
        'relative',
        'flex',
        'items-center',
        'mb-safe',
        'bg-blue',
        'w-full',
        'transition-all',
        'ease-in-out',
        'duration-normal',
        additionalUserContainerClasses,
      )}
      style={{
        height: height || 64,
        width: !isOpen ? sizeClose : '100%',
      }}
      {...getTestProps(testId)}
    >
      <SectionToggleSidebar
        onClick={onClick}
        isOpen={isOpen}
        {...getTestProps(testId, 'toggle', 'testId')}
      />

      {isModalOpen && menuItem?.length > 0 && (
        <SectionUserMenu
          items={menuItem}
          avatar={avatar}
          name={name}
          email={email}
          handleClose={() => setIsModalOpen(false)}
          isModalOnCloseOutside={isModalOnCloseOutside}
          leftOnOutside={sizeClose}
          isOpen={isOpen}
          additionalUserMenuContainerClasses={
            additionalUserMenuContainerClasses
          }
          additionalUserMenuClasses={additionalUserMenuClasses}
          hasEventOnCloseOutside={hasEventOnCloseOutside}
          {...getTestProps(testId, 'menu', 'testId')}
        />
      )}

      <div
        data-testid={'user-profile-container'}
        title={!isOpen ? name + ' - ' + email : ''}
        className={twMerge(
          'flex items-center w-full h-full pl-0 sm:pl-4',
          validWhenModal && menuItem?.length > 0 && 'cursor-pointer',
        )}
        {...(validWhenModal && { onClick: handleOpen })}
        {...getTestProps(testId, 'container')}
      >
        {avatar && (
          <AvatarIcon
            className={twMerge('w-12 pl-3 flex select-none text-white')}
          />
        )}

        <div
          className={twMerge(
            'relative flex flex-col ml-3 overflow-hidden transition-all ease-linear duration-fast',
            isOpen ? 'w-full' : 'w-0',
          )}
        >
          <div
            title={name?.length > 13 ? name : undefined}
            className={twMerge(
              'text-base text-white whitespace-nowrap relative overflow-hidden text-ellipsis pr-10',
              additionalUserNameClasses,
            )}
            {...getTestProps(testId, 'name')}
          >
            {name}
          </div>
          <CaretDownIcon
            className={twMerge(
              'absolute top-2 right-4 w-3 text-white cursor-pointer transition-all ease-linear duration-normal',
              isModalOpen && 'rotate-180',
              menuItem?.length === 0 && 'hidden',
            )}
            {...getTestProps(testId, 'arrow')}
          />
        </div>
      </div>
    </div>
  );
};

SectionUserProfile.propTypes = {
  /**
   * Images for user avatar
   */
  avatar: PropTypes.any,
  /**
   * User full name
   */
  name: PropTypes.string,
  /**
   * User email
   */
  email: PropTypes.string,
  /**
   * Menu item show on click
   */
  menuItem: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      text: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  /**
   * onClick
   */
  onClick: PropTypes.func,
  /**
   * Mode if is open/close
   */
  isOpen: PropTypes.bool,
  /**
   * Is Modal On Close to defined if we open when is closed
   */
  isModalOnClose: PropTypes.bool,
  /**
   * is Modal On Close Outside will be open and show on right
   */
  isModalOnCloseOutside: PropTypes.bool,
  /**
   * handle Sidebar Open
   */
  handleSidebarOpen: PropTypes.func,
  /**
   * size Close is helper props to defined width of the closed
   */
  sizeClose: PropTypes.number,
  /**
   * Additional User Container Classes
   */
  additionalUserContainerClasses: PropTypes.string,
  /**
   * Additional Username Classes
   */
  additionalUserNameClasses: PropTypes.string,
  /**
   * Additional User Email Classes
   */
  additionalUserEmailClasses: PropTypes.string,
  /**
   * Additional User Menu Container Classes
   */
  additionalUserMenuContainerClasses: PropTypes.string,
  /**
   * Additional User Menu Classes
   */
  additionalUserMenuClasses: PropTypes.string,
  /**
   * Add event to close modal after click outside
   */
  hasEventOnCloseOutside: PropTypes.bool,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

SectionUserProfile.defaultProps = {
  avatar: (
    <AvatarIcon className={twMerge('w-12 pl-3 flex select-none text-white')} />
  ),
  name: '',
  email: '',
  menuItem: [],
  onClick: undefined,
  isOpen: true,
  height: 64,
  isModalOnClose: false,
  isModalOnCloseOutside: false,
  hasEventOnCloseOutside: false,
  handleSidebarOpen: undefined,
  sizeClose: 80,
  additionalUserContainerClasses: '',
  additionalUserNameClasses: '',
  additionalUserEmailClasses: '',
  additionalUserMenuContainerClasses: '',
  additionalUserMenuClasses: '',
  testId: '',
};
