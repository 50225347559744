import { useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import TagManager from 'react-gtm-module';
import { twMerge } from 'tailwind-merge';
import useLocalStorageState from 'use-local-storage-state';

// :: Components
import Loader from '../../components/Loader/Loader';

// :: Lib
import { deleteAccount } from '../../lib/flotiq-client';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import { getMaskedOrganizationName, getTestProps } from '../../lib/helpers';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

const AccountDeletion = ({ testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const requestRef = useRef();
  const [darkMode] = useDarkMode();
  const [, , { removeItem }] = useLocalStorageState('cms.user');

  const fetchDeletion = useCallback(
    async (token) => {
      if (requestRef.current) return;
      requestRef.current = true;
      try {
        const { body, status } = await deleteAccount(null, null, {
          token,
        });
        checkResponseStatus(body, status);
        removeItem();

        TagManager.dataLayer({
          dataLayer: {
            event: 'account_request_delete',
            user_id: body.user_id,
            organization_id: body.organization_id,
            organization_name: getMaskedOrganizationName(
              body.organization_name,
            ),
            space_ids: body.space_ids,
            plan_id: undefined,
            plan_name: undefined,
            space_id: undefined,
          },
        });

        window.dataLayer.push(function () {
          this.reset();
        });

        navigate('/login?deleted=true');
      } catch (e) {
        navigate('/login?deleted=false');
      }
    },
    [navigate, removeItem],
  );

  useEffect(() => {
    if (token) {
      fetchDeletion(token);
    }
  }, [token, fetchDeletion]);

  return (
    <>
      <Helmet>
        <title>{t('Global.RegisterToFlotiq')}</title>
      </Helmet>
      <div
        className={twMerge(
          'w-full h-screen flex items-center justify-center',
          darkMode && 'dark:bg-gray-900',
        )}
      >
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      </div>
    </>
  );
};

AccountDeletion.propTypes = {
  /**
   * Test id for button
   */
  testId: PropTypes.string,
};
AccountDeletion.defaultProps = {
  testId: '',
};

export default AccountDeletion;
