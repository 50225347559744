export class RolePermissions {
  static PERMISSIONS_TYPES = {
    READ: 'canRead',
    CREATE: 'canCreate',
    DELETE: 'canDelete',
    UPDATE: 'canUpdate',
  };

  constructor(userRoles) {
    this.userRoles = userRoles;

    if (userRoles?.length > 0) {
      let currentPermissions = { CO: {}, CTD: {} };

      userRoles.forEach((permission) => {
        const type = permission.type;
        const ctdName = permission.ctdName || '*';

        if (!currentPermissions[type][ctdName]) {
          currentPermissions[type][ctdName] = {
            canCreate: permission.canCreate,
            canDelete: permission.canDelete,
            canRead: permission.canRead,
            canUpdate: permission.canUpdate,
          };
        } else {
          ['canCreate', 'canDelete', 'canRead', 'canUpdate'].forEach((can) => {
            if (!currentPermissions[type][ctdName][can] && permission[can]) {
              currentPermissions[type][ctdName][can] = true;
            }
          });
        }

        if (permission.canRead) {
          if (type === 'CTD') this.visibleCTDs = true;
          else this.visibleCOs = true;
        }
      });

      this.permissions = currentPermissions;
    }
  }

  #checkProperty(
    ctdName = '*',
    permission = RolePermissions.PERMISSIONS_TYPES.READ,
    type = 'CO',
  ) {
    if (!this.permissions) return true;

    return (
      !!this.permissions?.[type]?.['*']?.[permission] ||
      !!this.permissions?.[type]?.[ctdName]?.[permission]
    );
  }

  #checkProperties(ctdName = '*', type = 'CO') {
    return {
      canCreate: this.#checkProperty(
        ctdName,
        RolePermissions.PERMISSIONS_TYPES.CREATE,
        type,
      ),
      canDelete: this.#checkProperty(
        ctdName,
        RolePermissions.PERMISSIONS_TYPES.DELETE,
        type,
      ),
      canRead: this.#checkProperty(
        ctdName,
        RolePermissions.PERMISSIONS_TYPES.READ,
        type,
      ),
      canUpdate: this.#checkProperty(
        ctdName,
        RolePermissions.PERMISSIONS_TYPES.UPDATE,
        type,
      ),
    };
  }

  canCtd(ctdName, permission = RolePermissions.PERMISSIONS_TYPES.READ) {
    if (!this.userRoles) return false;

    return this.#checkProperty(ctdName, permission, 'CTD');
  }

  canCo(ctdName, permission = RolePermissions.PERMISSIONS_TYPES.READ) {
    if (!this.userRoles) return false;

    return this.#checkProperty(ctdName, permission);
  }

  getCtdPermissions(ctdName) {
    if (!this.userRoles)
      return {
        canCreate: false,
        canDelete: false,
        canRead: false,
        canUpdate: false,
      };

    return this.#checkProperties(ctdName, 'CTD');
  }

  getCoPermissions(ctdName) {
    if (!this.userRoles)
      return {
        canCreate: false,
        canDelete: false,
        canRead: false,
        canUpdate: false,
      };

    return this.#checkProperties(ctdName, 'CO');
  }

  ctdsVisible() {
    if (!this.userRoles) return false;

    if (!this.permissions) return true;

    return !!this.visibleCTDs;
  }

  cosVisible() {
    if (!this.userRoles) return false;

    if (!this.permissions) return true;

    return !!this.visibleCOs;
  }
}
