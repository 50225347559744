import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useModals } from '../../contexts/ModalContext';
import { useAllPlans, useSpace } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import SpaceForm from '../../form/SpaceForm/SpaceForm';
import {
  checkResponseStatus,
  ResponseError,
} from '../../lib/flotiq-client/response-errors';
import { getTestProps } from '../../lib/helpers';
import { WarningIcon } from '../../images/shapes';
import UserContext from '../../contexts/UserContext';
import Dropdown from '../../components/Dropdown/Dropdown';
import moment from 'moment';
import Button from '../../components/Button/Button';
import PlanHistoryModalContent from '../../components/PlanHistoryModalContent/PlanHistoryModalContent';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';
import { putSpaceHasPlan } from '../../lib/flotiq-client';
import useToken from '../../hooks/useToken';
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import CancelButton from '../../components/Topbar/buttons/CancelButton';
import SaveButton from '../../components/Topbar/buttons/SaveButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import SaveAndLeaveButton from '../../components/Topbar/buttons/SaveAndLeaveButtons';
import DeleteButton from '../../components/Topbar/buttons/DeleteButton';

const AddSpace = ({ testId }) => {
  const jwt = useToken();
  const { t } = useTranslation();
  const modal = useModals();
  const { isRoleAdmin } = useContext(UserContext);
  const navigateOnSave = useRef();

  const { navigateGrid, gridLink } = useGridNavigate(
    'spaces',
    '/spaces-data-preview',
  );

  const { id: spaceId } = useParams();

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [planId, setPlanId] = useState(null);

  const spaceOptions = useMemo(
    () => ({
      pause: !isRoleAdmin || !spaceId,
    }),
    [isRoleAdmin, spaceId],
  );

  const {
    entity: space,
    isLoading: spaceIsLoading,
    updateEntity: updateSpace,
    deleteEntity: deleteSpace,
    status: spaceStatus,
    errors: spaceErrors,
  } = useSpace(spaceId, null, spaceOptions);

  useApiErrorsToast(spaceErrors);

  const plansParams = useMemo(
    () => ({
      page: 1,
      limit: 1000,
    }),
    [],
  );

  const allPlansOptions = useMemo(
    () => ({
      pause: !isRoleAdmin,
    }),
    [isRoleAdmin],
  );

  const { data: allPlans, errors: allPlansErrors } = useAllPlans(
    plansParams,
    allPlansOptions,
  );

  useApiErrorsToast(allPlansErrors);

  const handleUpdate = useCallback(
    async (data) => {
      setIsSaving(true);
      try {
        const { body, status } = await updateSpace({
          ...data,
          paymentDueDate: data.paymentDueDate
            ? moment(data.paymentDueDate).toISOString()
            : null,
        });

        checkResponseStatus(body, status);
        toast.success(t('Spaces.Form.Updated'));
        if (planId && space.planLimits.id !== planId) {
          const { body: planChangeBody, status: planChangeStatus } =
            await putSpaceHasPlan(jwt, space.id, {
              id: space.id,
              planId: planId,
            });
          checkResponseStatus(planChangeBody, planChangeStatus);
          toast.success(t('Spaces.Form.PlanUpdated'));
        }
        setIsSaving(false);
        return [[data, {}], false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          setIsSaving(false);
          return [[data, {}], true];
        }

        toast.error(error.message || t('Spaces.Form.CouldntUpdate'));
        setIsSaving(false);
        return [[data, error.errors], true];
      }
    },
    [jwt, planId, space, t, updateSpace],
  );

  const handleDeleteSpace = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteSpace(spaceId);
      checkResponseStatus(body, status);
      toast.success(t('Spaces.Form.Deleted'));
      navigateGrid();
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
        return;
      }
      toast.error(t('Spaces.Form.CouldntDelete'));
    }
  }, [deleteSpace, modal, navigateGrid, spaceId, t]);

  const handleDelete = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(t('Spaces.ConfirmDelete'), 'delete-modal', () =>
      handleDeleteSpace(),
    );
    setIsDeleting(false);
  }, [handleDeleteSpace, modal, t]);

  const isFormDisabled = isSaving || isDeleting;

  const showForm = (!spaceIsLoading && space) || !spaceId;

  const noAccess = useMemo(() => {
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          {t('Global.NoAccess')}
        </div>
      </Heading>
    );
  }, [t, testId]);

  const emptyResult = useMemo(() => {
    if (!isRoleAdmin) {
      return noAccess;
    }

    if (showForm) return null;
    if (spaceId && spaceIsLoading) {
      return (
        <Loader
          size="small"
          type="spinner-grid"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      );
    }

    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('Spaces.CouldntFind')}
          />
          {spaceStatus === 404
            ? t('Spaces.Form.CouldntFind', { spaceId: spaceId })
            : t('Form.CommunicationErrorMessage')}
        </div>
      </Heading>
    );
  }, [
    isRoleAdmin,
    noAccess,
    spaceId,
    spaceIsLoading,
    spaceStatus,
    showForm,
    t,
    testId,
  ]);

  const openPlanHistoryModal = useCallback(async () => {
    await modal.info(
      t('PlanHistory.Title'),
      <PlanHistoryModalContent
        spaceId={spaceId}
        {...getTestProps(testId, 'plan-history', 'testId')}
      />,
      'plan-history',
      { size: 'lg' },
    );
  }, [modal, t, spaceId, testId]);

  return (
    <PageLayout
      page="spaces-data-preview"
      title={
        !spaceId
          ? t('Spaces.AddSpace')
          : t('Spaces.EditSpace', { name: space?.name })
      }
      buttonsDisabled={isSaving || isDeleting}
      breadcrumbs={
        <TopbarBreadcrumbs
          parentTitle={t('Global.Spaces')}
          parentLink={gridLink}
        />
      }
      buttons={
        isRoleAdmin ? (
          <>
            <CancelButton link={gridLink} />
            <SaveButton
              form="space-form"
              isLoading={isSaving}
              navigateOnSave={navigateOnSave}
            />
            <TopbarActionMenu>
              <SaveAndLeaveButton
                form="space-form"
                navigateOnSave={navigateOnSave}
              />
              {spaceId && <DeleteButton onClick={handleDelete} />}
            </TopbarActionMenu>
          </>
        ) : null
      }
      testId={testId}
    >
      {showForm && isRoleAdmin ? (
        <div className={predefinedLayoutClasses.withSidebar}>
          <div className={predefinedLayoutClasses.leftColumnWhite}>
            <SpaceForm
              space={space}
              onSubmit={handleUpdate}
              disabled={isFormDisabled}
              navigateOnSave={navigateOnSave}
              testId={testId}
            />
            <div className="px-5 pb-8 md:px-12">
              <Heading
                level={3}
                children={t('Spaces.Plan')}
                additionalClasses="dark:text-white"
              />
              <Dropdown
                name="planId"
                value={space?.planLimits?.id}
                options={allPlans.map((plan) => ({
                  label: plan.name,
                  value: plan.id,
                }))}
                onChange={(event) => {
                  setPlanId(event.target.value);
                }}
              />
            </div>
          </div>
          <div className={predefinedLayoutClasses.rightColumn}>
            {spaceId && (
              <Button
                buttonSize="sm"
                additionalClasses="w-fit"
                type="button"
                onClick={openPlanHistoryModal}
                {...getTestProps(testId, 'open-plan-history', 'testId')}
              >
                {t('PlanHistory.View')}
              </Button>
            )}
            <ContentObjectInformations
              createdAt={space?.createdAt}
              updatedAt={space?.updatedAt}
              testId={testId}
            />
          </div>
        </div>
      ) : (
        <div className={predefinedLayoutClasses.whiteBox}>{emptyResult}</div>
      )}
    </PageLayout>
  );
};
export default AddSpace;

AddSpace.propTypes = {
  /**
   * Test id for add space page
   */
  testId: PropTypes.string,
};

AddSpace.defaultProps = {
  testId: '',
};
