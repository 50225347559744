import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Listbox, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Icons
import { CaretDownIcon } from '../../images/shapes';

/**
 * Component for pagination navigation
 */
const ResultsPerPageDropdown = ({
  additionalClasses,
  results,
  setResultsPerPage,
  value,
  showAbove,
  label,
  additionalButtonClasses,
  disabled,
  testId,
}) => {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value]);

  return (
    <div
      className={twMerge(
        'py-3 flex flex-col items-center justify-center space-y-5',
        disabled &&
          'cursor-not-allowed opacity-50 pointer-events-none text-sm xl:text-base',
        additionalClasses,
      )}
      {...getTestProps(testId, 'wrapper')}
    >
      <div className="font-medium flex flex-row items-center whitespace-nowrap dark:text-white">
        {label}
        <Listbox
          value={currentValue}
          onChange={(val) => {
            setCurrentValue(val);
            setResultsPerPage(val);
          }}
        >
          {({ open }) => (
            <div className="ml-3 relative">
              <Listbox.Button
                className={twMerge(
                  'relative w-full border rounded-md pl-4 pr-6 2xl:pr-10',
                  'py-1 2xl:py-2 cursor-pointer focus:outline-none bg-white dark:bg-slate-950',
                  open
                    ? 'border-blue'
                    : 'border-slate-400/80 dark:border-slate-800',
                  additionalButtonClasses,
                )}
                {...getTestProps(testId, 'button')}
              >
                <span className="block leading-none text-blue-600 dark:text-white text-sm 2xl:text-base font-semibold">
                  {currentValue}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <CaretDownIcon
                    className={twMerge(
                      open ? 'rotate-180 transform' : '',
                      'w-2 2xl:w-3 text-indigo-950 dark:text-white',
                    )}
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className={twMerge(
                  'absolute z-50 w-full bg-white rounded-lg shadow-xl max-h-60 py-1 text-base',
                  'overflow-auto focus:outline-none sm:text-sm',
                  showAbove ? 'mb-2 bottom-full' : 'mt-2 top-full',
                )}
              >
                <Listbox.Options
                  className="absolute mt-1 z-10 w-full bg-white dark:bg-gray-900 rounded-lg
                  text-base overflow-auto focus:outline-none sm:text-sm dark:border dark:border-gray-800"
                >
                  {results.map((result) => (
                    <Listbox.Option
                      key={result}
                      className={({ active }) =>
                        twMerge(
                          active
                            ? 'text-blue-600 font-bold'
                            : 'text-black dark:text-white',
                          'cursor-pointer text-base relative py-1.5 pl-4 pr-8 hover:text-blue-600 hover:font-bold',
                        )
                      }
                      value={result}
                    >
                      {({ selected, active }) => {
                        const backgroundClass = active
                          ? 'text-blue-600 font-bold'
                          : 'text-black';
                        return (
                          <>
                            <span
                              className={twMerge(
                                selected ? 'text-blue-600 font-bold' : '',
                                'block',
                              )}
                            >
                              {result}
                            </span>
                            {selected ? (
                              <span
                                className={twMerge(
                                  'absolute inset-y-0 left-0 ' +
                                    'flex items-center pl-1.5',
                                  backgroundClass,
                                )}
                              />
                            ) : null}
                          </>
                        );
                      }}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>
      </div>
    </div>
  );
};

ResultsPerPageDropdown.propTypes = {
  /**
   * Options open above
   */
  showAbove: PropTypes.bool,
  /**
   * Additional classes for results dropdown
   */
  additionalClasses: PropTypes.string,
  /**
   * Results options
   */
  results: PropTypes.arrayOf(PropTypes.number),
  /**
   * Chosen results count
   */
  setResultsPerPage: PropTypes.func,
  /**
   * Default selected value
   */
  value: PropTypes.number,
  /**
   * Default selected value
   */
  label: PropTypes.string,
  /**
   * Additional classes for button
   */
  additionalButtonClasses: PropTypes.string,
  /**
   * If dropdown is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

ResultsPerPageDropdown.defaultProps = {
  results: [10, 20, 50, 100, 200],
  setResultsPerPage: /* istanbul ignore next */ () => true,
  additionalClasses: '',
  value: 10,
  showAbove: false,
  label: '',
  additionalButtonClasses: '',
  disabled: false,
  testId: '',
};

export default ResultsPerPageDropdown;
