import { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import TagManager from 'react-gtm-module';
import useLocalStorageState from 'use-local-storage-state';
import { useQueryClient } from '@tanstack/react-query';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import CancelButton from '../../components/Topbar/buttons/CancelButton';
import SaveButton from '../../components/Topbar/buttons/SaveButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import DeleteButton from '../../components/Topbar/buttons/DeleteButton';

// :: Hooks
import {
  useAllRolesAssigned,
  useAllUserRoles,
  useSpacesList,
  useUser,
} from '../../hooks/api';
import useToken from '../../hooks/useToken';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useFirstLoading from '../../hooks/useFirstLoading';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';

// :: Contexts
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';

// :: Lib
import { changePasswordRequest } from '../../lib/flotiq-client/api-helpers';
import {
  generateRedirectUrl,
  getMaskedOrganizationName,
  getTestProps,
} from '../../lib/helpers';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { deleteAccountRequest, postUser } from '../../lib/flotiq-client';

// :: Form
import UserForm from '../../form/UserForm/UserForm';
import UserActions from '../../form/UserForm/UserActions/UserActions';

// :: Images
import { WarningIcon } from '../../images/shapes';

// :: Layout
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

const Profile = ({ testId, owner, mode, isSectionToken }) => {
  const { t, i18n } = useTranslation();
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const modal = useModals();
  const { id } = useParams();
  const queryClient = useQueryClient();

  const {
    isAdmin,
    isRoleAdmin,
    userStorage: userDetails,
    baseUserEventData,
  } = useContext(UserContext);
  const [, setUser] = useLocalStorageState('cms.user');
  const { setDirty } = useContext(DirtyHandlerContext);

  const { navigateGrid, gridLink } = useGridNavigate('users', '/users');

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    entity: user,
    updateEntity: updateUser,
    deleteEntity: deleteUser,
    isLoading,
    errors,
  } = useUser(id || userDetails?.data?.id);

  const organizationHookOptions = useMemo(
    () => ({ pause: !isAdmin }),
    [isAdmin],
  );

  const adminOrganizationFilterParam = useMemo(() => {
    if (!isRoleAdmin || !id || mode !== 'edit') return null;
    return {
      organizationId: user?.organization?.id,
    };
  }, [id, isRoleAdmin, mode, user]);

  const { data: spacesData, isLoading: isLoadingSpacesData } = useSpacesList(
    adminOrganizationFilterParam,
    organizationHookOptions,
  );

  const {
    data: allUserRoles,
    isLoading: allUserRolesLoading,
    errors: userRolesErrors,
  } = useAllUserRoles(adminOrganizationFilterParam, organizationHookOptions);

  const {
    data: allAssignedRoles,
    isLoading: allAssignedRolesLoading,
    errors: allAssignedRolesErrors,
  } = useAllRolesAssigned(id || userDetails?.data?.id);

  const firstLoading = useFirstLoading(
    !isLoading &&
      !allAssignedRolesLoading &&
      (!isAdmin || (!isLoadingSpacesData && !allUserRolesLoading)),
    mode + id,
  );

  useApiErrorsToast(errors);
  useApiErrorsToast(userRolesErrors);
  useApiErrorsToast(allAssignedRolesErrors);

  const handleDeleteUser = useCallback(async () => {
    setIsDeleting(true);

    const result = await modal.confirmation(
      t('Users.UserDelete', {
        firstName: user.firstName,
        lastName: user.lastName,
      }),
      t('Global.Warning'),
      t('Global.Remove'),
      t('Global.Cancel'),
    );

    if (result) {
      try {
        const { body, status } = await deleteUser({ id });

        checkResponseStatus(body, status);
        toast.success(t('Users.UserDeleteSuccess', { name: user.email }));

        TagManager.dataLayer({
          dataLayer: {
            event: 'account_leave_org',
            user_id: body.user_id,
            organization_id: body.organization_id,
            organization_name: getMaskedOrganizationName(
              body.organization_name,
            ),
            space_ids: body.space_ids,
            plan_id: undefined,
            plan_name: undefined,
            space_id: undefined,
          },
        });

        TagManager.dataLayer({
          dataLayer: baseUserEventData,
        });

        setDirty(false);
        navigateGrid();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message ? error.message : t('ContentForm.CouldntDelete'),
          );
        }
      }
    }

    setIsDeleting(false);
  }, [
    modal,
    t,
    user,
    deleteUser,
    id,
    baseUserEventData,
    setDirty,
    navigateGrid,
  ]);

  const changeLanguage = useCallback(
    (lang) => {
      if (i18n.language !== lang) {
        i18n.changeLanguage(lang);
      }
    },
    [i18n],
  );

  const handleUserActionsErrors = useCallback(
    async (callback) => {
      try {
        await callback();
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return { global: t('Form.CommunicationErrorMessage') };
        }

        if (
          error.status === 403 &&
          error.message?.includes('members limit in space')
        )
          toast.error(t('UserForm.TeamMembersLimitExceeded'));
        else if (error.status === 403) toast.error(error.message);
        else
          toast.error(
            error.message
              ? t('ContentForm.Errors.TryAgain')
              : t('UserForm.UpdatingError'),
          );

        return error.errors;
      }
    },
    [t],
  );

  const onUserChange = useCallback(
    async (values) => {
      await handleUserActionsErrors(async () => {
        const { body, status } = await updateUser(values);
        checkResponseStatus(body, status);
        toast.success(t('UserForm.UpdatingSuccess'));
        setIsSaving(false);

        if (owner || userDetails?.data?.id === user?.id) {
          changeLanguage(values.language);
        }

        if (owner) {
          const newUserData = { ...userDetails };
          newUserData.data.firstName = values.firstName;
          newUserData.data.lastName = values.lastName;
          newUserData.data.username = values.username;
          newUserData.data.language = values.language;
          setUser(newUserData);
        }

        queryClient.invalidateQueries(['users']);

        return null;
      });
    },
    [
      handleUserActionsErrors,
      updateUser,
      t,
      owner,
      userDetails,
      user?.id,
      queryClient,
      changeLanguage,
      setUser,
    ],
  );

  const onPasswordRequest = useCallback(
    async (email) => {
      const errors = await changePasswordRequest(email, t);
      return errors?.general;
    },
    [t],
  );

  const onDelete = useCallback(
    async (email) => {
      try {
        let { body, status } = await deleteAccountRequest(jwt, space, {
          email,
          redirectUri: generateRedirectUrl('/delete-account-confirm'),
        });
        checkResponseStatus(body, status);
        toast.success(t('UserForm.DeleteLinkSuccess'));
        return null;
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return t('Form.CommunicationErrorMessage');
        }
        const errorMessage = error.errors?.form
          ? t('Form.FormErrorEmailSent')
          : t('UserForm.DeletingError');
        toast.error(errorMessage);
        return errorMessage;
      }
    },
    [jwt, t, space],
  );

  const handleNoData = useMemo(() => {
    if (!(firstLoading || errors)) return null;
    if (firstLoading) return <Loader size="big" type="spinner-grid" />;
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'no-user')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          <div className="h-full flex items-center justify-center">
            {t('UserForm.FetchError')}
          </div>
        </div>
      </Heading>
    );
  }, [errors, firstLoading, t, testId]);

  const onAddUser = useCallback(
    async (values) => {
      return await handleUserActionsErrors(async () => {
        const { body, status } = await postUser(jwt, undefined, {
          ...values,
          username: values.email,
          ...(process.env.REACT_APP_SEND_REGISTRATION_EMAIL.split(',').join(
            ',',
          ) !== 'false'
            ? {}
            : { enabled: true, sendMail: false }),
          redirectUri: generateRedirectUrl('/register/activate'),
        });
        checkResponseStatus(body, status);

        toast.success(t('UserForm.UpdatingSuccess'));

        TagManager.dataLayer({
          dataLayer: {
            event: 'account_join_org',
            user_id: body.user_id,
            organization_id: body.organization_id,
            organization_name: getMaskedOrganizationName(
              body.organization_name,
            ),
            space_ids: body.space_ids,
            space_id: undefined,
            plan_id: undefined,
            plan_name: undefined,
          },
        });

        TagManager.dataLayer({
          dataLayer: baseUserEventData,
        });

        navigateGrid();

        return null;
      });
    },
    [handleUserActionsErrors, jwt, t, baseUserEventData, navigateGrid],
  );

  const validView = owner || isAdmin;

  const handleNoAccess = useMemo(() => {
    if (!owner && !isAdmin) {
      return (
        <Heading
          level={2}
          additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
          {...getTestProps(testId, 'heading', 'testId')}
        >
          <div className="flex flex-col items-center justify-center text-center">
            <WarningIcon className="text-red w-14 md:w-20 mb-3" />
            {t('Global.NoAccess')}
          </div>
        </Heading>
      );
    }
  }, [isAdmin, owner, t, testId]);

  const pageTitle = useMemo(() => {
    if (owner) return t('Global.AccountSettings');
    return id ? t(`Users.Edit`) : t('Users.Add');
  }, [id, owner, t]);

  return (
    <PageLayout
      page="profile"
      title={pageTitle}
      buttonsDisabled={isSaving || isDeleting}
      breadcrumbs={
        <TopbarBreadcrumbs
          {...(!owner
            ? { parentTitle: t('Global.Users'), parentLink: gridLink }
            : {})}
        />
      }
      buttons={
        owner ? null : (
          <>
            <CancelButton link={gridLink} />
            <SaveButton
              label={mode === 'add' ? t('Global.Invite') : t('Global.Save')}
              isLoading={isSaving}
              form="user-form"
            />
            <TopbarActionMenu>
              {isAdmin && id && userDetails?.data?.id !== id && (
                <DeleteButton
                  label={t('Global.Remove')}
                  onClick={handleDeleteUser}
                />
              )}
            </TopbarActionMenu>
          </>
        )
      }
      testId={testId}
    >
      <div className={predefinedLayoutClasses.withSidebar}>
        <div
          className={twMerge(
            predefinedLayoutClasses.leftColumnWhite,
            !validView && 'flex justify-center items-center',
            'p-7 md:py-10 md:px-14',
          )}
        >
          {handleNoAccess}

          {validView && (firstLoading || errors) ? (
            <div className="h-full flex items-center justify-center">
              {handleNoData}
            </div>
          ) : (
            validView && (
              <>
                <UserForm
                  mode={mode}
                  user={mode === 'edit' ? user : {}}
                  onSubmit={mode === 'edit' ? onUserChange : onAddUser}
                  owner={owner}
                  spacesData={spacesData}
                  userRoles={allUserRoles}
                  assignedRoles={allAssignedRoles}
                  testId={testId}
                />

                {mode === 'edit' && user && (
                  <>
                    <div
                      className={
                        'h-0 border border-slate-200 dark:border-slate-800 w-full mt-6 md:mt-10 mb-3 md:mb-8'
                      }
                    />
                    <UserActions
                      user={user}
                      onDelete={onDelete}
                      onPasswordRequest={onPasswordRequest}
                      testId={testId}
                      deletable={owner || userDetails?.data?.id === id}
                      isSectionToken={isSectionToken}
                      isSectionTutorial={JSON.parse(
                        process.env.REACT_APP_ENABLE_TUTORIAL,
                      )}
                    />
                  </>
                )}
              </>
            )
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default Profile;

Profile.propTypes = {
  /**
   * Test id for page
   */
  testId: PropTypes.string,
  /**
   * Show section token
   */
  isSectionToken: PropTypes.bool,
};

Profile.defaultProps = {
  mode: 'edit',
  isSectionToken: false,
  testId: '',
};
