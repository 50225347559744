import { useCallback, useContext } from 'react';
import { useModals } from '../../contexts/ModalContext';
import useToken from '../../hooks/useToken';
import { Trans, useTranslation } from 'react-i18next';
import Loader from '../../components/Loader/Loader';
import FlotiqPlugins from '../../lib/flotiq-plugins/flotiqPluginsRegistry';
import { patchContentObject } from '../../lib/flotiq-client';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import {
  checkResponseStatus,
  ResponseError,
} from '../../lib/flotiq-client/response-errors';
import { sendEventAndClear } from '../../lib/helpers';
import UserContext from '../../contexts/UserContext';
import { WarningTriangleRedIcon } from '../../images/shapes';

const useUpdatePluginVersion = () => {
  const { t } = useTranslation();
  const modal = useModals();
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const { baseUserEventData } = useContext(UserContext);

  const modalId = 'updating-plugin';

  const showInfoModal = useCallback(
    () =>
      modal.info(
        <>
          {t('Plugins.Updating')}
          <Loader type="spinner-grid" additionalClasses="ml-2 w-6" />
        </>,
        t('Plugins.UpdatingInfo'),
        modalId,
        {
          hideClose: true,
          buttons: [
            {
              key: 'cancel',
              label: t('Global.Cancel'),
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        },
      ),
    [modal, t],
  );

  const showErrorModal = useCallback(
    async (error, plugin, newPluginVersion) => {
      await modal({
        id: 'updating-plugin',
        title: (
          <div className="inline-flex text-red font-bold text-3xl items-center">
            <WarningTriangleRedIcon className="h-5 mr-2.5" />
            {t('Plugins.UpdateFailed.Title')}
          </div>
        ),
        content: (
          <div className="space-y-3">
            <p>
              <Trans
                i18nKey={'Plugins.UpdateFailed.Info'}
                values={{
                  pluginName: plugin.name,
                  currentVersion: plugin.version,
                  newVersion: newPluginVersion.version,
                  interpolation: {
                    escapeValue: true,
                  },
                }}
                shouldUnescape
              />
            </p>
            <p>
              {error instanceof ResponseError
                ? t('Plugins.UpdateFailed.ContactUs')
                : t('Plugins.UpdateFailed.ContactCreator')}
            </p>
            {error.message && (
              <p>
                <Trans
                  i18nKey={'Plugins.UpdateFailed.Error'}
                  values={{
                    error: error.message,
                  }}
                  shouldUnescape
                />
              </p>
            )}
          </div>
        ),
        buttons: [
          {
            key: 'ok',
            label: t('Global.Ok'),
          },
        ],
      });
    },
    [modal, t],
  );

  const updatePluginVersion = useCallback(
    async (plugin, newPluginVersion, pluginType) => {
      const doUpdate = await modal.confirmation(
        t('Plugins.UpdatePluginWarning', { pluginName: plugin.name }),
      );
      if (!doUpdate) {
        return;
      }

      showInfoModal();

      await FlotiqPlugins.loadPlugin(plugin.id, newPluginVersion.url);

      try {
        const newSettings = await Promise.all(
          FlotiqPlugins.runScoped('flotiq.plugin::migrate', plugin.id, {
            previousVersion: plugin,
            newVersion: newPluginVersion,
          }),
        );

        const { body, status } = await patchContentObject(jwt, space, {
          contentTypeName: '_plugin_settings',
          id: plugin.id,
          name: newPluginVersion.name,
          enabled: plugin.enabled,
          description: newPluginVersion.description,
          url: newPluginVersion.url,
          version: newPluginVersion.version,
          manifest: JSON.stringify(newPluginVersion),
          ...(newSettings.length > 0
            ? { settings: newSettings[newSettings.length - 1] }
            : {}),
        });

        checkResponseStatus(body, status);

        sendEventAndClear(
          {
            event: 'plugin_updated',
            plugin_id: plugin.id,
            plugin_type: pluginType,
            enabled: plugin.enabled,
          },
          baseUserEventData,
        );

        modal.close(modalId);
      } catch (error) {
        await showErrorModal(error, plugin, newPluginVersion);
      }

      window.location.reload();
    },
    [baseUserEventData, jwt, modal, showErrorModal, showInfoModal, space, t],
  );

  return updatePluginVersion;
};

export default useUpdatePluginVersion;
