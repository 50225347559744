import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../../lib/helpers';

const dotColorClasses = {
  draft: 'bg-sky-200',
  archive: 'bg-red-200',
  review: 'bg-orange-200',
  public: 'bg-lime-200',
  modified: 'bg-yellow-200',
  saved: 'bg-lime-200',
};

const textColorClasses = {
  draft: 'text-sky-700 dark:text-sky-400',
  archive: 'text-red-600 dark:text-red-400',
  review: 'text-orange-600 dark:text-orange-400',
  public: 'text-lime-700 dark:text-lime-500',
  modified: 'text-yellow-600 dark:text-yellow-400',
  saved: 'text-lime-700 dark:text-lime-500',
};

const TopbarStatus = ({ status, label, testId }) => (
  <div
    className={twMerge(
      'flex gap-3 items-center font-bold',
      textColorClasses[status] || 'text-gray-600',
    )}
    {...getTestProps(testId, 'topbar-status')}
  >
    {label}
    <span
      className={twMerge(
        'rounded-full w-[18px] h-[18px]',
        dotColorClasses[status] || 'bg-gray-200',
      )}
    />
  </div>
);

export default TopbarStatus;

TopbarStatus.propTypes = {
  /**
   * Object workflow status
   */
  status: PropTypes.string,
  /**
   * Object workflow status label
   */
  label: PropTypes.node,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

TopbarStatus.defaultProps = {};
