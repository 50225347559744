import { useContext, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';

const DirtyHandler = ({ isDirty }) => {
  const { dirty, isSubmitting, touched } = useFormikContext();
  const { setDirty } = useContext(DirtyHandlerContext);

  const block = useMemo(
    () =>
      isDirty || (dirty && !isSubmitting && Object.keys(touched).length > 0),
    [dirty, isSubmitting, touched, isDirty],
  );

  useEffect(() => {
    setDirty(block);
    return () => {
      setDirty(false);
    };
  }, [block, setDirty]);
};

export default DirtyHandler;

DirtyHandler.propTypes = {
  /**
   * Dirty state used for overriding formik dirty state
   */
  isDirty: PropTypes.bool,
};

DirtyHandler.defaultProps = {};
