import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired when plugin settings are updated.
 * A plugin will **not** receive this event when settings are updated for any other plugin.
 * No result is expected.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.plugin.settings::changed"
 * @type {PluginSettingsChangedEvent}
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name PluginSettingsChangedEvent
 *
 * @property {object} settings Updated settings
 */
export class PluginSettingsChangedEvent extends IFlotiqPluginEvent {
  settings;
}
