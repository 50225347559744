import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../Loader/Loader';
import {
  BoxWithArrowIcon,
  DownloadCircleIcon,
  PencilIcon,
} from '../../../images/shapes';
import { useTranslation } from 'react-i18next';
import TopbarButton from './base/TopbarButton';
import { capitalizeText } from '../../../lib/helpers';
import TopbarActionButton from './base/TopbarActionButton';

const images = {
  public: <DownloadCircleIcon className="w-3 min-w-3 rotate-180" />,
  archive: <BoxWithArrowIcon className="w-3 min-w-3" />,
  review: <PencilIcon className="w-3 min-w-3" />,
};

const color = {
  public: 'green',
  archive: 'red',
  review: 'orange',
};

const TransitionButton = ({
  newStatus,
  onChange,
  isLoading,
  isActionButton,
  ...props
}) => {
  const { t, i18n } = useTranslation();

  const onClick = useCallback(() => {
    onChange(newStatus);
  }, [newStatus, onChange]);

  if (isActionButton) {
    return (
      <TopbarActionButton
        color={color[newStatus] || color.public}
        iconImage={images[newStatus]}
        label={
          i18n.exists(`ObjectStatus.Transition.${newStatus}`)
            ? t(`ObjectStatus.Transition.${newStatus}`)
            : capitalizeText(newStatus)
        }
        onClick={onClick}
        additionalClasses="md:hidden"
        additionalIconClasses="mr-3 ml-0.5"
        {...props}
      />
    );
  }

  return (
    <TopbarButton
      buttonColor={color[newStatus] || color.public}
      label={
        i18n.exists(`ObjectStatus.Transition.${newStatus}`)
          ? t(`ObjectStatus.Transition.${newStatus}`)
          : capitalizeText(newStatus)
      }
      iconImage={
        isLoading ? (
          <Loader size="small" type="spinner-grid" />
        ) : (
          images[newStatus]
        )
      }
      onClick={onClick}
      additionalClasses="hidden md:flex"
      {...props}
    />
  );
};

export default TransitionButton;

TransitionButton.propTypes = {
  /**
   * On transition callback
   */
  onChange: PropTypes.func.isRequired,
  /**
   * Object workflow transition status to
   */
  newStatus: PropTypes.string,
  /**
   * If transiting to new status
   */
  isLoading: PropTypes.bool,
  /**
   * If button is in topbar action menu
   */
  isActionButton: PropTypes.bool,
};

TransitionButton.defaultProps = {
  isLoading: false,
  isActionButton: false,
};
