import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

export const SectionLogo = ({
  logo,
  logoLink,
  height,
  marginBottom,
  marginTop,
  small,
  additionalLogoContainerClasses,
  testId,
}) => {
  return (
    <div
      className={twMerge(
        'overflow-hidden pl-4',
        additionalLogoContainerClasses,
      )}
      style={{
        height: height,
        marginBottom: marginBottom,
        marginTop: marginTop,
      }}
      {...getTestProps(testId, 'container')}
    >
      <div
        className={twMerge(
          'flex items-center overflow-hidden h-full',
          'transition-all ease-linear duration-fast',
          small ? 'w-10' : 'w-[154px]',
        )}
        {...getTestProps(testId, 'wrap')}
      >
        {logoLink ? (
          <Link
            to={logoLink}
            className="cursor-pointer"
            rel="noreferrer"
            {...getTestProps(testId, 'link')}
          >
            {logo}
          </Link>
        ) : (
          <span>{logo}</span>
        )}
      </div>
    </div>
  );
};

SectionLogo.propTypes = {
  /**
        Logo image
    */
  logo: PropTypes.any,
  /**
       Link to redirect on click
    */
  logoLink: PropTypes.string,
  /**
        Height of the container
    */
  height: PropTypes.number,
  /**
       Bottom space for container
    */
  marginBottom: PropTypes.number,
  /**
       Top space for container
    */
  marginTop: PropTypes.number,
  /**
        Show crop version of the logo
    */
  small: PropTypes.bool,
  /**
        Additional Logo Container Class
    */
  additionalLogoContainerClasses: PropTypes.string,
  /**
   * Section Logo test id
   */
  testId: PropTypes.string,
};

SectionLogo.defaultProps = {
  logo: undefined,
  logoLink: undefined,
  height: 73,
  marginBottom: 46,
  marginTop: 0,
  small: false,
  additionalLogoContainerClasses: '',
  testId: '',
};
