import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { ResponseError } from '../../lib/flotiq-client/response-errors';

/**
 * Decides whether or not to show api response error in toasts.
 *
 * @param {Error} error
 */
const useApiErrorsToast = (error) => {
  useEffect(() => {
    const errors = Array.isArray(error) ? error : [error];
    if (!errors?.length) return;
    errors
      .filter((er) => er instanceof ResponseError)
      .map((error) => {
        const toastId = toast.error(error.message);
        return toastId;
      });
  }, [error]);
};

export default useApiErrorsToast;
