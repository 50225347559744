import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Component
import Button from '../../../components/Button/Button';

// :: Helpers
import { getTestProps } from '../../../lib/helpers';

const PluginItem = ({ plugin, order, disabled, onClick, testId }) => {
  const { t } = useTranslation();
  return (
    <div
      className={twMerge(
        'flex flex-col sm:flex-row sm:items-center justify-between',
        'rounded-lg bg-white dark:bg-slate-950 p-3 md:p-5 border my-2 gap-2 dark:border-0',
      )}
      style={{ order: order }}
      {...getTestProps(testId, `container`)}
    >
      <div className="flex flex-col">
        <span
          className="text-xl font-bold mb-2 dark:text-white"
          {...getTestProps(testId, `name`)}
        >
          {plugin?.name}
        </span>
        <span
          className="dark:text-white"
          {...getTestProps(testId, `description`)}
        >
          {plugin?.description}
        </span>
      </div>
      <Button
        buttonSize="sm"
        color="blueBordered"
        additionalClasses="whitespace-nowrap ml-2 w-fit mx-auto sm:mx-0"
        onClick={() => onClick(plugin)}
        disabled={disabled}
        {...getTestProps(testId, `add-button`, 'testId')}
      >
        {t('Plugins.AddPlugin')}
      </Button>
    </div>
  );
};

export default PluginItem;

PluginItem.propTypes = {
  /**
   * Test id for plugin item
   */
  testId: PropTypes.string,
  /**
   * Order position in plugin item
   */
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Disabled button in plugin item
   */
  disabled: PropTypes.bool,
  /**
   * On click callback
   */
  onClick: PropTypes.func,
  /**
   * Plugin item object details
   */
  plugin: PropTypes.shape({
    id: PropTypes.string,
    contentTypeDefinitions: PropTypes.string,
    name: PropTypes.string.isRequired,
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
};

PluginItem.defaultProps = {
  testId: '',
  order: 0,
  disabled: false,
  onClick: undefined,
};
