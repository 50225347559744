import { useCallback } from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

// :: Components
import Heading from '../Heading/Heading';
import Button from '../Button/Button';

// :: Images
import { FlotiqLogo, WarningTriangleRedIcon } from '../../images/shapes';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const ErrorFallback = ({
  error,
  componentStack,
  eventId,
  resetError,
  withFeedback,
  testId,
}) => {
  const { t } = useTranslation();

  const handleReset = useCallback(() => {
    resetError?.();
  }, [resetError]);

  const handleFeedback = useCallback(() => {
    Sentry.showReportDialog({ eventId: eventId });
  }, [eventId]);

  return (
    <div
      className={twMerge(
        'bg-white dark:bg-gray-900 p-4 w-full',
        'flex flex-col justify-center items-center',
      )}
      {...getTestProps(testId, 'container')}
    >
      <Button
        onClick={handleReset}
        buttonColor="borderless"
        {...getTestProps(testId, 'logo', 'testId')}
      >
        <FlotiqLogo className="h-10 mb-4" />
      </Button>

      <Heading
        level={4}
        alignmen={'center'}
        additionalClasses="mb-4 xl:text-4xl dark:text-white"
        {...getTestProps(testId, 'title', 'testId')}
      >
        <div className="flex flex-row justify-center items-center">
          <WarningTriangleRedIcon className="w-6 h-6 mr-4" />
          {t('ErrorBoundary.SomethingWentWrong')}
        </div>
      </Heading>

      <details
        className={twMerge(
          'px-4 py-2 whitespace-pre-wrap mb-4 dark:text-white',
          'max-h-[60vh] overflow-auto border border-gray dark:border-slate-800 rounded-lg scrollbar-sm',
        )}
        open
        {...getTestProps(testId, 'details')}
      >
        {error && error.toString()}
        <br />
        {componentStack}
      </details>

      <div className="flex flex-row justify-center items-center mt-4">
        {withFeedback && (
          <Button
            type="button"
            buttonSize="base"
            additionalClasses="mr-2"
            onClick={handleFeedback}
            {...getTestProps(testId, 'report', 'testId')}
          >
            {t('ErrorBoundary.ReportFeedback')}
          </Button>
        )}

        <Button
          type="button"
          buttonSize="base"
          onClick={handleReset}
          {...getTestProps(testId, 'reset', 'testId')}
        >
          {t('ErrorBoundary.ClickHereToReset')}
        </Button>
      </div>
    </div>
  );
};

export default ErrorFallback;

ErrorFallback.propTypes = {
  /**
   * Error details
   */
  error: PropTypes.object,
  /**
   * Component Stack
   */
  componentStack: PropTypes.string,
  /**
   * Event id
   */
  eventId: PropTypes.string,
  /**
   * Reset error
   */
  resetError: PropTypes.func,
  /**
   * Add feedback button
   */
  withFeedback: PropTypes.bool,
  /**
   * Test id for Error Fallback Componenet
   */
  testId: PropTypes.string,
};

ErrorFallback.defaultProps = {
  error: undefined,
  componentStack: '',
  eventId: '',
  resetError: undefined,
  withFeedback: false,
  testId: '',
};
