import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Components
import Panel from '../Panel/Panel';
import DateContainer from '../DateContainer/DateContainer';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import {
  ClockIcon,
  PencilSolidIcon,
  WorldIcon,
  BoxWithArrowIcon,
} from '../../images/shapes';

const ContentObjectInformations = ({
  createdAt,
  createdAtText,
  updatedAt,
  updatedAtText,
  lastPublishedAt,
  lastPublishedText,
  archivedAt,
  archivedText,
  additionalElements,
  additionalClasses,
  additionalTitleClasses,
  additionalContainerClasses,
  additionalChildrenClasses,
  additionalIconClasses,
  isPinned,
  children,
  testId,
  id,
}) => {
  const { t } = useTranslation();

  return (
    <Panel
      title={t('ContentForm.Information')}
      isCollapsable={false}
      additionalContainerClasses={twMerge(
        'px-4',
        isPinned &&
          'xl:absolute xl:top-0 xl:right-0 xl:z-10 xl:rounded-tr-none xl:rounded-br-none ' +
            'xl:!w-[63px] xl:h-full',
        additionalClasses,
      )}
      additionalTitleClasses={twMerge(
        'text-lg',
        isPinned && 'xl:!mr-0 xl:max-w-[32px] xl:overflow-hidden',
        additionalTitleClasses,
      )}
      additionalChildrenClasses={twMerge(additionalChildrenClasses)}
      open
      id={id}
      {...getTestProps(testId, 'container', 'testId')}
    >
      <div
        className={twMerge(
          'flex flex-col space-y-6',
          isPinned && 'xl:justify-center xl:items-center',
          additionalContainerClasses,
        )}
      >
        {additionalElements}

        <DateContainer
          date={createdAt}
          icon={
            <PencilSolidIcon
              className={twMerge(
                'w-[18px] mr-3',
                isPinned && 'xl:!mr-0',
                additionalIconClasses,
              )}
              {...getTestProps(testId, 'created-at-icon')}
            />
          }
          text={createdAtText || t('TypeDefinitionCard.Created')}
          isCompact={isPinned}
          {...getTestProps(testId, 'data-created-at', 'testId')}
        />

        <DateContainer
          icon={
            <ClockIcon
              className={twMerge(
                'w-[18px] mr-3',
                isPinned && 'xl:!mr-0',
                additionalIconClasses,
              )}
              {...getTestProps(testId, 'updated-at-icon')}
            />
          }
          text={updatedAtText || t('TypeDefinitionCard.Updated')}
          date={updatedAt}
          isCompact={isPinned}
          {...getTestProps(testId, 'data-updated-at', 'testId')}
        />

        {lastPublishedAt && (
          <DateContainer
            icon={
              <WorldIcon
                className={twMerge(
                  'w-[18px] mr-3',
                  isPinned && 'xl:!mr-0',
                  additionalIconClasses,
                )}
                {...getTestProps(testId, 'last-published-at-icon')}
              />
            }
            text={lastPublishedText || t('ObjectStatus.Published')}
            date={lastPublishedAt}
            isCompact={isPinned}
            {...getTestProps(testId, 'data-last-published-at', 'testId')}
          />
        )}

        {archivedAt && (
          <DateContainer
            icon={
              <BoxWithArrowIcon
                className={twMerge(
                  'w-[18px] mr-3',
                  isPinned && 'xl:!mr-0',
                  additionalIconClasses,
                )}
                {...getTestProps(testId, 'last-published-at-icon')}
              />
            }
            text={archivedText || t('ObjectStatus.Archived')}
            date={archivedAt}
            isCompact={isPinned}
            {...getTestProps(testId, 'data-last-published-at', 'testId')}
          />
        )}

        {!isPinned && children && (
          <div {...getTestProps(testId, 'children')}>{children}</div>
        )}
      </div>
    </Panel>
  );
};

export default ContentObjectInformations;

ContentObjectInformations.propTypes = {
  /**
   * Created date of content object
   */
  createdAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    momentPropTypes.momentObj,
  ]),
  /**
   * Updated date of content object
   */
  updatedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    momentPropTypes.momentObj,
  ]),
  /**
   * Created by person first and last name
   */
  createdBy: PropTypes.string,
  /**
   * Last published date of content object
   */
  lastPublishedAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    momentPropTypes.momentObj,
  ]),
  /**
   * Content object information children
   */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  /**
   * Additional elements above id information
   */
  additionalElements: PropTypes.node,
  /**
   * Additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Container additional CSS classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Children additional CSS classes
   */
  additionalChildrenClasses: PropTypes.string,
  /**
   * Icon additional CSS classes
   */
  additionalIconClasses: PropTypes.string,
  /**
   * Created by label
   */
  createdByText: PropTypes.string,
  /**
   * Created at label
   */
  createdAtText: PropTypes.string,
  /**
   * Updated at label
   */
  updatedAtText: PropTypes.any,
  /**
   * Last published label
   */
  lastPublishedText: PropTypes.string,
  /**
   * Test id for information container
   */
  testId: PropTypes.string,
  /**
   * Id for information container
   */
  id: PropTypes.string,
};

ContentObjectInformations.defaultProps = {
  createdAt: '',
  updatedAt: '',
  createdBy: '',
  lastPublishedAt: '',
  createdByText: '',
  createdAtText: '',
  updatedAtText: '',
  lastPublishedText: '',
  additionalClasses: '',
  additionalTitleClasses: '',
  additionalContainerClasses: '',
  testId: '',
  id: '',
};
