import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired when a field is rendered in the object form.
 * Returning anything will embed that result in the form for the field instead of the original control.
 * This event is fired for every field in the form except hidden ones.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.form.field::render"
 * @type {FormRenderFieldEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 *          Null if rendering should be passed to either Flotiq or other plugins.
 *          Renderable result if plugin wants to replace the default renderer.
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name FormRenderFieldEvent
 *
 * @property {string} name Field name
 * @property {*} value Field value
 * @property {object} contentType Content type that includes the field
 * @property {object} initialData Initial data of the content object.
 *    This will be either a new object or the object being edited.
 *
 * @property {object} properties Field properties
 * @property {object} schema Field schema
 *
 * @property {boolean} required If field is required
 * @property {boolean} disabled If field is disabled
 * @property {boolean} isEditing If content object is editing
 *
 * @property {FormikContextType} formik An instance of Formik
 *
 * @property {string} error Field error returned by validation
 * @property {function} onMediaUpload Function to upload media files (mostly for media relations)
 *
 * @property {object} userPlugins User plugins data with settings
 */
export class FormRenderFieldEvent extends IFlotiqPluginEvent {
  name;
  value;
  contentType;

  properties;
  schema;

  isEditing;
  required;
  disabled;

  formik;

  error;
  onMediaUpload;

  initialData;

  userPlugins;
}
