import { createRoot } from 'react-dom/client';
import FlotiqImagePluginBody from './components/FlotiqImagePluginBody';
import FlotiqImagePluginSettings from './components/FlotiqImagePluginSettings';

// Icons
import {
  ArrowLeftRightIcon,
  AddBackgroundIcon,
  AddBorderIcon,
} from '../../../../images/shapes';
import { mediaIcon } from '../../../RichText/icons';

export default class FlotiqImage {
  constructor({ data, api, config, block, readOnly }) {
    this.readOnly = readOnly;

    this.config = config;
    this.block = block;
    this.wrapper = null;
    this.data = {
      url: data.url || '',
      extension: data.extension || '',
      width: data.width || 0,
      height: data.height || 0,
      fileName: data.fileName || '',
      type: data.type || '',
      caption: data.caption || '',
      withBorder: data.withBorder !== undefined ? data.withBorder : false,
      withBackground:
        data.withBackground !== undefined ? data.withBackground : false,
      stretched: data.stretched !== undefined ? data.stretched : false,
    };
    this.api = api;
    this.settings = [
      {
        name: 'withBorder',
        title: 'Add border',
        icon: <AddBorderIcon className="!w-5" />,
      },
      {
        name: 'stretched',
        title: 'Stretch image',
        icon: <ArrowLeftRightIcon className="!w-5" />,
      },
      {
        name: 'withBackground',
        title: 'Add background',
        icon: <AddBackgroundIcon className="!w-5" />,
      },
    ];
  }

  render() {
    if (!this.wrapper) {
      this.wrapper = document.createElement('div');
      this.root = createRoot(this.wrapper);
    }

    const saveData = (data) => {
      this.data = data;
    };

    this.root.render(
      <FlotiqImagePluginBody
        openModal={this.config.openModal}
        saveSelected={saveData}
        data={this.data}
        block={this.block}
        api={this.api}
        disabled={this.readOnly}
      />,
    );
    return this.wrapper;
  }

  renderSettings() {
    const wrapper = document.createElement('div');
    const root = createRoot(wrapper);

    const toggleTune = (tune) => {
      this.data[tune] = !this.data[tune];
      this._acceptTuneView();
      this.render();
    };

    root.render(
      <FlotiqImagePluginSettings
        settings={this.settings}
        api={this.api}
        toggleTune={toggleTune}
        block={this.block}
        tuneData={{
          withBorder: this.data.withBorder,
          withBackground: this.data.withBackground,
          stretched: this.data.stretched,
        }}
      />,
    );
    return wrapper;
  }

  static get toolbox() {
    return {
      title: 'Media (images, videos, files)',
      icon: mediaIcon,
    };
  }

  save(blockContent) {
    const caption = blockContent.querySelector('[contenteditable]');
    this.data.caption = caption ? caption.innerHTML : '';
    return this.data;
  }

  validate(savedData) {
    return savedData.url.trim();
  }

  /**
   * Add specified class corresponds with activated tunes
   * @private
   */
  _acceptTuneView() {
    this.settings.forEach((tune) => {
      if (tune.name === 'stretched') {
        this.api.blocks.stretchBlock(
          this.api.blocks.getCurrentBlockIndex(),
          !!this.data.stretched,
        );
      }
    });
  }

  /**
   * Automatic sanitize config
   */
  static get sanitize() {
    return {
      url: false, // disallow HTML
      caption: {}, // only tags from Inline Toolbar
    };
  }

  static get isReadOnlySupported() {
    return true;
  }
}
