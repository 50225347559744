export const imageExtensions = [
  'gif',
  'png',
  'jpg',
  'jpeg',
  'bmp',
  'webp',
  'svg',
  'ico',
  'cur',
  'tiff',
];

export const videoExtensions = [
  'midi',
  'mp3',
  'webm',
  'ogg',
  'ogv',
  'oga',
  'ogx',
  'spx',
  'wav',
  'mp4',
];

export const documentExtensions = [
  'txt',
  'pdf',
  'json',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'odt',
  'ods',
  'ppt',
  'pptx',
];

export const handleExtenstions = (currentValue, newValue, extensions) => {
  const newValueSet = new Set([...currentValue, ...extensions]);
  const containsAll = newValueSet.size === currentValue.length;

  if (!containsAll) {
    newValue.push(...extensions);
    return newValue;
  } else return newValue.filter((ext) => !extensions.includes(ext));
};

export const parseExtensionFilters = (currentValue) => {
  let newValue = [];

  currentValue.forEach((extension) => {
    switch (extension) {
      case 'images': {
        newValue = handleExtenstions(currentValue, newValue, imageExtensions);
        return;
      }
      case 'videos': {
        newValue = handleExtenstions(currentValue, newValue, videoExtensions);
        return;
      }
      case 'documents': {
        newValue = handleExtenstions(
          currentValue,
          newValue,
          documentExtensions,
        );
        return;
      }
      default: {
        newValue.push(extension);
        return;
      }
    }
  });

  return Array.from(new Set(newValue));
};

export const getExtensionOptions = (currentValue, t) => {
  const allImagesChecked = imageExtensions.every((extension) =>
    currentValue.includes(extension),
  );
  const allVideosChecked = videoExtensions.every((extension) =>
    currentValue.includes(extension),
  );
  const allDocumentsChecked = documentExtensions.every((extension) =>
    currentValue.includes(extension),
  );
  return [
    {
      group: t('Media.ExtentionOptions.Presets'),
      value: 'images',
      label: (
        <div className={allImagesChecked ? 'font-bold ' : ''}>
          {t('Media.ExtentionOptions.AllImages')}
        </div>
      ),
      ignoreCheckmark: true,
      searchString: 'images',
    },
    {
      group: t('Media.ExtentionOptions.Presets'),
      value: 'videos',
      label: (
        <div className={allVideosChecked ? 'font-bold ' : ''}>
          {t('Media.ExtentionOptions.AllVideos')}
        </div>
      ),
      ignoreCheckmark: true,
      searchString: 'videos',
    },
    {
      group: t('Media.ExtentionOptions.Presets'),
      value: 'documents',
      label: (
        <div className={allDocumentsChecked ? 'font-bold ' : ''}>
          {t('Media.ExtentionOptions.AllDocuments')}
        </div>
      ),
      ignoreCheckmark: true,
      searchString: 'documents',
    },
    ...[...imageExtensions, ...videoExtensions, ...documentExtensions].map(
      (extension) => ({
        value: extension,
        label: extension,
      }),
    ),
  ];
};
