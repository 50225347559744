import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { getDefaultImageProperties, getTestProps } from '../../lib/helpers';

const Avatar = ({
  userInitials,
  onClick,
  imageUrl,
  testId,
  additionalClasses,
}) => {
  return (
    <button
      className={twMerge(
        'flex items-center justify-center w-9 h-9 rounded-full text-xl text-white font-semibold',
        'bg-gradient-avatar cursor-default overflow-hidden',
        onClick &&
          '!cursor-pointer ease-in-out duration-[150ms]' +
            ' hover:outline hover:outline-2 hover:outline-orange hover:outline-offset-2',
        additionalClasses,
      )}
      onClick={onClick}
      {...getTestProps(testId, 'avatar-container')}
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          className="object-cover h-full w-full"
          alt={''}
          {...getDefaultImageProperties()}
          {...getTestProps(testId, 'avatar-image')}
        />
      ) : (
        userInitials
      )}
    </button>
  );
};

export default Avatar;

Avatar.propTypes = {
  /**
   * User initials
   */
  userInitials: PropTypes.string,
  /**
   * On click callback
   */
  onClick: PropTypes.func,
  /**
   * Avatar image url
   */
  imageUrl: PropTypes.string,
  /**
   * Additional container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Avatar test id
   */
  testId: PropTypes.string,
};

Avatar.defaultProps = {
  userInitials: '',
  imageUrl: '',
  additionalClasses: '',
  testId: '',
};
