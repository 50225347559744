import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';
import { getTestProps } from '../../lib/helpers';

const colorClasses = {
  blue: 'bg-blue-300 dark:border-blue-300 dark:bg-[#0B1E39] dark:text-blue-300',
  orange:
    'bg-amber-100 dark:border-amber-100 dark:bg-[#41382A] dark:text-amber-100',
  lime: 'bg-lime-100 dark:border-lime-100 dark:bg-[#2E3B1D] dark:text-lime-100',
  red: 'bg-red-300 dark:border-red-300 dark:bg-[#401925] dark:text-red-300',
  green:
    'bg-lime-300 dark:border-lime-300 dark:bg-[#2E3B1D] dark:text-lime-300',
  draft:
    'bg-sky-200 text-sky-700 dark:border-sky-200 dark:text-sky-200 dark:bg-transparent',
  review:
    'bg-orange-200 text-orange-700 dark:border-orange-200 dark:text-orange-200 dark:bg-transparent',
  public:
    'bg-lime-200 text-lime-700 dark:border-lime-200 dark:text-lime-200 dark:bg-transparent',
  archive:
    'bg-red-200 text-red-600 dark:border-red-200 dark:text-red-200 dark:bg-transparent',
  saved:
    'bg-gray text-black dark:text-gray dark:border-gray dark:bg-transparent',
};

const StatusPill = ({
  status,
  color,
  onClick,
  link,
  containerClasses,
  testId,
}) => {
  const Pill = useMemo(() => {
    if (link) return Link;
    if (onClick) return 'button';
    return 'div';
  }, [link, onClick]);

  return (
    <Pill
      className={twMerge(
        `w-fit max-w-full`,
        'rounded-md py-1.5 px-3 h-7 text-sm font-normal text-gray-700 !leading-tight',
        'focus:no-underline active:no-underline hover:no-underline',
        'focus:text-gray-700 active:text-gray-700 dark:border',
        (onClick || link) &&
          'cursor-pointer transition-shadow hover:drop-shadow-md hover:text-gray-700 ',
        colorClasses[color] || colorClasses.blue,
        containerClasses,
      )}
      onClick={onClick}
      {...(link ? { to: link } : {})}
      {...getTestProps(testId, 'container')}
    >
      <span className="align-middle">{status}</span>
    </Pill>
  );
};

StatusPill.propTypes = {
  /**
   * Status of the pill
   */
  status: PropTypes.node.isRequired,
  /**
   * Color of the pill
   */
  color: PropTypes.oneOf([
    'blue',
    'orange',
    'lime',
    'red',
    'green',
    'draft',
    'review',
    'public',
    'archive',
    'saved',
  ]),
  /**
   * On click callback
   */
  onClick: PropTypes.func,
  /**
   * Pill container additional classes
   */
  containerClasses: PropTypes.string,
  /**
   * Pill test id
   */
  testId: PropTypes.string,
};

StatusPill.defaultProps = {
  color: 'blue',
  containerClasses: '',
  testId: '',
};

export default StatusPill;
