import {
  useContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';

// :: Component
import Heading from '../../components/Heading/Heading';
import LinkButton from '../../components/LinkButton/LinkButton';
import Loader from '../../components/Loader/Loader';
import Pagination from '../../components/Pagination/Pagination';
import RelationModalContent from '../../components/RelationModalContent/RelationModalContent';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import Tooltip from '../../components/Tooltip/Tooltip';
import ElementFromPlugin from '../../components/ElementFromPlugin/ElementFromPlugin';
import DataGridControl from '../../components/DataGridControl/DataGridControl';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarButton from '../../components/Topbar/buttons/base/TopbarButton';
import EditObjectContentModal from '../../components/RelationField/EditObjectContentModal/EditObjectContentModal';

// :: Hooks
import useToken from '../../hooks/useToken';
import {
  useConstraints,
  useContentObjects,
  useContentType,
  useWorkflowDefinition,
} from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import { useRelations } from '../../hooks/api/useRelations';
import useActionsColumn from '../../components/DataGrid/useActions';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import usePluginResults from '../../hooks/usePluginResults';
import { useFeaturedImages } from '../../hooks/api/useFeaturedImages';
import { useGridFilters } from '../../components/DataGrid/useGridFilters';

// :: Context
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import {
  getObjectTitle,
  getTestProps,
  updateLocalStorageGridOptions,
  setInitialGridOptions,
  validateErrorMessage,
  capitalizeText,
} from '../../lib/helpers';
import {
  batchDeleteContentObjects,
  getSearchObject,
  listContentTypes,
} from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { RolePermissions } from '../../lib/rolePermissions';

// :: Utils
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';
import DataGridCell, {
  INPUT_TYPES_TO_RENDER,
} from '../../components/DataGrid/DataGridCell/DataGridCell';
import { GridAddElementEvent } from '../../lib/flotiq-plugins/plugin-events/GridAddElementEvent';
import { GridRenderEvent } from '../../lib/flotiq-plugins/plugin-events/GridRenderEvent';

// :: Layout
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

// :: Images
import { ZoomMaleWorkingImage, WarningIcon } from '../../images/shapes';

const DISPLAY_STATE = {
  LOADING_CTD: 0,
  EMPTY_CTD: 1,
  LOADING_DATA: 2,
  DATA: 3,
  EMPTY_FILTER: 4,
};

const ContentTypeObjects = ({
  allowInternals,
  testId,
  definedTypeName,
  definedColumns,
}) => {
  const { t, i18n } = useTranslation();
  const modal = useModals();
  const { permissions, planLimits } = useContext(UserContext);
  const [searchParams] = useSearchParams();

  let { contentTypeName } = useParams();
  if (definedTypeName) {
    contentTypeName = definedTypeName;
  }

  const { entity: ctoCount, reload: reloadCtoCount } =
    useConstraints('cto-count');
  const { entity: webhookCount, reload: reloadWebhookCount } =
    useConstraints('webhooks-count');

  const { space, buildUrlWithSpace } = useSelectedSpace();
  const sortingLocalStorageKey = `cms._sort_content-type-objects-${contentTypeName}`;
  const gridLocalStorageKey = `cms.content-type-objects-grid-state-v3-${contentTypeName}`;
  const dataGridContainer = useRef();

  const [firstLoading, setFirstLoading] = useState(true);
  const [columns, setColumns] = useState([]);
  const [isDeleting, setDeleting] = useState(false);
  const [checkedRows, setCheckedRows] = useState([]);

  const jwt = useToken();
  const [sort, setSort] = useState(
    getLocalStorage(sortingLocalStorageKey, true),
  );
  const [editGrid, setEditGrid] = useState(false);
  const [filterGrid, setFilterGrid] = useState(!!searchParams.get('filters'));
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const isFavorites = searchParams.has('favorites');

  // :: Grid Options
  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(gridLocalStorageKey, true),
  );

  const {
    filters,
    filtersApplied: hasFilters,
    handleFiltersChange,
    page,
    handlePageChange,
    limit,
    handleLimitChange,
    removeAllFilters,
  } = useGridFilters(`objects-${contentTypeName}`, true);

  const [relationsRawData, setRelationsRawData] = useState(null);
  const [relationsCurrentPage, setRelationsCurrentPage] = useState(1);

  const relationsParams = useMemo(
    () => ({
      page: relationsCurrentPage,
      limit: 10,
    }),
    [relationsCurrentPage],
  );

  const {
    data: relationsDict,
    errors: relationsErrors,
    isLoading: relationsAreLoading,
    pagination: relationsPagination,
  } = useRelations(relationsRawData, relationsParams);

  const contentTypes = useMemo(
    () =>
      relationsAreLoading || !relationsDict
        ? []
        : Object.values(relationsDict).map(({ contentType }) => contentType),
    [relationsAreLoading, relationsDict],
  );

  const [featuredImages, featuredImagesLoading] =
    useFeaturedImages(contentTypes);

  const handleRelationsPageChange = useCallback((page) => {
    setRelationsCurrentPage(page);
  }, []);

  const showRelationsModal = useCallback(async () => {
    await modal({
      id: 'relation-modal',
      content: (
        <>
          <RelationModalContent
            isLoading={relationsAreLoading}
            relationsData={relationsDict}
            featuredImages={featuredImages}
            featuredImagesLoading={featuredImagesLoading}
            testId={testId}
          />
          {relationsPagination?.total_pages > 1 && (
            <Pagination
              page={relationsCurrentPage}
              numOfPages={relationsPagination?.total_pages}
              onPageChange={handleRelationsPageChange}
              additionalClasses="!p-0 mt-4"
              {...getTestProps(testId, 'relation-pagination', 'testId')}
            />
          )}
        </>
      ),
      buttons: [
        {
          label: t('Global.Close'),
          key: 'Close',
        },
      ],
      size: 'lg',
      modalDialogAdditionalClasses: 'max-h-[calc(100vh-50px)]',
      ...getTestProps(testId, 'relations-modal', 'testId'),
    });
    setRelationsRawData(null);
  }, [
    modal,
    relationsAreLoading,
    relationsDict,
    featuredImages,
    featuredImagesLoading,
    testId,
    relationsPagination?.total_pages,
    relationsCurrentPage,
    handleRelationsPageChange,
    t,
  ]);

  useEffect(() => {
    if (relationsDict) {
      showRelationsModal();
    } else {
      setRelationsCurrentPage(1);
    }
  }, [relationsDict, showRelationsModal]);

  useEffect(() => {
    setSort(getLocalStorage(sortingLocalStorageKey, true));
  }, [sortingLocalStorageKey]);

  useEffect(() => {
    setGridOptions(getLocalStorage(gridLocalStorageKey, true));
  }, [gridLocalStorageKey]);

  const clearPage = useCallback(() => {
    setCheckedRows([]);
    setEditGrid(false);
    setInitDataHasContent(false);
  }, []);

  const objectParams = useMemo(() => {
    const urlFilters = searchParams.get('filters');

    return {
      page,
      limit,

      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),

      ...(urlFilters ? { filters: urlFilters } : {}),
    };
  }, [searchParams, page, limit, sort?.sortOrder, sort?.sortField]);

  const {
    entity: contentType,
    errors: contentTypeErrors,
    status: contentTypeStatus,
  } = useContentType(contentTypeName);

  const { data, errors, isLoading, pagination, reload } = useContentObjects(
    contentTypeName,
    objectParams,
  );

  const notAllowed = useMemo(() => {
    if (definedTypeName) {
      return allowInternals && !permissions.canCo(definedTypeName);
    }
    return (
      (contentType?.internal && contentTypeName !== '_tag') ||
      !permissions.canCo(contentTypeName)
    );
  }, [
    allowInternals,
    contentType?.internal,
    contentTypeName,
    definedTypeName,
    permissions,
  ]);

  const label = useMemo(() => contentType?.label || '', [contentType]);

  useApiErrorsToast(errors);
  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(relationsErrors);

  useEffect(() => {
    if (pagination?.total_pages < page)
      handlePageChange(pagination.total_pages);
  }, [handlePageChange, page, pagination?.total_pages]);

  const handleGetRelations = useCallback(
    (data) => setRelationsRawData(data),
    [],
  );

  const handleInitialGridOptions = useCallback(
    (cols, minWidth, contentType, gridLocalStorageKey) => {
      setInitialGridOptions(
        cols,
        minWidth,
        dataGridContainer.current.offsetWidth - 100,
        setGridOptions,
        setColumns,
        gridLocalStorageKey,
        contentType,
      );
    },
    [],
  );

  const limitReachedLabel = useMemo(() => {
    let label =
      planLimits?.cto_limit !== -1 && ctoCount?.data >= planLimits?.cto_limit
        ? t('ObjectsOfType.LimitExceeded')
        : '';

    if (definedTypeName === '_webhooks') {
      label =
        planLimits?.webhooks_limit !== -1 &&
        webhookCount?.data >= planLimits?.webhooks_limit
          ? t('Webhooks.LimitExceeded')
          : label;
    }

    return label;
  }, [
    ctoCount?.data,
    definedTypeName,
    planLimits?.cto_limit,
    planLimits?.webhooks_limit,
    t,
    webhookCount?.data,
  ]);

  useEffect(() => {
    clearPage();
  }, [clearPage]);

  const handleFetchDatasource = useCallback(
    async (query, { relationContenttype }) => {
      try {
        const { body: Objects, status: ObjectsStatus } = await getSearchObject(
          jwt,
          space,
          {
            q: query ? `*${query}*` : '*',
            limit: 10,
            order_by: 'internal.updatedAt',
            order_direction: 'asc',
            page: 1,
            'fields[]': 'object_data',
            ...(relationContenttype && {
              'content_type[]': relationContenttype,
            }),
          },
        );

        checkResponseStatus(Objects, ObjectsStatus);

        const ctdsName = [
          ...new Set(Objects.data.map((el) => el.item.internal.contentType)),
        ];

        const ctdDict = {};

        for (const name of ctdsName) {
          const { body: ctd, status } = await listContentTypes(jwt, space, {
            name: name,
          });
          checkResponseStatus(ctd, status);
          ctdDict[name] = ctd;
        }

        return Objects.data.map((el) => ({
          value: el?.item?.internal?.contentType
            ? `/api/v1/content/${el.item.internal.contentType}/${el.item.id}`
            : el.item.id,
          label: getObjectTitle(el.item, ctdDict[el.item.internal.contentType]),
        }));
      } catch (error) {
        toast.error(error.message);
      }
    },
    [jwt, space],
  );

  useEffect(() => {
    // Remove toasts on change content type
    toast.dismiss();

    // Remove toasts on exit
    return () => {
      toast.dismiss();
    };
  }, [contentType, contentTypeName]);

  const handleDeleteObjects = useCallback(
    async (checkedRows) => {
      setDeleting(true);

      // Remove previously added infinity toasts on errors
      toast.dismiss();

      if (checkedRows.length > 10) {
        modal.info(
          t('Global.Deleting'),
          t('Global.DeletingMany'),
          'delete-modal',
        );
      } else {
        modal.deleting('delete-modal');
      }

      try {
        const { body, status } = await batchDeleteContentObjects(
          jwt,
          space,
          Object.assign(checkedRows, {
            contentTypeName,
          }),
        );

        checkResponseStatus(body, status);
        toast.success(
          t('ObjectsOfType.DeletedCount', {
            deletedCount: body.deletedCount,
          }),
        );

        setCheckedRows([]);
        reload?.();
        reloadCtoCount();
        reloadWebhookCount();
        setDeleting(false);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(
            error.message
              ? validateErrorMessage(error.message)
              : t('ContentForm.CouldntDelete'),
            {
              duration: Infinity,
            },
          );
        }
        setCheckedRows([]);
        reload?.();
        setDeleting(false);
      }
    },
    [
      modal,
      t,
      jwt,
      space,
      contentTypeName,
      reload,
      reloadCtoCount,
      reloadWebhookCount,
    ],
  );

  const showDeleteModal = useCallback(
    (ids) => {
      let currentConfirmDeleteText = t('ObjectsOfType.ConfirmDelete', {
        count: ids.length,
      });

      if (definedTypeName === '_webhooks') {
        currentConfirmDeleteText = t('ObjectsOfType.ConfirmDeleteWebhooks', {
          count: ids.length,
        });
      }

      if (contentTypeName === '_tag') {
        currentConfirmDeleteText = t('ObjectsOfType.ConfirmDeleteTags', {
          count: ids.length,
        });
      }

      modal.delete(currentConfirmDeleteText, 'delete-modal', () =>
        handleDeleteObjects(ids),
      );
    },
    [t, definedTypeName, contentTypeName, modal, handleDeleteObjects],
  );

  const showDeleteManyModal = useCallback(() => {
    showDeleteModal(checkedRows);
  }, [checkedRows, showDeleteModal]);

  const showDeleteOneModal = useCallback(
    async (rowData) => {
      showDeleteModal([rowData.id]);
    },
    [showDeleteModal],
  );

  const showMassEditModal = useCallback(async () => {
    await modal({
      title: (
        <div className="text-xl md:text-3xl">
          {t('ObjectsOfType.MassEditTitle', { count: checkedRows.length })}
        </div>
      ),
      content: (
        <EditObjectContentModal
          contentTypeName={contentTypeName}
          isPatchable
          ids={checkedRows}
          {...getTestProps(testId, 'edit', 'testId')}
        />
      ),
      size: '2xl',
      dialogAdditionalClasses: 'max-h-[calc(100vh-32px)]',
    });
    setCheckedRows([]);
    reload?.();
    reloadCtoCount();
    reloadWebhookCount();
  }, [
    checkedRows,
    contentTypeName,
    modal,
    reload,
    reloadCtoCount,
    reloadWebhookCount,
    t,
    testId,
  ]);

  const { entity: workflowDefinition } = useWorkflowDefinition(
    contentType?.workflowId,
  );

  /**
   * @emits FlotiqPlugins."flotiq.grid::render"
   */
  const gridRenderResult = usePluginResults(
    'flotiq.grid::render',
    GridRenderEvent,
    {
      contentTypeName,
      contentType,
      contentObjects: data,
      pagination,
      handlePageChange,
    },
  );

  useEffect(() => {
    if (!contentType) return;
    if (contentTypeName !== contentType.name) return;

    const cols = [];

    const dataColumns = contentType.metaDefinition?.propertiesConfig || {};
    const currentLocalGridOption = getLocalStorage(gridLocalStorageKey, true);
    const gridOptionsByID = gridOptions?.reduce((acc, elem, idx) => {
      acc[elem.colId] = { ...elem, idx };
      return acc;
    }, {});

    // Case: generate state of hide based on currentLocalGridOption
    const hiddenColumnsById = {};
    const orderFromLocalStorage = [];

    for (let key in currentLocalGridOption) {
      hiddenColumnsById[currentLocalGridOption[key].colId] =
        currentLocalGridOption[key].hide;

      orderFromLocalStorage.push(currentLocalGridOption[key].colId);
    }

    let order = definedColumns || contentType.metaDefinition?.order || [];
    order = [
      ...new Set([
        ...(orderFromLocalStorage || []),
        'id',
        ...order,
        ...(contentType.workflowId !== 'generic'
          ? ['internal.workflowState', 'internal.workflowPublishedAt']
          : []),
        ...(typeof contentType.schemaDefinition?.allOf !== 'undefined' &&
        contentType.schemaDefinition.allOf[0]['$ref'] ===
          '#/components/schemas/AbstractContentTypeSchemaDefinition'
          ? ['internal.createdAt', 'internal.updatedAt']
          : []),
      ]),
    ];

    if (orderFromLocalStorage.length) {
      updateLocalStorageGridOptions(order, gridLocalStorageKey, setGridOptions);
    }

    const idColumnOption = {
      accessor: 'id',
      label: 'ID',
      filterInputType: 'text',
      sortable: !editGrid,
      resizable: true,
      removable: true,
      showByDefault: true,
      width: gridOptionsByID?.['id']?.width,
      hide: hiddenColumnsById['id'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="text"
          data={data}
          contentTypeName={contentTypeName}
          accessor="id"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    const states =
      Object.values(workflowDefinition?.places || {}).map((place) => {
        return {
          value: place,
          label: i18n.exists(`ObjectStatus.VersionStatus.${place}`)
            ? t(`ObjectStatus.VersionStatus.${place}`)
            : capitalizeText(place),
        };
      }) || [];

    const workflowStateColumn = {
      accessor: 'internal.workflowState',
      label: 'Status',
      filterInputType: 'select',
      filterInputOptions: states,
      sortable: !editGrid,
      resizable: true,
      removable: true,
      showByDefault: true,
      width: gridOptionsByID?.['internal.workflowState']?.width,
      hide: hiddenColumnsById['internal.workflowState'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="select"
          data={
            i18n.exists(`ObjectStatus.VersionStatus.${data}`)
              ? t(`ObjectStatus.VersionStatus.${data}`)
              : capitalizeText(data)
          }
          onClickCallback={handleGetRelations}
          color={data}
          contentTypeName={contentTypeName}
          accessor="internal.workflowState"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    const workflowPublishedAtColumn = {
      accessor: 'internal.workflowPublishedAt',
      label: t('ObjectStatus.Published'),
      filterInputType: 'select',
      filterInputOptions: [
        {
          value: '-',
          label: t('Global.True'),
        },
      ],
      sortable: !editGrid,
      resizable: true,
      removable: true,
      width: gridOptionsByID?.['internal.workflowPublishedAt']?.width,
      hide: hiddenColumnsById['internal.workflowPublishedAt'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="datePublished"
          data={data}
          onClickCallback={handleGetRelations}
          contentTypeName={contentTypeName}
          accessor="internal.workflowPublishedAt"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    const createdAtColumn = {
      accessor: 'internal.createdAt',
      label: t('ObjectsOfType.Created'),
      filterInputType: 'text',
      sortable: !editGrid,
      resizable: true,
      removable: true,
      width: gridOptionsByID?.['internal.createdAt']?.width,
      hide: hiddenColumnsById['internal.createdAt'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="text"
          data={data}
          contentTypeName={contentTypeName}
          accessor="internal.createdAt"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    const updatedAtColumn = {
      accessor: 'internal.updatedAt',
      label: t('ObjectsOfType.Modified'),
      filterInputType: 'text',
      sortable: !editGrid,
      resizable: true,
      removable: true,
      width: gridOptionsByID?.['internal.updatedAt']?.width,
      hide: hiddenColumnsById['internal.updatedAt'],
      render: (data, rowData) => (
        <DataGridCell
          inputType="text"
          data={data}
          contentTypeName={contentTypeName}
          accessor="internal.updatedAt"
          contentObject={rowData}
          contentType={contentType}
        />
      ),
    };

    order.forEach((accessor) => {
      if (accessor === 'id') {
        cols.push(idColumnOption);
      } else if (accessor === 'internal.workflowState')
        cols.push(workflowStateColumn);
      else if (accessor === 'internal.workflowPublishedAt')
        cols.push(workflowPublishedAtColumn);
      else if (accessor === 'internal.createdAt') cols.push(createdAtColumn);
      else if (accessor === 'internal.updatedAt') cols.push(updatedAtColumn);
      else if (
        INPUT_TYPES_TO_RENDER.includes(dataColumns[accessor]?.inputType)
      ) {
        const label = dataColumns[accessor]?.label || accessor;
        let currentFilterInputType = dataColumns[accessor]?.inputType;

        if (definedTypeName === '_webhooks' && accessor === 'actions') {
          currentFilterInputType = 'webhookActions';
        }

        cols.push({
          accessor: accessor,
          label,
          filterInputType: currentFilterInputType,
          filterInputOptions:
            dataColumns[accessor]?.options ||
            dataColumns[accessor]?.optionsWithLabels,
          filterValidation: dataColumns[accessor]?.validation,
          filterDatasourceFetch: (query) =>
            handleFetchDatasource(query, dataColumns[accessor]?.validation),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          width: gridOptionsByID?.[accessor]?.width,
          hide: hiddenColumnsById[accessor],
          render: (data, rowData) => {
            let columnData = data;
            if (
              currentFilterInputType === 'select' &&
              dataColumns[accessor].useOptionsWithLabels
            ) {
              columnData =
                dataColumns[accessor].optionsWithLabels?.find(
                  (option) => option.value === data,
                )?.label || data;
            }
            return (
              <DataGridCell
                inputType={currentFilterInputType}
                data={columnData}
                onClickCallback={handleGetRelations}
                items={dataColumns[accessor]?.items}
                contentTypeName={contentTypeName}
                accessor={accessor}
                contentObject={rowData}
                contentType={contentType}
                testId={testId}
              />
            );
          },
        });
      }
    });
    if (gridRenderResult.length !== 0) return;
    if (!gridOptions) {
      handleInitialGridOptions(cols, 100, contentType, gridLocalStorageKey);
    } else {
      setColumns(cols);
    }
  }, [
    contentType,
    handleGetRelations,
    handleFetchDatasource,
    contentTypeName,
    editGrid,
    t,
    handleInitialGridOptions,
    gridOptions,
    gridLocalStorageKey,
    definedColumns,
    showDeleteManyModal,
    showDeleteOneModal,
    workflowDefinition?.places,
    definedTypeName,
    testId,
    permissions,
    gridRenderResult.length,
    i18n,
  ]);

  const actionColumn = useActionsColumn(
    contentTypeName === '_webhooks' ? 'webhooks' : '',
    ['edit'],
    ['edit', 'duplicate', 'delete'],
    contentTypeName,
    false,
    showDeleteOneModal,
    testId,
  );

  const allColumns = useMemo(
    () => [actionColumn, ...columns],
    [columns, actionColumn],
  );

  const handleResetGrid = useCallback(() => {
    setSort();
    setCheckedRows([]);
    removeLocalStorage(`cms._sort_content-type-objects-${contentTypeName}`);
    removeAllFilters();
    removeLocalStorage(gridLocalStorageKey);
    setGridOptions();
  }, [contentTypeName, gridLocalStorageKey, removeAllFilters]);

  useEffect(() => {
    setFirstLoading(true);
  }, [contentTypeName]);

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (!isLoading) setFirstLoading(false);
  }, [isLoading, firstLoading]);

  const handleCheckRows = useCallback((checked) => setCheckedRows(checked), []);

  useEffect(() => {
    if (data.length && !initDataHasContent) {
      setInitDataHasContent(true);
    } else if (data.length === 0 && initDataHasContent) {
      setInitDataHasContent(false);
    }
  }, [data.length, initDataHasContent]);

  const displayLevel = useMemo(() => {
    if (firstLoading) return DISPLAY_STATE.LOADING_CTD;
    if (
      (!data.length && !hasFilters && !initDataHasContent) ||
      contentTypeStatus === 404 ||
      notAllowed
    ) {
      return DISPLAY_STATE.EMPTY_CTD;
    }
    if (isLoading || isDeleting) return DISPLAY_STATE.LOADING_DATA;
    if (!data.length && hasFilters) return DISPLAY_STATE.EMPTY_FILTER;
    return DISPLAY_STATE.DATA;
  }, [
    firstLoading,
    data.length,
    hasFilters,
    initDataHasContent,
    contentTypeStatus,
    notAllowed,
    isLoading,
    isDeleting,
  ]);

  const parseFilters = useCallback(
    (currentFilters) => {
      let res = {};

      for (let key in currentFilters) {
        const { type, filter } = currentFilters[key];

        let currentFilterType = type;

        if (!currentFilterType) {
          currentFilterType =
            definedTypeName === '_webhooks' && key === 'type'
              ? 'equals'
              : 'contains';
        }

        res[key] = { type: currentFilterType, filter };
      }

      return res;
    },
    [definedTypeName],
  );

  const onFilter = useCallback(
    (currentFilters) => {
      handleFiltersChange(currentFilters, parseFilters);
    },
    [handleFiltersChange, parseFilters],
  );

  const emptyData = useMemo(() => {
    if (contentTypeStatus === 404 || notAllowed)
      return (
        <Heading
          level={2}
          additionalClasses={twMerge(
            'text-3xl md:text-4xl leading-8 dark:text-white',
          )}
        >
          <div
            className="flex flex-col items-center justify-center text-center"
            {...getTestProps(testId, 'not-existing')}
          >
            <WarningIcon className="text-red w-14 md:w-20 mb-3" />
            {t('ObjectsOfType.NotExisting', { contentTypeName })}
          </div>
        </Heading>
      );
    if (displayLevel === DISPLAY_STATE.LOADING_CTD)
      return (
        <Loader
          size={'small'}
          type={'spinner-grid'}
          testId={testId ? `${testId}-loading` : ''}
        />
      );

    let buttonAddPath = buildUrlWithSpace(
      `content-type-objects/add/${contentTypeName}`,
    );
    let buttonAddLabel = t('ObjectsOfType.Add');

    if (definedTypeName === '_webhooks') {
      buttonAddPath = buildUrlWithSpace('webhooks/add');
      buttonAddLabel = t('Global.AddNew', { name: 'Webhook' });
    }

    if (contentTypeName === '_tag') {
      buttonAddLabel = t('Global.AddNew', { name: 'Tag' });
    }

    return (
      <>
        <ZoomMaleWorkingImage className="w-52" />
        <div
          className="text-center font-bold p-5"
          {...getTestProps(testId, 'empty-data')}
        >
          <p className="text-blue-600 text-2xl sm:text-3xl md:text-4xl">
            {t('ContentDefinition.CreateFirst')}
          </p>
          <p className="text-indigo-950 dark:text-white 0 text-3xl sm:text-4xl md:text-5xl">
            {t('ObjectsOfType.ObjectOfType', {
              contentTypeName: label,
            })}
          </p>
        </div>
        <LinkButton
          buttonSize="base"
          additionalClasses="mt-1 md:mt-2"
          link={buttonAddPath}
          disabled={!!limitReachedLabel}
          {...getTestProps(testId, 'add', 'testId')}
        >
          {limitReachedLabel ? (
            <Tooltip tooltip={limitReachedLabel}>{buttonAddLabel}</Tooltip>
          ) : (
            buttonAddLabel
          )}
        </LinkButton>
      </>
    );
  }, [
    contentTypeStatus,
    notAllowed,
    testId,
    t,
    contentTypeName,
    displayLevel,
    buildUrlWithSpace,
    definedTypeName,
    label,
    limitReachedLabel,
  ]);

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-plugins::render"
   */
  const beforeGridResults = usePluginResults(
    'flotiq.grid::add',
    GridAddElementEvent,
    {
      contentTypeName,
      contentType,
      contentObjects: data,
      pagination,
      handlePageChange,
      reload,
    },
  );

  const hasBeforeGridPlugin =
    displayLevel >= DISPLAY_STATE.EMPTY_CTD && beforeGridResults?.length > 0;

  const appContextData = useMemo(() => {
    if (definedTypeName === '_webhooks') {
      return {
        page: 'webhooks',
        menuItemOpen: '',
      };
    }
    if (contentTypeName === '_tag') {
      return {
        page: 'tags',
        menuItemOpen: '',
      };
    }

    if (!label) return {};
    return {
      page: isFavorites
        ? `favorites/${label || contentTypeName}-pinned`
        : `content/${label || contentTypeName}`,
      menuItemOpen: isFavorites ? 'favorites' : 'content',
    };
  }, [contentTypeName, definedTypeName, isFavorites, label]);

  const { title, addNew, addNewLink } = useMemo(() => {
    if (definedTypeName === '_webhooks') {
      return {
        title: t('Global.Webhooks'),
        addNew: t('Global.AddNew', { name: 'Webhook' }),
        addNewLink: buildUrlWithSpace('webhooks/add'),
      };
    }
    if (contentTypeName === '_tag') {
      return {
        title: t('Global.Tags'),
        addNew: t('Global.AddNew', { name: 'Tag' }),
        addNewLink: buildUrlWithSpace(
          `content-type-objects/add/${contentTypeName}`,
        ),
      };
    }
    return {
      title: t('ObjectsOfType.Title', { contentTypeName: label }),
      addNew: t('ContentForm.Add', { contentTypeName: label }),
      addNewLink: buildUrlWithSpace(
        `content-type-objects/add/${contentTypeName}`,
      ),
    };
  }, [buildUrlWithSpace, contentTypeName, definedTypeName, label, t]);

  return (
    <PageLayout
      title={title}
      breadcrumbs={<TopbarBreadcrumbs />}
      buttons={
        <>
          {!definedTypeName &&
            contentTypeName !== '_tag' &&
            permissions.canCtd(
              contentTypeName,
              RolePermissions.PERMISSIONS_TYPES.UPDATE,
            ) && (
              <TopbarButton
                label={t('ContentDefinition.Edit')}
                link={buildUrlWithSpace(
                  `content-type-definitions/edit/${contentTypeName}`,
                )}
                buttonColor="blueBordered"
              />
            )}
          {permissions.canCo(
            contentTypeName,
            RolePermissions.PERMISSIONS_TYPES.CREATE,
          ) && (
            <TopbarButton
              label={addNew}
              link={addNewLink}
              {...(limitReachedLabel
                ? {
                    disabled: true,
                    tooltip: limitReachedLabel,
                    tooltipPlacement: 'bottomRight',
                    phoneTooltipPlacement: 'topCenter',
                  }
                : {})}
              {...getTestProps(
                testId,
                `topbar-add-${contentTypeName}`,
                'testId',
              )}
            />
          )}
        </>
      }
      secondaryTopbar={
        <DataGridControl
          displayGridCheckboxes={
            columns.length > 0 && displayLevel >= DISPLAY_STATE.LOADING_DATA
          }
          displayGridControl={
            displayLevel >= DISPLAY_STATE.EMPTY_CTD &&
            displayLevel >= DISPLAY_STATE.LOADING_DATA
          }
          editGrid={editGrid}
          filterGrid={filterGrid}
          setEditGrid={setEditGrid}
          setFilterGrid={setFilterGrid}
          handleResetGrid={handleResetGrid}
          limit={limit}
          handleLimitChange={handleLimitChange}
          columns={columns}
          gridOptions={gridOptions}
          optionsLocalStorageKey={gridLocalStorageKey}
          setGridOptions={setGridOptions}
          columnsVisibilityAdditionalClasses="bg-slate-50 w-fit"
          testId={testId}
        />
      }
      testId={testId}
      noPaddings
      {...appContextData}
    >
      <div className="w-full h-[calc(100vh-200px)] lg:h-[calc(100vh-140px)] px-5 lg:px-7">
        {gridRenderResult.length !== 0 ? (
          <ElementFromPlugin results={gridRenderResult} />
        ) : (
          <div className="flex flex-col h-full w-full">
            {hasBeforeGridPlugin && (
              <ElementFromPlugin results={beforeGridResults} />
            )}
            <div
              className={twMerge(
                predefinedLayoutClasses.dataGridContainer,
                hasBeforeGridPlugin && 'mt-2 lg:mt-2',
              )}
              ref={dataGridContainer}
            >
              {displayLevel >= DISPLAY_STATE.LOADING_DATA ? (
                <CustomizableDataGrid
                  columns={allColumns}
                  setColumns={setColumns}
                  data={data}
                  isLoading={displayLevel === DISPLAY_STATE.LOADING_DATA}
                  setSort={setSort}
                  sort={sort?.sortField || undefined}
                  sortOrder={sort?.sortOrder || undefined}
                  sortingLocalStorageKey={sortingLocalStorageKey}
                  setCurrentPage={handlePageChange}
                  optionsLocalStorageKey={gridLocalStorageKey}
                  editGrid={editGrid}
                  gridOptions={gridOptions}
                  setGridOptions={setGridOptions}
                  checkedRows={checkedRows}
                  onCheckRows={handleCheckRows}
                  statusBar={
                    <StatusBar
                      rows={pagination.count}
                      checkedRows={checkedRows}
                      contentTypeName={contentTypeName}
                      handleDeleteClick={showDeleteManyModal}
                      handleMassEditClick={showMassEditModal}
                      currentPage={page}
                      pagesCount={pagination.total_pages}
                      handlePageChange={handlePageChange}
                      handleDataUpdate={reload}
                      compactForSmall
                      resultsFrom={(pagination.current_page - 1) * limit + 1}
                      resultsTo={
                        (pagination.current_page - 1) * limit + pagination.count
                      }
                      resultsTotalCount={pagination.total_count}
                      disabled={isDeleting}
                      limitReached={limitReachedLabel}
                      {...getTestProps(testId, 'statusbar', 'testId')}
                    />
                  }
                  noDataInfoText={t('ObjectsOfType.NoMatchingData')}
                  loadingDataInfoText={
                    isDeleting
                      ? t('ObjectsOfType.DeletingCount', {
                          deletingCount: checkedRows.length,
                        })
                      : ''
                  }
                  hasFilters={filterGrid}
                  disableFilters={editGrid || isDeleting}
                  filters={filters}
                  onFilter={onFilter}
                  additionalClasses={twMerge(
                    isDeleting && 'opacity-50 pointer-events-none',
                  )}
                  hasSelectColumn
                  contentType={contentType}
                  {...getTestProps(testId, 'grid', 'testId')}
                />
              ) : (
                <div className={predefinedLayoutClasses.whiteBox}>
                  {emptyData}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default ContentTypeObjects;

ContentTypeObjects.propTypes = {
  /**
   * Information if we want to allow internals
   */
  allowInternals: PropTypes.bool,
  /**
   * Defined columns when we want to specify them
   */
  definedColumns: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.any),
    PropTypes.string,
  ]),
  /**
   * Defined content type name
   */
  definedTypeName: PropTypes.string,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

ContentTypeObjects.defaultProps = {
  allowInternals: false,
  definedColumns: '',
  definedTypeName: '',
  testId: '',
};
