import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { TailSpin } from 'react-loader-spinner';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

// :: Components
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';

// :: Images
import { WarningIcon } from '../../images/shapes';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import { emailSchema } from '../../lib/yupHelpers';

const ChangePasswordRequestForm = ({ onSubmit, testId }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validationSchema = yup.object({
    email: emailSchema(t),
  });
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validateOnChange: validateAfterSubmit,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!onSubmit) return null;
      setIsLoading(true);
      const errors = await onSubmit(values.email);
      formik.setStatus({ ...formik.status, errors });
      if (!errors) setIsSending(true);
      setIsLoading(false);
    },
  });

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setValidateAfterSubmit(true);
      formik.handleSubmit();
    },
    [formik],
  );

  return (
    <div>
      {!isSending && (
        <form
          id={'change-password-request-from'}
          className="w-full max-w-xs m-auto"
          onSubmit={handleSubmit}
          noValidate={true}
          {...getTestProps(testId)}
        >
          <div
            className="flex flex-wrap mb-5 w-full"
            {...getTestProps(testId, 'email-container')}
          >
            <Input
              name="email"
              placeholder={t('Global.Email')}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email || formik.status?.errors?.email}
            />
          </div>

          <div className="flex flex-wrap -mx-3 mb-5">
            <div className="w-full flex flex-col justify-center text-center">
              <Button
                type="submit"
                buttonSize="lg"
                additionalClasses="min-w-[300px] justify-center"
                additionalChildrenClasses="flex flex-row items-center"
                disabled={isLoading}
              >
                {t('Global.SendInstructions')}
                <TailSpin
                  height="15"
                  width="20"
                  color="white"
                  ariaLabel="tail-spin-loading"
                  radius="1"
                  wrapperStyle={{}}
                  wrapperClass="ml-4"
                  visible={isLoading}
                />
              </Button>
              {formik?.status?.errors && (
                <div className="text-red py-2 inline-flex">
                  <WarningIcon className="h-5 w-5 mr-2" />
                  <div className="block text-center">
                    {formik.status.errors?.form ||
                      formik.status.errors?.general}
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      )}

      {isSending && (
        <div
          className={'text-center mb-5 dark:text-white'}
          {...getTestProps(testId, 'send')}
        >
          <span>{t('Form.FormChangePasswordRequestPasswordSendToEmail')}</span>
        </div>
      )}

      <div className="flex flex-wrap mb-5">
        <div className="w-full m-auto text-center dark:text-white">
          {t('Global.GoBackTo')}
          <Link className="text-blue ml-1" to="/login">
            {t('Form.FormLoginPage')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordRequestForm;

ChangePasswordRequestForm.propTypes = {
  /**
   * ChangePasswordRequestForm on submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Form test id
   */
  testId: PropTypes.string,
};

ChangePasswordRequestForm.defaultProps = { testId: '' };
