import { isValidElement, useEffect, useRef } from 'react';

const attachedEvent = new Event('flotiq.attached');
const detachedEvent = new Event('flotiq.detached');

const FromHtmlElement = ({ htmlElement, order }) => {
  const ref = useRef();
  useEffect(() => {
    if (!htmlElement) return;
    ref.current.innerHTML = '';
    ref.current.appendChild(htmlElement);
    htmlElement.dispatchEvent(attachedEvent);
    return () => htmlElement.dispatchEvent(detachedEvent);
  }, [htmlElement]);

  return <div ref={ref} style={{ order: order }} />;
};

const ElementFromPlugin = ({ results, order }) =>
  results.map((result, idx) => {
    const key = idx;
    return isValidElement(result) || typeof result !== 'object' ? (
      result
    ) : (
      <FromHtmlElement key={key} htmlElement={result} order={order} />
    );
  });

export default ElementFromPlugin;
