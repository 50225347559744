import { useCallback } from 'react';
import PropTypes from 'prop-types';
import DataGrid from '../../components/DataGrid/DataGrid';
import { removeLocalStorage, setLocalStorage } from '../../utils/localStorage';
import Loader from '../Loader/Loader';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import { resizeColumn } from '../../lib/helpers';

const CustomizableDataGrid = ({
  setColumns,
  setSort,
  sortingLocalStorageKey,
  optionsLocalStorageKey,
  setCurrentPage,
  editGrid,
  gridOptions,
  setGridOptions,
  ...props
}) => {
  const handleSort = useCallback(
    (sortField, sortOrder) => {
      if (sortField && sortOrder) {
        setSort({ sortField, sortOrder });
        setLocalStorage(sortingLocalStorageKey, {
          sortField,
          sortOrder,
        });
      } else {
        setSort();
        removeLocalStorage(sortingLocalStorageKey);
      }
      setCurrentPage(1);
    },
    [setCurrentPage, setSort, sortingLocalStorageKey],
  );

  const handleLocalstorageGridOptionsOrder = useCallback(
    (columns) => {
      const updateOrder = [];

      for (let item in columns) {
        const indexSaved = gridOptions.findIndex(
          (el) => el.colId === columns[item].accessor,
        );
        if (indexSaved > -1) {
          updateOrder.push(gridOptions[indexSaved]);
        }
      }

      setGridOptions(updateOrder);
      setLocalStorage(optionsLocalStorageKey, updateOrder);
    },
    [gridOptions, optionsLocalStorageKey, setGridOptions],
  );

  const debouncedGridOptionsOrder = useDebounceCallback(
    handleLocalstorageGridOptionsOrder,
    10,
  );

  const handleColumnsOrder = useCallback(
    (sourceAccessor, targetAccessor) => {
      if (sourceAccessor === targetAccessor) return;
      setColumns((oldColumns) => {
        const targetIdx = oldColumns.findIndex(
          (column) => column.accessor === targetAccessor,
        );
        const sourceIdx = oldColumns.findIndex(
          (column) => column.accessor === sourceAccessor,
        );
        const newCols = [...oldColumns];
        newCols.splice(targetIdx, 0, newCols.splice(sourceIdx, 1)[0]);
        debouncedGridOptionsOrder(newCols);
        return newCols;
      });
    },
    [debouncedGridOptionsOrder, setColumns],
  );

  const debouncedOnColumnsOrder = useDebounceCallback(handleColumnsOrder, 1);

  const handleColumnResize = useCallback(
    (columnWidth, dataKey) =>
      resizeColumn(
        columnWidth,
        dataKey,
        gridOptions,
        setGridOptions,
        optionsLocalStorageKey,
      ),
    [gridOptions, setGridOptions, optionsLocalStorageKey],
  );

  const debouncedOnColumnResize = useDebounceCallback(handleColumnResize, 100);

  const handleOnRemoveColumn = useCallback(
    (id) => {
      const columnIndex = gridOptions.findIndex((el) => el.colId === id);

      if (columnIndex > -1) {
        const updateGridOptions = [...gridOptions];
        updateGridOptions[columnIndex].hide = true;
        setLocalStorage(optionsLocalStorageKey, updateGridOptions);
        setGridOptions(updateGridOptions);
      }
    },
    [gridOptions, optionsLocalStorageKey, setGridOptions],
  );

  return (
    <div className="h-[calc(100%-40px)] md:h-[calc(100%-72px)]">
      <DataGrid
        {...props}
        autoHeight={false}
        fillHeight
        onSortColumn={handleSort}
        loadingIcon={<Loader size={'small'} type={'spinner-grid'} />}
        onDrop={editGrid ? debouncedOnColumnsOrder : null}
        onResize={debouncedOnColumnResize}
        onRemoveColumn={handleOnRemoveColumn}
        removableColumns={editGrid}
      />
    </div>
  );
};

export default CustomizableDataGrid;

CustomizableDataGrid.propTypes = {
  ...DataGrid.propTypes,
  /**
   * Function to handle columns change
   */
  setColumns: PropTypes.func,
  /**
   * Function to handle sort change
   */
  setSort: PropTypes.func,
  /**
   * Local storage key for sorting
   */
  sortingLocalStorageKey: PropTypes.string,
  /**
   * Local storage key for grid options
   */
  optionsLocalStorageKey: PropTypes.string,
  /**
   * Function to handle current page change
   */
  setCurrentPage: PropTypes.func,
  /**
   * If grid is currently editing
   */
  editGrid: PropTypes.bool,
  /**
   * Grid options from local storage
   */
  gridOptions: PropTypes.arrayOf(PropTypes.object),
  /**
   * Function to handle local storage grid options
   */
  setGridOptions: PropTypes.func,
};

CustomizableDataGrid.defaultProps = {
  ...DataGrid.defaultProps,
  setColumns: /* istanbul ignore next */ () => {},
  sortData: {},
  setSort: /* istanbul ignore next */ () => {},
  sortingLocalStorageKey: '',
  optionsLocalStorageKey: '',
  setCurrentPage: /* istanbul ignore next */ () => {},
  editGrid: false,
  gridOptions: [],
  setGridOptions: /* istanbul ignore next */ () => {},
};
