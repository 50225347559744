import TagManager from 'react-gtm-module';
import { toast } from 'react-hot-toast';
import { ResponseError, checkResponseStatus } from './response-errors';
import { postLogin } from './auth-requests';
import { getMaskedOrganizationName } from '../helpers';

/**
 * Fetch login
 * @param values that contains email and password {object}
 * @param t {func}
 * @param navigate {func}
 */
export async function fetchLogin(
  values,
  setUser,
  t,
  navigate = null,
  navigateUrl = '',
) {
  try {
    const { body, status } = await postLogin(values.email, values.password);
    checkResponseStatus(body, status);
    setUser(body);

    TagManager.dataLayer({
      dataLayer: {
        event: 'login',
        user_id: body.data?.id,
        organization_id: body.data?.organization,
        organization_name: getMaskedOrganizationName(
          body.data?.organization_name,
        ),
        plan_id: body.data?.limits_plan?.id,
        plan_name: body.data?.limits_plan?.name,
        space_id: body.data?.default_space?.id,
      },
    });

    if (navigate) navigate(navigateUrl || '/');
    return [body.token, null];
  } catch (error) {
    let errors = null;

    if (!(error instanceof ResponseError)) {
      toast.error(t('Form.CommunicationErrorMessage'));
      errors = { global: t('Form.CommunicationErrorMessage') };
    } else {
      if (error.message === 'invalid_credentials') {
        toast.error(t('Global.ErrorInvalidLoginOrPassword'));
        errors = { global: t('Global.ErrorInvalidLoginOrPassword') };
      } else if (error.message === 'user_disabled') {
        toast.error(t('Login.UserDisabled'));
        errors = { global: t('Login.UserDisabled') };
      } else if (error.message === 'user_not_activated') {
        toast.error(t('Login.UserNotActivated'));
        errors = { global: t('Login.UserNotActivated') };
      } else {
        toast.error(error.message);
        errors = { ...error.errors };
      }
    }
    return [null, errors];
  }
}
