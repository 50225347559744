import { useMemo, useCallback, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
// :: Icons
import {
  CaretLeftIcon,
  CaretLeftStopIcon,
  CaretRightIcon,
  CaretRightStopIcon,
} from '../../images/shapes';

// :: Component
import Input from '../Input/Input';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const Pagination = ({
  additionalClasses,
  page,
  numOfPages,
  onPageChange,
  testId,
}) => {
  const [focusPagination, setFocusPagination] = useState(false);
  const paginationInputRef = useRef(null);
  const currentPage = useMemo(
    () => Math.min(Math.max(page, 1), numOfPages),
    [page, numOfPages],
  );

  useEffect(() => {
    if (focusPagination) {
      paginationInputRef.current.focus();
    }
  }, [focusPagination]);
  const handlePageChange = useCallback(
    (pageNumber) => {
      if (pageNumber < 1 || pageNumber > numOfPages) {
        return;
      }
      if (pageNumber !== page) {
        onPageChange(pageNumber);
      }
    },
    [numOfPages, onPageChange, page],
  );

  const handleOnEnterSubmit = useCallback(
    (event) => {
      if (event.key === 'Enter') {
        const result = parseInt(event.target.value);
        handlePageChange(!isNaN(result) ? result : 1);
        setFocusPagination(false);
      }
    },
    [handlePageChange],
  );

  const handleOnBlur = useCallback(
    (event) => {
      const result = parseInt(event.target.value);
      handlePageChange(!isNaN(result) ? result : 1);
      setFocusPagination(false);
    },
    [handlePageChange],
  );

  const currentPageInput = useMemo(
    () => (
      <div
        onClick={() => setFocusPagination(true)}
        className={twMerge('mx-1 md:mx-2', !focusPagination && 'my-2')}
        {...getTestProps(testId, 'page-no-mode-swap')}
      >
        {focusPagination ? (
          <Input
            type={'number'}
            value={currentPage}
            onBlur={handleOnBlur}
            additionalInputClasses={twMerge(
              'text-blue-600 !bg-transparent border-transparent px-2 h-8',
            )}
            onKeyDown={handleOnEnterSubmit}
            min="1"
            max={numOfPages}
            refs={paginationInputRef}
            {...getTestProps(testId, 'page-no', 'testId')}
          />
        ) : (
          <span
            className={twMerge(
              'px-1 cursor-pointer text-blue-600 dark:text-blue-400',
            )}
            {...getTestProps(testId, 'page-no-mode-span')}
          >
            {currentPage}
          </span>
        )}
      </div>
    ),
    [
      focusPagination,
      currentPage,
      handleOnBlur,
      handleOnEnterSubmit,
      numOfPages,
      testId,
    ],
  );

  const goToPrevPage = useCallback(() => {
    handlePageChange(currentPage - 1);
  }, [handlePageChange, currentPage]);

  const goToNextPage = useCallback(() => {
    handlePageChange(currentPage + 1);
  }, [handlePageChange, currentPage]);

  const goToFirstPage = useCallback(() => {
    handlePageChange(1);
  }, [handlePageChange]);

  const goToLastPage = useCallback(() => {
    handlePageChange(numOfPages);
  }, [handlePageChange, numOfPages]);

  const { t } = useTranslation();

  return (
    <div
      className={twMerge(
        'px-1 py-3 flex items-center justify-center sm:px-6 space-x-12 select-none',
        additionalClasses,
      )}
      {...getTestProps(testId, 'wrapper')}
    >
      <nav
        className={twMerge(
          'relative z-0 inline-flex',
          'text-sm md:text-base font-medium leading-none flex items-center space-x-1 md:space-x-3',
        )}
        aria-label="Pagination"
      >
        <div className="flex items-center space-x-1 md:space-x-3">
          <span
            onClick={goToFirstPage}
            className={twMerge(
              currentPage <= 1
                ? 'opacity-40 select-none'
                : 'cursor-pointer hover:underline',
            )}
            {...getTestProps(testId, 'left-end-arrow')}
          >
            <CaretLeftStopIcon
              className="h-3 text-zinc-600 dark:text-slate-400"
              title={t('Pagination.FirstPage')}
            />
          </span>
          <span
            onClick={goToPrevPage}
            className={twMerge(
              'flex items-center font-medium',
              currentPage <= 1
                ? 'opacity-40 select-none'
                : 'cursor-pointer hover:underline',
            )}
            {...getTestProps(testId, 'left-arrow')}
          >
            <CaretLeftIcon
              className="h-3 text-zinc-600 dark:text-slate-400"
              title={t('Pagination.PreviousPage')}
            />
          </span>
        </div>
        <div className="flex items-center px-2 dark:text-white">
          <span className="hidden sm:inline sm:text-xs md:text-sm 2xl:text-base">
            {t('Pagination.Page')}
          </span>
          {currentPageInput}
          {t('Pagination.NumOfPages', { numOfPages })}
        </div>
        <div className="flex items-center space-x-1 md:space-x-4">
          <span
            onClick={goToNextPage}
            className={twMerge(
              'flex items-center font-medium',
              numOfPages === currentPage
                ? 'opacity-50 select-none'
                : 'cursor-pointer hover:underline',
            )}
            {...getTestProps(testId, 'right-arrow')}
          >
            <CaretRightIcon
              className="h-3 text-zinc-600 dark:text-slate-400"
              title={t('Pagination.NextPage')}
            />
          </span>
          <span
            onClick={goToLastPage}
            className={twMerge(
              numOfPages === currentPage
                ? 'opacity-50 select-none'
                : 'cursor-pointer hover:underline',
            )}
            {...getTestProps(testId, 'right-end-arrow')}
          >
            <CaretRightStopIcon
              className="h-3 text-zinc-600 dark:text-slate-400"
              title={t('Pagination.LastPage')}
            />
          </span>
        </div>
      </nav>
    </div>
  );
};

Pagination.propTypes = {
  /**
   * Current page number
   */
  page: PropTypes.number.isRequired,
  /**
   * Number of pages
   */
  numOfPages: PropTypes.number.isRequired,
  /**
   * Additional classes for pagination box
   */
  additionalClasses: PropTypes.string,
  /**
   * Page change function
   */
  onPageChange: PropTypes.func,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Pagination.defaultProps = {
  additionalClasses: '',
  onPageChange: /* istanbul ignore next */ () => true,
  numOfPages: 1,
  page: 1,
  testId: '',
};

export default Pagination;
