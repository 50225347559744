import { createContext } from 'react';
import PropTypes from 'prop-types';

export const DirtyHandlerContext = createContext({});

DirtyHandlerContext.propTypes = {
  value: PropTypes.shape({
    /**
     * If form is dirty
     */
    dirty: PropTypes.bool,
    /**
     * Callback for setting form dirty
     */
    setDirty: PropTypes.func,
  }).isRequired,
};

export default DirtyHandlerContext;
