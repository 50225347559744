import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import ContentObjectForm from '../../../../form/ContentObjectForm/ContentObjectForm';
import { useTranslation } from 'react-i18next';
import UnsplashMedia from '../../../../pages/MediaLibrary/Subpages/UnsplashMedia';
import Tabs from '../../../Tabs/Tabs';
import {
  findBorderColor,
  findThumbnail,
  getMediaData,
} from '../../../../lib/newMediaHelpers';
import MediaCard from '../../../MediaCard/MediaCard';
import NewMediaContext from '../../../../contexts/NewMediaContext';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../../../lib/helpers';
import FileButton from '../../../FileButton/FileButton';

const selectNewMedia = (appeared, newMedia, selectMedia) => {
  if (!appeared) return;
  newMedia.forEach((media) => {
    const { card, errors, isLoaded } = getMediaData(media);
    if (!isLoaded || errors) return;
    if (!appeared[card.id]) {
      appeared[card.id] = true;
      selectMedia(card);
    }
  });
};

const CreateObject = ({
  selectedType,
  formId,
  onSubmit,
  disabled,
  onMediaUpload,
  isUploading,
  setIsUploading,
  selectMedia,
  selectedMedia,
  isMultiple,
  testId,
}) => {
  const appearedMediaRef = useRef({});
  const { t } = useTranslation();
  const { newMedia } = useContext(NewMediaContext);
  const isMedia = useMemo(
    () => selectedType.name === '_media',
    [selectedType.name],
  );

  useEffect(() => {
    if (isMedia) {
      selectNewMedia(appearedMediaRef.current, newMedia, selectMedia);
    }
  }, [isMedia, newMedia, selectMedia]);

  const onUpload = useCallback(
    async (files) => {
      setIsUploading(true);
      await onMediaUpload(files);
      setIsUploading(false);
    },
    [setIsUploading, onMediaUpload],
  );

  const renderNewMedia = useCallback(
    (media) => {
      const { card, errors, isLoaded } = getMediaData(media);
      return (
        <MediaCard
          key={card.id}
          mediaData={card}
          thumbnail={findThumbnail(errors, isLoaded, false)}
          hideOverlay={errors || !isLoaded ? true : false}
          onClick={!isLoaded || errors ? null : selectMedia}
          isSelected={selectedMedia?.[card.id] ? true : false}
          additionalContainerClasses={findBorderColor(errors, isLoaded, false)}
          {...getTestProps(testId, card.id, 'testId')}
        />
      );
    },
    [selectMedia, selectedMedia, testId],
  );

  const mediaTabsContent = useMemo(() => {
    if (!isMedia) return null;
    return [
      {
        label: t('Media.Tabs.UploadedFiles'),
        key: 'userMedia',
        render: () => (
          <div className="space-y-2">
            <FileButton
              onUpload={onUpload}
              multiple={isMultiple}
              disabled={isUploading}
              label={t('ContentForm.Relation.AddMedia')}
              additionalClasses="w-fit"
              testId={testId}
            />
            <div
              className={twMerge(
                'grid gap-4 sm:gap-6 grid-cols-[repeat(auto-fill,_minmax(10rem,_1fr))]',
                'sm:grid-cols-[repeat(auto-fill,_minmax(14.9rem,_1fr))]',
              )}
            >
              {newMedia.map((media) => renderNewMedia(media))}
            </div>
          </div>
        ),
      },
      {
        label: t('Media.Tabs.UnsplashFiles'),
        key: 'unsplashMedia',
        render: () => (
          <UnsplashMedia
            mediaOnPageLimit={16}
            hasPanel={false}
            showAsNewMedia
          />
        ),
      },
    ];
  }, [
    isMedia,
    isMultiple,
    isUploading,
    newMedia,
    onUpload,
    renderNewMedia,
    t,
    testId,
  ]);

  return isMedia ? (
    <Tabs tabs={mediaTabsContent} />
  ) : (
    <ContentObjectForm
      contentType={selectedType}
      onSubmit={onSubmit}
      disabled={disabled}
      formId={formId}
      testId={testId}
    />
  );
};

export default CreateObject;

CreateObject.propTypes = {
  /**
   * Selected content type
   */
  selectedType: PropTypes.object.isRequired,
  /**
   * Content object form id
   */
  formId: PropTypes.string.isRequired,
  /**
   * On form submit callback
   */
  onSubmit: PropTypes.func,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * On media upload callback
   */
  onMediaUpload: PropTypes.func,
  /**
   * If media are uploading
   */
  isUploading: PropTypes.bool,
  /**
   * Callback to change state of isUploading
   */
  setIsUploading: PropTypes.func,
  /**
   * Function for selecting media
   */
  selectMedia: PropTypes.func,
  /**
   * Selected media
   */
  selectedMedia: PropTypes.object,
  /**
   * If relation is multiple
   */
  isMultiple: PropTypes.bool,
  /**
   * Id for tests
   */
  testId: PropTypes.string,
};

CreateObject.defaultProps = {
  onSubmit: /* istanbul ignore next */ () => null,
  disabled: false,
  onMediaUpload: /* istanbul ignore next */ () => null,
  isUploading: false,
  setIsUploading: /* istanbul ignore next */ () => null,
  selectMedia: /* istanbul ignore next */ () => null,
  selectedMedia: {},
  isMultiple: false,
  testId: '',
};
