import { useCallback, useState, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import * as Sentry from '@sentry/react';
import useLocalStorageState from 'use-local-storage-state';

// :: React dnd
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';

// :: Layout
import Structure from './Structure';

// :: Components
import GlobalSearch from '../components/GlobalSearch/GlobalSearch';
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';

// :: Context
import AppContext from '../contexts/AppContext';
import NewMediaContext from '../contexts/NewMediaContext';
import SidebarContext from '../contexts/SidebarContext';
import UserContext from '../contexts/UserContext';

// :: Hooks
import {
  usePlugins,
  useUser,
  useConstraints,
  usePluginsSettings,
} from '../hooks/api';
import useOnce from '../hooks/useOnce';
import useDarkMode from '../hooks/useDarkMode';
import useSelectedSpace from '../hooks/useSelectedSpace';
import useToken from '../hooks/useToken';

// :: Lib
import { getTestProps } from '../lib/helpers';
import { checkResponseStatus } from '../lib/flotiq-client/response-errors';
import FlotiqPlugins from '../lib/flotiq-plugins/flotiqPluginsRegistry';
import { LanguageChangedEvent } from '../lib/flotiq-plugins/plugin-events/LanguageChangedEvent';
import { putUserConfig } from '../lib/flotiq-client';

// :: Images
import {
  LogOutIcon,
  UnionIcon,
  SunIcon,
  MoonIcon,
  AvatarIcon,
  HouseIcon,
} from '../images/shapes';
import LoaderPage from '../pages/LoaderPage/LoaderPage';
import { useSpaceMenuItems } from '../hooks/UIPersonalization/useSpaceMenuItems';

const PLUGINS_LEGACY_PARAMS = {
  limit: 10000,
  page: 1,
  filters: JSON.stringify({
    type: {
      type: 'equals',
      filter: 'ui-extension',
    },
  }),
};

const USER_PLUGINS_PARAMS = {
  limit: 10000,
  page: 1,
};

/**
 * @emits FlotiqPlugins."flotiq.language::changed"
 */
const Layout = ({ testId, additionalClasses, children }) => {
  const { t, i18n } = useTranslation();
  const [darkMode, setDarkMode] = useDarkMode();
  const { appContext, updateAppContext } = useContext(AppContext);
  const { isAdmin, permissions, updateUserContext } = useContext(UserContext);
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const [user, setUser] = useLocalStorageState('cms.user');
  const [layoutLoading, setLayoutLoading] = useState(FlotiqPlugins.enabled());
  const [pluginsLoaded, setPluginLoaded] = useState(false);

  const { entity: mimeTypes } = useConstraints('mimetypes');

  const updateUserMimeTypes = useCallback(async () => {
    if (mimeTypes?.data && user) {
      setUser({
        ...user,
        data: { ...user.data, allowedMimetypes: mimeTypes.data },
      });
    }
  }, [mimeTypes?.data, setUser, user]);

  useOnce(updateUserMimeTypes);

  const jwt = useToken();

  const systemPlugins = useMemo(
    () => JSON.parse(process.env.REACT_APP_SYSTEM_PLUGINS || '[]'),
    [],
  );

  const { data: pluginsLegacy, isLoading: pluginsLegacyAreLoading } =
    usePlugins(PLUGINS_LEGACY_PARAMS);

  const { data: userPlugins, isLoading: userPluginsAreLoading } =
    usePluginsSettings(USER_PLUGINS_PARAMS);

  useEffect(() => {
    if (
      !user ||
      (permissions.userRoles &&
        !pluginsLegacyAreLoading &&
        !pluginsLegacy.length &&
        !userPluginsAreLoading &&
        !userPlugins.length)
    ) {
      setLayoutLoading(false);
    }
  }, [
    permissions.userRoles,
    pluginsLegacy.length,
    pluginsLegacyAreLoading,
    user,
    userPlugins.length,
    userPluginsAreLoading,
  ]);

  useEffect(() => {
    if (
      !permissions.userRoles ||
      userPluginsAreLoading ||
      pluginsLegacyAreLoading
    )
      return;

    if (pluginsLoaded) return;

    const enabledPlugins = userPlugins?.filter(({ enabled }) => enabled);

    const pluginsLegacyUI = pluginsLegacy.map((plugin) => {
      const url = plugin.settings?.find(
        (setting) => setting.key === 'source-url',
      )?.value;
      return { id: plugin?.id, url, settings: '' };
    });

    Promise.all(
      [...pluginsLegacyUI, ...systemPlugins, ...enabledPlugins].map(
        ({ id, url, settings }) => {
          if (!id || !url) return null;
          return FlotiqPlugins.loadPlugin(id, url, settings);
        },
      ),
    ).then(() => {
      setLayoutLoading(false);
      setPluginLoaded(true);
    });
  }, [
    permissions.userRoles,
    pluginsLegacy,
    pluginsLegacyAreLoading,
    pluginsLoaded,
    systemPlugins,
    userPlugins,
    userPluginsAreLoading,
  ]);

  const { onUpload } = useContext(NewMediaContext);

  const [userData] = useUser(user?.data?.id);

  const planLimits = useMemo(
    () => userData?.spaces?.filter((el) => el.id === space)?.[0]?.planLimits,
    [space, userData?.spaces],
  );

  const handleUpdatePlan = useCallback(() => {
    updateUserContext?.((prevState) => ({
      ...prevState,
      planLimits,
    }));
  }, [updateUserContext, planLimits]);

  useOnce(handleUpdatePlan);

  const pinContentCallback = useCallback(
    async (id, pined) => {
      if (!user?.data) return;

      const removeElement = (arr, id) => {
        const index = arr[space].indexOf(id);
        if (index > -1) {
          arr[space].splice(index, 1);
        }
        return arr;
      };

      let newPinnedContent = { ...user.data.config?.pinedDefinitions };

      if (!pined) {
        if (!newPinnedContent[space]) {
          newPinnedContent[space] = [id];
        } else {
          newPinnedContent[space].push(id);
        }
      } else {
        newPinnedContent = removeElement(newPinnedContent, id);
      }

      const newConfig = {
        ...user.data.config,
        pinedDefinitions: newPinnedContent,
      };

      try {
        const { body, status } = await putUserConfig(jwt, null, {
          id: user.data.id,
          config: newConfig,
        });
        checkResponseStatus(body, status);

        const newUser = { ...user };
        newUser.data.config = newConfig;

        setUser(newUser);
      } catch (e) {
        toast.error(t('Form.CommunicationErrorMessage'));
      }
    },
    [jwt, setUser, space, t, user],
  );

  const handleGetUserDetails = useCallback(() => {
    const userDetails = user?.data;
    const token = user?.token;

    if (
      !appContext?.user?.username ||
      appContext?.user?.username !== user?.data?.username
    ) {
      updateAppContext?.((prevState) => ({
        ...prevState,
        ...(token && { token }),
        ...(userDetails && { user: userDetails }),
      }));
    }
  }, [user?.data, user?.token, appContext?.user?.username, updateAppContext]);

  useOnce(handleGetUserDetails);

  const { menuItems: sidebarMenuItems, reloadCtd } = useSpaceMenuItems();

  useEffect(() => {
    const lngChangedCallback = (language) => {
      FlotiqPlugins.run(
        'flotiq.language::changed',
        new LanguageChangedEvent({ language }),
      );
      moment.locale(language === 'en' ? 'en-gb' : 'pl');
    };

    i18n.on('languageChanged', lngChangedCallback);
    return () => i18n.off('languageChanged', lngChangedCallback);
  }, [i18n]);

  const MENU_USER_ITEMS = useMemo(() => {
    const currentMenu = [
      {
        icon: darkMode ? (
          <SunIcon className="w-4 text-white" />
        ) : (
          <MoonIcon className="w-4 text-indigo-950" />
        ),
        text: darkMode
          ? t('Global.ChooseLightTheme')
          : t('Global.ChooseDarkTheme'),
        onClick: () => setDarkMode(!darkMode),
      },
      {
        icon: (
          <AvatarIcon className="w-4 h-4 text-indigo-950 dark:text-white" />
        ),
        text: t('Global.ProfileSettings'),
        link: '/profile',
      },
      {
        icon: (
          <LogOutIcon className="w-4 h-4 text-indigo-950 dark:text-white" />
        ),
        text: t('Global.LogOut'),
        link: '/logout',
      },
    ];

    if (isAdmin) {
      currentMenu.splice(1, 0, {
        icon: <HouseIcon className="w-4 h-4 text-indigo-950 dark:text-white" />,
        text: t('Global.OrganizationSettings'),
        link: '/organization',
        hidden: true,
      });
    }

    return currentMenu;
  }, [darkMode, setDarkMode, isAdmin, t]);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item) {
        onUpload(item.files);
      },
      canDrop: () => true,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [onUpload],
  );

  const sidebarContextValue = useMemo(() => ({ reloadCtd }), [reloadCtd]);

  const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

  return layoutLoading ? (
    <LoaderPage />
  ) : (
    <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
      <GlobalSearch />
      <SidebarContext.Provider value={sidebarContextValue}>
        <Structure
          testId={testId}
          showUpgrade
          additionalClasses={additionalClasses}
          ref={() => drop(document.body)}
          logoLink={space ? buildUrlWithSpace('') : '/'}
          menuUserItems={MENU_USER_ITEMS}
          sidebarMenuItems={sidebarMenuItems}
          pinContentCallback={pinContentCallback}
          additionalElement={
            <Transition
              className="z-50"
              show={canDrop && isOver}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              enter="transition ease-in duration-100"
              enterFrom="opacity-0"
              enterTo="opacity-100"
            >
              <div className="fixed top-0 right-0 h-screen w-full bg-white opacity-75 z-50" />
              <div
                className={twMerge(
                  'fixed top-1/2 left-1/2 bg-blue aspect-square rounded-full z-50',
                  'h-60 md:h-80 lg:h-96 2xl:h-[500px]',
                  'text-white font-bold text-center',
                  'text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl -translate-x-1/2 -translate-y-1/2',
                )}
                {...getTestProps(testId, 'add-media')}
              >
                <div className="absolute h-full w-full animate-slowPing rounded-full bg-blue opacity-20" />
                <div
                  className={
                    'h-full w-full p-5 md:p-8 lg:py-10 2xl:py-20 grid grid-rows-3 justify-center items-center'
                  }
                >
                  <UnionIcon className="animate-slowSpin justify-self-center h-2 md:h-4 lg:h-6 opacity-80" />
                  <p>{t('Media.DropFiles')}</p>
                  <p className="px-5 2xl:px-20 self-end opacity-80 text-base md:text-2xl 2xl:text-3xl">
                    {t('Media.AddToLibrary')}
                  </p>
                </div>
              </div>
            </Transition>
          }
        >
          {children ? children : <Outlet />}
        </Structure>
      </SidebarContext.Provider>
    </Sentry.ErrorBoundary>
  );
};

export default Layout;

Layout.propTypes = {
  /*
   ** Checking if the sidebar is open
   */
  isSidebarOpen: PropTypes.bool,
  /*
   ** Additional classes for layout
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

Layout.defaultProps = {
  additionalClasses: '',
  isSidebarOpen: true,
  testId: '',
};
