import {
  useCallback,
  useState,
  useContext,
  useMemo,
  forwardRef,
  useEffect,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import 'moment/locale/pl';
import 'moment/locale/en-gb';
import * as Sentry from '@sentry/react';
import useLocalStorageState from 'use-local-storage-state';

// :: Components
import Sidebar from '../components/Sidebar/Sidebar';
import ErrorFallback from '../components/ErrorFallback/ErrorFallback';
import AnnouncementComponent from '../components/Announcement/AnnouncementComponent';
import ImpersonateCard from '../components/ImpersonateCard/ImpersonateCard';

// :: Context
import AppContext from '../contexts/AppContext';
import UserContext from '../contexts/UserContext';

// :: Utils
import { getLocalStorage, setLocalStorage } from '../utils/localStorage';

// :: Lib
import { getHash, getTestProps } from '../lib/helpers';

// :: Hooks
import useDarkMode from '../hooks/useDarkMode';
import { useGridNavigate } from '../components/DataGrid/useGridFilters';

// :: Images
import {
  FlotiqLogo,
  LogOutIcon,
  FlotiqLogoWhite,
  LifebuoyColorIcon,
  LifebuoyColorIconWhite,
} from '../images/shapes';

const Structure = forwardRef(
  (
    {
      menuUserItems,
      logoLink,
      additionalElement,
      testId,
      additionalClasses,
      children,
      sidebarMenuItems,
      pinContentCallback,
      showUpgrade,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const { appContext, updateAppContext } = useContext(AppContext);
    const { userData, planLimits } = useContext(UserContext);
    const [darkMode] = useDarkMode();
    const [, setTutorials] = useLocalStorageState('cms.tutorials');
    const [impersonate, , { removeItem: removeImpersonation }] =
      useLocalStorageState('cms.impersonate');
    const isFree = planLimits?.price === 0;

    const { navigateGrid } = useGridNavigate(
      'all-users',
      '/users-data-preview',
    );

    const endImpersonation = useCallback(() => {
      removeImpersonation();
      updateAppContext?.((prevState) => ({
        ...prevState,
        space: undefined,
        spaceSlug: undefined,
      }));
      navigateGrid();
    }, [navigateGrid, removeImpersonation, updateAppContext]);

    useEffect(() => {
      const handleUnload = () => {
        removeImpersonation();
      };
      window.addEventListener('beforeunload', handleUnload);
      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    }, [removeImpersonation]);

    const [sidebarOpen, setSidebarOpen] = useLocalStorageState(
      'cms.user_sideBarOpen',
    );

    const announcement =
      process.env.REACT_APP_ANNOUNCEMENT.split(',').join(',');

    const announcementHash = useMemo(() => {
      return getHash(announcement);
    }, [announcement]);

    const announcementHide = useMemo(() => {
      if (!announcement) return true;
      const announcementData =
        getLocalStorage('cms.user_hideAnnouncement', true) || {};
      if (announcementData.id === announcementHash)
        return announcementData.hide;
      return false;
    }, [announcement, announcementHash]);

    const [hideAnnouncement, setHideAnnouncement] = useState(announcementHide);

    const handleCloseAnnouncement = useCallback(() => {
      setHideAnnouncement(true);
      setLocalStorage(`cms.user_hideAnnouncement`, {
        id: announcementHash,
        hide: true,
      });
    }, [announcementHash]);

    const isSizeSM = useMediaQuery({
      query: '(max-width: 1024px)',
    });

    const sidebarOpened =
      typeof sidebarOpen === 'boolean' ? sidebarOpen : !isSizeSM;

    const handleSidebar = useCallback(
      (value) => {
        setSidebarOpen(value);
      },
      [setSidebarOpen],
    );

    const MENU_USER_ITEMS = useMemo(
      () => [
        {
          icon: (
            <LogOutIcon className="w-4 h-4 text-indigo-950 dark:text-white" />
          ),
          text: t('Global.LogOut'),
          link: '/logout',
        },
      ],
      [t],
    );

    const handleTutorialReset = useCallback(
      async (event) => {
        event.preventDefault();
        setTutorials({ run: true });
      },
      [setTutorials],
    );

    const helpCenterContent = useMemo(() => {
      const links = [
        {
          title: t('HelpCenter.Documentation'),
          link: process.env.REACT_APP_DOCUMENTATION,
          target: '_blank',
          rel: 'noreferrer',
          key: 'Documentation',
          hidePin: true,
        },
        {
          title: t('HelpCenter.Support'),
          link: process.env.REACT_APP_DISCORD,
          target: '_blank',
          rel: 'noreferrer',
          key: 'Support',
          hidePin: true,
        },
        {
          title: t('HelpCenter.Demo'),
          link: process.env.REACT_APP_DEMO,
          target: '_blank',
          rel: 'noreferrer',
          key: 'Demo',
          hidePin: true,
        },
      ];
      if (JSON.parse(process.env.REACT_APP_ENABLE_TUTORIAL)) {
        links.push({
          title: t('UserForm.ResetTutorials'),
          key: 'ResetTutorials',
          target: '_self',
          onClick: handleTutorialReset,
          hidePin: true,
        });
      }
      return links;
    }, [t, handleTutorialReset]);

    const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

    const sidebarItems = useMemo(
      () => [
        ...(sidebarMenuItems || appContext?.sidebarMenuItems || []),
        {
          key: 'help-center',
          icon: darkMode ? (
            <LifebuoyColorIconWhite className="w-5 min-w-5 ml-0.5" />
          ) : (
            <LifebuoyColorIcon className="w-5 min-w-5 ml-0.5" />
          ),
          title: t('Global.HelpCenter'),
          children: helpCenterContent,
          hidePin: true,
        },
      ],
      [
        sidebarMenuItems,
        appContext?.sidebarMenuItems,
        darkMode,
        t,
        helpCenterContent,
      ],
    );

    return (
      <>
        <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
          <AnnouncementComponent
            additionalClasses="h-16"
            announcement={announcement}
            handleClose={handleCloseAnnouncement}
            hasCloseButton={true}
            hidden={hideAnnouncement}
            {...getTestProps(testId, 'announcement', 'testId')}
          />
          <main
            ref={ref}
            className={twMerge(
              'flex items-start justify-between brand-bg-gradient w-full',
              'dark:bg-gray-900 from-blue-700 to-blue-650',
              'min-h-screen md:pb-0 font-inter',
              'transition-all',
              'ease-in-out',
              'duration-normal relative ',
              sidebarOpened
                ? 'pl-0 sm:pl-[68px] lg:pl-[240px]'
                : 'pl-0 sm:pl-[68px]',
              !hideAnnouncement && 'pt-16',
              additionalClasses,
            )}
            data-testid={testId}
          >
            {additionalElement}
            <Sidebar
              showUpgrade={showUpgrade && isFree}
              additionalContainerClasses={twMerge(
                'fixed top-14 left-0 z-20',
                hideAnnouncement ? 'top-0' : 'top-16',
              )}
              logo={
                darkMode ? (
                  <FlotiqLogoWhite className="h-10" />
                ) : (
                  <FlotiqLogo className="h-10" />
                )
              }
              logoLink={logoLink || '/'}
              menuItems={sidebarItems}
              logoConfig={{
                height: 64,
                marginBottom: showUpgrade && isFree ? 16 : 36,
                marginTop: hideAnnouncement ? 0 : 64,
              }}
              upgradeConfig={
                showUpgrade && isFree
                  ? { height: 32, marginBottom: 20, marginTop: 0 }
                  : null
              }
              menuConfig={{
                selected: appContext?.page,
                openItem: appContext?.menuItemOpen,
              }}
              userConfig={{
                name: `${userData?.firstName || ''} ${
                  userData?.lastName || ''
                }`,
                email: userData?.email || '',
                height: 64,
                isModalOnClose: true,
                isModalOnCloseOutside: true,
                hasEventOnCloseOutside: true,
                menuItem: menuUserItems || MENU_USER_ITEMS,
              }}
              isOpen={sidebarOpened}
              handleSidebar={handleSidebar}
              pinContentCallback={pinContentCallback}
              additionalUserEmailClasses={
                'break-words leading-normal whitespace-normal mr-2 pb-0.5 line-clamp-2'
              }
              {...getTestProps(testId, 'sidebar', 'testId')}
            />
            {children ? children : <Outlet />}
            {impersonate && (
              <ImpersonateCard
                userInitials={
                  userData?.firstName?.[0] + userData?.lastName?.[0] || 'N/A'
                }
                email={impersonate.email}
                onClose={endImpersonation}
                {...getTestProps(testId, 'impersonate-card', 'testId')}
              />
            )}
          </main>
        </Sentry.ErrorBoundary>
      </>
    );
  },
);

export default Structure;
