import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

// :: Component
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Loader from '../../components/Loader/Loader';

// :: Icons
import { WarningIcon } from '../../images/shapes';

// :: Helpers
import { emailSchema, simplePasswordSchema } from '../../lib/yupHelpers';
import { getTestProps } from '../../lib/helpers';

const LoginForm = ({ onSubmit, buttonText, error, testId }) => {
  const { t } = useTranslation();
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);
  const [errorFormCatch, setErrorFormCatch] = useState(error);
  const [isLogging, setIsLogging] = useState(false);

  const validationSchema = yup.object({
    email: emailSchema(t),
    password: simplePasswordSchema(t),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: validateAfterSubmit,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLogging(true);
      const [, errors] = await onSubmit(values);
      formik.setStatus({ ...formik.status, errors });
      if (errors?.global) setErrorFormCatch(errors.global);
      setIsLogging(false);
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    setValidateAfterSubmit(true);
    formik.handleSubmit();
  };

  return (
    <form
      id={'authentication-from'}
      className="w-full max-w-lg m-auto"
      onSubmit={handleSubmit}
      noValidate={true}
      {...getTestProps(testId)}
    >
      <div
        className="flex flex-wrap mb-6"
        {...getTestProps(testId, 'email-container')}
      >
        <Input
          name="email"
          placeholder={t('Global.Email')}
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.errors.email}
        />
      </div>

      <div
        className="flex flex-wrap mb-6"
        {...getTestProps(testId, 'password-container')}
      >
        <Input
          name="password"
          type="password"
          placeholder={t('Global.Password')}
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.errors.password}
        />
      </div>

      <div className="flex flex-wrap mb-6">
        <div className="w-full m-auto text-right text-blue">
          <Link to="/change-password-request">
            {t('Form.FormLoginForgotYourPassword')}
          </Link>
        </div>
      </div>

      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full flex flex-col justify-center text-center">
          <Button
            buttonSize="lg"
            type="submit"
            disabled={isLogging}
            iconImage={
              isLogging ? <Loader size="small" type="spinner-grid" /> : null
            }
            additionalClasses="min-w-[300px] justify-center"
          >
            {t('Global.LogIn')}
          </Button>
          {buttonText && <div className="text-blue py-2">{buttonText}</div>}
          {errorFormCatch && (
            <div className="text-red py-2 inline-flex">
              <div className="h-full">
                <WarningIcon className="h-5 w-5" />
              </div>
              <div className="block text-left pl-2">{errorFormCatch}</div>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default LoginForm;

LoginForm.propTypes = {
  /**
   * LoginForm on submit handler
   */
  onSubmit: PropTypes.func,
  /**
   * Text under button
   */
  buttonText: PropTypes.node,
  /**
   * Error text under button
   */
  error: PropTypes.node,
  /**
   * Form test id
   */
  testId: PropTypes.string,
};

LoginForm.defaultProps = {
  onSubmit: /* istanbul ignore next */ () => null,
  buttonText: '',
  error: '',
  testId: '',
};
