import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';
import CheckCircle from '../CheckCircle/CheckCircle';

export const PasswordValidationBox = ({ password, testId }) => {
  const { t } = useTranslation();
  const validation = [
    {
      key: 'characters',
      label: t('Password.Characters'),
      value: password.length >= 8,
    },
    { key: 'number', label: t('Password.Number'), value: /\d/.test(password) },
    {
      key: 'upperLetter',
      label: t('Password.UpperLetter'),
      value: /[A-Z]/.test(password),
    },
    {
      key: 'smallLetter',
      label: t('Password.SmallLetter'),
      value: /[a-z]/.test(password),
    },
    {
      key: 'specialCharacter',
      label: t('Password.SpecialCharacter'),
      value: /\W/.test(password),
    },
  ];
  return (
    <div className="pt-1">
      <div
        className={twMerge(
          'border-slate-200 border rounded-lg p-4 w-full text-sm',
          'space-y-2.5 font-medium absolute z-10 bg-white leading-5',
        )}
        {...getTestProps(testId, 'password-validation')}
      >
        <p>{t('Password.MustConatain')}:</p>
        {validation.map((condition) => (
          <div key={condition.key} className="flex items-center">
            <CheckCircle
              checked={condition.value}
              additionalClasses="pr-2 inline-flex"
            />
            {t('Password.AtLeastOne', { character: condition.label })}
          </div>
        ))}
      </div>
    </div>
  );
};
