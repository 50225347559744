import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import './i18n';
import TagManager from 'react-gtm-module';

// :: Pages
import App from './pages/App/App';

// :: Fonts
import '@fontsource/inter/100.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

// :: Styles
import './styles/index.css';
import FlotiqPlugins from './lib/flotiq-plugins/flotiqPluginsRegistry';

// :: Components
import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import { getLocalStorage } from './utils/localStorage';
import { getMaskedOrganizationName } from './lib/helpers';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    ignoreErrors: ['Plugin does not have permission to'],
  });
}

if (process.env.REACT_APP_GTM_ID) {
  const user = getLocalStorage('cms.user', true);

  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
    ...(user?.data
      ? {
          dataLayer: {
            user_id: user.data.id,
            organization_id: user.data.organization,
            organization_name: getMaskedOrganizationName(
              user.data.organization_name,
            ),
            plan_id: user.data.limits_plan?.id,
            plan_name: user.data.limits_plan?.name,
            space_id: user.data.default_space?.id,
          },
        }
      : {}),
  });
}

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

FlotiqPlugins.init();

const getErrorFallbackPage = (event) => <ErrorFallback {...event} />;

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={getErrorFallbackPage}>
      <App />
    </Sentry.ErrorBoundary>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
