import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

const ValidationToastHandler = () => {
  const { t } = useTranslation();
  const toastRef = useRef();
  const { isValid, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (!isValid && isSubmitting) {
      if (toastRef.current) toast.remove(toastRef.current);
      toastRef.current = toast.error(t('ContentForm.Errors.TryAgain'));
    }
  }, [isValid, isSubmitting, t]);
};

export default ValidationToastHandler;

ValidationToastHandler.propTypes = {};

ValidationToastHandler.defaultProps = {};
