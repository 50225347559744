import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useSearchParams } from 'react-router-dom';

// :: Components
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import DataGridControl from '../../components/DataGridControl/DataGridControl';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import TopbarButton from '../../components/Topbar/buttons/base/TopbarButton';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarActionButton from '../../components/Topbar/buttons/base/TopbarActionButton';

// :: Layouts
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

// :: Utils
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';

// :: Hooks
import { useAllSpaces } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useActionsColumn from '../../components/DataGrid/useActions';
import { useGridFilters } from '../../components/DataGrid/useGridFilters';
import useToken from '../../hooks/useToken';

// :: Contexts
import UserContext from '../../contexts/UserContext';

// :: Lib
import {
  getTestProps,
  setInitialGridOptions,
  prepareColumns,
  generateExportSpacesCsvUrl,
} from '../../lib/helpers';
import { getDefinedColumns } from './definedColumns';

// :: Images
import { WarningIcon } from '../../images/shapes';

const getApiFilters = (filters) => {
  if (!filters) return {};

  const newFilters = {};

  Object.keys(filters).forEach((key) => {
    if (key === 'organization.name') {
      newFilters.organizationName = filters['organization.name'].filter;
    } else if (key === 'organization.id') {
      newFilters.organizationId = filters['organization.id'].filter;
    } else if (key === 'organization.paymentDueDate') {
      newFilters.organizationPaymentDueDate =
        filters['organization.paymentDueDate'].filter;
    } else {
      newFilters[key] = filters[key].filter;
    }
  });

  return newFilters;
};

const Spaces = ({ testId }) => {
  const jwt = useToken();
  const SORT_KEY = 'cms.all-spaces-sort';
  const OPTIONS_KEY = 'cms.all-spaces-grid-state';

  const spacesGridContainer = useRef();
  const { t } = useTranslation();
  const { isRoleAdmin } = useContext(UserContext);
  const [searchParams] = useSearchParams();

  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const [sort, setSort] = useState(getLocalStorage(SORT_KEY, true));
  const [firstLoading, setFirstLoading] = useState(true);
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const [editGrid, setEditGrid] = useState(false);
  const [filterGrid, setFilterGrid] = useState(!!searchParams.get('filters'));
  const [columns, setColumns] = useState([]);

  const {
    filters,
    filtersApplied,
    handleFiltersChange,
    page,
    handlePageChange,
    limit,
    handleLimitChange,
    removeAllFilters,
  } = useGridFilters('spaces');

  useEffect(() => {
    setSort(getLocalStorage(SORT_KEY, true));
  }, [SORT_KEY]);

  useEffect(() => {
    setGridOptions(getLocalStorage(OPTIONS_KEY, true));
  }, [OPTIONS_KEY]);

  const spacesParams = useMemo(
    () => ({
      page: page,
      limit: limit,
      ...getApiFilters(filters),
      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),
    }),
    [page, limit, filters, sort?.sortOrder, sort?.sortField],
  );

  const allSpacesOptions = useMemo(
    () => ({
      pause: !isRoleAdmin,
    }),
    [isRoleAdmin],
  );

  const {
    data: allSpaces,
    errors: allSpacesError,
    status: allSpacesStatus,
    isLoading: allSpacesAreLoading,
    pagination,
    reload: allSpacesReload,
  } = useAllSpaces(spacesParams, allSpacesOptions);

  useApiErrorsToast(allSpacesError);

  useEffect(() => {
    if (pagination?.total_pages < page)
      handlePageChange(pagination.total_pages);
  }, [handlePageChange, page, pagination?.total_pages]);

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (!allSpacesAreLoading) setFirstLoading(false);
  }, [allSpacesAreLoading, firstLoading]);

  useEffect(() => {
    if (!initDataHasContent && allSpaces.length) {
      setInitDataHasContent(true);
    }
  }, [initDataHasContent, allSpaces.length]);

  const handleResetGrid = useCallback(() => {
    setSort();
    removeAllFilters();
    removeLocalStorage(SORT_KEY);
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
  }, [removeAllFilters]);

  const showGrid = useMemo(() => {
    return (
      isRoleAdmin &&
      (allSpaces.length > 0 ||
        (allSpacesAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent)
    );
  }, [
    firstLoading,
    filtersApplied,
    initDataHasContent,
    isRoleAdmin,
    allSpaces.length,
    allSpacesAreLoading,
  ]);

  const emptyData = useMemo(() => {
    if (showGrid) return null;
    if (firstLoading && isRoleAdmin) {
      return (
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loading', 'testId')}
        />
      );
    }
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          {allSpacesStatus === 403 || !isRoleAdmin
            ? t('Global.NoAccess')
            : t('Media.OnErrorMessage')}
        </div>
      </Heading>
    );
  }, [showGrid, firstLoading, isRoleAdmin, testId, allSpacesStatus, t]);

  const handleInitialGridOptions = useCallback(
    (cols, minWidth) => {
      setInitialGridOptions(
        cols,
        minWidth,
        spacesGridContainer.current.offsetWidth - 50,
        setGridOptions,
        setColumns,
        OPTIONS_KEY,
      );
    },
    [OPTIONS_KEY],
  );

  useEffect(() => {
    prepareColumns(
      gridOptions,
      OPTIONS_KEY,
      editGrid,
      t,
      testId,
      setGridOptions,
      handleInitialGridOptions,
      setColumns,
      getDefinedColumns,
    );
  }, [t, handleInitialGridOptions, gridOptions, testId, editGrid, OPTIONS_KEY]);

  const actionColumn = useActionsColumn('/spaces');

  const allColumns = useMemo(
    () => [actionColumn, ...columns],
    [columns, actionColumn],
  );

  return (
    <PageLayout
      page="admin/all-spaces"
      menuItemOpen="admin"
      title={t('Global.AllSpaces')}
      breadcrumbs={<TopbarBreadcrumbs />}
      buttons={
        <>
          <TopbarButton
            label={t('Users.Export')}
            link={generateExportSpacesCsvUrl(jwt)}
          />
          <TopbarActionMenu>
            <TopbarActionButton
              label={t('Users.ExportCancelled')}
              link={generateExportSpacesCsvUrl(jwt, {
                subscriptionStatus: 'canceled',
              })}
            />
          </TopbarActionMenu>
        </>
      }
      secondaryTopbar={
        <DataGridControl
          displayGridCheckboxes={showGrid && columns.length > 0}
          displayGridControl={showGrid}
          editGrid={editGrid}
          filterGrid={filterGrid}
          setEditGrid={setEditGrid}
          setFilterGrid={setFilterGrid}
          handleResetGrid={handleResetGrid}
          limit={limit}
          handleLimitChange={handleLimitChange}
          columns={columns}
          gridOptions={gridOptions}
          optionsLocalStorageKey={OPTIONS_KEY}
          setGridOptions={setGridOptions}
          columnsVisibilityAdditionalClasses={twMerge('-top-2 xs:top-[0.2rem]')}
          testId={testId}
        />
      }
      noPaddings
    >
      <div className={predefinedLayoutClasses.withDataGrid}>
        <div
          className={predefinedLayoutClasses.dataGridContainer}
          ref={spacesGridContainer}
        >
          {showGrid ? (
            <CustomizableDataGrid
              columns={allColumns}
              setColumns={setColumns}
              data={allSpaces}
              isLoading={allSpacesAreLoading}
              setSort={setSort}
              sort={sort?.sortField || undefined}
              sortOrder={sort?.sortOrder || undefined}
              sortingLocalStorageKey={SORT_KEY}
              optionsLocalStorageKey={OPTIONS_KEY}
              setCurrentPage={handlePageChange}
              editGrid={editGrid}
              gridOptions={gridOptions}
              setGridOptions={setGridOptions}
              noDataInfoText={t('Spaces.FiltersEmptyResult')}
              hasFilters={filterGrid}
              filters={filters}
              disableFilters={editGrid}
              onFilter={handleFiltersChange}
              statusBar={
                <StatusBar
                  rows={pagination.count}
                  currentPage={page}
                  pagesCount={pagination.total_pages}
                  handlePageChange={handlePageChange}
                  handleDataUpdate={allSpacesReload}
                  resultsFrom={(pagination.current_page - 1) * limit + 1}
                  resultsTo={
                    (pagination.current_page - 1) * limit + pagination.count
                  }
                  resultsTotalCount={pagination.total_count}
                  {...getTestProps(testId, 'statusbar', 'testId')}
                />
              }
              additionalClasses="bg-white dark:bg-gray-900"
              {...getTestProps(testId, 'grid', 'testId')}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              {emptyData}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default Spaces;

Spaces.propTypes = {
  /**
   * Test id for users page
   */
  testId: PropTypes.string,
};

Spaces.defaultProps = {
  testId: '',
};
