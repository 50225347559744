import PropTypes from 'prop-types';

// :: Component
import Panel from '../../components/Panel/Panel';
import SocialButton from '../../components/SocialButton/SocialButton';

const SocialsPanel = ({ title, items, id }) => {
  return (
    <Panel
      title={title}
      open={false}
      additionalContainerClasses="py-4 px-4"
      additionalChildrenClasses="!h-full"
      id={id}
    >
      <ul className="flex flex-wrap w-full">
        {items?.map((item) => (
          <li key={`social-${item.name}`} className="w-[36%] mr-8 mb-3">
            <SocialButton name={item.name} icon={item.icon} link={item.link} />
          </li>
        ))}
      </ul>
    </Panel>
  );
};

export default SocialsPanel;

SocialsPanel.propTypes = {
  title: PropTypes.any,
  items: PropTypes.any,
  spaceless: PropTypes.any,
  id: PropTypes.string,
};

SocialsPanel.defaultProps = {
  title: '',
  items: [],
  spaceless: false,
  id: '',
};
