import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  GoogleMap,
  MarkerF,
  useJsApiLoader,
  Autocomplete,
} from '@react-google-maps/api';
import Loader from '../Loader/Loader';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';

const libraries = ['places'];

const Maps = ({ center, markerPosition, disabled, onMarkerPositionChange }) => {
  const { t } = useTranslation();
  const [currentCenter, setCurrentCenter] = useState(center);
  const [currentMarkerPosition, setCurrentMarkerPosition] =
    useState(markerPosition);
  const [autocomplete, setAutocomplete] = useState(null);

  useEffect(() => {
    setCurrentMarkerPosition(markerPosition);
  }, [markerPosition]);

  useEffect(() => {
    setCurrentCenter(center);
  }, [center]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const handleMarkerPositionChange = useCallback(
    (pos) => {
      if (disabled) return;
      setCurrentMarkerPosition({
        lat: pos.latLng.lat(),
        lng: pos.latLng.lng(),
      });
      onMarkerPositionChange({ lat: pos.latLng.lat(), lon: pos.latLng.lng() });
    },
    [onMarkerPositionChange, disabled],
  );

  const onLoad = useCallback((autocomp) => {
    setAutocomplete(autocomp);
  }, []);

  const onPlaceChanged = useCallback(() => {
    if (autocomplete !== null) {
      const newPosition = {
        lat: autocomplete.getPlace().geometry.location.lat(),
        lng: autocomplete.getPlace().geometry.location.lng(),
      };
      setCurrentMarkerPosition(newPosition);
      setCurrentCenter(newPosition);
      onMarkerPositionChange({ lat: newPosition.lat, lon: newPosition.lng });
    }
  }, [autocomplete, onMarkerPositionChange]);

  return (
    <div className="w-full h-fit border border-white-600 dark:border-slate-800 rounded-lg overflow-hidden">
      {isLoaded ? (
        <>
          {!disabled && (
            <Autocomplete
              className="h-fit w-full"
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                className={twMerge(
                  'text-indigo-950 text-base h-10 w-full border-slate-200 dark:border-slate-800 border-b',
                  'rounded-t-lg px-4 py-3 text-ellipsis focus:outline-none focus:border-blue dark:bg-transparent',
                  'placeholder:text-slate-400 placeholder:font-light dark:text-white',
                )}
                type="text"
                placeholder={t('ContentForm.FindPlace')}
                disabled={!autocomplete}
              />
            </Autocomplete>
          )}
          <GoogleMap
            mapContainerClassName="w-full h-[280px]"
            center={currentCenter}
            zoom={10}
            onClick={handleMarkerPositionChange}
          >
            <MarkerF
              position={currentMarkerPosition}
              onDragEnd={handleMarkerPositionChange}
              draggable={!disabled}
            />
          </GoogleMap>
        </>
      ) : (
        <div className="border-slate-200 border rounded-lg flex items-center justify-center w-full h-[280px]">
          <Loader size="small" type="spinner-grid" />
        </div>
      )}
    </div>
  );
};

export default Maps;

Maps.propTypes = {
  /**
   * Postition where map should be centered
   */
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  /**
   * Marker position
   */
  markerPosition: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  /**
   * If marker changes are disabled
   */
  disabled: PropTypes.bool,
  /**
   * On marker position change callback
   */
  onMarkerPositionChange: PropTypes.func,
};

Maps.defaultProps = {
  center: {
    lat: 0,
    lng: 0,
  },
  markerPosition: {
    lat: 0,
    lng: 0,
  },
  disabled: false,
  onMarkerPositionChange: /* istanbul ignore next */ () => null,
};
