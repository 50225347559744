import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Images
import { CheckCircleIcon, DotIcon } from '../../images/shapes';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const CheckCircle = ({ checked, additionalClasses, testId, ...props }) => {
  return (
    <>
      {checked ? (
        <CheckCircleIcon
          className={twMerge('text-blue w-6', additionalClasses)}
          {...props}
          {...getTestProps(testId, 'checked')}
        />
      ) : (
        <DotIcon
          className={twMerge(
            'text-gray dark:text-gray-700 w-6',
            additionalClasses,
          )}
          {...props}
          {...getTestProps(testId, 'unchecked')}
        />
      )}
    </>
  );
};

export default CheckCircle;

CheckCircle.propTypes = {
  /**
   * True of false checkbox
   */
  checked: PropTypes.bool,
  /**
   * Additional CSS classes for component
   */
  additionalClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

CheckCircle.defaultProps = {
  checked: true,
  additionalClasses: '',
  testId: '',
};
