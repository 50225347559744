import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from '@headlessui/react';
import {
  DndProvider,
  MouseTransition,
  MultiBackend,
  TouchTransition,
} from 'react-dnd-multi-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import useLocalStorageState from 'use-local-storage-state';
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

// :: Router
import AppRouter from '../../router/AppRouter';

// :: Context
import { AppContext } from '../../contexts/AppContext';
import NewMediaContext from '../../contexts/NewMediaContext';

// :: Hooks
import useToken from '../../hooks/useToken';
import useSelectedSpace from '../../hooks/useSelectedSpace';

// :: Helpers
import { patchMedia, uploadFiles } from '../../lib/flotiq-client/api-helpers';

// :: Components
import Toast from '../../components/Toast/Toast';

const INITIAL_STATE = {
  fullName: 'Flotiq Page',
  language: 'en',
  page: 'dashboard',
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      placeholderData: keepPreviousData,
    },
  },
});

const App = () => {
  const { t, i18n } = useTranslation();
  const jwt = useToken();
  const { space } = useSelectedSpace();
  const [appContext, setAppContext] = useState(INITIAL_STATE);
  const [user] = useLocalStorageState('cms.user');
  const [debuggerEnabled] = useLocalStorageState('cms.debugger');
  const userLang = useMemo(() => user?.data?.language, [user]);

  const providerValue = useMemo(
    () => ({ appContext, updateAppContext: setAppContext }),
    [appContext],
  );

  useEffect(() => {
    setAppContext((prevState) => ({
      ...prevState,
      user: {
        ...prevState.user,
        language: userLang,
      },
    }));

    if (userLang !== i18n.language) {
      i18n.changeLanguage(userLang);
    }
  }, [userLang, i18n]);

  const [newMedia, setNewMedia] = useState([]);
  const [reloadMediaLimit, setReloadMediaLimit] = useState(false);

  const onUpload = useCallback(
    async (files) => {
      if (!jwt || !files) return;

      const mediaList = Array.from(files);
      if (!mediaList.length) return;

      mediaList.forEach((file) => (file.id = Date.now() + Math.random()));
      setNewMedia((previousList) => [...mediaList, ...previousList]);

      const media = await uploadFiles(jwt, appContext?.space, mediaList, t);
      setNewMedia((previousList) => {
        const newList = [...previousList];
        media.forEach((uploadedMedia) => {
          const index = newList.findIndex(
            (element) =>
              element[uploadedMedia[1] ? 'id' : 'name'] ===
              uploadedMedia[0][uploadedMedia[1] ? 'id' : 'fileName'],
          );
          if (index > -1) newList[index] = uploadedMedia;
          else newList.push(uploadedMedia);
        });
        return newList;
      });
      setReloadMediaLimit(true);
      return media;
    },
    [jwt, appContext?.space, t],
  );

  const updateVariants = useCallback(
    (mediaId, variants, isDeleting = false) => {
      if (!jwt || !mediaId) return;

      return patchMedia(
        jwt,
        space,
        mediaId,
        {
          variants,
        },
        t,
        isDeleting
          ? t('MediaEdit.Variant.Deleted')
          : t('MediaEdit.Variant.Updated'),
        isDeleting
          ? t('MediaEdit.Variant.DeleteError')
          : t('MediaEdit.Variant.UpdateError'),
      );
    },
    [jwt, t, space],
  );

  const newMediaContextValue = useMemo(
    () => ({
      newMedia,
      setNewMedia,
      onUpload,
      reloadMediaLimit,
      setReloadMediaLimit,
      updateVariants: updateVariants,
    }),
    [newMedia, onUpload, reloadMediaLimit, updateVariants],
  );

  const HTML5toTouch = useMemo(
    () => ({
      backends: [
        {
          id: 'html5',
          backend: HTML5Backend,
          transition: MouseTransition,
        },
        {
          id: 'touch',
          backend: TouchBackend,
          options: { enableMouseEvents: true },
          preview: true,
          transition: TouchTransition,
        },
      ],
    }),
    [],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <Portal>
        <Toast />
      </Portal>
      <DndProvider backend={MultiBackend} options={HTML5toTouch}>
        <AppContext.Provider value={providerValue}>
          <NewMediaContext.Provider value={newMediaContextValue}>
            <AppRouter />
          </NewMediaContext.Provider>
        </AppContext.Provider>
      </DndProvider>
      {debuggerEnabled === true && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
};

export default App;
