import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';

const FormStateHandler = ({ setFormikState }) => {
  const {
    // form state
    dirty,
    values,
    errors,
    touched,
    status,
    isValid,

    // form handlers
    submitForm,
    validateForm,
    resetForm,

    // field handlers
    setFieldValue,
    setFieldTouched,
  } = useFormikContext();

  useEffect(() => {
    setFormikState?.({
      dirty,
      values,
      errors,
      touched,
      status,
      isValid,
      submitForm,
      validateForm,
      resetForm,
      setFieldValue,
      setFieldTouched,
    });
  }, [
    dirty,
    errors,
    isValid,
    resetForm,
    setFieldTouched,
    setFieldValue,
    setFormikState,
    status,
    submitForm,
    touched,
    validateForm,
    values,
  ]);
};

export default FormStateHandler;

FormStateHandler.propTypes = {
  /**
   * Callback for updating formik state
   */
  setFormikState: PropTypes.func,
};

FormStateHandler.defaultProps = {};
