import { useCallback, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useModals } from '../../contexts/ModalContext';
import { useTranslation } from 'react-i18next';

const Blocker = ({ dirty }) => {
  const { t } = useTranslation();
  const modal = useModals();

  const blocker = useBlocker(dirty);

  const handleUnload = useCallback(
    (event) => {
      if (dirty) {
        event.preventDefault();
        event.returnValue = '';
      }
    },
    [dirty],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleUnload);
    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [handleUnload]);

  const showModal = useCallback(async () => {
    const result = await modal.confirmation(
      t('DirtyHandler.ShouldLeaveConfirm'),
      t('DirtyHandler.ShouldLeave'),
      t('DirtyHandler.Leave'),
      t('DirtyHandler.Stay'),
    );
    if (result) {
      blocker.proceed();
    } else {
      blocker.reset();
    }
  }, [modal, blocker, t]);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      if (!dirty) {
        blocker.proceed();
      } else {
        showModal();
      }
    }
  }, [showModal, blocker, dirty]);
};

export default Blocker;

Blocker.propTypes = {
  /**
   * Dirty state used for overriding formik dirty state
   */
  dirty: PropTypes.bool,
};

Blocker.defaultProps = { dirty: false };
