import { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';

export default function useDarkMode() {
  const [darkMode, setDarkMode] = useLocalStorageState('flotiq-dark-theme', {
    defaultValue: false,
  });

  useEffect(() => {
    const root = window.document.documentElement;

    if (darkMode) {
      root.classList.add('mode-dark');
    } else {
      root.classList.remove('mode-dark');
    }
  }, [darkMode]);

  return [darkMode, setDarkMode];
}
