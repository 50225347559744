import { useCallback, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import SpaceSelectorElement from './SpaceSelectorElement/SpaceSelectorElement';
import { getTestProps } from '../../lib/helpers';
import { getIn } from 'formik';
import UserContext from '../../contexts/UserContext';

const SpaceSelector = ({
  arrayHelpers,
  spaces,
  userRolesBySpace,
  disabled,
  error,
  testId,
}) => {
  const { isAdmin } = useContext(UserContext);

  const value = useMemo(
    () => getIn(arrayHelpers.form.values, arrayHelpers.name) || [],
    [arrayHelpers.name, arrayHelpers.form],
  );

  const selectedIds = useMemo(() => value.map(({ id }) => id), [value]);

  const removeSpace = useCallback(
    async (idx) => {
      arrayHelpers.remove(idx);
    },
    [arrayHelpers],
  );

  const availableSpaces = useMemo(
    () => spaces.filter(({ value }) => !selectedIds.includes(value)),
    [selectedIds, spaces],
  );

  const placeholderIndex = useMemo(() => value?.length || 0, [value?.length]);

  return (
    <div
      className="flex flex-col gap-8 md:gap-4"
      {...getTestProps(testId, 'space-selector-container')}
    >
      {value.map(({ id }, idx) => (
        <SpaceSelectorElement
          key={id}
          arrayHelpers={arrayHelpers}
          idx={idx}
          spaceId={id}
          onRemove={removeSpace}
          availableSpaces={availableSpaces}
          allSpaces={spaces}
          userRolesBySpace={userRolesBySpace}
          disabled={disabled}
          testId={testId}
        />
      ))}

      {isAdmin && (
        <SpaceSelectorElement
          key={placeholderIndex}
          arrayHelpers={arrayHelpers}
          idx={placeholderIndex}
          availableSpaces={availableSpaces}
          allSpaces={spaces}
          userRolesBySpace={userRolesBySpace}
          disabled={disabled}
          testId={testId}
        />
      )}
      <HelpErrorTextsTemplate error={error} />
    </div>
  );
};

export default SpaceSelector;

SpaceSelector.propTypes = {
  /**
   *  Array helpers from formik
   */
  arrayHelpers: PropTypes.object.isRequired,
  /**
   * Array of space links
   */
  spaces: PropTypes.arrayOf(PropTypes.object),
  /**
   * User roles organized by space
   */
  userRolesBySpace: PropTypes.object,
  /**
   * If field is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Field errors
   */
  error: PropTypes.any,
  /**
   * Test id for SpaceSelector
   */
  testId: PropTypes.string,
};

SpaceSelector.defaultProps = {
  spaces: [],
  disabled: false,
  testId: '',
  userRolesBySpace: {},
};
