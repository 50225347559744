import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired on language change in the editor.
 * No result is expected.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.language::changed"
 * @type {LanguageChangedEvent}
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name LanguageChangedEvent
 *
 * @property {("pl"|"en")} language Current selected language
 */
export class LanguageChangedEvent extends IFlotiqPluginEvent {
  language;
}
