import { useCallback, useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Components
import Card from '../Card/Card';
import Button from '../Button/Button';
import Loader from '../Loader/Loader';

// :: Images
import { CheckmarkIcon, DownloadIcon } from '../../images/shapes';

// :: Contexts
import UserContext from '../../contexts/UserContext';

const UnsplashCard = ({ mediaData, saveMediaCallback, testId }) => {
  const { t } = useTranslation();
  const { permissions } = useContext(UserContext);

  const { canCreate } = useMemo(
    () => permissions.getCoPermissions('_media') || {},
    [permissions],
  );

  const imageUrl = useMemo(() => mediaData.urls.thumb, [mediaData]);

  const [isSaving, setIsSaving] = useState(false);
  const [locked, setLocked] = useState(false);

  const saveImage = useCallback(async () => {
    setIsSaving(true);
    setLocked(await saveMediaCallback(mediaData.id));
    setIsSaving(false);
  }, [mediaData, saveMediaCallback]);

  const btnIcon = useMemo(() => {
    if (locked) {
      return (
        <CheckmarkIcon
          className="w-4 text-blue"
          title={t('Media.MediaCard.SavedMediaBtn')}
        />
      );
    }
    return (
      <DownloadIcon
        className="w-4 text-blue"
        title={t('Media.MediaCard.SaveMediaBtn')}
      />
    );
  }, [locked, t]);

  const imageOverlay = useMemo(() => {
    return (
      <div className="flex flex-col h-full p-3 justify-end">
        <div className="flex justify-end transition duration-200 ease-in-out p-1 space-x-2">
          {canCreate && (
            <Button
              testId={`${testId}-save-media-btn`}
              additionalClasses={twMerge(
                'bg-white dark:bg-[#0B1E39]',
                'border-blue',
                'hover:bg-white',
                'hover:border-white dark:hover:border-slate-800',
                'disabled:bg-white',
              )}
              buttonSize="xs"
              iconColor="black"
              iconImage={
                isSaving ? (
                  <Loader
                    size={'small'}
                    type={'spinner-grid'}
                    testId={`${testId}-media-loading`}
                  />
                ) : (
                  btnIcon
                )
              }
              children={''}
              disabled={isSaving || locked}
              onClick={(event) => {
                event.stopPropagation();
                saveImage();
              }}
            />
          )}
        </div>
      </div>
    );
  }, [btnIcon, canCreate, isSaving, locked, saveImage, testId]);

  return (
    <Card
      testId={testId}
      body={
        <div className="w-full h-full p-2 text-xs sm:text-sm bg-white dark:bg-slate-950 font-light sm:p-4 ">
          <div className="flex flex-col mt-2">
            <div className="text-sm">
              <div
                {...getTestProps(testId, 'image-author')}
                className="dark:text-white"
              >
                {t('Media.MediaCard.ImageData.UnsplashAuthor')}
                <Link
                  to={mediaData.user.links.html}
                  className="text-blue whitespace-nowrap"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {mediaData.user.name}
                </Link>
              </div>
              <div
                {...getTestProps(testId, 'image-source')}
                className="dark:text-white"
              >
                {t('Media.MediaCard.ImageData.UnsplashSource')}
                <Link
                  to={mediaData.links.html}
                  className="text-blue whitespace-nowrap"
                  target={'_blank'}
                  rel="noreferrer"
                >
                  {t('Media.MediaCard.Unsplash')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      }
      imageOverlay={imageOverlay}
      imageUrl={imageUrl}
      imageUrlAlt={mediaData.fileName}
      additionalContainerClasses="w-auto border border-slate-100 dark:border-none"
      additionalImageClasses="object-scale-down h-32 w-auto sm:h-52 dark:bg-gray-700"
      additionalBodyClasses="h-full"
      additionalImageContainerClasses="h-32 w-auto sm:h-52 dark:bg-gray-700"
    />
  );
};

export default UnsplashCard;

UnsplashCard.propTypes = {
  /**
   * Object with image data
   */
  mediaData: PropTypes.object,
  /**
   * Callback for saving unsplash media
   */
  saveMediaCallback: PropTypes.func,
  /**
   * Card test id
   */
  testId: PropTypes.string,
};

UnsplashCard.defaultProps = {
  mediaData: {
    fileName: 'Unknown',
    size: 'Unknown',
    source: 'Unknown',
    sourceUrl: '#',
    author: 'Unknown',
    authorUrl: '#',
    links: { html: '#' },
    urls: { thumb: '#' },
    user: { links: { html: '#' } },
  },
  testId: '',
  saveMediaCallback: () => {},
};
