import * as yup from 'yup';

export const simplePasswordSchema = (t) =>
  yup
    .string()
    .min(8, t('Form.FormErrorPasswordMinimumLength'))
    .required(t('Form.FormErrorPasswordIsRequired'));

export const passwordSchema = (t) =>
  simplePasswordSchema(t).matches(
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.* )(?=.*[\W_])[A-Za-z\d\W_]{8,}$/,
    t('Form.FormErrorPasswordMatches'),
  );

export const emailSchema = (t) =>
  yup
    .string()
    .email(t('Form.FormErrorEnterValidEmail'))
    .required(t('Form.FormErrorEmailIsRequired'));
