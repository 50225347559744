import {
  checkboxRenderer,
  defaultRenderer,
} from '../../components/DataGrid/DataGridCell/cellRenderer';

export const getDefinedColumns = (
  gridOptionsByID,
  hiddenColumnsById,
  editGrid,
  t,
) => ({
  id: {
    accessor: 'id',
    label: 'ID',
    width: gridOptionsByID?.['id']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.id,
  },
  name: {
    accessor: 'name',
    label: t('Plans.Name'),
    width: gridOptionsByID?.['name']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.name,
  },
  visibleName: {
    accessor: 'visibleName',
    label: t('Plans.VisibleName'),
    width: gridOptionsByID?.['visibleName']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.visibleName,
  },
  displayOrder: {
    accessor: 'displayOrder',
    label: t('Plans.DisplayOrder'),
    width: gridOptionsByID?.['displayOrder']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.displayOrder,
    baseWidth: 100,
  },
  stripePriceApiIdMonthly: {
    accessor: 'stripePriceApiIdMonthly',
    label: t('Plans.StripePriceApiIdMonthly'),
    width: gridOptionsByID?.['stripePriceApiIdMonthly']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.stripePriceApiIdMonthly,
  },
  stripePriceApiIdYearly: {
    accessor: 'stripePriceApiIdYearly',
    label: t('Plans.stripePriceApiIdYearly'),
    width: gridOptionsByID?.['stripePriceApiIdYearly']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.stripePriceApiIdYearly,
  },
  price: {
    accessor: 'price',
    label: t('Plans.Price'),
    width: gridOptionsByID?.['price']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.price,
    baseWidth: 100,
  },
  priceYearly: {
    accessor: 'priceYearly',
    label: t('Plans.PriceYearly'),
    width: gridOptionsByID?.['priceYearly']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.price,
    baseWidth: 100,
  },
  enabled: {
    accessor: 'enabled',
    label: t('Plans.Enabled'),
    width: gridOptionsByID?.['enabled']?.width,
    render: (data) => checkboxRenderer(data),
    sortable: !editGrid,
    filterInputType: 'checkbox',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.enabled,
    baseWidth: 110,
  },
  visible: {
    accessor: 'visible',
    label: t('Plans.Visible'),
    width: gridOptionsByID?.['visible']?.width,
    render: (data) => checkboxRenderer(data),
    sortable: !editGrid,
    filterInputType: 'checkbox',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.visible,
    baseWidth: 110,
  },
  defaultPlan: {
    accessor: 'defaultPlan',
    label: t('Plans.DefaultPlan'),
    width: gridOptionsByID?.['defaultPlan']?.width,
    render: (data) => checkboxRenderer(data),
    sortable: !editGrid,
    filterInputType: 'checkbox',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.defaultPlan,
    baseWidth: 110,
  },
  defaultFreePlan: {
    accessor: 'defaultFreePlan',
    label: t('Plans.DefaultFreePlan'),
    width: gridOptionsByID?.['defaultFreePlan']?.width,
    render: (data) => checkboxRenderer(data),
    sortable: !editGrid,
    filterInputType: 'checkbox',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.defaultFreePlan,
    baseWidth: 110,
  },
});
