import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { DragIcon } from '../../../images/shapes';
import RelationCard from '../../RelationCard/RelationCard';
import { getTestProps } from '../../../lib/helpers';

const DraggableRelationCard = ({
  onDrop,
  dataUrl,
  disabled,
  relationsLength,
  testId,
  ...cardProps
}) => {
  const ref = useRef();

  const [, drop] = useDrop({
    accept: 'relation-card',
    canDrop: () => !disabled,
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }

      if (monitor.canDrop() && !monitor.didDrop() && !monitor.isOver()) {
        onDrop(item.dataUrl, dataUrl);
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { dataUrl },
    type: 'relation-card',
    canDrag: () => !disabled,
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  return (
    <RelationCard
      {...cardProps}
      ref={drop(preview(ref))}
      dragElement={
        relationsLength > 1 ? (
          <div
            className={twMerge(
              'w-fit px-1 rounded-sm opacity-50 hover:opacity-100 cursor-grab',
              isDragging && 'cursor-grabbing',
              disabled && 'cursor-not-allowed',
            )}
            {...getTestProps(testId, 'drag-icon')}
            ref={drag}
          >
            <DragIcon className="text-gray-700 dark:text-gray-50 w-2" />
          </div>
        ) : null
      }
      disabled={disabled}
      relationsLength={relationsLength}
      additionalContainerClasses={isDragging ? 'opacity-50 shadow-lg ' : ''}
      testId={testId}
    />
  );
};

export default DraggableRelationCard;

DraggableRelationCard.propTypes = {
  /**
   * On card drop
   */
  onDrop: PropTypes.func.isRequired,
  /**
   * Relation data URL
   */
  dataUrl: PropTypes.string.isRequired,
  ...RelationCard.propTypes,
};

DraggableRelationCard.defaultProps = {};
