import useLocalStorageState from 'use-local-storage-state';
import { getUserPreferences } from './UISettings/uiSettings';
import { useCallback, useMemo } from 'react';

export const usePagePreferences = (page) => {
  const [userLS] = useLocalStorageState('cms.user');

  const viewPreferences = useMemo(() => getUserPreferences(userLS), [userLS]);

  const isInViewPreferences = useCallback(
    (itemId) => viewPreferences?.[page]?.[itemId] !== false,
    [page, viewPreferences],
  );

  return isInViewPreferences;
};
