import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

// :: Component
import Button from '../../components/Button/Button';

// :: Images
import { FlotiqLogo } from '../../images/shapes';

const Page404 = () => {
  const { t } = useTranslation();
  const [darkMode] = useDarkMode();

  return (
    <div
      className={twMerge(
        'w-full h-screen flex flex-col justify-center items-center dark:text-white',
        darkMode ? 'bg-gray-900' : 'brand-bg-gradient',
      )}
    >
      <FlotiqLogo className="h-10 mb-16" />
      <p className="mb-10 text-[300px] font-bold leading-[230px]">404</p>
      <p className="mb-14 text-5xl">{t('Global.PageNotFound')}</p>
      <Link to="/">
        <Button>{`${t('Global.BackTo')} ${t('Global.Dashboard')}`}</Button>
      </Link>
    </div>
  );
};

export default Page404;
