import { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

// :: Components
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import Dropdown from '../../components/Dropdown/Dropdown';
import StatusPill from '../../components/StatusPill/StatusPill';
import Heading from '../../components/Heading/Heading';
import Card from '../../components/Card/Card';
import Pagination from '../../components/Pagination/Pagination';
import Loader from '../../components/Loader/Loader';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarButton from '../../components/Topbar/buttons/base/TopbarButton';

// :: Hooks
import useDebounceCallback from '../../hooks/useDebounceCallback';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { useStarters } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Layout
import PageLayout from '../../layout/PageLayout/PageLayout';

// :: Images
import {
  PeopleWithHeartImage,
  RefreshIcon,
  GatsbyIcon,
  NextJSIcon,
  ArrowUpRightIcon,
  EyeIcon,
} from '../../images/shapes';

const optionsCategory = [
  { value: 'Blog', label: 'Blog' },
  { value: 'Boilerplate', label: 'Boilerplate' },
  { value: 'e-Commerce', label: 'e-Commerce' },
  { value: 'Event Calendar', label: 'Event Calendar' },
  { value: 'Portfolio', label: 'Portfolio' },
  { value: 'Recipe', label: 'Recipe' },
];

const optionsFramework = [
  { value: 'Gatsby', label: 'Gatsby' },
  { value: 'Next.js', label: 'Next.js' },
];

const frameworkIcon = (framework) => {
  if (framework === 'Gatsby') {
    return <GatsbyIcon className="w-6 h-6" />;
  } else if (framework === 'Next.js') {
    return <NextJSIcon className="w-6 h-6" />;
  }
};

const Starters = ({ testId }) => {
  const { t } = useTranslation();
  const { buildUrlWithSpace } = useSelectedSpace();
  const navigate = useNavigate();

  // :: Pagination
  const [pageLimit, setPageLimit] = useState(20);
  const [page, setPage] = useState(1);

  // :: Filters
  const [filterName, setFilterName] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [filterFramework, setFilterFramework] = useState('');

  const filters = useMemo(() => {
    return {
      name: { type: 'contains', filter: filterName },
      category: { type: 'contains', filter: filterCategory },
      framework: { type: 'contains', filter: filterFramework },
    };
  }, [filterName, filterCategory, filterFramework]);

  const params = useMemo(() => {
    return {
      limit: pageLimit,
      page: page,
      hydrate: 1,
      filters: JSON.stringify(filters),
    };
  }, [pageLimit, page, filters]);

  const [dataStarters, pagination, loading, , errors] = useStarters(params);

  useApiErrorsToast(errors);

  const handlePageChange = useCallback((newPage) => setPage(newPage), []);

  const handleBrowseMore = () => {
    setPage(1);
    setPageLimit((prevState) => prevState + 5);
  };

  const handleFilterDebounced = useDebounceCallback((e) => {
    setFilterName(e.target.value);
  }, 500);

  return (
    <PageLayout
      page="starters"
      title={t('Global.Starters')}
      breadcrumbs={<TopbarBreadcrumbs />}
      buttons={
        <TopbarButton
          label={t('Global.Documentation')}
          link={process.env.REACT_APP_DOCUMENTATION}
          target="_blank"
          rel="noreferrer"
        />
      }
      testId={testId}
    >
      <div
        className="flex flex-col items-center sm:flex-row mb-4"
        {...getTestProps(testId, 'container')}
      >
        <div
          className={twMerge(
            'flex flex-col justify-between',
            'text-start mb-4 sm:mb-0',
            'w-full md:w-1/3',
          )}
        >
          <div
            className={twMerge(
              'flex flex-col justify-between',
              'text-start font-bold text-2xl',
              'lg:text-4xl xl:text-5xl',
              'leading-[1.3rem] lg:leading-[3rem] xl:leading-[52px]',
            )}
          >
            <p className="text-indigo-950 dark:text-white">
              {t('Starters.StartWithProfessional')}
            </p>
          </div>
          <p className="text-sm md:text-base lg:text-xl font-normal max-w-xl dark:text-gray-50">
            {t('Starters.WeHaveAlreadyConfigured')}
          </p>
        </div>

        <div className="flex flex-col justify-between items-end text-start w-1/3 pr-6">
          <PeopleWithHeartImage className="mb-4 sm:mb-0 h-28 w-28 lg:h-48 lg:w-48" />
        </div>

        <div
          className={twMerge(
            'flex flex-col justify-between text-start',
            'w-full md:w-1/3',
          )}
        >
          <div
            className={twMerge(
              'flex flex-col justify-between text-start',
              'font-bold text-2xl lg:text-4xl xl:text-5xl  xl:leading-[52px]',
            )}
          >
            <p className="text-indigo-950 dark:text-white">
              {t('Starters.EveryStarter')}
            </p>
          </div>
          <p className="text-sm md:text-base lg:text-xl font-normal max-w-xl dark:text-gray-150">
            {t('Starters.UseOneOfTheStarters')}
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row  bg-white dark:bg-slate-950 rounded-lg p-5 mt-2">
        <Input
          label={t('ContentForm.Relation.Search')}
          placeholder={t('Starters.TypeNameOfTemplate')}
          onChange={handleFilterDebounced}
          value={filterName}
          type="search"
          additionalClasses="mr-5 w-full md:w-1/3 mb-4 md:mb-0"
          {...getTestProps(testId, 'filter-search')}
        />

        <Dropdown
          label={t('Starters.Category')}
          placeholder={t('Starters.SearchByCategory')}
          options={optionsCategory}
          value={filterCategory}
          onChange={(e) => setFilterCategory(e.target.value)}
          nullable
          additionalClasses="mr-5 w-full md:w-1/3 mb-4 md:mb-0"
          {...getTestProps(testId, 'filter-category')}
        />

        <Dropdown
          label={'Framework'}
          placeholder={t('Starters.SearchByFramework')}
          options={optionsFramework}
          value={filterFramework}
          onChange={(e) => setFilterFramework(e.target.value)}
          nullable
          additionalClasses="w-full md:w-1/3 mb-4 md:mb-0"
          {...getTestProps(testId, 'filter-framework')}
        />
      </div>

      <div className="flex-col justify-center space-y-14 mt-5">
        <div
          className={twMerge(
            'relative h-fit grid gap-2',
            'sm:gap-6 grid-cols-[repeat(auto-fill,_minmax(20rem,_1fr))] min-h-[70px]',
          )}
          {...getTestProps(testId, 'cards-container')}
        >
          {dataStarters.map((el) => (
            <Card
              key={el.id}
              imageUrl={`${process.env.REACT_APP_FLOTIQ_STARTERS_MEDIA_URL}${el.banner[0].url}`}
              imageOverlay={
                <div className="flex flex-col h-full p-3 justify-between relative group">
                  <div className="flex justify-between transition duration-200 ease-in-out p-1 space-x-2">
                    <StatusPill
                      onClick={() => setFilterCategory(el.category)}
                      status={
                        <div className="flex items-center">{el.category}</div>
                      }
                      containerClasses="!font-medium text-base bg-blue-600 text-white"
                      {...getTestProps(testId, `category-${el.id}`, 'testId')}
                    />
                    <Button
                      buttonColor="borderless"
                      noPaddings
                      iconImage={frameworkIcon(el.framework)}
                      onClick={() => setFilterFramework(el.framework)}
                      {...getTestProps(testId, `framework-${el.id}`, 'testId')}
                    />
                  </div>
                  <Button
                    iconImage={<EyeIcon className="h-5 w-5" />}
                    additionalClasses={twMerge(
                      'bg-white text-black border-none',
                      'hover:bg-white hover:text-black',
                      'hidden group-hover:flex',
                      'opacity-50 hover:opacity-100 !text-base',
                      'absolute bottom-4',
                    )}
                    additionalIconClasses={'h-5 w-5'}
                    buttonSize="xs"
                    onClick={() => window.open(el.demo_url, '_blank')}
                    {...getTestProps(testId, `livedemo-${el.id}`, 'testId')}
                  >
                    {t('Starters.LiveDemo')}
                  </Button>
                </div>
              }
              body={
                <div className="bg-white dark:bg-slate-950 p-5">
                  <Heading
                    level={2}
                    additionalClasses="text-xl pt-1 pb-2 leading-none min-h-[50px] dark:text-white"
                  >
                    {el.name}
                  </Heading>
                  <div
                    className="mt-1 min-h-[65px] dark:text-gray-50"
                    {...getTestProps(testId, `excerpt-${el.id}`)}
                  >
                    {el.excerpt}
                  </div>
                  <div className="h-0 border-t border-slate-200 dark:border-slate-800 w-full mt-4 mb-4" />
                  <div className="flex flex-wrap justify-between">
                    <Button
                      buttonColor="borderless"
                      noPaddings
                      iconImage={<ArrowUpRightIcon className="w-3 text-blue" />}
                      iconPosition="end"
                      additionalClasses="mt-2"
                      additionalChildrenClasses="text-base"
                      onClick={() => window.open(el.github_url, '_blank')}
                      {...getTestProps(testId, `github-${el.id}`, 'testId')}
                    >
                      Github
                    </Button>

                    <Button
                      buttonColor="borderless"
                      noPaddings
                      iconImage={<ArrowUpRightIcon className="w-3 text-blue" />}
                      iconPosition="end"
                      additionalChildrenClasses="text-base whitespace-nowrap"
                      additionalClasses="mt-2"
                      onClick={() =>
                        navigate(
                          buildUrlWithSpace(
                            `content-type-definitions/add?defaultType=${el.ctd_url}`,
                          ),
                        )
                      }
                      {...getTestProps(
                        testId,
                        `predefinedct-${el.id}`,
                        'testId',
                      )}
                    >
                      {t('Starters.StartWithPredefinedCT')}
                    </Button>
                  </div>
                </div>
              }
              additionalContainerClasses="w-full mb-4 md:mb-0"
              additionalImageContainerClasses="h-[288px]"
              testId={testId}
            />
          ))}
          {loading && (
            <div
              className={twMerge(
                'flex flex-row justify-center px-5 py-10 absolute',
                'rounded-lg top-0 bg-white dark:bg-slate-950 w-full h-full items-center',
                'opacity-90 min-h-[70px]',
              )}
              {...getTestProps(testId, `loader`)}
            >
              <Loader size="big" type="spinner-grid" />
            </div>
          )}
        </div>

        {page !== pagination.total_pages && pagination.total_pages > 1 && (
          <div className="flex flex-row justify-center">
            <Button
              buttonColor="blueBordered"
              iconPosition="end"
              buttonSize="sm"
              iconImage={<RefreshIcon className="w-5" />}
              onClick={handleBrowseMore}
              additionalClasses={'bg-transparent group'}
              additionalIconClasses={'text-blue group-hover:text-white'}
              {...getTestProps(testId, `browse-more`, 'testId')}
            >
              {t('Starters.BrowseMore')}
            </Button>
          </div>
        )}

        <Pagination
          page={page}
          numOfPages={pagination.total_pages}
          onPageChange={handlePageChange}
          additionalClasses="!p-0"
          {...getTestProps(testId, `pagination`, 'testId')}
        />
      </div>
    </PageLayout>
  );
};

export default Starters;

Starters.propTypes = {
  /**
   * Test id for starters page
   */
  testId: PropTypes.string,
};

Starters.defaultProps = {
  testId: '',
};
