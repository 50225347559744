import PropTypes from 'prop-types';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

const LogModalHeaders = ({ headers, testId }) => {
  if (headers && Object.entries(headers).length > 0) {
    const res = [];
    for (const [key, value] of Object.entries(headers)) {
      res.push(
        <div
          key={key}
          className="mb-1"
          {...getTestProps(testId, `item-${key}`)}
        >
          <span className="text-base">{key}</span>
          <span className="mr-2">:</span>
          <span className="text-base">{value}</span>
        </div>,
      );
    }
    return res;
  }
};

export default LogModalHeaders;

LogModalHeaders.propTypes = {
  /**
   * Response Headers
   */
  headers: PropTypes.object,
  /**
   * Test id
   */
  testId: PropTypes.string,
};

LogModalHeaders.defaultProps = {
  headers: {},
  testId: '',
};
