import { useCallback } from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel/Panel';
import { getTestProps } from '../../lib/helpers';
import { twMerge } from 'tailwind-merge';

const ContentObjectPlugins = ({
  plugins,
  contentObject,
  ctdName,
  additionalClasses,
  testId,
}) => {
  const handleLoad = useCallback(
    (e, message) => e.target.contentWindow.window.postMessage(message, '*'),
    [],
  );

  return (
    <Panel
      title={'Plugins'}
      isCollapsable={false}
      additionalTitleClasses="text-lg"
      additionalContainerClasses={twMerge(
        'py-5 px-4',
        additionalClasses,
      )}
      open
      {...getTestProps(testId, '', 'testId')}
    >
      <div className="flex flex-col mx-auto">
        {plugins.map((plugin) => (
          <iframe
            key={plugin.id}
            title={plugin.name}
            src={plugin.code}
            onLoad={(e) =>
              handleLoad(e, {
                type: 'plugin.init',
                settings: plugin.settings,
                context: {
                  id: contentObject?.id ? contentObject.id : false,
                  ctd: ctdName,
                  fields: contentObject || {},
                },
              })
            }
            className="min-h-[160px]"
            {...getTestProps(testId, plugin.id)}
          />
        ))}
      </div>
    </Panel>
  );
};

export default ContentObjectPlugins;

ContentObjectPlugins.propTypes = {
  /**
   * List of plugins
   */
  plugins: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      settings: PropTypes.shape({}),
      code: PropTypes.string,
    }),
  ),
  /**
   * Content object data
   */
  contentObject: PropTypes.shape({}),
  /**
   * Content object definition name
   */
  ctdName: PropTypes.string,
  /**
   * Additional css classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

ContentObjectPlugins.defaultProps = {
  plugins: [],
  contentObject: {},
  ctdName: '',
  testId: '',
};
