import { StarIcon } from '../../images/shapes';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';
import { useMemo, useState } from 'react';
import Loader from '../Loader/Loader';

export const PinContent = ({
  pined,
  parent,
  title,
  pinContentCallback,
  additionalContainerClasses,
  elementId,
  testId,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const elementLabel = useMemo(() => {
    if (!parent) return title;
    const folderName = parent.split('/')?.[1];
    const inFavorite = title.split('/').length !== 1;
    return folderName && !inFavorite ? `${folderName}/${title}` : title;
  }, [parent, title]);

  if (!parent) return;

  return (
    <div
      className={twMerge(
        additionalContainerClasses,
        'absolute flex justify-center left-1',
      )}
      onClick={async (event) => {
        event.stopPropagation();
        event.preventDefault();

        setIsLoading(true);
        await pinContentCallback(elementId, pined);
        setIsLoading(false);
      }}
      {...getTestProps(testId, `pin-content-${elementLabel}`)}
    >
      <Loader
        additionalClasses={twMerge(
          'h-4 w-4 transition-opacity !duration-700 invisible opacity-0 dark:text-white dark:invert',
          isLoading && 'visible opacity-100 ml-[18px]',
        )}
      />
      <StarIcon
        {...getTestProps(testId, `pin-content-icon-${elementLabel}`)}
        className={twMerge(
          'h-5 cursor-pointer hidden',
          !isLoading &&
            (pined
              ? 'block fill-amber-400'
              : 'group-hover:fill-gray-500 group-hover:!block'),
        )}
      />
    </div>
  );
};

export default PinContent;

PinContent.defaultProps = {
  pined: false,
  parent: '',
  title: '',
  pinContentCallback: () => {},
  elementId: '',
  additionalContainerClasses: '',
  testId: '',
};
