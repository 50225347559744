import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Components
import Button from '../Button/Button';
import ActionMenu from '../ActionMenu/ActionMenu';
import LinkButton from '../LinkButton/LinkButton';

// :: Images
import { CaretDownIcon } from '../../images/shapes';

const ActionButton = ({
  menuItems,
  children,
  actionMenuPlacement,
  additionalClasses,
  additionalPopoverPanelClasses,
  testId,
  ...props
}) => {
  const ButtonComponent = props.link ? LinkButton : Button;

  return (
    <div
      className="flex items-center relative"
      {...getTestProps(testId, 'action-button-container')}
    >
      <ButtonComponent
        additionalChildrenClasses="flex items-center justify-between"
        additionalClasses={twMerge(
          'text-base 2xl:text-base pr-2 rounded-r-none',
          additionalClasses,
        )}
        {...getTestProps(testId, 'action-btn', 'testId')}
        {...props}
      >
        {children}
      </ButtonComponent>
      <ActionMenu
        menuItems={menuItems}
        actionMenuPlacement={actionMenuPlacement}
        button={
          <CaretDownIcon
            className={'h-3 w-3 text-white'}
            {...getTestProps(testId, 'open')}
          />
        }
        additionalClasses={twMerge('rounded-l-none pl-2.5', additionalClasses)}
        additionalActionMenuClasses="static"
        additionalPopoverPanelClasses={additionalPopoverPanelClasses}
        as={Button}
        {...getTestProps(testId)}
        {...props}
        iconImage={undefined}
      />
    </div>
  );
};

ActionButton.propTypes = {
  /**
   * Action menu items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.node,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      additionalClasses: PropTypes.string,
    }),
  ).isRequired,
  /**
   * ActionButton contents
   */
  children: PropTypes.node,

  /**
   * Action menu test id
   */
  testId: PropTypes.string,
  /**
   *
   */
  actionMenuPlacement: PropTypes.oneOf([
    'topLeft',
    'topCenter',
    'topRight',
    'rightTop',
    'rightCenter',
    'rightBottom',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
    'leftTop',
    'leftCenter',
    'leftBottom',
  ]),
  /**
   * On parent button click callback
   */
  onClick: PropTypes.func,
  /**
   * Parent button icon image
   */
  iconImage: PropTypes.node,
  /**
   * Button additional classes
   */
  additionalClasses: PropTypes.string,
};

ActionButton.defaultProps = {
  children: '',
  onClick: /* istanbul ignore next */ () => null,
  iconImage: '',
  actionMenuPlacement: 'bottomRight',
  additionalClasses: '',
  testId: '',
};

export default ActionButton;
