import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Helpers
import { getDefaultImageProperties, getTestProps } from '../../lib/helpers';

// :: Components
import Loader from '../Loader/Loader';

const Card = forwardRef(
  (
    {
      imageUrl,
      imageUrlAlt,
      imageUrlLoading,
      thumbnail,
      body,
      imageOverlay,
      onClick,
      horizontal,
      darkenOnHover,
      additionalContainerClasses,
      additionalImageContainerClasses,
      additionalImageClasses,
      additionalBodyClasses,
      additionalThumbnailClasses,
      testId,
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={twMerge(
        'w-fit h-fit transition-all duration-300 relative overflow-hidden',
        'items-center justify-center group rounded-lg',
        horizontal ? 'inline-flex' : 'flex-col',
        onClick && 'cursor-pointer hover:shadow-xl',
        additionalContainerClasses,
      )}
      onClick={onClick}
      {...getTestProps(testId, 'card-container')}
    >
      <div
        className={twMerge(
          'group flex w-full h-full bg-gray overflow-hidden justify-center items-center',
          'relative',
          additionalImageContainerClasses,
        )}
        {...getTestProps(testId, 'image-container')}
      >
        {imageUrlLoading && (
          <div className="flex absolute inset-0 bg-indigo-950 opacity-60">
            <Loader type="spinner-white" additionalClasses="m-auto" />
          </div>
        )}
        {darkenOnHover && (
          <div
            className="transition-opacity duration-300 ease-in-out absolute h-full
                    w-full bg-indigo-950 opacity-0 group-hover:opacity-60"
            {...getTestProps(testId, 'image-darken')}
          />
        )}
        {imageOverlay && (
          <div
            className="absolute h-full w-full"
            {...getTestProps(testId, 'image-overlay')}
          >
            {imageOverlay}
          </div>
        )}

        {imageUrl && (
          <img
            key={imageUrl}
            src={imageUrl}
            alt={imageUrlAlt}
            className={twMerge(
              'w-full h-full object-center object-cover',
              additionalImageClasses,
            )}
            {...getDefaultImageProperties()}
            {...getTestProps(testId, 'image')}
          />
        )}

        {thumbnail && (
          <div
            className={twMerge('h-full w-full', additionalThumbnailClasses)}
            {...getTestProps(testId, 'thumbnail')}
          >
            {thumbnail}
          </div>
        )}
      </div>
      {body && (
        <div
          className={twMerge(additionalBodyClasses)}
          {...getTestProps(testId, 'body')}
        >
          {body}
        </div>
      )}
    </div>
  ),
);

Card.propTypes = {
  /**
   * Image url
   */
  imageUrl: PropTypes.string,
  /**
   * Image alt attribute
   */
  imageUrlAlt: PropTypes.string,
  /**
   * If image url is loading
   */
  imageUrlLoading: PropTypes.bool,
  /**
   * Image thumbnail if want to use different than image with url
   */
  thumbnail: PropTypes.node,
  /**
   * Body content
   */
  body: PropTypes.node,
  /**
   * Image overlay content
   */
  imageOverlay: PropTypes.node,
  /**
   * On card click callback
   */
  onClick: PropTypes.func,
  /**
   * If body should be placed next to the image
   */
  horizontal: PropTypes.bool,
  /**
   * If card should be darkened on hover
   */
  darkenOnHover: PropTypes.bool,
  /**
   * Additional card container classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Additional image container classes
   */
  additionalImageContainerClasses: PropTypes.string,
  /**
   * Additional image classes
   */
  additionalImageClasses: PropTypes.string,
  /**
   * Additional body classes
   */
  additionalBodyClasses: PropTypes.string,
  /**
   * Additional image thumbnail classes
   */
  additionalThumbnailClasses: PropTypes.string,
  /**
   * Test id of the card
   */
  testId: PropTypes.string,
};

Card.defaultProps = {
  imageUrl: '',
  imageUrlAlt: '',
  imageUrlLoading: false,
  body: '',
  thumbnail: '',
  imageOverlay: '',
  horizontal: false,
  darkenOnHover: true,
  additionalContainerClasses: '',
  additionalImageClasses: '',
  additionalBodyClasses: '',
  additionalImageContainerClasses: '',
  additionalThumbnailClasses: '',
  testId: '',
};

export default Card;
