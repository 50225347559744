import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired when the "plugins" panel in a sidebar is rendered for content object
 * Returning anything will embed that result in the sidebar in addition to the original sidebar.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.form.sidebar-plugins::render"
 * @type {FormRenderSidebarPluginsEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 *          Null if rendering should be passed to either Flotiq or other plugins.
 *          Renderable result if plugin wants to replace the default renderer.
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name FormRenderSidebarPluginsEvent
 *
 * @property {object} contentType Content type that includes the field
 * @property {object} contentObject Data of the content object.
 *    This will be either a new object or the object being edited.
 * @property {array} plugins List of plugins
 *
 * @property {boolean} disabled If form is disabled
 * @property {boolean} duplicate If an object is being duplicated
 * @property {boolean} create If an object is being created
 */
export class FormRenderSidebarPluginsEvent extends IFlotiqPluginEvent {
  contentType;
  contentObject;
  plugins;

  disabled;
  duplicate;
  create;
}
