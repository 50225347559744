export const planColors = {
  Free: '#A0DA25',
  Basic: '#0083FC',
  Growth: '#17d88e',
  'Basic | Growth': '#eaa009',
};

export const upgradeBenefits = (t) => {
  return [
    {
      title: t('UpgradeBenefits.MoreProjectsAndEnvironments.Title'),
      description: t('UpgradeBenefits.MoreProjectsAndEnvironments.Description'),
    },
    {
      title: t('UpgradeBenefits.AdvancedIntegrations.Title'),
      plan: 'Basic',
      color: planColors['Basic'],
      description: t('UpgradeBenefits.AdvancedIntegrations.Description'),
    },
    {
      title: t('UpgradeBenefits.TeamCollaboration.Title'),
      plan: 'Basic | Growth',
      color: planColors['Basic | Growth'],
      description: t('UpgradeBenefits.TeamCollaboration.Description'),
    },
    {
      title: t('UpgradeBenefits.PrioritySupport.Title'),
      plan: 'Growth',
      color: planColors['Growth'],
      description: t('UpgradeBenefits.PrioritySupport.Description'),
    },
  ];
};
