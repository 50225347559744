import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import useDarkMode from '../../hooks/useDarkMode';
import { getTestProps } from '../../lib/helpers';
import { FlotiqLogo } from '../../images/shapes';
import { Loader } from 'rsuite';

const LoaderPage = ({ testId }) => {
  const [darkMode] = useDarkMode();

  return (
    <div
      className={twMerge(
        'w-full h-screen flex flex-col justify-center items-center',
        darkMode && 'dark:bg-gray-900',
      )}
      {...getTestProps(testId, 'loading')}
    >
      <div className="inline-flex items-center justify-center">
        <FlotiqLogo className={'h-9 m-6'} />
      </div>

      <Loader type="spinner-grid" />
    </div>
  );
};

export default LoaderPage;

LoaderPage.propTypes = {
  /**
   * Test id for loader page
   */
  testId: PropTypes.string,
};
LoaderPage.defaultProps = {
  testId: '',
};
