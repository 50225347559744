import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';
import useLocalStorageState from 'use-local-storage-state';
import { setNestedObjectValues } from 'formik';

// :: Contexts
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';

// :: Hooks
import {
  useContentObject,
  useContentType,
  useContentObjectVersions,
  useWorkflow,
  usePlugins,
  usePluginsSettings,
  useWorkflowDefinition,
} from '../../hooks/api';
import useToken from '../../hooks/useToken';
import useOnce from '../../hooks/useOnce';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import ContentObjectInformations from '../../components/ContentObjectInformations/ContentObjectInformations';
import ContentObjectVersions from '../../components/ContentObjectVersions/ContentObjectVersions';
import ContentObjectWebhooks from '../../components/ContentObjectWebhooks/ContentObjectWebhooks';
import ContentObjectPlugins from '../../components/ContentObjectPlugins/ContentObjectPlugins';
import Button from '../../components/Button/Button';
import ElementFromPlugin from '../../components/ElementFromPlugin/ElementFromPlugin';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import SaveAndLeaveButton from '../../components/Topbar/buttons/SaveAndLeaveButtons';
import DuplicateButton from '../../components/Topbar/buttons/DuplicateButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import CancelButton from '../../components/Topbar/buttons/CancelButton';
import SaveButton from '../../components/Topbar/buttons/SaveButton';
import DeleteButton from '../../components/Topbar/buttons/DeleteButton';

// :: Components Inner
import ContentObjectBacklinks from './ContentObjectBacklinks/ContentObjectBacklinks';

// :: Form
import ContentObjectForm from '../../form/ContentObjectForm/ContentObjectForm';

// :: Helpers
import {
  getSortedBy,
  getTestProps,
  getPluginsIframeOptions,
  capitalizeText,
} from '../../lib/helpers';

// :: Lib Api
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import {
  getContentObjectVersion,
  listWebhooks,
  putWorkflow,
} from '../../lib/flotiq-client';
import { saveNewCTO, updateCTO } from '../../lib/flotiq-client/api-helpers';
import { FormAddSidebarPanelEvent } from '../../lib/flotiq-plugins/plugin-events/FormAddSidebarPanelEvent';
import { FormRenderSidebarPluginsEvent } from '../../lib/flotiq-plugins/plugin-events/FormRenderSidebarPluginsEvent';

// :: Icons
import {
  WarningIcon,
  ArrowCollapseLeftIcon,
  ArrowCollapseRightIcon,
  BoxWithArrowIcon,
  WarningTriangleRedIcon,
  CloseIcon,
} from '../../images/shapes';

// :: Layout
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

// :: Hooks
import useFirstLoading from '../../hooks/useFirstLoading';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import usePluginResults from '../../hooks/usePluginResults';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';
import { useBacklinks } from '../../hooks/api/useBacklinks';
import TopbarActionButton from '../../components/Topbar/buttons/base/TopbarActionButton';
import TransitionButton from '../../components/Topbar/buttons/TransitionButton';
import TopbarStatus from '../../components/Topbar/status/TopbarStatus';

const versionsHookParams = {
  limit: 10000,
  page: 1,
};

const USER_PLUGINS_PARAMS = {
  limit: 10000,
  page: 1,
};

/**
 * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
 */
const AddContentObject = ({ duplicate, testId, mode }) => {
  const { t, i18n } = useTranslation();
  const navigateOnSave = useRef();
  const modal = useModals();
  const jwt = useToken();
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const navigate = useNavigate();
  const { permissions } = useContext(UserContext);
  const { setDirty } = useContext(DirtyHandlerContext);

  const formikRef = useRef();
  const [formikState, setFormikState] = useState();
  const [loadedVersion, setLoadedVersion] = useState(null);

  const [user, setUser] = useLocalStorageState('cms.user');

  const { data: userPlugins } = usePluginsSettings(USER_PLUGINS_PARAMS);

  const pluginsOptions = useMemo(
    () => ({
      pause: !permissions.canCo('_plugin'),
    }),
    [permissions],
  );
  const { data: plugins } = usePlugins(null, pluginsOptions);

  const { contentTypeName, id } = useParams();
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isVersionLoading, setIsVersionLoading] = useState(false);
  const [isTransisting, setIsTransisting] = useState(false);
  const [showSidebar, setShowSidebar] = useState(true);

  const { navigateGrid, gridLink } = useGridNavigate(
    `objects-${contentTypeName}`,
    buildUrlWithSpace(`content-type-objects/${contentTypeName}`),
  );

  const ctdPlugins = useMemo(
    () => getPluginsIframeOptions(plugins, contentTypeName),
    [plugins, contentTypeName],
  );

  const {
    backlinks,
    backlinkContentTypes,
    backlinkPagination,
    setBacklinksPage,
  } = useBacklinks(id, contentTypeName, duplicate);

  const {
    data: ctoVersions,
    errors: contentVersionsErrors,
    isLoading: ctoVersionLoading,
    reload: reloadContentVersions,
  } = useContentObjectVersions(contentTypeName, id, versionsHookParams);

  const [customWebhooks, setCustomWebhooks] = useState([]);

  const {
    entity: contentObject,
    isLoading: contentObjectIsLoading,
    updateEntity: updateContentObject,
    deleteEntity: deleteContentObject,
    errors: contentObjectErrors,
    reload: contentObjectReload,
  } = useContentObject(contentTypeName, id);

  const fetchWebhooks = useCallback(async () => {
    if (!permissions.canCo('_webhooks')) return;
    try {
      // List webhooks with content type definitions based on content type name
      const { body, status } = await listWebhooks(jwt, space, {
        filters: JSON.stringify({
          'actions[*].action': { type: 'contains', filter: '"Custom"' },
          'content_type_definitions[*].content_type_definition_name': {
            type: 'contains',
            filter: `"${contentTypeName}"`,
          },
          enabled: { type: 'equals', filter: true },
        }),
      });

      // List webhooks with content type definitions that is for All (default)
      const { body: bodyDefault, status: statusDefault } = await listWebhooks(
        jwt,
        space,
        {
          filters: JSON.stringify({
            'actions[*].action': { type: 'contains', filter: '"Custom"' },
            content_type_definitions: {
              type: 'contains',
              filter: '[]',
            },
            enabled: { type: 'equals', filter: true },
          }),
        },
      );

      const webhooksList = [...body.data, ...bodyDefault.data];
      const mapFromWebhooks = new Map(webhooksList.map((c) => [c.id, c]));
      const uniqueList = [...mapFromWebhooks.values()];

      checkResponseStatus(body, status);
      checkResponseStatus(bodyDefault, statusDefault);

      setCustomWebhooks(uniqueList);
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message ? error.message : t('ContentForm.CouldntFetch'),
        );
      }
    }
  }, [permissions, jwt, space, contentTypeName, t]);

  useOnce(fetchWebhooks);

  const handleDeleteObject = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteContentObject({ contentTypeName });
      checkResponseStatus(body, status);
      toast.success(t('ContentForm.Deleted'));

      setDirty(false);
      navigateGrid();
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message ? error.message : t('ContentForm.CouldntDelete'),
        );
      }
    }
  }, [modal, deleteContentObject, contentTypeName, t, setDirty, navigateGrid]);

  const deleteObject = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(t('ContentForm.ConfirmDelete'), 'delete-modal', () =>
      handleDeleteObject(),
    );
    setIsDeleting(false);
  }, [handleDeleteObject, modal, t]);

  const {
    entity: contentType,
    errors: contentTypeErrors,
    status: contentTypeStatus,
    isLoading: contentTypeLoading,
  } = useContentType(contentTypeName);

  const firstLoading = useFirstLoading(
    (!contentTypeLoading || !contentTypeName) &&
      (!contentObjectIsLoading || !id),
    contentTypeName + id,
  );

  const workflowDefinitionHookOptions = useMemo(
    () => ({
      pause: !!id || !contentType?.workflowId || duplicate,
    }),
    [contentType?.workflowId, duplicate, id],
  );

  const { entity: workflowDefinitionConfig, errors: workflowDefinitionErrors } =
    useWorkflowDefinition(
      contentType?.workflowId,
      null,
      workflowDefinitionHookOptions,
    );

  const {
    entity: workflowConfig,
    errors: workflowErrors,
    reload: workflowConfigReload,
  } = useWorkflow(contentTypeName, id, contentObject?.internal?.latestVersion);

  useApiErrorsToast(workflowErrors);
  useApiErrorsToast(contentTypeErrors);
  useApiErrorsToast(contentVersionsErrors);
  useApiErrorsToast(contentObjectErrors);
  useApiErrorsToast(workflowDefinitionErrors);

  const { canCreate, canDelete, canUpdate, canRead } = useMemo(
    () => permissions.getCoPermissions(contentTypeName) || {},
    [contentTypeName, permissions],
  );

  const notAllowed = useMemo(
    () => (contentType?.internal && contentType?.name !== '_tag') || !canRead,
    [contentType, canRead],
  );

  const label = useMemo(
    () =>
      contentType?.name === '_tag' ? t('Global.Tag') : contentType?.label || '',
    [contentType?.label, contentType?.name, t],
  );

  const pageTitle = useMemo(() => {
    if (duplicate) return 'Duplicate';
    else if (id) return 'Edit';
    return 'Add';
  }, [id, duplicate]);

  useEffect(() => {
    workflowConfigReload();
  }, [contentObject, workflowConfigReload]);

  const saveNewObject = useCallback(
    async (values) => {
      setIsSaving(true);
      const [formikValues, hasErrors] = await saveNewCTO(
        jwt,
        space,
        values,
        contentTypeName,
        user,
        setUser,
        t,
      );
      setIsSaving(false);
      if (!hasErrors) {
        navigate(
          buildUrlWithSpace(
            `content-type-objects/edit/${contentTypeName}/${formikValues[0].id}`,
          ),
        );
      }
      return formikValues;
    },
    [
      jwt,
      space,
      contentTypeName,
      user,
      setUser,
      t,
      navigate,
      buildUrlWithSpace,
    ],
  );

  const updateObject = useCallback(
    async (values) => {
      setIsSaving(true);

      const [formikValues] = await updateCTO(
        values,
        contentTypeName,
        updateContentObject,
        t,
      );

      await reloadContentVersions();
      setIsSaving(false);
      return formikValues;
    },
    [updateContentObject, contentTypeName, t, reloadContentVersions],
  );

  const handleVersionSelectCallback = useCallback(
    async (versionNumber) => {
      setIsVersionLoading(true);
      try {
        const { body, status } = await getContentObjectVersion(jwt, space, {
          contentTypeName: contentTypeName,
          id: id,
          versionNumber,
        });
        checkResponseStatus(body, status);

        delete body.id;
        delete body.internal;

        if (formikRef.current) {
          const errors = await formikRef.current.setValues(body, true);
          formikRef.current.setTouched(
            setNestedObjectValues(body, true),
            false,
          );
          formikRef.current.setErrors(errors);
        }
        setLoadedVersion(versionNumber);
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
        } else {
          toast.error(t('ContentForm.CouldntRevertVersion'));
        }
      }
      setIsVersionLoading(false);
    },
    [contentTypeName, id, jwt, t, space],
  );

  const emptyCTD = useMemo(() => {
    if (firstLoading) {
      return (
        <Loader
          size={'small'}
          type={'spinner-grid'}
          testId={testId ? `${testId}-loader` : ''}
        />
      );
    }
    if (notAllowed) {
      toast.error(t('ContentForm.CouldntFind', { contentTypeName: label }));
    }
    return (
      <Heading
        level={2}
        additionalClasses={twMerge(
          'text-3xl md:text-4xl leading-8 dark:text-white',
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('ContentForm.CouldntFind')}
          />
          {contentTypeStatus === 404 || notAllowed
            ? t('ContentForm.CouldntFind', { contentTypeName })
            : t('ContentForm.CouldntFetch')}
        </div>
      </Heading>
    );
  }, [
    firstLoading,
    notAllowed,
    testId,
    t,
    contentTypeStatus,
    contentTypeName,
    label,
  ]);

  const showForm = useMemo(
    () => !notAllowed && !firstLoading && contentType,
    [notAllowed, firstLoading, contentType],
  );

  const showArchiveModal = useCallback(
    () =>
      modal({
        title: (
          <div className="inline-flex text-red font-bold text-3xl items-center">
            <WarningTriangleRedIcon className="h-5 mr-2.5" />
            {t('Global.Warning')}
          </div>
        ),
        content: t('ObjectStatus.ArchiveConfirmation'),
        buttons: [
          {
            key: 'archive',
            label: t('ObjectStatus.Transition.archive'),
            result: true,
            color: 'red',
            iconImage: <BoxWithArrowIcon className="w-3 min-w-3" />,
            iconPosition: 'start',
          },
          {
            key: 'cancel',
            label: t('Global.Cancel'),
            result: false,
            color: 'blueBordered',
            iconImage: <CloseIcon className="w-3 min-w-3" />,
            iconPosition: 'start',
          },
        ],
      }),
    [modal, t],
  );

  const handleChangeObjectStatus = useCallback(
    async (objectStatus, publicVersion = null) => {
      if (objectStatus === 'archive') {
        const result = await showArchiveModal();
        if (!result) return;
      }

      if (contentObject.internal.workflowState !== objectStatus) {
        setIsTransisting(true);
        try {
          const { body, status } = await putWorkflow(jwt, space, {
            objectType: contentTypeName,
            objectId: id,
            revision: publicVersion || contentObject.internal.latestVersion,
            action: `_${
              publicVersion ? 'public' : contentObject.internal.workflowState
            }_${objectStatus}`,
          });
          checkResponseStatus(body, status);
          toast.success(t('ObjectStatus.SuccessWorkflowStatusUpdate'));
          contentObjectReload();
          workflowConfigReload();
        } catch (error) {
          toast.error(error.message);
        }
        await reloadContentVersions();
        setIsTransisting(false);
      }
    },
    [
      contentObject?.internal?.workflowState,
      contentObject?.internal?.latestVersion,
      showArchiveModal,
      reloadContentVersions,
      jwt,
      space,
      contentTypeName,
      id,
      t,
      contentObjectReload,
      workflowConfigReload,
    ],
  );

  const handleShowSidebar = useCallback(() => {
    setShowSidebar(!showSidebar);
  }, [showSidebar]);

  const isFormDisabled = useMemo(() => {
    if (isSaving || isDeleting) return true;
    if (id) return !canUpdate;
    return !canCreate;
  }, [canCreate, canUpdate, id, isDeleting, isSaving]);

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-panel::add"
   */
  const pluginSidebarPanels = usePluginResults(
    'flotiq.form.sidebar-panel::add',
    FormAddSidebarPanelEvent,
    {
      contentType,
      contentObject,
      disabled: isFormDisabled,
      duplicate,
      create: !id || duplicate,
      userPlugins,
      formik: formikState,
    },
  );

  /**
   * @emits FlotiqPlugins."flotiq.form.sidebar-plugins::render"
   */
  const pluginReplacementPanels = usePluginResults(
    'flotiq.form.sidebar-plugins::render',
    FormRenderSidebarPluginsEvent,
    {
      plugins,
      contentObject,
      contentType,
      duplicate,
      create: !id,
      disabled: isFormDisabled,
    },
  );

  const validPluginReplacement = useCallback(
    (pluginReplacementPanels, ctdPlugins) => {
      let ctdCurrentPlugins;

      if (pluginReplacementPanels[0]?.results) {
        if (pluginReplacementPanels[0].other) {
          ctdCurrentPlugins = getPluginsIframeOptions(
            pluginReplacementPanels[0].other,
            contentTypeName,
          );
        }
      } else if (pluginReplacementPanels.length > 0) {
        ctdCurrentPlugins = [];
      } else {
        ctdCurrentPlugins = ctdPlugins;
      }

      return (
        <>
          <ElementFromPlugin
            results={
              pluginReplacementPanels[0]?.results || pluginReplacementPanels
            }
            order={pluginReplacementPanels[0]?.order || 60}
          />
          {ctdCurrentPlugins.length > 0 && (
            <ContentObjectPlugins
              key={contentObject?.internal?.updatedAt}
              plugins={ctdCurrentPlugins}
              contentObject={contentObject}
              ctdName={contentTypeName}
              additionalClasses={twMerge(
                `order-60`,
                !showSidebar && 'xl:hidden',
              )}
              {...getTestProps(testId, 'plugins', 'testId')}
            />
          )}
        </>
      );
    },
    [contentObject, contentTypeName, showSidebar, testId],
  );

  const archiveVersion = useMemo(
    () =>
      ctoVersions?.find(
        ({ internal }) => internal?.workflowState === 'archive',
      ),
    [ctoVersions],
  );

  const currentWorkflowStatus = workflowConfig?.state;
  const publicVersionNumber = contentObject?.internal?.workflowPublicVersion;

  const hasArchiveAction = useMemo(
    () =>
      !duplicate &&
      currentWorkflowStatus !== 'public' &&
      publicVersionNumber > -1 &&
      !!(workflowConfig?.workflow?.transitions || []).find(
        ({ name }) => name === '_public_archive',
      ),
    [
      currentWorkflowStatus,
      duplicate,
      publicVersionNumber,
      workflowConfig?.workflow?.transitions,
    ],
  );

  const availableTransitions = useMemo(() => {
    if (!workflowConfig || !currentWorkflowStatus || duplicate) return [];
    return (workflowConfig.enabled_transitions || []).filter(
      ({ name }) =>
        ![
          `_${currentWorkflowStatus}_${currentWorkflowStatus}`,
          `_${currentWorkflowStatus}_${workflowConfig.workflow?.initialPlaces?.[0]}`,
        ].includes(name),
    );
  }, [currentWorkflowStatus, duplicate, workflowConfig]);

  const onArchiveClick = useCallback(() => {
    handleChangeObjectStatus('archive', publicVersionNumber);
  }, [publicVersionNumber, handleChangeObjectStatus]);

  const topbarStatus = useMemo(() => {
    if (!currentWorkflowStatus || duplicate) return '';
    if (
      currentWorkflowStatus === workflowConfig?.workflow?.initialPlaces?.[0] &&
      publicVersionNumber > -1
    )
      return 'modified';
    return currentWorkflowStatus;
  }, [currentWorkflowStatus, workflowConfig, duplicate, publicVersionNumber]);

  const isSaveButton = (canUpdate && id) || (canCreate && !id);
  const isSaveDisabled = !!id && !duplicate && !formikState?.dirty;

  return (
    <PageLayout
      id={contentTypeName === '_tag' ? 'tags' : `contentTypeObjects-${mode}`}
      page={
        contentTypeName === '_tag'
          ? 'tags'
          : `content/${label || contentTypeName}`
      }
      menuItemOpen={contentTypeName === '_tag' ? '' : 'content'}
      title={t(`ContentForm.${pageTitle}`, { contentTypeName: label })}
      buttonsDisabled={isSaving || isDeleting || isTransisting}
      breadcrumbs={
        <TopbarBreadcrumbs
          parentTitle={t('ObjectsOfType.Title', { contentTypeName: label })}
          parentLink={gridLink}
        />
      }
      buttons={
        <>
          <CancelButton link={gridLink} />
          {isSaveButton && (
            <SaveButton
              form="cto-form"
              isLoading={isSaving}
              navigateOnSave={navigateOnSave}
              label={
                [
                  workflowDefinitionConfig?.initialPlaces?.[0],
                  workflowConfig?.workflow?.initialPlaces?.[0],
                ].includes('draft')
                  ? t('ObjectStatus.SaveDraft')
                  : t('Global.Save')
              }
              disabled={isSaveDisabled}
            />
          )}
          {availableTransitions.map(({ name, tos }) => (
            <TransitionButton
              key={name}
              newStatus={tos[0]}
              onChange={handleChangeObjectStatus}
              isLoading={isTransisting}
            />
          ))}
          <TopbarActionMenu>
            {canCreate && id && !duplicate && (
              <DuplicateButton
                link={buildUrlWithSpace(
                  `content-type-objects/duplicate/${contentTypeName}/${id}`,
                )}
              />
            )}
            {isSaveButton && (
              <SaveAndLeaveButton
                form="cto-form"
                navigateOnSave={navigateOnSave}
                disabled={isSaveDisabled}
              />
            )}

            {availableTransitions.map(({ name, tos }) => (
              <TransitionButton
                key={name}
                newStatus={tos[0]}
                onChange={handleChangeObjectStatus}
                isActionButton
              />
            ))}
            {hasArchiveAction && (
              <TopbarActionButton
                color="red"
                label={t('ObjectStatus.ArchivePublic')}
                iconImage={<BoxWithArrowIcon className="w-3 min-w-3" />}
                onClick={onArchiveClick}
                additionalIconClasses="ml-0.5 mr-3"
              />
            )}

            {canDelete && id && !duplicate && (
              <DeleteButton onClick={deleteObject} />
            )}
          </TopbarActionMenu>
        </>
      }
      status={
        topbarStatus ? (
          <TopbarStatus
            label={
              i18n.exists(`ObjectStatus.VersionStatus.${topbarStatus}`)
                ? t(`ObjectStatus.VersionStatus.${topbarStatus}`)
                : capitalizeText(topbarStatus)
            }
            status={topbarStatus}
            testId={testId}
          />
        ) : null
      }
      testId={testId}
    >
      {showForm ? (
        <div className={predefinedLayoutClasses.withSidebar}>
          <div
            className={twMerge(
              predefinedLayoutClasses.leftColumnWhite,
              'relative',
              !showSidebar && 'xl:col-span-7 xl:mr-16',
            )}
          >
            {isVersionLoading && (
              <div
                className={twMerge(
                  'absolute top-0 left-0 w-full h-full flex bg-white dark:bg-gray-800',
                  'justify-center items-center z-10 rounded-md opacity-90',
                )}
                {...getTestProps(testId, 'container-loader')}
              >
                <Loader
                  type="spinner-grid"
                  size="small"
                  additionalClasses="fixed top-1/2"
                />
              </div>
            )}
            <div className="space-y-3 md:space-y-6 p-7 md:py-10 md:px-14">
              <ContentObjectForm
                key={pageTitle}
                contentType={contentType}
                contentObject={contentObject || {}}
                isEditing={!!(id && !duplicate)}
                onSubmit={id && !duplicate ? updateObject : saveNewObject}
                disabled={isFormDisabled}
                navigateOnSave={navigateOnSave}
                hasInitialData={!!id}
                isFullSize={!showSidebar}
                userPlugins={userPlugins}
                formikRef={formikRef}
                loadedVersion={loadedVersion}
                setFormikState={setFormikState}
                {...getTestProps(testId, 'form', 'testId')}
              />
            </div>
            <Button
              onClick={handleShowSidebar}
              iconImage={
                showSidebar ? (
                  <ArrowCollapseRightIcon className="w-5 h-5 text-blue" />
                ) : (
                  <ArrowCollapseLeftIcon className="w-5 h-5 text-blue" />
                )
              }
              additionalIconClasses={'min-w-10 w-10'}
              buttonColor="borderless"
              additionalClasses={twMerge(
                'hidden absolute top-2.5 right-1.5',
                process.env.REACT_APP_ENABLE_CTO_FORM_RESIZE.split(',').join(
                  ',',
                ) === 'true' && 'xl:flex',
              )}
              noPaddings
              {...getTestProps(testId, 'toggle-sidebar', 'testId')}
            />
          </div>
          <div
            className={twMerge(
              predefinedLayoutClasses.rightColumn,
              !showSidebar &&
                'xl:border-none xl:absolute xl:right-0 xl:w-16 xl:p-0 xl:h-full xl:max-h-[calc(100%-55px)]',
            )}
            {...getTestProps(testId, 'sidebar-container')}
          >
            {!duplicate && (
              <ContentObjectInformations
                createdAt={contentObject?.internal?.createdAt}
                updatedAt={contentObject?.internal?.updatedAt}
                updatedAtText={
                  contentType?.workflowId === 'publishing' &&
                  t('TypeDefinitionCard.LastUpdated')
                }
                lastPublishedAt={contentObject?.internal?.workflowPublishedAt}
                archivedAt={
                  contentObject?.internal?.workflowPublishedAt
                    ? ''
                    : archiveVersion?.updatedAt
                }
                additionalClasses={twMerge('order-20')}
                isPinned={!showSidebar}
                testId={testId}
                id={'content_object-informations'}
              />
            )}

            {customWebhooks.length > 0 && !duplicate && id && (
              <ContentObjectWebhooks
                webhooks={customWebhooks}
                contentObjectId={id}
                contentTypeDefinitionName={contentTypeName}
                additionalClasses={twMerge(
                  'order-30',
                  !showSidebar && 'xl:hidden',
                )}
                testId={testId}
              />
            )}

            {ctoVersions?.length > 0 && !duplicate && (
              <ContentObjectVersions
                loading={ctoVersionLoading}
                versions={getSortedBy(ctoVersions, 'version')}
                testId={testId}
                selectVersionCallback={handleVersionSelectCallback}
                additionalClasses={twMerge(
                  'order-40',
                  !showSidebar && 'xl:hidden',
                )}
              />
            )}

            {!duplicate && backlinks?.length > 0 && (
              <ContentObjectBacklinks
                backlinks={backlinks}
                contentTypes={backlinkContentTypes}
                pagination={backlinkPagination}
                onPageChange={setBacklinksPage}
                additionalClasses={twMerge(
                  'order-50',
                  !showSidebar && 'xl:hidden',
                )}
              />
            )}

            {!duplicate &&
              ctdPlugins &&
              ctdPlugins.length > 0 &&
              validPluginReplacement(pluginReplacementPanels, ctdPlugins)}

            {pluginSidebarPanels?.length > 0 && showSidebar && (
              <ElementFromPlugin results={pluginSidebarPanels} />
            )}
          </div>
        </div>
      ) : (
        <div className={predefinedLayoutClasses.whiteBox}>{emptyCTD}</div>
      )}
    </PageLayout>
  );
};

export default AddContentObject;

AddContentObject.propTypes = {
  /**
   * If object is duplicating
   */
  duplicate: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
  /**
   * Content Object mode
   */
  mode: PropTypes.string,
};

AddContentObject.defaultProps = {
  duplicate: false,
  testId: '',
  mode: '',
};
