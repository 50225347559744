import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { getTestProps } from '../../lib/helpers';

const Tooltip = ({
  testId,
  children,
  tooltip,
  tooltipPlacement,
  phoneTooltipPlacement,
  additionalClasses,
  additionalTooltipClasses,
}) => {
  const bottomArrowPlacement =
    'after:left-1/2 after:translate-y-1/2 after:-translate-x-1/2 after:bottom-full after:rounded-tl';

  const topArrowPlacement =
    'after:left-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:top-full after:rounded-br';

  const placementReset =
    'top-auto left-auto right-auto bottom-auto translate-x-0 translate-y-0 m-0';

  const afterPlacementReset = twMerge(
    'after:block after:left-auto after:right-auto after:top-auto after:bottom-auto',
    ' after:translate-x-0 after:translate-y-0 after:rounded-none',
  );

  const tooltipPlacementClasses = useMemo(() => {
    return {
      topLeft: twMerge(
        placementReset,
        topArrowPlacement,
        'left-0 right-auto bottom-full top-auto mb-4 rounded rounded-bl-none -translate-x-0 after:hidden ',
      ),
      topCenter: twMerge(
        placementReset,
        afterPlacementReset,
        topArrowPlacement,
        'left-1/2 bottom-full right-auto top-auto -translate-x-1/2 mb-4 rounded',
      ),
      topRight: twMerge(
        placementReset,
        topArrowPlacement,
        'right-0 bottom-full top-auto left-auto mb-4 rounded rounded-br-none -translate-x-0 after:hidden',
      ),
      rightTop: twMerge(
        placementReset,
        topArrowPlacement,
        'left-full bottom-0 ml-4 rounded-bl-none',
        'after:hidden',
      ),
      rightCenter: twMerge(
        placementReset,
        afterPlacementReset,
        'left-full top-1/2 bottom-auto ml-4 ',
        '-translate-x-0 -translate-y-1/2 rounded after:block',
        'after:left-0 after:top-1/2 after:bottom-auto',
        'after:-translate-x-1/2 after:-translate-y-1/2',
        'after:rounded-bl',
      ),
      rightBottom: twMerge(
        bottomArrowPlacement,
        placementReset,
        'rounded bottom-auto',
        'left-full top-0 ml-4 rounded-tl-none ',
        'after:hidden',
      ),
      bottomLeft: twMerge(
        bottomArrowPlacement,
        placementReset,
        'rounded bottom-auto',
        'left-full top-full mt-4 rounded-tl-none',
        'after:hidden',
      ),
      bottomCenter: twMerge(
        afterPlacementReset,
        bottomArrowPlacement,
        placementReset,
        'rounded bottom-auto',
        'left-1/2 -translate-x-1/2 top-full mt-4',
      ),
      bottomRight: twMerge(
        bottomArrowPlacement,
        placementReset,
        'rounded bottom-auto',
        '-translate-x-0 translate-y-0 left-auto',
        'right-0 top-full mt-4 rounded-tr-none',
        'after:hidden',
      ),
      leftTop: twMerge(
        topArrowPlacement,
        placementReset,
        'rounded bottom-full',
        'top-auto right-full mr-4 rounded-br-none',
        'after:hidden',
      ),
      leftCenter: twMerge(
        placementReset,
        afterPlacementReset,
        'top-1/2 right-full bottom-auto mr-4 rounded ',
        '-translate-x-0 -translate-y-1/2 after:!rounded-tl-none',
        'after:left-full after:-translate-x-1/2 after:translate-y-1/2 after:bottom-1/2 after:rounded-tr',
      ),
      leftBottom: twMerge(
        bottomArrowPlacement,
        placementReset,
        '-translate-x-0 translate-y-0 left-auto top-0 bottom-auto',
        'right-full mr-4 rounded rounded-tr-none',
        'after:hidden',
      ),
    };
  }, [afterPlacementReset]);

  const baseTooltipPlacementClasses = useMemo(
    () =>
      tooltipPlacementClasses[tooltipPlacement]
        .split(' ')
        .map((el) => `sm:${el}`)
        .join(' '),
    [tooltipPlacementClasses, tooltipPlacement],
  );

  if (!tooltip) {
    return <>{children}</>;
  }

  return (
    <div
      className={twMerge('relative h-fit group/tooltip', additionalClasses)}
      {...getTestProps(testId, 'Tooltip-container')}
    >
      {children}
      <div
        className={twMerge(
          'absolute rounded-md p-3 w-max bg-zinc-600 dark:bg-slate-700 z-20',
          'opacity-0 invisible group-hover/tooltip:visible group-hover/tooltip:opacity-100 text-white text-xs',
          'transition-opacity ease-in-out duration-300 after:content[""] after:absolute after:h-4 after:w-4',
          'after:rotate-45 after:bg-zinc-600 dark:after:bg-slate-700 md:p-4 max-w-[200px] md:max-w-xs',
          tooltipPlacementClasses[phoneTooltipPlacement],
          baseTooltipPlacementClasses,
          additionalTooltipClasses,
        )}
        {...getTestProps(testId, 'Tooltip-content')}
      >
        {tooltip}
      </div>
    </div>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  /**
   * Test id for Tooltip
   */
  testId: PropTypes.string,
  /**
   * Node with tooltip children
   */
  children: PropTypes.node.isRequired,
  /**
   * Node with tooltip element
   */
  tooltip: PropTypes.node,
  /**
   * Tooltip placement variant
   */
  tooltipPlacement: PropTypes.oneOf([
    'topLeft',
    'topCenter',
    'topRight',
    'rightTop',
    'rightCenter',
    'rightBottom',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
    'leftTop',
    'leftCenter',
    'leftBottom',
  ]),
  /**
   * Tooltip placement variant for phone (under md)
   */
  phoneTooltipPlacement: PropTypes.oneOf([
    'topLeft',
    'topCenter',
    'topRight',
    'rightTop',
    'rightCenter',
    'rightBottom',
    'bottomLeft',
    'bottomCenter',
    'bottomRight',
    'leftTop',
    'leftCenter',
    'leftBottom',
  ]),
  /**
   * Additional classes for tooltip group
   */
  additionalClasses: PropTypes.string,
  /**
   * Additional classes for tooltip
   */
  additionalTooltipClasses: PropTypes.string,
};

Tooltip.defaultProps = {
  testId: '',
  tooltipPlacement: 'topCenter',
  phoneTooltipPlacement: 'topCenter',
  additionalClasses: '',
  additionalTooltipClasses: '',
};
