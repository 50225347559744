import PropTypes from 'prop-types';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

const LogModalCodePreview = ({ code, testId }) => {
  return code ? (
    <pre
      className="rounded-lg p-2 bg-gray-200"
      {...getTestProps(testId, `container`)}
    >
      <code>{JSON.stringify(code, null, 2)}</code>
    </pre>
  ) : null;
};

export default LogModalCodePreview;

LogModalCodePreview.propTypes = {
  /**
   * Code object
   */
  code: PropTypes.any,
  /**
   * Test id
   */
  testId: PropTypes.string,
};

LogModalCodePreview.defaultProps = {
  testId: '',
};
