import PropTypes from 'prop-types';
import Editor from '@monaco-editor/react';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Components
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';

const CodeEditor = ({
  caption,
  onChange,
  value,
  defaultValue,
  defaultLanguage,
  theme,
  height,
  error,
  testId,
  ...props
}) => {
  return (
    <div
      className="bg-white dark:bg-slate-950"
      {...getTestProps(testId, 'container')}
    >
      <Editor
        height={height}
        theme={theme}
        defaultLanguage={defaultLanguage}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        {...getTestProps(testId, 'editor', 'testId')}
        {...props}
      />
      <HelpErrorTextsTemplate
        helpText={caption}
        error={error}
        additionalHelpClasses="text-slate-400"
        testId={testId}
      />
    </div>
  );
};

export default CodeEditor;

CodeEditor.propTypes = {
  /**
   * On code editor value change callback
   */
  onChange: PropTypes.func,
  /**
   * Additional caption description below editor code
   */
  caption: PropTypes.node,
  /**
   * Default code editor value
   */
  defaultValue: PropTypes.string,
  /**
   * Code editor value
   */
  value: PropTypes.string,
  /**
   * Default code editor language
   */
  defaultLanguage: PropTypes.string,
  /**
   * Code editor theme
   */
  theme: PropTypes.string,
  /**
   * Code editor height
   */
  height: PropTypes.string,
  /**
   * Code editor error
   */
  error: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

CodeEditor.defaultProps = {
  onChange: /* istanbul ignore next */ () => {},
  caption: '',
  defaultValue: '',
  value: '',
  defaultLanguage: 'javascript',
  theme: 'vs-dark',
  height: '40vh',
  error: '',
  testId: '',
};
