import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { toast } from 'react-hot-toast';
import useLocalStorageState from 'use-local-storage-state';
import { useSearchParams } from 'react-router-dom';

// :: Components
import Heading from '../../components/Heading/Heading';
import Loader from '../../components/Loader/Loader';
import StatusBar from '../../components/StatusBar/StatusBar';
import CustomizableDataGrid from '../../components/CustomizableDataGrid/CustomizableDataGrid';
import DataGridControl from '../../components/DataGridControl/DataGridControl';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';
import TopbarButton from '../../components/Topbar/buttons/base/TopbarButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import TopbarActionButton from '../../components/Topbar/buttons/base/TopbarActionButton';

//:: Layout
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

// :: Utils
import { getLocalStorage, removeLocalStorage } from '../../utils/localStorage';

// :: Hooks
import { useAllUsers, useConstraints, useUsers } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useActionsColumn from '../../components/DataGrid/useActions';
import { useGridFilters } from '../../components/DataGrid/useGridFilters';

// :: Contexts
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';

// :: Lib
import {
  generateExportUsersCsvUrl,
  getTestProps,
  setInitialGridOptions,
  prepareColumns,
} from '../../lib/helpers';
import { getSpacesList } from '../../lib/flotiq-client';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import { actionsRenderer } from '../../components/DataGrid/DataGridCell/cellRenderer';

// :: Inner
import { getDefinedColumns } from './definedColumns';

// :: Images
import { AvatarIcon, WarningIcon } from '../../images/shapes';

const getApiFilters = (filters) => {
  if (!filters) return {};

  const newFilters = {};

  Object.keys(filters).forEach((key) => {
    if (key === 'organization.name') {
      newFilters.organizationName = filters['organization.name'].filter;
    } else if (key === 'organization.id') {
      newFilters.organizationId = filters['organization.id'].filter;
    } else if (key === 'roles') {
      newFilters.roles = filters[key].filter.join(',');
    } else {
      newFilters[key] = filters[key].filter;
    }
  });

  return newFilters;
};

const Users = ({ isAllUsers, testId }) => {
  const jwt = useToken();
  const [, setImpersonate] = useLocalStorageState('cms.impersonate');
  const [searchParams] = useSearchParams();

  const SORT_KEY = isAllUsers ? 'cms.all-users-sort' : 'cms.user-sort';
  const OPTIONS_KEY = isAllUsers
    ? 'cms.all-users-grid-state'
    : 'cms.user-grid-state';

  const usersGridContainer = useRef();
  const { t } = useTranslation();
  const { updateAppContext } = useContext(AppContext);
  const { isRoleAdmin, isAdmin, userStorage: user } = useContext(UserContext);

  const usersLimit = useMemo(
    () => user?.data?.organizationLimits?.userLimit,
    [user],
  );
  const [gridOptions, setGridOptions] = useState(
    getLocalStorage(OPTIONS_KEY, true),
  );

  const { entity: membersCount } = useConstraints('organization-users');

  const disabledAdding = useMemo(
    () =>
      isAllUsers || usersLimit === -1
        ? false
        : membersCount?.data >= usersLimit,
    [isAllUsers, usersLimit, membersCount?.data],
  );

  const [sort, setSort] = useState(getLocalStorage(SORT_KEY, true));
  const [firstLoading, setFirstLoading] = useState(true);
  const [initDataHasContent, setInitDataHasContent] = useState(false);
  const [columns, setColumns] = useState([]);
  const [editGrid, setEditGrid] = useState(false);
  const [filterGrid, setFilterGrid] = useState(!!searchParams.get('filters'));

  useEffect(() => {
    setSort(getLocalStorage(SORT_KEY, true));
  }, [SORT_KEY]);

  useEffect(() => {
    setGridOptions(getLocalStorage(OPTIONS_KEY, true));
  }, [OPTIONS_KEY]);

  const {
    filters,
    filtersApplied,
    handleFiltersChange,
    page,
    handlePageChange,
    limit,
    handleLimitChange,
    removeAllFilters,
  } = useGridFilters(isAllUsers ? 'all-users' : 'users');

  const usersParams = useMemo(
    () => ({
      page,
      limit,
      ...getApiFilters(filters),
      ...(sort?.sortOrder ? { order_direction: sort?.sortOrder } : {}),
      ...(sort?.sortField ? { order_by: sort?.sortField } : {}),
    }),
    [page, limit, filters, sort?.sortOrder, sort?.sortField],
  );

  const usersOptions = useMemo(
    () => ({
      pause: isAllUsers,
    }),
    [isAllUsers],
  );

  const {
    data: users,
    errors: usersError,
    status: usersStatus,
    isLoading: usersAreLoading,
    pagination: usersPagination,
    reload: usersReload,
  } = useUsers(usersParams, usersOptions);

  const allUsersOptions = useMemo(
    () => ({
      pause: !isRoleAdmin || !isAllUsers,
    }),
    [isRoleAdmin, isAllUsers],
  );

  const {
    data: allUsers,
    errors: allUsersError,
    status: allUsersStatus,
    isLoading: allUsersAreLoading,
    pagination: allUsersPagination,
    reload: allUsersReload,
  } = useAllUsers(usersParams, allUsersOptions);

  useApiErrorsToast(usersError);
  useApiErrorsToast(allUsersError);

  const pagination = useMemo(
    () => (isAllUsers ? allUsersPagination : usersPagination),
    [allUsersPagination, isAllUsers, usersPagination],
  );

  useEffect(() => {
    if (pagination?.total_pages < page)
      handlePageChange(pagination.total_pages);
  }, [handlePageChange, page, pagination?.total_pages]);

  useEffect(() => {
    if (firstLoading) {
      setInitDataHasContent(false);
    }
    if (
      (!usersAreLoading && !isAllUsers) ||
      (!allUsersAreLoading && isAllUsers)
    )
      setFirstLoading(false);
  }, [allUsersAreLoading, firstLoading, isAllUsers, usersAreLoading]);

  useEffect(() => {
    if (!initDataHasContent && (users.length || allUsers.length)) {
      setInitDataHasContent(true);
    }
  }, [users.length, initDataHasContent, allUsers.length]);

  const handleResetGrid = useCallback(() => {
    setSort();
    removeAllFilters();
    removeLocalStorage(SORT_KEY);
    removeLocalStorage(OPTIONS_KEY);
    setGridOptions();
  }, [OPTIONS_KEY, SORT_KEY, removeAllFilters]);

  const showGrid = useMemo(() => {
    if (!isAllUsers)
      return (
        users.length > 0 ||
        (usersAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent
      );
    return (
      isRoleAdmin &&
      (allUsers.length > 0 ||
        (allUsersAreLoading && !firstLoading) ||
        filtersApplied ||
        initDataHasContent)
    );
  }, [
    isAllUsers,
    users.length,
    usersAreLoading,
    firstLoading,
    filtersApplied,
    initDataHasContent,
    isRoleAdmin,
    allUsers.length,
    allUsersAreLoading,
  ]);

  const emptyData = useMemo(() => {
    if (showGrid) return null;
    if (firstLoading && (!isAllUsers || isRoleAdmin)) {
      return (
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loading', 'testId')}
        />
      );
    }
    return (
      <Heading
        level={2}
        additionalClasses="text-3xl md:text-4xl leading-8 dark:text-white"
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon className="text-red w-14 md:w-20 mb-3" />
          {(usersStatus || allUsersStatus) === 403 ||
          (!isRoleAdmin && isAllUsers)
            ? t('Global.NoAccess')
            : t('Media.OnErrorMessage')}
        </div>
      </Heading>
    );
  }, [
    showGrid,
    firstLoading,
    isAllUsers,
    isRoleAdmin,
    testId,
    usersStatus,
    allUsersStatus,
    t,
  ]);

  const handleInitialGridOptions = useCallback(
    (cols, minWidth) => {
      setInitialGridOptions(
        cols,
        minWidth,
        usersGridContainer.current.offsetWidth - 50,
        setGridOptions,
        setColumns,
        OPTIONS_KEY,
      );
    },
    [OPTIONS_KEY],
  );

  const handleFilterDataSourceFetch = useCallback(
    async (accessor, query) => {
      if (accessor === 'spaces') {
        try {
          const { body: Objects, status: ObjectsStatus } = await getSpacesList(
            jwt,
            undefined,
            {
              name: query ? query : '',
              page: 1,
              limit: 10,
              order_by: 'name',
              order_direction: 'asc',
            },
          );

          checkResponseStatus(Objects, ObjectsStatus);

          return Objects.data.map((el) => ({
            value: el.name,
            label: el.name,
          }));
        } catch (error) {
          if (error.message) {
            toast.error(error.message);
          } else {
            toast.error(t('Spaces.SpacesListFetchError'));
          }
        }
      }

      return [];
    },
    [t, jwt],
  );

  useEffect(() => {
    prepareColumns(
      gridOptions,
      OPTIONS_KEY,
      editGrid,
      t,
      testId,
      setGridOptions,
      handleInitialGridOptions,
      setColumns,
      getDefinedColumns,
      isAllUsers,
      handleFilterDataSourceFetch,
      isRoleAdmin,
    );
  }, [
    OPTIONS_KEY,
    editGrid,
    gridOptions,
    handleFilterDataSourceFetch,
    handleInitialGridOptions,
    isAllUsers,
    isRoleAdmin,
    t,
    testId,
  ]);

  const impersonateAction = useCallback(
    ({ email, id }) => ({
      key: 'impersonate',
      label: t('Global.Impersonate'),
      onClick: () => {
        setImpersonate({
          email,
          id,
        });
        updateAppContext?.((prevState) => ({
          ...prevState,
          space: undefined,
          spaceSlug: undefined,
        }));
      },
      icon: <AvatarIcon className="text-gray-500 w-4 min-w-4" />,
      link: '/',
    }),
    [setImpersonate, t, updateAppContext],
  );

  const impersonateColumn = useMemo(
    () => ({
      accessor: '_impersonate',
      label: '',
      width: 50,
      minWidth: 50,
      flexGrow: 0,
      justify: 'center',
      notDraggable: true,
      render: (_, rowData) =>
        actionsRenderer(rowData, [impersonateAction(rowData)], []),
      resizable: false,
      sortable: false,
    }),
    [impersonateAction],
  );

  const actionColumn = useActionsColumn('/users');

  const allColumns = useMemo(
    () => [
      actionColumn,
      ...(isAllUsers ? [impersonateColumn] : []),
      ...columns,
    ],
    [actionColumn, isAllUsers, impersonateColumn, columns],
  );

  const exportActions = useMemo(
    () => [
      {
        key: 'paying',
        label: t('Users.ExportPaying'),
        link: generateExportUsersCsvUrl(jwt, { paying: true }),
      },
      {
        key: 'freeLogged',
        label: t('Users.ExportFreeLogged'),
        link: generateExportUsersCsvUrl(jwt, {
          planName: 'free',
          loginFromDays: 0,
          loginToDays: 30,
        }),
      },
      {
        key: '90days',
        label: t('Users.Export90Days'),
        link: generateExportUsersCsvUrl(jwt, {
          loginFromDays: 90,
          loginToDays: 179,
        }),
      },
      {
        key: '180days',
        label: t('Users.Export180Days'),
        link: generateExportUsersCsvUrl(jwt, {
          loginFromDays: 180,
          loginToDays: 364,
        }),
      },
      {
        key: '365days',
        label: t('Users.Export365Days'),
        link: generateExportUsersCsvUrl(jwt, {
          loginFromDays: 365,
        }),
      },
    ],
    [jwt, t],
  );

  return (
    <PageLayout
      page={isAllUsers ? 'admin/all-users' : 'users'}
      id={isAllUsers ? 'allUsers' : 'users'}
      menuItemOpen={isAllUsers ? 'admin' : ''}
      title={isAllUsers ? t('Global.AllUsers') : t('Global.Users')}
      breadcrumbs={<TopbarBreadcrumbs />}
      buttons={
        <>
          {(isAdmin || isRoleAdmin) && (
            <TopbarButton
              label={t('Users.Add')}
              link={'/users/add'}
              {...(disabledAdding
                ? {
                    disabled: true,
                    tooltip: t('Users.LimitReached'),
                    tooltipPlacement: 'leftBottom',
                    additionalTooltipClasses: 'hidden md:block',
                  }
                : {})}
            />
          )}
          {isRoleAdmin && isAllUsers && (
            <>
              <TopbarButton
                label={t('Users.Export')}
                link={generateExportUsersCsvUrl(jwt)}
              />
              <TopbarActionMenu>
                {exportActions.map(({ key, label, link }) => (
                  <TopbarActionButton key={key} label={label} link={link} />
                ))}
              </TopbarActionMenu>
            </>
          )}
        </>
      }
      secondaryTopbar={
        <DataGridControl
          displayGridCheckboxes={showGrid && columns.length > 0}
          displayGridControl={showGrid}
          editGrid={editGrid}
          filterGrid={filterGrid}
          setEditGrid={setEditGrid}
          setFilterGrid={setFilterGrid}
          handleResetGrid={handleResetGrid}
          limit={limit}
          handleLimitChange={handleLimitChange}
          columns={columns}
          gridOptions={gridOptions}
          optionsLocalStorageKey={OPTIONS_KEY}
          setGridOptions={setGridOptions}
          columnsVisibilityAdditionalClasses={twMerge(
            isAllUsers && '-top-2 xs:top-[0.2rem]',
          )}
          testId={testId}
        />
      }
      noPaddings
      testId={testId}
    >
      <div className={predefinedLayoutClasses.withDataGrid}>
        <div
          className={predefinedLayoutClasses.dataGridContainer}
          ref={usersGridContainer}
        >
          {showGrid ? (
            <CustomizableDataGrid
              columns={allColumns}
              setColumns={setColumns}
              data={isAllUsers ? allUsers : users}
              isLoading={isAllUsers ? allUsersAreLoading : usersAreLoading}
              setSort={setSort}
              sort={sort?.sortField || undefined}
              sortOrder={sort?.sortOrder || undefined}
              sortingLocalStorageKey={SORT_KEY}
              optionsLocalStorageKey={OPTIONS_KEY}
              setCurrentPage={handlePageChange}
              editGrid={editGrid}
              gridOptions={gridOptions}
              setGridOptions={setGridOptions}
              statusBar={
                <StatusBar
                  rows={pagination.count}
                  currentPage={page}
                  pagesCount={pagination.total_pages}
                  handlePageChange={handlePageChange}
                  handleDataUpdate={isAllUsers ? allUsersReload : usersReload}
                  resultsFrom={(pagination.current_page - 1) * limit + 1}
                  resultsTo={
                    (pagination.current_page - 1) * limit + pagination.count
                  }
                  resultsTotalCount={pagination.total_count}
                  {...getTestProps(testId, 'statusbar', 'testId')}
                />
              }
              noDataInfoText={t('Users.FiltersEmptyResult')}
              hasFilters={filterGrid}
              filters={filters}
              disableFilters={editGrid}
              onFilter={handleFiltersChange}
              additionalClasses="bg-white dark:bg-gray-900"
              {...getTestProps(testId, 'grid', 'testId')}
            />
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              {emptyData}
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default Users;

Users.propTypes = {
  /**
   * If page is for all users data
   */
  isAllUsers: PropTypes.bool,
  /**
   * Test id for users page
   */
  testId: PropTypes.string,
};

Users.defaultProps = {
  isAllUsers: false,
  testId: 'users-grid',
};
