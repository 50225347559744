import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './plan-history-table.css';

// :: Lib
import { getFormatedSize, getTestProps } from '../../lib/helpers';
import { getDetailsMap } from './getDetailsMap';

// :: Hooks
import { useSpacePlanHistory } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';

// :: Components
import Pagination from '../Pagination/Pagination';
import Loader from '../Loader/Loader';
import Panel from '../Panel/Panel';

const CURRENT_PLAN_PARAMS = {
  limit: 1,
  filters: JSON.stringify({ current: { type: 'equals', filter: true } }),
};

const getPropertyFormat = (
  property,
  t,
  isBoolean = false,
  isDate = false,
  mode = null,
) => {
  if (isBoolean) return (!!property).toString();
  if (isDate) return moment(property).format('L LT');
  return (
    getFormatedSize(property, mode, t('Global.Unlimited')) || 'N/A'
  ).toString();
};

const PlanHistoryModalContent = ({ spaceId, testId }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const {
    data: currentPlan,
    isLoading: currentPlanIsLoading,
    errors: currentPlanErrors,
  } = useSpacePlanHistory(spaceId, CURRENT_PLAN_PARAMS);

  const planHistoryParams = useMemo(
    () => ({
      limit: 10,
      page,
    }),
    [page],
  );

  const {
    data: planHistory,
    isLoading: planHistoryIsLoading,
    errors: planHistoryErrors,
    pagination,
  } = useSpacePlanHistory(spaceId, planHistoryParams);

  useApiErrorsToast(planHistoryErrors);
  useApiErrorsToast(currentPlanErrors);

  const informationBlock = useMemo(
    () => [
      {
        key: 'name',
        name: t('PlanHistory.CurrentPlan'),
        value: currentPlan[0]?.planCopy?.name,
      },
      {
        key: 'changed',
        name: t('PlanHistory.LastChange'),
        value: currentPlan[0]?.createdAt
          ? moment(currentPlan[0].createdAt).format('L LT')
          : 'N/A',
      },
    ],
    [currentPlan, t],
  );

  const planLimitsDetails = useMemo(() => getDetailsMap(t), [t]);

  return planHistoryIsLoading || currentPlanIsLoading ? (
    <div className="flex justify-center items-center h-24">
      <Loader type="spinner-grid" size="small" className="w-5" />
    </div>
  ) : (
    <>
      <div className="pb-2" {...getTestProps(testId, 'information')}>
        {informationBlock.map((info) => (
          <div key={info.key}>
            <strong>{info.name}</strong>: {info.value}
          </div>
        ))}
      </div>
      {planHistory.map(({ id, notes, plan, planCopy, current, createdAt }) => (
        <Panel
          key={id}
          title={createdAt ? moment(createdAt).format('L LT') : 'N/A'}
          additionalContainerClasses={
            'px-0 py-3 bg-transparent dark:bg-transparent border-t rounded-none'
          }
          {...getTestProps(testId, id, 'testId')}
        >
          <p>
            <strong>{t('PlanHistory.Current')}: </strong>
            {getPropertyFormat(current, t, true)}
          </p>
          {notes && (
            <p className="mt-2">
              <strong>{t('PlanHistory.Notes')}: </strong>
              {notes}
            </p>
          )}
          <table className="plan-history-table mt-4">
            <thead>
              <tr>
                <td>{t('PlanHistory.Property')}</td>
                <td>{t('PlanHistory.UserValue')}</td>
                <td>{t('PlanHistory.PlanValue')}</td>
              </tr>
            </thead>
            <tbody>
              {planLimitsDetails.map(
                ({
                  planCopyProperty,
                  planProperty,
                  label,
                  isBoolean,
                  isDate,
                  mode,
                }) => {
                  const planCopyValue = getPropertyFormat(
                    planCopy[planCopyProperty],
                    t,
                    isBoolean,
                    isDate,
                    mode,
                  );
                  const planValue = getPropertyFormat(
                    plan[planProperty],
                    t,
                    isBoolean,
                    isDate,
                    mode,
                  );

                  return (
                    <tr key={planCopyProperty}>
                      <td>{label}</td>
                      <td
                        className={
                          planCopyValue !== planValue ? 'text-red' : ''
                        }
                        {...getTestProps(
                          testId,
                          `${id}-plan-copy-${planCopyProperty}`,
                        )}
                      >
                        {planCopyValue}
                      </td>
                      <td
                        {...getTestProps(
                          testId,
                          `${id}-plan-${planCopyProperty}`,
                        )}
                      >
                        {planValue}
                      </td>
                    </tr>
                  );
                },
              )}
            </tbody>
          </table>
        </Panel>
      ))}
      {pagination?.total_pages > 1 && (
        <Pagination
          numOfPages={pagination.total_pages}
          page={page}
          onPageChange={setPage}
          additionalClasses="!p-0"
          {...getTestProps(testId, 'pagination', 'testId')}
        />
      )}
    </>
  );
};

export default PlanHistoryModalContent;

PlanHistoryModalContent.propTypes = {
  /**
   * Space id
   */
  spaceId: PropTypes.string.isRequired,
  /**
   * Test id for component
   */
  testId: PropTypes.string,
};

PlanHistoryModalContent.defaultProps = {
  testId: '',
};
