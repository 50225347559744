import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { getIn, useFormikContext } from 'formik';

// :: Contexts
import { useModals } from '../../../contexts/ModalContext';
import ContentTypeFormContext from '../../../contexts/ContentTypeFormContext';
import ListPropertiesContext from '../../../contexts/ListPropertiesContext';
import PropertyModalCtdsContext from '../../../contexts/PropertyModalCtdsContext';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

// :: Hooks
import useDebounceCallback from '../../../hooks/useDebounceCallback';

// :: Components
import Button from '../../../components/Button/Button';
import HelpErrorTextsTemplate from '../../../components/HelpErrorTextsTemplate/HelpErrorTextsTemplate';

// :: Form
import { EDITABLE } from '../../PropertyModalForm/PropertiesSettings/propertiesFields';
import DraggableCard from '../DraggableCard/DraggableCard';
import PropertyModalForm from '../../PropertyModalForm/PropertyModalForm';

// :: Form Helpers
import {
  changeOrderDown,
  changeOrderUp,
  deleteProperty,
  updateOrder,
  updateProperties,
} from '../helpers/parser';

// Icons
import { CaretUpIcon, PlusCircleOutlineIcon } from '../../../images/shapes';
import Tooltip from '../../../components/Tooltip/Tooltip';

const ListProperties = ({
  listName,
  listPropertiesItems,
  listSchemaItems,
  isOpen,
  additionalClasses,
  testId,
  parentInputType,
}) => {
  const { t } = useTranslation();
  const modal = useModals();
  const formik = useFormikContext();
  const [open, setOpen] = useState(isOpen);
  const ctdContext = useContext(ContentTypeFormContext);
  const { oldCtd, isEditing, setDirty } = ctdContext;
  const { highListName } = useContext(ListPropertiesContext);
  const propertyContext = useContext(PropertyModalCtdsContext);

  const isInPropertyModal = useMemo(
    () => Object.keys(propertyContext).length > 0,
    [propertyContext],
  );

  const listPropertiesContextValue = useMemo(
    () => ({
      highListName: listName,
      isNestedList: !!highListName,
    }),
    [highListName, listName],
  );

  const metaPath = useMemo(() => {
    const pathEnd = highListName ? `.propertiesConfig.${listName}.items` : '';

    if (isInPropertyModal) {
      if (
        parentInputType === 'edit_object_object' ||
        parentInputType === 'edit_undefined_object'
      ) {
        return `config.items`;
      }

      return `config.items` + pathEnd;
    }
    return (
      `metaDefinition.propertiesConfig.${
        highListName ? highListName : listName
      }.items` + pathEnd
    );
  }, [highListName, isInPropertyModal, listName, parentInputType]);

  const schemaPath = useMemo(() => {
    const pathEnd = highListName ? `.properties.${listName}.items` : '';

    if (isInPropertyModal) {
      if (
        parentInputType === 'edit_object_object' ||
        parentInputType === 'edit_undefined_object'
      ) {
        return `schema.items`;
      }

      return `schema.items` + pathEnd;
    }

    return (
      `schemaDefinition.allOf[1].properties.${
        highListName ? highListName : listName
      }.items` + pathEnd
    );
  }, [highListName, isInPropertyModal, listName, parentInputType]);

  const oldOrder = useMemo(
    () => getIn(oldCtd, `${metaPath}.order`) || [],
    [metaPath, oldCtd],
  );

  useEffect(() => {
    if (
      !listSchemaItems?.properties ||
      Array.isArray(listSchemaItems?.properties)
    ) {
      formik.setFieldValue(`${schemaPath}.properties`, {});
    }
    if (
      !listPropertiesItems?.propertiesConfig ||
      Array.isArray(listPropertiesItems?.propertiesConfig)
    ) {
      formik.setFieldValue(`${metaPath}.propertiesConfig`, {});
    }
  }, [
    formik,
    listPropertiesItems?.propertiesConfig,
    listSchemaItems?.properties,
    metaPath,
    schemaPath,
  ]);

  const handleDeleteField = useCallback(
    async (fieldName, formik) => {
      let result = null;
      const showModal = isEditing && oldOrder.indexOf(fieldName) > -1;
      if (!showModal) result = true;
      else result = await modal.delete(t('ContentTypeForm.DeleteProperty'));
      if (result) {
        deleteProperty(
          formik,
          fieldName,
          metaPath,
          schemaPath,
          schemaPath,
          setDirty,
        );
      }
    },
    [isEditing, oldOrder, modal, t, metaPath, schemaPath, setDirty],
  );

  const handleChangeOrder = useCallback(
    (sourceName, targetName, formik) => {
      updateOrder(sourceName, targetName, formik, metaPath, setDirty);
    },
    [metaPath, setDirty],
  );

  const onDropCallback = useDebounceCallback(handleChangeOrder, 10);

  const handleOrderUp = useCallback(
    (idx, formik) => {
      changeOrderUp(formik, metaPath, idx, setDirty);
    },
    [metaPath, setDirty],
  );

  const handleOrderDown = useCallback(
    (idx, formik) => {
      changeOrderDown(formik, metaPath, idx, setDirty);
    },
    [metaPath, setDirty],
  );

  const updateFormikProperties = useCallback(
    (formik, property, newProperty, copiedPropertyIdx) => {
      updateProperties(
        formik,
        property,
        newProperty,
        copiedPropertyIdx,
        metaPath,
        schemaPath,
        schemaPath,
        setDirty,
      );
    },
    [metaPath, schemaPath, setDirty],
  );

  const openPropertyModal = useCallback(
    async (
      formik,
      fieldName,
      fieldProps,
      fieldSchema,
      isRequired,
      copiedPropertyIdx,
      parentInputType,
    ) => {
      let property = null;
      if (fieldName) {
        property = { key: fieldName };
        property.config = fieldProps || { label: '' };
        property.schema = fieldSchema || { type: '' };
        property.required = !!isRequired;
      }

      const newProperty = await modal({
        title: (
          <div className="font-bold text-xl sm:text-3xl">
            {t('ContentTypeForm.ContentProperty')}
          </div>
        ),
        content: (
          <ContentTypeFormContext.Provider value={ctdContext}>
            <ListPropertiesContext.Provider value={listPropertiesContextValue}>
              <PropertyModalForm
                property={property ? property : null}
                order={listPropertiesItems?.order}
                isNew={
                  copiedPropertyIdx > -1 || oldOrder.indexOf(property?.key) < 0
                }
                isDuplicate={copiedPropertyIdx > -1}
                parentInputType={`edit_${parentInputType}`}
                {...getTestProps(testId, 'property-form', 'testId')}
              />
            </ListPropertiesContext.Provider>
          </ContentTypeFormContext.Provider>
        ),
        size: '2xl',
        dialogAdditionalClasses: 'h-full lg:max-h-[calc(100vh-2rem)]',
      });

      if (!newProperty) return;

      updateFormikProperties(
        formik,
        copiedPropertyIdx > -1 ? null : property,
        newProperty,
        copiedPropertyIdx,
      );
    },
    [
      modal,
      t,
      ctdContext,
      listPropertiesContextValue,
      listPropertiesItems?.order,
      oldOrder,
      testId,
      updateFormikProperties,
    ],
  );

  const handleEditField = useCallback(
    async (
      fieldName,
      fieldProps,
      fieldSchema,
      isRequired,
      formik,
      copiedPropertyIdx = -1,
      parentInputType,
    ) => {
      if (EDITABLE.indexOf(fieldProps.inputType) < 0) {
        alert('Not implemented');
        return;
      }

      await openPropertyModal(
        formik,
        fieldName,
        fieldProps,
        fieldSchema,
        isRequired,
        copiedPropertyIdx,
        parentInputType,
      );
    },
    [openPropertyModal],
  );

  const renderField = useCallback(
    (fieldName, idx, currentOrder, parentInputType) => (
      <DraggableCard
        key={fieldName}
        fieldName={fieldName}
        idx={idx}
        currentOrder={currentOrder}
        parentInputType={parentInputType}
        fieldProps={listPropertiesItems?.propertiesConfig?.[fieldName]}
        fieldSchema={listSchemaItems?.properties?.[fieldName]}
        isRequired={listSchemaItems?.required?.indexOf(fieldName) > -1}
        onDelete={handleDeleteField}
        onEdit={handleEditField}
        onDrop={onDropCallback}
        onUp={handleOrderUp}
        onDown={handleOrderDown}
        onDuplicate={handleEditField}
        additionalContainerClasses="bg-slate-400/10 border border-slate-200"
        testId={testId}
      />
    ),
    [
      listPropertiesItems?.propertiesConfig,
      listSchemaItems?.properties,
      listSchemaItems?.required,
      handleDeleteField,
      handleEditField,
      onDropCallback,
      handleOrderUp,
      handleOrderDown,
      testId,
    ],
  );

  return (
    <div className={twMerge('space-y-3 px-4 pb-4', additionalClasses)}>
      <div className="flex justify-end">
        <Button
          onClick={() => setOpen(!open)}
          buttonColor="borderless"
          buttonSize="xs"
          iconImage={
            <CaretUpIcon
              className={twMerge(
                'w-2 rotate-180 text-blue',
                open ? 'rotate-0' : 'rotate-180',
              )}
            />
          }
          additionalIconClasses="ml-1"
          noPaddings
          additionalClasses="mr-2"
          {...getTestProps(testId, 'show-properties', 'testId')}
        >
          {t('ContentTypeForm.ShowProperties')}
        </Button>
      </div>

      <ListPropertiesContext.Provider value={listPropertiesContextValue}>
        {open &&
          (listPropertiesItems?.order || []).map(
            (fieldName, idx, currentOrder) =>
              renderField(fieldName, idx, currentOrder, parentInputType),
          )}
      </ListPropertiesContext.Provider>
      <Button
        onClick={() => openPropertyModal(formik)}
        buttonSize="xs"
        buttonColor="blueBordered"
        iconImage={
          <PlusCircleOutlineIcon className="w-5 text-blue group-hover:text-white" />
        }
        iconPosition="end"
        additionalClasses="border group !mt-3.5"
        additionalChildrenClasses="font-medium"
        disabled={!listName}
        {...getTestProps(testId, 'add-property', 'testId')}
      >
        {listName ? (
          t('ContentTypeForm.AddProperty')
        ) : (
          <Tooltip
            tooltip={t('ContentTypeForm.NameMissing')}
            tooltipPlacement="rightCenter"
          >
            {t('ContentTypeForm.AddProperty')}
          </Tooltip>
        )}
      </Button>
      <HelpErrorTextsTemplate
        error={
          (getIn(formik.touched, metaPath) && getIn(formik.errors, metaPath)) ||
          ''
        }
        {...getTestProps(testId, 'items', 'testId')}
      />
    </div>
  );
};

export default ListProperties;

ListProperties.propTypes = {
  /**
   * List property name
   */
  listName: PropTypes.string.isRequired,
  /**
   * List properties items
   */
  listPropertiesItems: PropTypes.object,
  /**
   * List schema items
   */
  listSchemaItems: PropTypes.object,
  /**
   * If properties are default shown
   */
  isOpen: PropTypes.bool,
  /**
   * List properties additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

ListProperties.defaultProps = {
  listPropertiesItems: {},
  listSchemaItems: {},
  isOpen: false,
  additionalClasses: '',
  testId: '',
};
