import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired on manage button click.
 * Returning object will be used to render manage modal content.
 *
 * A plugin will **not** receive this event when manage button is clicked for any other plugin.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.plugins.manage::render"
 * @type {PluginsManageEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 */

/**
 * Callback to update plugin settings.
 *
 * @callback updateSettings
 * @memberof FlotiqPlugins.Events.PluginsManageEvent
 * @param {string} settings New plugin settings
 * @returns {Promise<[string,object]>} Result of save operation. 
 *          The first element of the array contains new settings in case of success, 
 *          and the second element contains possible errors
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name PluginsManageEvent
 *
 * @property {object} plugin Plugin data
 * @property {func} reload Callback for reloading plugin settings
 * @property {object} modalInstance Settings modal instance with resolve method
 * @property {array} contentTypes Content types data
 * @property {updateSettings} updateSettings Callback to update plugin settings. 
 */
export class PluginsManageEvent extends IFlotiqPluginEvent {
  plugin;
  reload;
  modalInstance;
  contentTypes;
  updateSettings;
}
