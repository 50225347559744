import { getIn } from 'formik';

export const updateProperties = (
  formik,
  property,
  newProperty,
  copiedPropertyIdx,
  metaPath,
  schemaPath,
  requiredBasePath,
  setDirty,
) => {
  const required = getIn(formik.values, `${requiredBasePath}.required`);
  const order = getIn(formik.values, `${metaPath}.order`);

  if (property && property.key !== newProperty.key) {
    formik.setFieldValue(
      `${metaPath}.propertiesConfig.${property.key}`,
      undefined,
    );
    formik.setFieldValue(`${schemaPath}.properties.${property.key}`, undefined);

    const oldIdx = required.indexOf(property.key);
    required.splice(oldIdx, 1);
    const oldOrderIdx = order.indexOf(property.key);
    order.splice(oldOrderIdx, 1, newProperty.key);
  }

  formik.setFieldValue(
    `${metaPath}.propertiesConfig.${newProperty.key}`,
    newProperty.config,
  );
  formik.setFieldValue(
    `${schemaPath}.properties.${newProperty.key}`,
    newProperty.schema,
  );

  if (order?.indexOf(newProperty.key) < 0 && !property) {
    if (copiedPropertyIdx > -1)
      order.splice(copiedPropertyIdx + 1, 0, newProperty.key);
    else order.push(newProperty.key);
  }

  formik.setFieldValue(`${metaPath}.order`, order);

  const requiredIdx = required?.indexOf(newProperty.key);
  if (newProperty.required) {
    if (requiredIdx < 0) required.push(newProperty.key);
  } else {
    if (requiredIdx > -1) required.splice(requiredIdx, 1);
  }

  formik.setFieldValue(`${requiredBasePath}.required`, required);
  setDirty(true);
};

export const updateOrder = (
  sourceName,
  targetName,
  formik,
  orderBasePath,
  setDirty,
) => {
  if (sourceName === targetName) return;
  const order = getIn(formik.values, `${orderBasePath}.order`);
  if (!order || order.length < 2) return;

  const targetIdx = order.findIndex((name) => name === targetName);
  const sourceIdx = order.findIndex((name) => name === sourceName);
  const newOrder = [...order];
  newOrder.splice(targetIdx, 0, newOrder.splice(sourceIdx, 1)[0]);

  formik.setFieldValue(`${orderBasePath}.order`, newOrder);
  setDirty(true);
};

export const changeOrderUp = (formik, orderBasePath, idx, setDirty) => {
  const newOrder = getIn(formik.values, `${orderBasePath}.order`);
  newOrder.splice(idx - 1, 0, newOrder.splice(idx, 1)[0]);

  formik.setFieldValue(`${orderBasePath}.order`, newOrder);
  setDirty(true);
};

export const changeOrderDown = (formik, orderBasePath, idx, setDirty) => {
  const newOrder = getIn(formik.values, `${orderBasePath}.order`);
  newOrder.splice(idx + 1, 0, newOrder.splice(idx, 1)[0]);

  formik.setFieldValue(`${orderBasePath}.order`, newOrder);
  setDirty(true);
};

export const deleteProperty = (
  formik,
  fieldName,
  metaPath,
  schemaPath,
  requiredBasePath,
  setDirty,
) => {
  const required = getIn(formik.values, `${requiredBasePath}.required`);
  const order = getIn(formik.values, `${metaPath}.order`);

  formik.setFieldValue(
    `${metaPath}.order`,
    order.filter((element) => element !== fieldName),
  );
  formik.setFieldValue(
    `${requiredBasePath}.required`,
    required.filter((element) => element !== fieldName),
  );

  formik.setFieldValue(`${metaPath}.propertiesConfig.${fieldName}`, undefined);
  formik.setFieldValue(`${schemaPath}.properties.${fieldName}`, undefined);
  setDirty(true);
};
