import { useCallback, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// :: Helpers
import { getTestProps } from '../../lib/helpers';

// :: Components
import StatusPill from '../StatusPill/StatusPill';
import Popover from '../Popover/Popover';
import Heading from '../Heading/Heading';
import DateContainer from '../DateContainer/DateContainer';
import Card from '../Card/Card';
import Button from '../Button/Button';
import LinkButton from '../LinkButton/LinkButton';

// :: Icons
import {
  ArrowRightCurvedThinIcon,
  ClipboardIcon,
  DuplicateIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PencilSquareIcon,
  ReloadIcon,
  TrashIcon,
} from '../../images/shapes';
import { useNavigate } from 'react-router-dom';

// Context
import UserContext from '../../contexts/UserContext';
import { RolePermissions } from '../../lib/rolePermissions';
import useSelectedSpace from '../../hooks/useSelectedSpace';

const TypeDefinitionCard = ({
  imageUrl,
  imageUrlAlt,
  imageUrlLoading,
  contentType,
  objectCount,
  onDelete,
  testId,
  isFolder,
  onClickFolder,
  limitReached,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { permissions } = useContext(UserContext);
  const { buildUrlWithSpace } = useSelectedSpace();
  const { canDelete, canRead } = useMemo(
    () =>
      isFolder
        ? { canDelete: true, canRead: true }
        : permissions.getCtdPermissions(contentType.name) || {},
    [contentType.name, isFolder, permissions],
  );
  const canReadCo = isFolder ? true : permissions.canCo(contentType.name);
  const canCreateNewCtd = permissions.canCtd(
    '*',
    RolePermissions.PERMISSIONS_TYPES.CREATE,
  );

  const popoverButton = (
    <div
      className="flex items-center justify-center w-8 h-8 rounded-full bg-slate-400/50 hover:bg-slate-400/80"
      {...getTestProps(testId, 'menu-toggle-button')}
    >
      <EllipsisVerticalIcon className="h-5 text-white" />
    </div>
  );

  const popoverMenuContentItems = useMemo(
    () => [
      {
        label: t('TypeDefinitionCard.Edit'),
        key: 'edit',
        icon: <PencilIcon className="w-4" />,
        link: buildUrlWithSpace(
          `content-type-definitions/edit/${contentType.name}`,
        ),
      },
      {
        label: t('TypeDefinitionCard.GoTo'),
        key: 'go',
        icon: <ArrowRightCurvedThinIcon className="w-4" />,
        link: buildUrlWithSpace(`content-type-objects/${contentType.name}`),
        disabled: !canReadCo,
      },
      {
        label: t('TypeDefinitionCard.DuplicateDefinition'),
        key: 'duplicate',
        icon: <DuplicateIcon className="w-4" />,
        link: buildUrlWithSpace(
          `content-type-definitions/duplicate/${contentType.name}`,
        ),
        disabled: !canCreateNewCtd || limitReached,
      },
      {
        label: (
          <span className="text-red">{t('TypeDefinitionCard.Delete')}</span>
        ),
        key: 'delete',
        icon: <TrashIcon className="w-4 text-red" />,
        onClick: (e) => {
          e.stopPropagation();
          e.preventDefault();
          onDelete(contentType.name);
        },
        disabled: !canDelete,
      },
    ],
    [
      t,
      buildUrlWithSpace,
      contentType.name,
      canReadCo,
      canCreateNewCtd,
      limitReached,
      canDelete,
      onDelete,
    ],
  );

  const popoverMenuContent = (
    <>
      {popoverMenuContentItems?.map((item) => {
        const { icon, label, disabled, ...props } = item;
        const Component = item.link ? LinkButton : Button;
        return (
          <Component
            buttonColor="borderless"
            buttonSize="sm"
            iconImage={icon}
            iconPosition="start"
            additionalClasses={twMerge(
              'last:border-t last:border-gray last:decoration-red last:pt-3',
              'last:text-red rounded-none w-full justify-end text-sm lg:text-base font-normal',
              !disabled &&
                'cursor-pointer hover:!text-blue hover:underline hover:bg-blue-300/25 last:hover:!text-red ',
            )}
            additionalIconClasses="mr-3"
            disabled={disabled}
            {...props}
            {...getTestProps(testId, `item-${item.key}`, 'testId')}
          >
            {label}
          </Component>
        );
      })}
    </>
  );

  const handleCardClick = useCallback(() => {
    if (isFolder) {
      onClickFolder?.(contentType.name);
    } else {
      navigate(buildUrlWithSpace(`content-type-objects/${contentType.name}`));
    }
  }, [contentType.name, buildUrlWithSpace, navigate, isFolder, onClickFolder]);

  return (
    <Card
      onClick={canReadCo ? handleCardClick : null}
      imageUrl={imageUrl}
      imageUrlAlt={imageUrlAlt}
      imageUrlLoading={imageUrlLoading}
      imageOverlay={
        <div className="flex flex-col h-full p-3 justify-between">
          <div className="flex justify-between transition duration-200 ease-in-out p-1 space-x-2 z-0">
            {canReadCo && (
              <StatusPill
                link={
                  !isFolder &&
                  buildUrlWithSpace(`content-type-objects/${contentType.name}`)
                }
                status={
                  <div className="flex items-center">
                    <ClipboardIcon className="w-3 mr-1.5 text-indigo-950 dark:text-blue" />
                    {isFolder
                      ? t('TypeDefinitionCard.TypeNumber', {
                          count: objectCount,
                        })
                      : t('TypeDefinitionCard.ObjectNumber', {
                          count: objectCount,
                        })}
                  </div>
                }
                color="green"
                containerClasses={twMerge(
                  '!font-medium py-1 flex items-center',
                  isFolder ? 'bg-orange hover:bg-amber-100' : 'hover:bg-lime',
                )}
                onClick={
                  isFolder ? () => onClickFolder?.(contentType.name) : null
                }
                testId={testId ? `${testId}-pill` : ''}
              />
            )}
            {canRead && !isFolder && (
              <div
                className="ml-auto"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Popover
                  content={popoverMenuContent}
                  popoverButton={popoverButton}
                  popoverPanelPlacement="leftBottom"
                  popoverPhonePanelPlacement="leftBottom"
                  additionalPopoverPanelClasses="w-max !mr-2 px-0 !py-4 rounded space-y-3.5"
                />
              </div>
            )}
          </div>
        </div>
      }
      body={
        <div className="bg-white dark:bg-slate-800 p-5 space-y-2">
          <Heading
            level={2}
            additionalClasses="text-xl pt-1 pb-2 leading-none dark:text-white"
          >
            {contentType.label}
          </Heading>
          <DateContainer
            date={contentType.createdAt}
            icon={<PencilSquareIcon className="text-blue w-4 mr-2" />}
            text={t('TypeDefinitionCard.Created')}
          />
          <DateContainer
            date={contentType.updatedAt}
            icon={<ReloadIcon className="text-blue w-4 mr-2" />}
            text={t('TypeDefinitionCard.Updated')}
          />
        </div>
      }
      additionalContainerClasses={'w-full'}
      additionalImageContainerClasses="aspect-[1.5]"
      darkenOnHover={canReadCo}
      testId={testId}
    />
  );
};

TypeDefinitionCard.propTypes = {
  /**
   * Image url
   */
  imageUrl: PropTypes.string,
  /**
   * Image alt attribute
   */
  imageUrlAlt: PropTypes.string,
  /**
   * If image url is loading
   */
  imageUrlLoading: PropTypes.bool,
  /**
   * Content type data
   */
  contentType: PropTypes.shape({
    createdAt: PropTypes.any,
    updatedAt: PropTypes.any,
    label: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  /**
   * Content objects count
   */
  objectCount: PropTypes.number,
  /**
   * CT delete callback
   */
  onDelete: PropTypes.func,
  /**
   * If card represents folder
   */
  isFolder: PropTypes.bool,
  /**
   * If limit of ctds is reached
   */
  limitReached: PropTypes.bool,
  /**
   * Test id of the card
   */
  testId: PropTypes.string,
};

TypeDefinitionCard.defaultProps = {
  imageUrl: '',
  imageUrlAlt: '',
  imageUrlLoading: false,
  objectCount: 0,
  onDelete: /* istanbul ignore next */ () => null,
  testId: '',
  isFolder: false,
  limitReached: false,
};

export default TypeDefinitionCard;
