import Avatar from '../../components/Avatar/Avatar';
import {
  checkboxRenderer,
  dateRenderer,
  defaultRenderer,
} from '../../components/DataGrid/DataGridCell/cellRenderer';
import LinkButton from '../../components/LinkButton/LinkButton';
import { ArrowUpRightIcon } from '../../images/shapes';
import { getStripeURL } from '../../lib/helpers';

export const getDefinedColumns = (
  gridOptionsByID,
  hiddenColumnsById,
  editGrid,
  t,
  testId,
  isAllUsers,
  filterDatasourceFetch,
  isRoleAdmin,
) => ({
  email: {
    accessor: 'email',
    label: t('Global.Email'),
    width: gridOptionsByID?.['email']?.width,
    render: (data, rowData) => (
      <div className="flex items-center h-full font-normal">
        <span className="w-fit h-fit mr-2">
          <Avatar
            userInitials={rowData.firstName[0] + rowData.lastName[0]}
            testId={testId}
          />
        </span>
        <span className="truncate">{data}</span>
      </div>
    ),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.email,
  },
  firstName: {
    accessor: 'firstName',
    label: t('Global.FirstName'),
    width: gridOptionsByID?.['firstName']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.firstName,
  },
  lastName: {
    accessor: 'lastName',
    label: t('Global.LastName'),
    width: gridOptionsByID?.['lastName']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.lastName,
  },
  enabled: {
    accessor: 'enabled',
    label: t('Users.Status'),
    width: gridOptionsByID?.['enabled']?.width,
    render: (data) =>
      isAllUsers
        ? checkboxRenderer(data)
        : defaultRenderer(data ? t('Users.Active') : t('Users.Invited')),
    sortable: !editGrid,
    filterInputType: isAllUsers ? 'checkbox' : 'select',
    filterInputOptions: isAllUsers
      ? null
      : [
          { label: t('Users.Active'), value: 'true' },
          { label: t('Users.Invited'), value: 'false' },
        ],
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.enabled,
    baseWidth: isAllUsers ? 120 : 150,
  },
  spaces: {
    accessor: 'spaces',
    label: t('Global.Spaces'),
    width: gridOptionsByID?.['spaces']?.width,
    render: (data) => defaultRenderer(data?.map((el) => el.name).join(', ')),
    sortable: !editGrid,
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['spaces'],
    filterInputType: 'datasource',
    filterInputOptions: undefined,
    filterDatasourceFetch: (query) => filterDatasourceFetch?.('spaces', query),
  },
  roles: {
    accessor: 'roles',
    label: t('Users.Roles'),
    width: gridOptionsByID?.['roles']?.width,
    render: (data) =>
      defaultRenderer(
        data
          ?.map((role) =>
            role
              .replace('ROLE_HEADLESS_ADMIN', 'Organization Admin')
              .replace('ROLE_HEADLESS_USER', 'Organization User')
              .replace('ROLE_ADMIN', 'Flotiq Admin')
              .replace('ROLE_USER', 'Flotiq User'),
          )
          .join(', '),
      ),
    sortable: true,
    filterInputType: 'select',
    filterInputOptions: [
      {
        value: 'role_headless_admin',
        label: 'Organization Admin',
      },
      {
        value: 'role_headless_user',
        label: 'Organization User',
      },
      ...(isRoleAdmin
        ? [
            {
              value: 'role_admin',
              label: 'Flotiq Admin',
            },
            {
              value: 'role_user',
              label: 'Flotiq User',
            },
          ]
        : []),
    ],
    multiple: true,
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['roles'],
  },
  ...(isAllUsers
    ? {
        'organization.name': {
          accessor: 'organization.name',
          label: t('Users.Organization'),
          width: gridOptionsByID?.['organization.name']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById['organization.name'],
        },
        'organization.id': {
          accessor: 'organization.id',
          label: t('Users.OrganizationId'),
          width: gridOptionsByID?.['organization.id']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById['organization.id'],
        },
        paymentClientId: {
          accessor: 'paymentClientId',
          label: t('Users.PaymentClientId'),
          width: gridOptionsByID?.['paymentClientId']?.width,
          render: (data) =>
            data
              ? defaultRenderer(
                  <div className="flex gap-1 items-center">
                    <LinkButton
                      link={getStripeURL(`/customers/${data}`)}
                      target="_blank"
                      rel="noreferrer"
                      iconImage={<ArrowUpRightIcon className="w-5" />}
                      buttonColor="borderless"
                      buttonSize="xs"
                      iconColor="blue"
                      iconPosition="start"
                      additionalClasses={
                        '!p-2 text-indigo-950 text-sm' +
                        ' lg:text-base 2xl:text-base font-normal max-w-full focus:hover:underline'
                      }
                      additionalChildrenClasses="truncate"
                    >
                      {data}
                    </LinkButton>
                  </div>,
                )
              : '',
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById['paymentClientId'],
        },
        source: {
          accessor: 'source',
          label: t('Users.Source'),
          width: gridOptionsByID?.['source']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          filterInputType: 'text',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.source,
          baseWidth: 150,
        },
        defaultSpace: {
          accessor: 'defaultSpace',
          label: t('Users.DefaultSpace'),
          width: gridOptionsByID?.['defaultSpace']?.width,
          render: (data) => checkboxRenderer(data),
          sortable: !editGrid,
          filterInputType: 'checkbox',
          resizable: true,
          removable: true,
          hide: hiddenColumnsById['defaultSpace'],
        },
        lastLogin: {
          accessor: 'lastLogin',
          label: t('Users.LastLogin'),
          width: gridOptionsByID?.['lastLogin']?.width,
          render: (data) => dateRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.lastLogin,
        },
        createdAt: {
          accessor: 'createdAt',
          label: t('Users.Registered'),
          width: gridOptionsByID?.['createdAt']?.width,
          render: (data) => dateRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.createdAt,
        },
        companyName: {
          accessor: 'companyName',
          label: t('Users.CompanyName'),
          filterInputType: 'text',
          width: gridOptionsByID?.['companyName']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.companyName,
        },
        country: {
          accessor: 'country',
          label: t('Users.Country'),
          filterInputType: 'text',
          width: gridOptionsByID?.['country']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.country,
        },
        occupation: {
          accessor: 'occupation',
          label: t('Users.Occupation'),
          width: gridOptionsByID?.['occupation']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.occupation,
          filterInputType: 'select',
          filterInputOptions: [
            {
              value: 'developer',
              label: 'Developer',
            },
            {
              value: 'contentCreatorManager',
              label: 'Content creator manager',
            },
            {
              value: 'both',
              label: 'Both',
            },
          ],
        },
        workType: {
          accessor: 'workType',
          label: t('Users.WorkType'),
          width: gridOptionsByID?.['workType']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.workType,
          filterInputType: 'select',
          filterInputOptions: [
            {
              value: 'agency',
              label: 'Agency',
            },
            {
              value: 'businessOrOrganization',
              label: 'Business or organization',
            },
          ],
        },
        termsOfService: {
          accessor: 'termsOfService',
          label: t('Users.TermsOfService'),
          width: gridOptionsByID?.['termsOfService']?.width,
          render: (data) => checkboxRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.termsOfService,
          filterInputType: 'checkbox',
        },
        privacyPolicy: {
          accessor: 'privacyPolicy',
          label: t('Users.PrivacyPolicy'),
          width: gridOptionsByID?.['privacyPolicy']?.width,
          render: (data) => checkboxRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.privacyPolicy,
          filterInputType: 'checkbox',
        },
        agreeNewsUpdates: {
          accessor: 'agreeNewsUpdates',
          label: t('Users.AgreeNewsUpdates'),
          width: gridOptionsByID?.['agreeNewsUpdates']?.width,
          render: (data) => checkboxRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.agreeNewsUpdates,
          filterInputType: 'checkbox',
        },
        additionalInformationStatus: {
          accessor: 'additionalInformationStatus',
          label: t('Users.AdditionalInformationStatus'),
          width: gridOptionsByID?.['additionalInformationStatus']?.width,
          render: (data) => defaultRenderer(data),
          sortable: !editGrid,
          resizable: true,
          removable: true,
          hide: hiddenColumnsById.additionalInformationStatus,
          filterInputType: 'select',
          filterInputOptions: [
            {
              value: 'required',
              label: 'Required',
            },
            {
              value: 'optional',
              label: 'Optional',
            },
            {
              value: 'done',
              label: 'Done',
            },
          ],
        },
      }
    : {}),
});
