import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import UserContext from '../../contexts/UserContext';
import { DeleteIcon, DuplicateIcon, PencilIcon } from '../../images/shapes';
import { RolePermissions } from '../../lib/rolePermissions';
import { actionsRenderer } from './DataGridCell/cellRenderer';
import { useParams } from 'react-router-dom';

const useActionsColumn = (
  baseUrl = '',
  actions = ['edit'],
  mobileActions = [],
  contentTypeName = '',
  ignorePermissions = true,
  onDelete = null,
  testId = '',
) => {
  const { t } = useTranslation();
  const { spaceSlug } = useParams();
  const { buildUrlWithSpace } = useSelectedSpace();
  const { permissions } = useContext(UserContext);

  const getLink = useCallback(
    (action, rowData) => {
      if (!spaceSlug) return `${baseUrl}/${action}/${rowData.id}`;
      else if (!baseUrl)
        return buildUrlWithSpace(
          `content-type-objects/${action}/${contentTypeName}/${rowData.id}`,
        );
      return buildUrlWithSpace(`${baseUrl}/${action}/${rowData.id}`);
    },
    [baseUrl, buildUrlWithSpace, contentTypeName, spaceSlug],
  );

  const getActionsDictionary = useCallback(
    (rowData, onDelete) => ({
      edit: {
        key: 'edit',
        label: t('Global.Edit'),
        link: getLink('edit', rowData),
        icon: <PencilIcon className="text-blue w-3.5" />,
      },
      duplicate: {
        key: 'duplicate',
        label: t('Global.Duplicate'),
        link: getLink('duplicate', rowData),
        disabled: ignorePermissions
          ? false
          : !permissions.canCo(
              contentTypeName,
              RolePermissions.PERMISSIONS_TYPES.CREATE,
            ),
        icon: <DuplicateIcon className="text-gray-500 w-3.5" />,
      },
      delete: {
        key: 'delete',
        label: t('Global.Delete'),
        onClick: () => onDelete?.(rowData),
        disabled: ignorePermissions
          ? false
          : !permissions.canCo(
              contentTypeName,
              RolePermissions.PERMISSIONS_TYPES.DELETE,
            ),
        icon: <DeleteIcon className="text-red w-3.5" />,
      },
    }),
    [contentTypeName, getLink, ignorePermissions, permissions, t],
  );

  const getActions = useCallback(
    (actions, rowData, onDelete) => {
      const dictionary = getActionsDictionary(rowData, onDelete);

      let actionsData = [];
      actions.forEach((action) => {
        if (dictionary[action]) return actionsData.push(dictionary[action]);
      });

      return actionsData;
    },
    [getActionsDictionary],
  );

  const actionColumn = useMemo(
    () => ({
      accessor: '_actions',
      label: '',
      width: 50,
      minWidth: 50,
      flexGrow: 0,
      fixed: 'left',
      align: 'right',
      justify: 'center',
      notDraggable: true,
      render: (_, rowData) =>
        actionsRenderer(
          rowData,
          getActions(actions, rowData, onDelete),
          getActions(mobileActions, rowData, onDelete),
          testId,
        ),
      resizable: false,
      sortable: false,
    }),
    [actions, getActions, mobileActions, onDelete, testId],
  );

  return actionColumn;
};

export default useActionsColumn;
