import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

// :: Components
import Heading from '../../../components/Heading/Heading';
import Button from '../../../components/Button/Button';
import Panel from '../../../components/Panel/Panel';
import SpaceLimits from '../SpaceLimits/SpaceLimits';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

const SpaceDetails = ({
  name,
  id,
  slug,
  planLimits,
  paymentDueDate,
  paymentStatus,
  handleUpgrade,
  isAdmin,
  testId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-row w-full">
      <Panel
        title={
          <div className="flex flex-col md:flex-row items-center w-full">
            <Heading
              level={3}
              additionalClasses="text-3.5xl dark:text-white py-0 leading-6"
            >
              {name}
            </Heading>

            <div
              className={twMerge(
                'ml-0 md:ml-auto mt-4 sm:mt-2 md:mt-0 space-y-4 xs:space-y-0',
                'flex flex-col xs:flex-row w-full justify-start md:justify-end',
              )}
            >
              <Button
                buttonColor="grayBordered"
                buttonSize="xs"
                onClick={() => navigate(`/s/${slug}`)}
                additionalClasses="mr-4 w-fit"
                {...getTestProps(testId, 'go-to-space', 'testId')}
              >
                {t('Spaces.GoToSpace')}
              </Button>

              <Button
                buttonColor="grayBordered"
                buttonSize="xs"
                onClick={() => navigate(`/space/edit/${id}`)}
                additionalClasses="mr-4 w-fit"
                {...getTestProps(testId, 'edit-space', 'testId')}
              >
                {t('Spaces.EditSpace', { name: '' })}
              </Button>

              <Button
                buttonSize="xs"
                onClick={(e) => handleUpgrade(e, id)}
                additionalClasses="mr-4 w-fit"
                disabled={planLimits?.price === -1}
                {...getTestProps(testId, 'upgrade-space', 'testId')}
              >
                {t('Spaces.UpgradeSpace')}
              </Button>
            </div>
          </div>
        }
        open={true}
        additionalTitleWrapClasses="w-full"
        additionalTitleClasses="w-full p-2 pr-4 bg-slate-50 dark:bg-slate-900 rounded-t-lg"
        additionalContainerClasses="!w-full p-0 my-4  border border-slate-200 dark:border-slate-800"
        additionalChildrenClasses="!h-full p-4 border-t border-slate-200 dark:border-slate-800"
      >
        <SpaceLimits
          id={id}
          planLimits={planLimits}
          paymentDueDate={paymentDueDate}
          paymentStatus={paymentStatus}
          isAdmin={isAdmin}
          testId={testId}
        />
      </Panel>
    </div>
  );
};

export default SpaceDetails;

SpaceDetails.propTypes = {
  /**
   * Test id for page
   */
  testId: PropTypes.string,
  /**
   * Space details name
   */
  name: PropTypes.string,
  /**
   * Space details id
   */
  id: PropTypes.string,
  /**
   * Space details slug
   */
  slug: PropTypes.string,
  /**
   * Space details plan limits details
   */
  planLimits: PropTypes.any,
  /**
   * Space details payment due date
   */
  paymentDueDate: PropTypes.string,
  /**
   * Space details payment status
   */
  paymentStatus: PropTypes.string,
  /**
   * Space details upgrade handler
   */
  handleUpgrade: PropTypes.func,
  /**
   * Space details if is admin
   */
  isAdmin: PropTypes.bool,
};

SpaceDetails.defaultProps = {
  name: '',
  id: '',
  slug: '',
  planLimits: {},
  handleUpgrade: undefined,
  isAdmin: false,
  testId: '',
};
