import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useCallback, useContext } from 'react';
import { useModals } from '../../contexts/ModalContext';
import { Trans, useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { UpgradeIcon } from '../../images/shapes';
import { planColors, upgradeBenefits } from '../../lib/upgradeHelpers';
import TagManager from 'react-gtm-module';
import { useNavigate } from 'react-router-dom';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import { getTestProps } from '../../lib/helpers';
import UserContext from '../../contexts/UserContext';

export const SectionUpgrade = ({
  additionalUpgradeContainerClasses,
  height,
  marginBottom,
  marginTop,
  isOpen,
  testId,
}) => {
  const { t } = useTranslation();
  const modal = useModals();
  const navigate = useNavigate();
  const { isAdmin } = useContext(UserContext);
  const { space } = useSelectedSpace();

  const handleUpgrade = useCallback(
    (e) => {
      e.stopPropagation();
      navigate(`/space/upgrade/${space}`);
    },
    [navigate, space],
  );

  const openUpgradeModal = useCallback(async () => {
    TagManager.dataLayer({ dataLayer: { event: 'upgrade_modal_opened' } });

    const renderPlanName = (plan) => (
      <span className="font-bold uppercase">
        {plan.split(' | ').map((planPart, index, array) => (
          <span key={index}>
            <span style={{ color: planColors[planPart] || 'inherit' }}>
              {planPart}
            </span>
            {index < array.length - 1 && (
              <span className="text-gray-300"> | </span>
            )}
          </span>
        ))}
      </span>
    );

    await modal({
      content: (
        <div
          {...getTestProps(testId, 'modal-content')}
          className="px-4 text-xl text-gray-700 dark:text-white"
        >
          <p className="text-base xs:text-xl [&_strong]:text-lime-400 [&_strong]:font-bold">
            <Trans i18nKey={'UpgradeBenefits.FreePlanMessage'} />
          </p>
          <h3 className="mt-5 text-[0.9rem] xs:text-2xl md:text-3xl leading-normal [&_strong]:text-blue">
            <Trans i18nKey={'UpgradeBenefits.ExamplesOfProjectBoosters'} />
          </h3>
          <ul>
            {upgradeBenefits(t).map((benefit, index) => (
              <li key={index} className="my-7 xs:my-10 md:my-12">
                <h2
                  className="leading-none flex items-center gap-5 font-halvar text-[1rem] xs:text-[1.5rem]
                  sm:text-[1.6rem] md:text-[1.9rem]"
                >
                  <span className="inline-block font-medium pb-0.5 font-inter text-3xl">
                    |
                  </span>
                  {benefit.title}
                  {benefit.plan && (
                    <span className="font-inter text-sm sm:text-base pl-1 uppercase">
                      {renderPlanName(benefit.plan)}
                    </span>
                  )}
                </h2>
                <p className="text-base xs:text-xl mt-2 xs:mt-4 text-gray-700 dark:text-gray-200">
                  {benefit.description}
                </p>
              </li>
            ))}
          </ul>
          {isAdmin ? (
            <Button
              onClick={handleUpgrade}
              buttonSize="lg"
              additionalClasses={twMerge(
                'h-10 xs:h-12 md:h-14 border-0 px-8 bg-gradient-button-bg-dark font-semibold mx-auto w-full',
                'justify-center',
              )}
              additionalChildrenClasses="flex items-center gap-2"
              {...getTestProps(testId, 'upgrade-button')}
            >
              <UpgradeIcon className={twMerge('w-4')} />
              {t('Global.UpgradePlan')}
            </Button>
          ) : (
            <div className="text-center font-bold">
              <h3 className="mt-5 text-[0.9rem] xs:text-2xl md:text-3xl leading-normal text-blue">
                {t('UpgradeBenefits.UpgradeForExtraFeatures')}
              </h3>
              <p className="font-light text-gray-700 dark:text-gray-400 text-[0.9rem]">
                {t('UpgradeBenefits.ContactAdminToUpgrade')}
              </p>
            </div>
          )}
        </div>
      ),
      buttons: [],
      size: 'lg',
      dialogAdditionalClasses: 'h-full lg:max-h-[calc(100vh-2rem)]',
      contentAdditionalClasses: 'py-12 md:px-5',
    });
  }, [handleUpgrade, isAdmin, modal, t, testId]);

  return (
    <div>
      <div
        {...getTestProps(testId, 'container')}
        className={twMerge(
          'flex items-center justify-center',
          additionalUpgradeContainerClasses,
        )}
        style={{
          height: height,
          marginBottom: marginBottom,
          marginTop: marginTop,
        }}
      >
        <Button
          onClick={openUpgradeModal}
          buttonSize="xs"
          additionalClasses={twMerge(
            'border-0 px-8 bg-gradient-button-bg-dark font-semibold',
            !isOpen ? 'px-2.5' : 'px-8',
          )}
          additionalChildrenClasses="flex items-center gap-2"
          {...getTestProps(testId, 'upgrade-plan')}
        >
          <UpgradeIcon className={twMerge(!isOpen ? 'w-3.5' : 'w-3')} />
          <span className={twMerge(!isOpen ? 'hidden' : '')}>Upgrade plan</span>
        </Button>
      </div>
    </div>
  );
};

SectionUpgrade.propTypes = {
  additionalUpgradeContainerClasses: PropTypes.string,
  height: PropTypes.number,
  marginBottom: PropTypes.number,
  marginTop: PropTypes.number,
  isOpen: PropTypes.bool,
  testId: PropTypes.string,
};

SectionUpgrade.defaultProps = {
  additionalUpgradeContainerClasses: '',
  height: 32,
  marginBottom: 20,
  marginTop: 0,
  isOpen: true,
  testId: '',
};
