import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

const LogModalRow = ({ label, value, colDirection, testId }) => (
  <div
    className={twMerge(
      'flex mb-2 w-full',
      colDirection ? 'flex-col' : 'flex-row',
    )}
    {...getTestProps(testId, `container`)}
  >
    <label className="text-base font-bold mr-2">{label}</label>
    <label className="text-base font-normal">{value}</label>
  </div>
);

export default LogModalRow;

LogModalRow.propTypes = {
  /**
   * Row label
   */
  label: PropTypes.any,
  /**
   * Row value
   */
  value: PropTypes.any,
  /**
   * Has column direction
   */
  colDirection: PropTypes.any,
  /**
   * Test id
   */
  testId: PropTypes.string,
};

LogModalRow.defaultProps = {
  colDirection: false,
  testId: '',
};
