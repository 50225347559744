export const EDITABLE = [
  'text',
  'textarea',
  'richtext',
  'textMarkdown',
  'number',
  'email',
  'block',
  'datasource',
  'checkbox',
  'media',
  'geo',
  'select',
  'radio',
  'object',
  'dateTime',
  'simpleList',
];

export const PROPERTIES_FIELDS = {
  text: [
    'unique',
    'required',
    'isTitlePart',
    'readonly',
    'hidden',
    'default',
    'pattern',
    'minLength',
  ],
  textarea: [
    'unique',
    'required',
    'isTitlePart',
    'readonly',
    'hidden',
    'default',
    'minLength',
  ],
  richtext: [
    'unique',
    'required',
    'readonly',
    'hidden',
    'default',
    'minLength',
  ],
  textMarkdown: [
    'unique',
    'required',
    'readonly',
    'hidden',
    'default',
    'minLength',
  ],
  number: [
    'unique',
    'required',
    'isTitlePart',
    'readonly',
    'hidden',
    'default',
  ],
  email: [
    'unique',
    'required',
    'isTitlePart',
    'readonly',
    'hidden',
    'default',
    'minLength',
  ],
  block: ['required', 'hidden', 'readonly', 'minItems', 'blockEditorTypes'],
  geo: ['unique', 'required', 'readonly', 'hidden', 'default'],
  checkbox: ['required', 'hidden', 'readonly', 'default'],
  datasource: [
    'required',
    'relationMultiple',
    'hidden',
    'readonly',
    'minItems',
    'default',
    'validation',
  ],
  select: [
    'unique',
    'required',
    'isTitlePart',
    'readonly',
    'hidden',
    'default',
    'minLength',
    'options',
    'optionsWithLabels',
    'useOptionsWithLabels',
    'multiple',
  ],
  radio: [
    'unique',
    'required',
    'isTitlePart',
    'readonly',
    'hidden',
    'default',
    'minLength',
    'options',
  ],
  object: [
    'required',
    'readonly',
    'isTitlePart',
    'hidden',
    'default',
    'minItems',
  ],
  dateTime: [
    'unique',
    'required',
    'isTitlePart',
    'readonly',
    'hidden',
    'default',
    'showTime',
  ],
  simpleList: ['required', 'readonly', 'hidden', 'default', 'pattern'],
};

export const MIN_PROPERTY = {
  text: { key: 'minLength' },
  textarea: { key: 'minLength' },
  richtext: { key: 'minLength' },
  textMarkdown: { key: 'minLength' },
  email: { key: 'minLength' },
  radio: { key: 'minLength' },
  select: { key: 'minLength' },
  datasource: { key: 'minItems' },
  dateTime: { key: 'minLength' },
  block: { key: 'properties.blocks.minItems' },
  geo: { key: 'required', value: ['lat', 'lon'] },
  object: { key: 'minItems' },
  simpleList: { key: 'minItems' },
};
