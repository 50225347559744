import { useCallback, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { getCropAttributes, getTestProps } from '../../lib/helpers';
import Card from '../Card/Card';
import Button from '../Button/Button';
import { ArrowUpRightIcon, DeleteIcon, PencilIcon } from '../../images/shapes';
import {
  getMediaTransformUrl,
  getMediaUrl,
} from '../../lib/flotiq-client/api-helpers';
import Tooltip from '../Tooltip/Tooltip';
import { createPortal } from 'react-dom';

export const VariantCard = ({
  variant,
  media,
  onEdit,
  onDelete,
  deleteTooltip,
  errors,
  isNew,
  disabled,
  additionalContainerClasses,
  testId,
}) => {
  const deleteButtonRef = useRef();

  const cropAttributes = useMemo(
    () => getCropAttributes(variant, media),
    [media, variant],
  );

  const mediaUrl = isNew
    ? getMediaTransformUrl(
        media,
        cropAttributes.left,
        cropAttributes.top,
        cropAttributes.width,
        cropAttributes.height,
        200,
        0,
      )
    : getMediaUrl(media, 200, 0, variant.name);

  const mediaUrlToOpen = isNew
    ? getMediaTransformUrl(
        media,
        cropAttributes.left,
        cropAttributes.top,
        cropAttributes.width,
        cropAttributes.height,
      )
    : getMediaUrl(media, 0, 0, variant.name);

  const openFullSizeImage = useCallback(() => {
    window.open(mediaUrlToOpen);
  }, [mediaUrlToOpen]);

  const [position, setPostion] = useState();

  const handlePosition = useCallback(() => {
    setPostion(deleteButtonRef.current?.getBoundingClientRect());
  }, []);

  const resetCurrentTop = useCallback(() => {
    setPostion();
  }, []);

  const portalTooltip = useMemo(
    () =>
      onDelete &&
      deleteTooltip &&
      position &&
      createPortal(
        <div
          className="fixed z-[90] pointer-events-none"
          style={{
            top: position.top,
            left: position.left,
          }}
        >
          <Tooltip
            tooltip={deleteTooltip}
            tooltipPlacement="topCenter"
            phoneTooltipPlacement="topCenter"
            additionalTooltipClasses={twMerge(
              'group-focus/tooltip:!invisible group-active/tooltip:!invisible',
              '!visible !opacity-100',
            )}
          >
            <div className="w-5" />
          </Tooltip>
        </div>,
        document.body,
      ),
    [position, deleteTooltip, onDelete],
  );

  return (
    <Card
      body={
        <div className="relative space-y-2">
          <div className="truncate dark:text-white" title={variant.name}>
            {variant.name}
          </div>
          <div className="truncate text-sm text-slate-400 pr-5">
            {`${cropAttributes.width} x ${cropAttributes.height} px`}
          </div>
          <div className="absolute right-0 bottom-0.5">
            <div className="flex gap-2 absolute bottom-12 right-0">
              {onEdit && (
                <Button
                  iconImage={
                    <PencilIcon className="text-gray-700 dark:text-gray-50 opacity-50 hover:opacity-100 w-3.5" />
                  }
                  buttonColor="borderless"
                  onClick={onEdit}
                  additionalClasses="w-fit"
                  noPaddings
                  disabled={disabled}
                  {...getTestProps(testId, 'edit', 'testId')}
                />
              )}

              {onDelete && (
                <>
                  <Button
                    ref={deleteButtonRef}
                    iconImage={
                      <DeleteIcon className="h-3.5 text-gray-700 dark:text-red-300 opacity-50 hover:opacity-100" />
                    }
                    buttonColor="borderless"
                    additionalClasses="w-fit"
                    onClick={onDelete}
                    noPaddings
                    onMouseEnter={handlePosition}
                    onMouseLeave={resetCurrentTop}
                    onFocus={resetCurrentTop}
                    disabled={disabled}
                    {...getTestProps(testId, 'delete', 'testId')}
                  />
                  {portalTooltip}
                </>
              )}
            </div>
          </div>
        </div>
      }
      imageUrl={mediaUrl}
      imageOverlay={
        mediaUrl ? (
          <div className="hidden group-hover:flex absolute top-2 right-2">
            <Button
              buttonColor="gray"
              buttonSize="xs"
              iconColor="blue"
              iconImage={<ArrowUpRightIcon className="w-2.5" />}
              additionalClasses="shrink-0 rounded-full !p-1 w-auto h-auto"
              onClick={(event) => {
                event.stopPropagation();
                openFullSizeImage();
              }}
            />
          </div>
        ) : null
      }
      additionalContainerClasses={twMerge(
        'bg-gray dark:bg-slate-800 w-full h-24',
        errors && 'border border-red',
        additionalContainerClasses,
      )}
      additionalImageClasses="max-w-full max-h-full"
      additionalImageContainerClasses="w-1/3 max-w-[150px] dark:bg-gray-700"
      additionalBodyClasses="px-4 py-2 w-2/3 grow"
      horizontal
      testId={testId}
    />
  );
};

export default VariantCard;

VariantCard.propTypes = {
  /**
   * Array helpers from formik array field
   */
  variant: PropTypes.object.isRequired,
  /**
   * User data
   */
  media: PropTypes.object.isRequired,
  /**
   * On edit callback
   */
  onEdit: PropTypes.func,
  /**
   * On edit callback
   */
  onDelete: PropTypes.func,
  /**
   * Delete button tooltip
   */
  deleteTooltip: PropTypes.string,
  /**
   * Variant errors
   */
  errors: PropTypes.any,
  /**
   * If variant is new
   */
  isNew: PropTypes.bool,
  /**
   * Additional card container classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * If card actions are disabled
   */
  disabled: PropTypes.bool,
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

VariantCard.defaultProps = {
  isNew: false,
  deleteTooltip: '',
  disabled: false,
  additionalContainerClasses: '',
  testId: '',
};
