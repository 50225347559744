import { makeExternalServiceBodilessQuery } from './base-request';

export const getStarters = makeExternalServiceBodilessQuery(
  process.env.REACT_APP_FLOTIQ_STARTERS_URL,
  'GET',
  {
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': process.env.REACT_APP_FLOTIQ_STARTERS_X_AUTH_TOKEN,
    },
  },
);

export const getMarketplacePlugins = makeExternalServiceBodilessQuery(
  `${process.env.REACT_APP_MARKETPLACE_API_URL.replace(/\/+$/, '')}/manifests`,
  'GET',
);

export const getTutorial = makeExternalServiceBodilessQuery(
  process.env.REACT_APP_FLOTIQ_TUTORIAL_URL,
  'GET',
  {
    headers: {
      'Content-Type': 'application/json',
      'X-Auth-Token': process.env.REACT_APP_FLOTIQ_TUTORIAL_X_AUTH_TOKEN,
    },
  },
);
