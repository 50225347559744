import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { getTestProps } from '../../lib/helpers';

const RequiredTemplate = ({ absolute, isIcon, additionalClasses, testId }) => {
  return (
    <span
      className={twMerge(
        'text-red text-sm leading-none select-none z-10 ml-1',
        absolute && 'absolute top-5',
        absolute && isIcon ? 'right-2' : 'right-3',
        additionalClasses,
      )}
      {...getTestProps(testId, 'required-template')}
    >
      *
    </span>
  );
};

export default RequiredTemplate;

RequiredTemplate.propTypes = {
  /**
   * If component posiotion should be absolute
   */
  absolute: PropTypes.bool,
  /**
   * If required label is icon
   */
  isIcon: PropTypes.bool,
  /**
   * Additional container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

RequiredTemplate.defaultProps = {
  absolute: false,
  isIcon: true,
  additionalClasses: '',
  testId: '',
};
