import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Trans } from 'react-i18next';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const ResultsPerPageLabel = ({
  resultsIntervalFrom,
  resultsIntervalTo,
  resultsTotalCount,
  additionalClasses,
  testId,
}) => {
  return (
    <div
      className={twMerge(
        'font-medium text-sm 2xl:text-base leading-none text-center py-3 select-none dark:text-white',
        additionalClasses,
      )}
      {...getTestProps(testId, 'wrapper')}
    >
      <div {...getTestProps(testId, 'text')}>
        <Trans i18nKey="ResultsPerPageLabel.phrase">
          {{ resultsIntervalFrom: resultsIntervalFrom || 0 }}
          to
          {{ resultsIntervalTo: resultsIntervalTo || 0 }}
          of
          <span className="font-bold">
            {{ resultsTotalCount: resultsTotalCount || 0 }}
          </span>
        </Trans>
      </div>
    </div>
  );
};

export default ResultsPerPageLabel;

ResultsPerPageLabel.propTypes = {
  /**
   * Index of first result
   */
  resultsIntervalFrom: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  /**
   * Index of last result
   */
  resultsIntervalTo: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  /**
   * Results total count
   */
  resultsTotalCount: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
  ]),
  /**
   * Component wrapper additional classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

ResultsPerPageLabel.defaultProps = {
  resultsIntervalFrom: 1,
  resultsIntervalTo: 1,
  resultsTotalCount: 1,
  additionalClasses: '',
  testId: '',
};
