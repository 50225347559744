import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import LinkButton from '../../../LinkButton/LinkButton';
import { useContext } from 'react';
import Button from '../../../Button/Button';
import { TopbarContext } from '../../Topbar';
import { getTestProps } from '../../../../lib/helpers';

const colorClasses = {
  blue: 'text-blue hover:text-blue active:text-blue focus:text-blue',
  red: 'text-red-600 hover:text-red-600 dark:text-red-400',
  green: 'text-lime-700 hover:text-lime-700 dark:text-lime-300',
  orange: 'text-orange-600 hover:text-orange-700 dark:text-orange-700',
};

const TopbarActionButton = ({
  color,
  label,
  disabled,
  additionalClasses,
  additionalIconClasses,
  ...props
}) => {
  const ButtonComponent = props.link ? LinkButton : Button;

  const { buttonsDisabled, testId } = useContext(TopbarContext);
  const isDisabled = disabled || buttonsDisabled;

  return (
    <ButtonComponent
      buttonColor="borderless"
      buttonSize="xs"
      iconPosition="start"
      additionalClasses={twMerge(
        'h-7 2xl:h-8 py-1 2xl:py-1.5 px-6',
        'cursor-pointer hover:text-blue justify-end text-base 2xl:text-base text-blue',
        'hover:underline rounded-none w-full',
        colorClasses[color],
        additionalClasses,
      )}
      additionalIconClasses={twMerge('mr-2', additionalIconClasses)}
      disabled={isDisabled}
      {...props}
      {...getTestProps(testId, `${label}-action`, 'testId')}
    >
      {label}
    </ButtonComponent>
  );
};

export default TopbarActionButton;

TopbarActionButton.propTypes = {
  /**
   * Button text color
   */
  color: PropTypes.string,
  /**
   * Button label
   */
  label: PropTypes.node,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
};

TopbarActionButton.defaultProps = {
  color: 'blue',
  label: '',
  disabled: false,
};
