import {
  BlockIcon,
  CheckboxIcon,
  DateIcon,
  EmailIcon,
  GeoIcon,
  ListIcon,
  MarkdownIcon,
  MediaIcon,
  NumberIcon,
  RadioIcon,
  RelationIcon,
  RichTextIcon,
  SelectIcon,
  TextIcon,
  TextareaIcon,
  SimpleListIcon,
} from '../images/shapes';

export const ICONS = {
  block: <BlockIcon />,
  checkbox: <CheckboxIcon />,
  dateTime: <DateIcon />,
  email: <EmailIcon />,
  geo: <GeoIcon />,
  object: <ListIcon />,
  textMarkdown: <MarkdownIcon />,
  media: <MediaIcon />,
  number: <NumberIcon />,
  radio: <RadioIcon />,
  datasource: <RelationIcon />,
  richtext: <RichTextIcon />,
  select: <SelectIcon />,
  text: <TextIcon />,
  textarea: <TextareaIcon />,
  simpleList: <SimpleListIcon />,
};

export const findIcon = (fieldProps) => {
  const isMedia =
    fieldProps.inputType === 'datasource' &&
    fieldProps.validation?.relationContenttype === '_media';
  return ICONS[isMedia ? 'media' : fieldProps.inputType];
};
