import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Tab } from '@headlessui/react';
import { getTestProps } from '../../lib/helpers';

const Tabs = ({
  tabs,
  defaultSelectedIndex,
  onChange,
  ignoreSelecting,
  additionalContainerClasses,
  additionalTabClasses,
  additionalTabPanelsClasses,
  testId,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultSelectedIndex);
  useEffect(() => setSelectedTab(defaultSelectedIndex), [defaultSelectedIndex]);

  const handleChange = useCallback(
    (tab) => {
      if (!ignoreSelecting) setSelectedTab(tab);
      if (onChange) {
        onChange(tab);
      }
    },
    [ignoreSelecting, onChange],
  );

  return (
    <div
      className={twMerge('h-full flex flex-col', additionalContainerClasses)}
      {...getTestProps(testId, 'tabs-container')}
      onKeyDown={(event) => event.stopPropagation()}
    >
      <Tab.Group selectedIndex={selectedTab} onChange={handleChange} manual>
        <Tab.List
          className={
            'border-b dark:border-slate-800 flex scrollbar-sm scrollbar-hidden'
          }
        >
          {tabs.map((tab, idx) => (
            <Tab
              key={`${tab.key}-tab-selector`}
              {...getTestProps(testId, `${idx}-tab-selector`)}
              className={({ selected }) =>
                twMerge(
                  'font-semibold uppercase outline-0',
                  'mx-1 px-1 py-2 md:mx-2 md:px-7 md:py-4',
                  'text-sm md:text-base whitespace-nowrap',
                  'border-b-[3px] border-transparent -mb-0.5 dark:text-gray-200',
                  selected && 'border-blue text-blue dark:text-blue',
                  additionalTabClasses,
                )
              }
            >
              {tab.label}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels
          className={twMerge(additionalTabPanelsClasses, 'h-full mt-5 md:mt-3')}
        >
          {tabs.map((tab, idx) => (
            <Tab.Panel
              key={`${tab.key}-tab-panel`}
              className="h-full"
              {...getTestProps(testId, `${idx}-tab-panel`)}
              tabIndex={-1}
            >
              {tab.render()}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Tabs;

Tabs.propTypes = {
  /**
   * Tabs content
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      key: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
    }),
  ).isRequired,
  /**
   * Index of default selected tab (starts from 0)
   */
  defaultSelectedIndex: PropTypes.number,
  /**
   * Callback run on tab change
   */
  onChange: PropTypes.func,
  /**
   * If selecting new tab on onChange should be ignored
   */
  ignoreSelecting: PropTypes.bool,
  /**
   * Tabs container additional CSS classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Single tab additional CSS classes
   */
  additionalTabClasses: PropTypes.string,
  /**
   * Tabs panels container additional CSS classes
   */
  additionalTabPanelsClasses: PropTypes.string,
  /**
   * Test id for tabs component
   */
  testId: PropTypes.string,
};
Tabs.defaultProps = {
  defaultSelectedIndex: 0,
  ignoreSelecting: false,
  additionalContainerClasses: '',
  additionalTabClasses: '',
  additionalTabPanelsClasses: '',
  testId: '',
};
