import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { twMerge } from 'tailwind-merge';
import { FlotiqLogo } from '../../images/shapes';
import Button from '../../components/Button/Button';
import { Formik } from 'formik';
import { useUser } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useLocalStorageState from 'use-local-storage-state';
import Loader from '../../components/Loader/Loader';
import { getApiKey } from '../../lib/flotiq-client';
import useToken from '../../hooks/useToken';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import Dropdown from '../../components/Dropdown/Dropdown';
import toast from 'react-hot-toast';
import useDarkMode from '../../hooks/useDarkMode';
import { getTestProps } from '../../lib/helpers';

const ApiKeyAccess = ({ testId }) => {
  const { t } = useTranslation();
  const token = useToken();
  const navigate = useNavigate();
  const [user] = useLocalStorageState('cms.user');
  const [isLoading, setIsLoading] = useState(false);

  useDarkMode();

  const { state } = useLocation();
  const redirectUri = state?.redirect_uri;
  const responseType = state?.response_type;
  const keyType = state?.key_type;
  const applicationName = state?.application_name || 'Flotiq CLI';

  const roAccess = !keyType || keyType === 'both' || keyType === 'ro';
  const rwAccess = keyType === 'both' || keyType === 'rw';

  const {
    entity: userData,
    isLoading: userLoading,
    errors,
  } = useUser(user?.data?.id);

  useApiErrorsToast(errors);

  const validationSchema = yup.object({
    space: yup.string().required(t('Form.FormErrorNotBlank')),
  });

  const urlToRedirect = useMemo(() => {
    try {
      const url = new URL(redirectUri);
      url.hostname = 'localhost';
      return url;
    } catch {
      return null;
    }
  }, [redirectUri]);

  useEffect(() => {
    if (!urlToRedirect) {
      toast.error(t('ApiKeyAccess.WrongRedirect'));
      navigate('/');
    }
  }, [navigate, t, urlToRedirect]);

  const onSubmit = useCallback(
    async (values) => {
      if (responseType !== 'code') {
        urlToRedirect.searchParams.append('status', 'rejected');
        window.location.replace(urlToRedirect.toString());
        return;
      }

      setIsLoading(true);

      try {
        const { body, status } = await getApiKey(token, values.space, {
          global: true,
        });

        checkResponseStatus(body, status);

        const apiKeys = body.data;
        const roApiKey = apiKeys.find(
          ({ canCreate, canUpdate, canDelete, canRead }) =>
            !canCreate && !canUpdate && !canDelete && canRead,
        ).apiKey;

        const rwApiKey = apiKeys.find(
          ({ canCreate, canUpdate, canDelete, canRead }) =>
            canCreate && canUpdate && canDelete && canRead,
        ).apiKey;

        urlToRedirect.searchParams.append('status', 'approved');
        if (roAccess) {
          urlToRedirect.searchParams.append('api_key', roApiKey);
        }
        if (rwAccess) {
          urlToRedirect.searchParams.append('api_key_rw', rwApiKey);
        }
      } catch {
        urlToRedirect.searchParams.append('status', 'failed');
      }

      window.location.replace(urlToRedirect.toString());
    },
    [responseType, roAccess, rwAccess, token, urlToRedirect],
  );

  const onCancel = useCallback(() => {
    urlToRedirect.searchParams.append('status', 'rejected');
    window.location.replace(urlToRedirect.toString());
  }, [urlToRedirect]);

  const spaceOptions = useMemo(
    () =>
      (userData?.spaces || []).map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [userData?.spaces],
  );

  return (
    <div
      className="bg-gradient-blue dark:bg-gradient-bg-dark min-h-screen 
                flex items-center justify-center p-2 md:p-6"
    >
      <div
        className="relative bg-white flex flex-col max-w-4xl items-center justify-center
                  rounded-lg shadow-2xl p-6 md:py-10 lg:px-10 dark:bg-gray-900 dark:text-white"
      >
        <FlotiqLogo className="h-10 mb-4 md:mb-6" />

        {isLoading && (
          <div
            className={twMerge(
              'absolute top-0 left-0 w-full h-full flex bg-white/80 dark:bg-gray-900/80',
              'justify-center items-center z-10 rounded-md',
            )}
          >
            <Loader type="spinner-grid" size="small" />
          </div>
        )}

        <h1 className="mb-2 md:mb-4 text-3xl xl:text-4xl">
          {t('ApiKeyAccess.AppAccess', { app: applicationName })}
        </h1>

        <h4 className="text-center leading-snug text-xl mb-4 md:mb-8">
          {t('ApiKeyAccess.AccessRequested')}
        </h4>

        <ul className="list-disc ml-4 md:ml-8 mb-4">
          {roAccess && (
            <li>
              <strong>{t('ApiKeyAccess.ReadOnlyApiKey')}</strong> -{' '}
              {t('ApiKeyAccess.ReadOnlyApiKeyDescription')}
            </li>
          )}
          {rwAccess && (
            <li>
              <strong>{t('ApiKeyAccess.ReadWriteApiKey')}</strong> -{' '}
              {t('ApiKeyAccess.ReadWriteApiKeyDescription')}
            </li>
          )}
        </ul>

        {userLoading ? (
          <div className="h-24 flex items-center">
            <Loader type="spinner-grid" size="small" />
          </div>
        ) : (
          <Formik
            initialValues={{
              space: '',
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {(formik) => (
              <form
                id="access-keys"
                className="w-full"
                onSubmit={formik.handleSubmit}
                noValidate={true}
              >
                <p className="my-4 text-center w-full">
                  {t('ApiKeyAccess.SelectSpace')}
                </p>
                <Dropdown
                  name="space"
                  value={formik.values.space}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  options={spaceOptions}
                  placeholder={t('Spaces.SpaceSelector.PlaceHolder')}
                  emptyOptions={
                    <div className="py-2 px-4 dark:text-white">
                      {t('Spaces.SpaceSelector.EmptySpaces')}
                    </div>
                  }
                  additionalClasses="max-w-sm mx-auto"
                  additionalDropdownClasses="bottom-full top-auto mb-2 border"
                  disabled={isLoading}
                  error={formik.errors.space}
                  hideSearch
                  {...getTestProps(testId, 'spaces', 'testId')}
                />
              </form>
            )}
          </Formik>
        )}

        <div className="flex flex-row items-center mt-4 md:mt-10 gap-6">
          <Button
            buttonSize="sm"
            onClick={onCancel}
            buttonColor="blueBordered"
            disabled={userLoading}
          >
            {t('Global.Cancel')}
          </Button>
          <Button
            type="submit"
            form="access-keys"
            buttonSize="sm"
            disabled={userLoading}
          >
            {t('ApiKeyAccess.GrantAccess')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyAccess;
