/* eslint-disable max-len */
import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired when a variant modal with crop is open.
 * No result is expected, but event may modify the config object for overiding crop presets.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.media.crop::config"
 * @type {MediaConfigCropEvent}
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name MediaConfigCropEvent
 *
 * @property {object} config Variants crop configuration with default presets thay is array of objects with unique key,
 *                    label that will be shown in the popover menu, name that will be set when no variant name was provided and aspect ratio.
 */
export class MediaConfigCropEvent extends IFlotiqPluginEvent {
  config;
}
