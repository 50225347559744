import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldArray, Formik } from 'formik';
import * as yup from 'yup';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

// :: Lib
import {
  deepAssignKeyValue,
  getDefaultImageProperties,
  getTestProps,
  isImagePreviewSupported,
} from '../../lib/helpers';
import { getMediaUrl } from '../../lib/flotiq-client/api-helpers';

// :: Components
import Input from '../../components/Input/Input';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';
import VariantsField from '../../components/VariantsField/VariantsField';
import useSelectedSpace from '../../hooks/useSelectedSpace';
import CustomFormElement from '../ContentObjectForm/CustomFormElement/CustomFormElement';
import FormStateHandler from '../../components/FormStateHandler/FormStateHandler';

const getInitialValues = (media) => {
  const fileNameGroup = (media?.fileName || '').match(
    /(?<fileName>.*)\.(?<extenstion>[^.]+)$/,
  )?.groups;

  return {
    fileName: fileNameGroup?.fileName || media.fileName || '',
    fileExtension: fileNameGroup?.extenstion,
    alt: media?.alt,
    ...(media?.variants ? { variants: media.variants } : {}),
  };
};

const MediaForm = ({
  media,
  onSubmit,
  navigateOnSave,
  disabled,
  setFormikState,
  testId,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { buildUrlWithSpace } = useSelectedSpace();
  const noPreview =
    media.type !== 'image' || !isImagePreviewSupported(media.extension);

  const validationSchema = useMemo(
    () =>
      yup.object({
        fileName: yup.string().required(t('Form.FormErrorNotBlank')),
        alt: yup.string(),
      }),
    [t],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      setIsLoading(true);

      const newValues = {
        fileName: values.fileExtension
          ? values.fileName + '.' + values.fileExtension
          : values.fileName,
        alt: values.alt,
        ...(values.variants ? { variants: values.variants } : {}),
      };

      const [[newMedia, errors], hasErrors] = await onSubmit(newValues);

      if (hasErrors) {
        const parsedErrors = {};
        Object.entries(errors).forEach(([key, value]) => {
          deepAssignKeyValue(key, value, parsedErrors);
        });
        formik.setStatus({ ...formik.status, errors: parsedErrors });
      } else {
        if (navigateOnSave.current) {
          navigate(buildUrlWithSpace('media'));
        } else formik.resetForm({ values: getInitialValues(newMedia) });
      }

      setIsLoading(false);
    },
    [navigate, navigateOnSave, onSubmit, buildUrlWithSpace],
  );

  const preview = useMemo(() => {
    if (noPreview) {
      return (
        <div className="flex items-center justify-center w-fit order-first mt-4">
          <div className="flex items-center justify-center rounded-full h-12 w-12 bg-blue-300">
            {media.extension || ''}
          </div>
        </div>
      );
    }
    return (
      <img
        src={getMediaUrl(media)}
        alt={media.fileName}
        className={'max-h-80 object-center object-contain'}
        {...getDefaultImageProperties()}
        {...getTestProps(testId, 'image')}
      />
    );
  }, [media, noPreview, testId]);

  return (
    <Formik
      initialValues={getInitialValues(media)}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {(formik) => (
        <form
          id="media-form"
          className="flex flex-col gap-4 md:gap-6"
          onSubmit={formik.handleSubmit}
          noValidate={true}
        >
          <div
            className={twMerge(
              'bg-white dark:bg-slate-950 rounded-lg p-7 md:p-6 w-full',
              'flex flex-col gap-4 md:gap-6',
            )}
          >
            <div className={twMerge('max-w-screen-md space-y-4')}>
              <CustomFormElement />
              <div
                className="flex w-full gap-2"
                {...getTestProps(testId, 'inputs')}
              >
                {noPreview && preview}
                <Input
                  name="fileName"
                  label={t('Media.Name')}
                  value={formik.values.fileName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.errors.fileName || formik.status?.errors?.fileName
                  }
                  disabled={disabled}
                  additionalInputClasses={
                    isLoading ? '' : '!bg-white dark:!bg-gray-900'
                  }
                  required
                  {...getTestProps(testId, 'fileName', 'testId')}
                />
                {formik.values.fileExtension && (
                  <Input
                    name="fileExtension"
                    label={t('Media.Extension')}
                    value={formik.values.fileExtension}
                    required
                    disabled
                    additionalClasses="w-max"
                    {...getTestProps(testId, 'extension', 'testId')}
                  />
                )}
              </div>
              <Input
                name="alt"
                label={t('Media.Alt')}
                value={formik.values.alt}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.errors.alt || formik.status?.errors?.alt}
                disabled={disabled}
                additionalInputClasses={
                  isLoading ? '' : '!bg-white dark:!bg-gray-900'
                }
                additionalClasses={noPreview ? ' row-span-1' : ''}
                {...getTestProps(testId, 'fileName', 'testId')}
              />
              {!noPreview && preview}
            </div>
          </div>
          {!noPreview && (
            <>
              <p className="text-lg font-bold dark:text-white">
                {t('MediaEdit.ExistingVariants', {
                  length: formik.values.variants?.length || 0,
                })}
              </p>
              <div className="bg-white dark:bg-slate-950 w-full rounded-lg gap-4 md:gap-6 p-7 md:p-6">
                <FieldArray name="variants">
                  {(arrayHelpers) => (
                    <VariantsField
                      arrayHelpers={arrayHelpers}
                      media={media}
                      errors={formik.status?.errors?.variants}
                      disabled={disabled}
                      {...getTestProps(testId, 'variants', 'testId')}
                    />
                  )}
                </FieldArray>
              </div>
            </>
          )}
          <DirtyHandler />
          <FormStateHandler setFormikState={setFormikState} />
        </form>
      )}
    </Formik>
  );
};

export default MediaForm;

MediaForm.propTypes = {
  /**
   * On submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * User data
   */
  media: PropTypes.object.isRequired,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Callback for updating formik state
   */
  setFormikState: PropTypes.func,
  /**
   * User form test id
   */
  testId: PropTypes.string,
};

MediaForm.defaultProps = {
  disabled: false,
  testId: '',
};
