import PropTypes from 'prop-types';

// :: Component
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import Panel from '../../components/Panel/Panel';
import LinkButton from '../../components/LinkButton/LinkButton';

// :: Icons
import { ArrowRightThinIcon } from '../../images/shapes';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import { twMerge } from 'tailwind-merge';

const AccountDetails = ({
  title,
  resources,
  seeMoreText,
  seeMoreLink,
  testId,
  id,
}) => {
  return (
    <Panel
      title={title}
      open={true}
      isCollapsable={false}
      additionalTitleClasses="w-full !mr-0"
      additionalTitleWrapClasses="w-full"
      additionalContainerClasses={twMerge(
        'py-4 px-4',
        seeMoreLink && seeMoreText ? 'min-h-[200px]' : 'min-h-[250px]',
      )}
      id={id}
    >
      <div
        className={twMerge(
          'grid grid-cols-[repeat(auto-fit,_minmax(8.5rem,_1fr))]',
          'gap-7 bg-white dark:bg-slate-950',
        )}
      >
        {resources.map((resource) => (
          <ProgressBar
            isCompact
            key={resource.key}
            barBackgroundColor={resource.barColor || 'auto'}
            progressBarLabel={resource.label}
            completionPercentValue={resource.percent}
            outOfCompletionTotalValue={resource.totalValue}
            outOfCompletionTotalValueUnit={resource.totalUnit}
            outOfCompletionValue={resource.value}
            outOfCompletionValueUnit={resource.unit}
            barThickness="thin"
            hidePercentValue={resource.hidePercentages}
            additionalCompletionTotalValuesClasses="mt-1"
            additionalCompletionPercentValueClasses="text-sm"
            additionalContainerClasses="min-h-[52px]"
            additionalBarClasses="mt-auto"
          />
        ))}
      </div>

      {seeMoreLink && seeMoreText && (
        <LinkButton
          buttonColor="borderless"
          noPaddings
          buttonSize="sm"
          iconImage={<ArrowRightThinIcon />}
          iconPosition="end"
          iconColor={'blue'}
          link={seeMoreLink}
          additionalClasses="ml-0 mt-7 w-fit"
          {...getTestProps(testId, 'see-more', 'testId')}
        >
          {seeMoreText}
        </LinkButton>
      )}
    </Panel>
  );
};

export default AccountDetails;

AccountDetails.propTypes = {
  /**
   * Title for your Account details panel
   */
  title: PropTypes.any,
  /**
   * Resources usage
   */
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      barColor: PropTypes.string,
      label: PropTypes.node.isRequired,
      percent: PropTypes.number,
      totalValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      totalUnit: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      unit: PropTypes.string,
      hidePercentages: PropTypes.bool,
      key: PropTypes.string.isRequired,
    }),
  ),
  /**
   * See more button text
   */
  seeMoreText: PropTypes.string,
  /**
   * See more button click handler
   */
  seeMoreLink: PropTypes.string,
  /**
   * Test id
   */
  testId: PropTypes.string,
  /**
   * Account Details Id
   */
  id: PropTypes.string,
};

AccountDetails.defaultProps = {
  title: '',
  resources: [],
  seeMoreLink: '',
  testId: '',
  id: '',
};
