import {
  dateRenderer,
  defaultRenderer,
  pillRenderer,
} from '../../components/DataGrid/DataGridCell/cellRenderer';
import LinkButton from '../../components/LinkButton/LinkButton';
import { ArrowUpRightIcon } from '../../images/shapes';
import { getStripeURL } from '../../lib/helpers';

export const getDefinedColumns = (
  gridOptionsByID,
  hiddenColumnsById,
  editGrid,
  t,
) => ({
  id: {
    accessor: 'id',
    label: 'ID',
    width: gridOptionsByID?.['id']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.id,
  },
  name: {
    accessor: 'name',
    label: t('Global.Name'),
    width: gridOptionsByID?.['name']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.name,
  },
  slug: {
    accessor: 'slug',
    label: 'Slug',
    width: gridOptionsByID?.['slug']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.slug,
  },
  'organization.name': {
    accessor: 'organization.name',
    label: t('Users.Organization'),
    width: gridOptionsByID?.['organization.name']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['organization.name'],
  },
  'organization.id': {
    accessor: 'organization.id',
    label: t('Users.OrganizationId'),
    width: gridOptionsByID?.['organization.id']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['organization.id'],
  },
  planName: {
    accessor: 'planName',
    label: t('Plans.Name'),
    width: gridOptionsByID?.['planName']?.width,
    render: (data) => defaultRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['planName'],
  },
  paymentStatus: {
    accessor: 'paymentStatus',
    label: t('Users.PaymentStatus'),
    width: gridOptionsByID?.['paymentStatus']?.width,
    render: (data) => pillRenderer(data),
    sortable: !editGrid,
    filterInputType: 'select',
    filterInputOptions: ['paid', 'non-paid', 'trial'],
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['paymentStatus'],
  },
  paymentInterval: {
    accessor: 'paymentInterval',
    label: t('Users.PaymentInterval'),
    width: gridOptionsByID?.['paymentInterval']?.width,
    render: (data) => pillRenderer(data),
    sortable: !editGrid,
    filterInputType: 'select',
    filterInputOptions: ['month', 'year'],
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['paymentInterval'],
  },
  paymentDueDate: {
    accessor: 'paymentDueDate',
    label: t('Users.PaymentDueDate'),
    width: gridOptionsByID?.['paymentDueDate']?.width,
    render: (data) => dateRenderer(data),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['paymentDueDate'],
  },
  subscriptionId: {
    accessor: 'subscriptionId',
    label: t('Spaces.SubscriptionId'),
    width: gridOptionsByID?.['subscriptionId']?.width,
    render: (data) =>
      defaultRenderer(
        data ? (
          <div className="flex gap-1 items-center">
            <LinkButton
              link={getStripeURL(`/subscriptions/${data}`)}
              target="_blank"
              rel="noreferrer"
              iconImage={<ArrowUpRightIcon className="w-5" />}
              buttonColor="borderless"
              buttonSize="xs"
              iconColor="blue"
              iconPosition="start"
              additionalClasses={
                'lg:text-base 2xl:text-base font-normal max-w-full focus:hover:underline ' +
                '!p-2 text-indigo-950 text-sm'
              }
              additionalChildrenClasses="truncate"
            >
              {data}
            </LinkButton>
          </div>
        ) : null,
      ),
    sortable: !editGrid,
    filterInputType: 'text',
    resizable: true,
    removable: true,
    hide: hiddenColumnsById['paymentClientId'],
  },
  createdAt: {
    accessor: 'createdAt',
    label: t('ObjectsOfType.Created'),
    width: gridOptionsByID?.['createdAt']?.width,
    render: (data) => dateRenderer(data),
    sortable: !editGrid,
    resizable: true,
    removable: true,
    hide: hiddenColumnsById.createdAt,
  },
});
