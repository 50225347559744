import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const SocialButton = ({ name, icon, link }) => (
  <Link
    to={link}
    className={'flex items-center w-full dark:decoration-white'}
    target="_blank"
    rel="noreferrer"
  >
    <span className="w-[26px]">{icon}</span>
    <div className={'pl-2 font-header text-sm md:text-base dark:text-white'}>
      {name}
    </div>
  </Link>
);

export default SocialButton;

SocialButton.propTypes = {
  name: PropTypes.any,
  icon: PropTypes.node,
  link: PropTypes.any,
};

SocialButton.defaultProps = {
  name: '',
  icon: '',
  link: '',
};
