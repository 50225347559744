import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { getTestProps } from '../../lib/helpers';
import { useTranslation } from 'react-i18next';

// :: Components
import ResultsPerPageDropdown from '../Pagination/ResultsPerPageDropdown';
import ColumnsVisibility from '../../pages/ContentTypeObjects/ColumnsVisibility';
import Button from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';

// :: Images
import { RefreshIcon } from '../../images/shapes';

// :: Helpers
import { setLocalStorage } from '../../utils/localStorage';

const DataGridControl = ({
  displayGridCheckboxes,
  displayGridControl,
  handleResetGrid,
  editGrid,
  setEditGrid,
  filterGrid,
  limit,
  setFilterGrid,
  handleLimitChange,
  gridOptions,
  columns,
  optionsLocalStorageKey,
  setGridOptions,
  columnsVisibilityAdditionalClasses,
  isDeleting,
  testId,
}) => {
  const { t } = useTranslation();

  const handleVisibleColumnsChange = useCallback(
    (options) => {
      setLocalStorage(optionsLocalStorageKey, options);
      setGridOptions(options);
    },
    [optionsLocalStorageKey, setGridOptions],
  );

  return (
    <>
      {displayGridCheckboxes && (
        <div
          className={twMerge(
            'inline-flex items-center w-full xs:w-auto',
            isDeleting && 'cursor-not-allowed opacity-50',
          )}
        >
          <Checkbox
            name="edit"
            checked={editGrid}
            onChange={() => setEditGrid(!editGrid)}
            label={t('ObjectsOfType.EditGrid')}
            additionalLabelClasses={twMerge(
              'ml-2 text-sm md:text-base mr-2',
              isDeleting && 'opacity-50',
            )}
            additionalCheckboxClasses="mt-0 lg:mt-0.5 h-4 md:h-5 w-4 md:w-5
                bg-[length:8px_8px] md:bg-[length:10px_10px] bg-white border-neutral-300 checked:border-blue"
            disabled={isDeleting}
          />

          <Checkbox
            name="filter"
            checked={filterGrid}
            onChange={() => setFilterGrid(!filterGrid)}
            label={t('ObjectsOfType.FilterGrid')}
            additionalLabelClasses="ml-2 mr-2 text-sm md:text-base"
            additionalCheckboxClasses="mt-0 lg:mt-0.5 h-4 md:h-5 w-4 md:w-5
                bg-[length:8px_8px] md:bg-[length:10px_10px] bg-white border-neutral-300 checked:border-blue"
            {...getTestProps(testId, `checkbox-filter-data`, 'testId')}
          />
        </div>
      )}

      {displayGridControl && (
        <div
          className={twMerge(
            'flex items-center justify-start sm:justify-end w-full sm:w-auto !space-x-5',
            isDeleting && 'cursor-not-allowed opacity-50',
            editGrid && 'invisible xl:visible',
          )}
        >
          <Button
            buttonSize="sm"
            iconImage={<RefreshIcon className="text-blue w-3 md:w-4" />}
            onClick={handleResetGrid}
            buttonColor={'borderless'}
            additionalClasses={twMerge(
              'text-sm md:text-base',
              isDeleting &&
                'bg-transparent hover:bg-transparent active:bg-transparent opacity-50',
            )}
            testId={'button-grid-reset'}
            disabled={isDeleting}
            noPaddings
          >
            {t('Global.ResetGrid')}
          </Button>
          <ResultsPerPageDropdown
            value={limit}
            setResultsPerPage={handleLimitChange}
            additionalButtonClasses="!pr-8 2xl:!py-1"
            additionalClasses="!p-0 md:ml-7 text-sm md:text-base"
            label={t('Global.ResultsPerPage')}
            disabled={isDeleting}
            {...getTestProps(testId, 'results-per-page-dropdown', 'testId')}
          />
        </div>
      )}

      {editGrid && (
        <ColumnsVisibility
          columns={columns}
          testId={testId}
          gridOptions={gridOptions}
          onChange={handleVisibleColumnsChange}
          additionalClasses={twMerge(
            'sm:pl-2 rounded-lg !bg-transparent w-auto',
            'bottom-1.5 sm:bottom-auto sm:top-1.5 md:top-1 left:0 sm:left-auto xl:left-[300px]',
            'sm:right-3 xl:right-auto',
            columnsVisibilityAdditionalClasses,
          )}
        />
      )}
    </>
  );
};

export default DataGridControl;

DataGridControl.propTypes = {
  /**
   * Display Edit Grid and Filter Data checkboxes
   */
  displayGridCheckboxes: PropTypes.bool,
  /**
   * Display grid controls
   */
  displayGridControl: PropTypes.bool,
  /**
   * Reset grid callback
   */
  handleResetGrid: PropTypes.func,
  /**
   * Is grid edited
   */
  editGrid: PropTypes.bool,
  /**
   * Set is grid edited
   */
  setEditGrid: PropTypes.func,
  /**
   * Filter grid
   */
  filterGrid: PropTypes.bool,
  /**
   * Set filter grid
   */
  setFilterGrid: PropTypes.func,
  /**
   * Rows limit
   */
  limit: PropTypes.number,
  /**
   * Handle limit rows
   */
  handleLimitChange: PropTypes.func,
  /**
   * Grid options
   */
  gridOptions: PropTypes.array,
  /**
   * Grid columns
   */
  columns: PropTypes.array,
  /**
   * Handle visible column changes
   */
  optionsLocalStorageKey: PropTypes.string.isRequired,
  /**
   * Callback to update grid options value on visibility change
   */
  setGridOptions: PropTypes.func,
  /**
   * Additional classes
   */
  columnsVisibilityAdditionalClasses: PropTypes.string,
  /**
   *
   */
  isDeleting: PropTypes.bool,
  /**
   * Test id
   */
  testId: PropTypes.string,
};

DataGridControl.defaultProps = {
  displayGridCheckboxes: false,
  displayGridControl: false,
  handleResetGrid: /* istanbul ignore next */ () => {},
  editGrid: false,
  setEditGrid: /* istanbul ignore next */ () => {},
  filterGrid: false,
  limit: 20,
  gridOptions: [],
  columns: [],
  columnsVisibilityAdditionalClasses: '',
  isDeleting: false,
  setGridOptions: /* istanbul ignore next */ () => {},
  testId: '',
};
