import { createContext } from 'react';
import PropTypes from 'prop-types';

export const PropertyModalCtdsContext = createContext({});

PropertyModalCtdsContext.propTypes = {
  value: PropTypes.shape({
    /**
     * Content types data
     */
    ctds: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * Function to get data from chosen validation type
     */
    getValidationType: PropTypes.func,
    /**
     * If content types are loading
     */
    ctdsAreLoading: PropTypes.bool,
    /**
     * Filter content types callback
     */
    filterCtds: PropTypes.func,
  }).isRequired,
};

export default PropertyModalCtdsContext;
