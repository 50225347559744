import { GridAddElementEvent } from './GridAddElementEvent';

/**
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.grid::render"
 * @type {GridRenderEvent}
 * @returns {null|string|array|number|boolean|HTMLElement|ReactElement}
 *          Null if rendering should be passed to either Flotiq or other plugins.
 *          Renderable result if plugin wants to place the entire grid.
 */

/**
 * @class
 * @extends GridAddElementEvent
 * @memberof FlotiqPlugins.Events
 * @name GridRenderEvent
 *
 */
export class GridRenderEvent extends GridAddElementEvent {}
