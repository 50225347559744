import { useEffect, useState } from 'react';

const useFirstLoading = (value, refreshKey = '') => {
  const [firstLoading, setFirstLoading] = useState(true);

  useEffect(() => {
    setFirstLoading(true);
  }, [refreshKey]);

  useEffect(() => {
    if (!firstLoading) return;
    if (value) {
      setFirstLoading(false);
    }
  }, [firstLoading, value]);

  return firstLoading;
};

export default useFirstLoading;
