import { twMerge } from 'tailwind-merge';
import { getTestProps } from './helpers';
import Loader from '../components/Loader/Loader';

/**
 * Get media data from upload
 * @param newMedia array/object
 * @returns {object} object with card data object, is loaded boolean and errors string
 */
export const getMediaData = (newMedia) => {
  const isLoaded = Array.isArray(newMedia);
  const errors = isLoaded ? newMedia[1] : '';
  let card = isLoaded ? newMedia[0] : newMedia;
  if (!isLoaded || errors) {
    card = {
      id: card.id,
      fileName: card.name,
      extension: 'file',
      size: card.size,
    };
  }
  return { card, isLoaded, errors };
};

/**
 * Get border for media card
 * @param errors string
 * @param isLoaded boolean
 * @param isNew boolean
 * @returns {string} border classes
 */
export const findBorderColor = (errors, isLoaded, isNew) => {
  if (errors) return 'border-2 border-red';
  if (!isNew) return '';
  if (!isLoaded) return 'border-2 border-zinc-600';
  return 'border-2 border-green-500';
};

/**
 * Get thumbnail for media card
 * @param errors string
 * @param isLoaded boolean
 * @param small boolean
 * @param testId string
 * @returns {object|string}
 */
export const findThumbnail = (errors, isLoaded, small, testId) => {
  if (errors)
    return (
      <div className="h-full w-full flex items-center justify-center">
        <p
          className={twMerge(
            'p-2 max-h-full max-w-full text-red whitespace-normal overflow-hidden align-middle',
            small && 'text-xs',
          )}
          {...getTestProps(testId, 'card-uploaded-error')}
        >
          {errors}
        </p>
      </div>
    );
  if (!isLoaded)
    return (
      <div
        className="h-full w-full flex items-center justify-center"
        {...getTestProps(testId, 'card-uploading')}
      >
        <Loader size={small ? 'small' : 'big'} type="spinner-grid" />
      </div>
    );
  return '';
};
