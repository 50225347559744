import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { TailSpin } from 'react-loader-spinner';
import PropTypes from 'prop-types';

// :: Components
import Input from '../../components/Input/Input';
import Button from '../../components/Button/Button';

// :: Images
import { WarningIcon } from '../../images/shapes';

// :: Lib
import { passwordSchema } from '../../lib/yupHelpers';
import { getTestProps } from '../../lib/helpers';

const ChangePasswordForm = ({ onSubmit, testId }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [validateAfterSubmit, setValidateAfterSubmit] = useState(false);

  const validationSchema = yup.object({
    plainPassword: passwordSchema(t),
  });

  const formik = useFormik({
    initialValues: { plainPassword: '' },
    validateOnChange: validateAfterSubmit,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!onSubmit) return null;
      setIsLoading(true);
      const errors = await onSubmit(values.plainPassword);
      formik.setStatus({ ...formik.status, errors });
      setIsLoading(false);
    },
  });
  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      setValidateAfterSubmit(true);
      formik.handleSubmit();
    },
    [formik],
  );

  return (
    <div>
      <form
        id={'change-password-from'}
        className="w-full max-w-md m-auto"
        onSubmit={handleSubmit}
        noValidate={true}
        {...getTestProps(testId)}
      >
        <div
          className="flex flex-wrap mb-5 w-full m-auto max-w-[300px]"
          {...getTestProps(testId, 'password-container')}
        >
          <Input
            name="plainPassword"
            type="password"
            placeholder={t('Global.Password')}
            value={formik.values.plainPassword}
            onChange={formik.handleChange}
            error={
              formik.errors.plainPassword ||
              formik.status?.errors?.plainPassword
            }
            showPasswordInfo
          />
        </div>

        <div className="flex flex-wrap -mx-3 mb-5">
          <div className="w-full text-center">
            <Button
              type="submit"
              buttonSize="lg"
              additionalClasses="m-auto min-w-[300px] justify-center"
              additionalChildrenClasses="flex flex-row items-center"
            >
              {t('Global.Save')}
              <TailSpin
                height="15"
                width="20"
                color="white"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass="ml-4"
                visible={isLoading}
              />
            </Button>
            {formik?.status?.errors && (
              <div className="text-red py-2 inline-flex">
                <WarningIcon className="h-5 w-5 mr-2" />
                <div className="block text-left">
                  {formik.status.errors?.token || formik.status.errors?.general}
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};
export default ChangePasswordForm;

ChangePasswordForm.propTypes = {
  /**
   * ChangePasswordRequestForm on submit handler
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * Form test id
   */
  testId: PropTypes.string,
};

ChangePasswordForm.defaultProps = {
  testId: '',
};
