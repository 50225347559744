import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Images
import primaryBg from '../../images/graphics/tile-backgrounds/tile_bg_1-dark.svg';
import secondaryBg from '../../images/graphics/tile-backgrounds/tile_bg_2-dark.svg';

const backgrounds = {
  primary: primaryBg,
  secondary: secondaryBg,
};

const Tile = ({
  icon,
  title,
  onClick,
  link,
  bg,
  additionalClasses,
  additionalContainerClasses,
  additionalTitleClasses,
  testId,
  ...props
}) => {
  const TileComponent = useMemo(() => {
    if (link) return Link;
    if (onClick) return 'button';
    return 'div';
  }, [link, onClick]);

  return (
    <TileComponent
      className={twMerge(
        'group w-full relative rounded-lg cursor-pointer hover:scale-105',
        'transition duration-300 ease-in-out bg-no-repeat bg-cover bg-center',
        'focus:no-underline active:no-underline hover:no-underline text-start bg-gradient-green relative',
        additionalClasses,
      )}
      onClick={onClick}
      {...(link ? { to: link } : {})}
      {...props}
      {...getTestProps(testId, `${title}-container`)}
    >
      <div
        className="hidden dark:block absolute top-[3px] left-[3px] bg-gray-900 h-[calc(100%-6px)] w-[calc(100%-6px)]
        rounded-md"
      />
      <div
        className={twMerge(
          'pl-5 pr-3.5 py-5 w-full h-full bg-tile-bg-1 bg-no-repeat',
          'bg-right bg-contain relative',
          additionalContainerClasses,
        )}
        style={{ backgroundImage: `url(${backgrounds[bg] || bg})` }}
        {...getTestProps(testId, `${title}-inner-container`)}
      >
        {icon && icon !== 'none' && icon}

        {title && (
          <span
            className={twMerge(
              'text-base text-white z-20 mt-1',
              'font-semibold',
              additionalTitleClasses,
            )}
            {...getTestProps(testId, title)}
          >
            {title}
          </span>
        )}
      </div>
    </TileComponent>
  );
};

export default Tile;

Tile.propTypes = {
  /**
   * Icon
   */
  icon: PropTypes.node,
  /**
   * Title
   */
  title: PropTypes.string,
  /**
   * Click handler
   */
  onClick: PropTypes.func,
  /**
   * Link to navigate on click
   */
  link: PropTypes.string,
  /**
   * Tile background
   */
  bg: PropTypes.any,
  /**
   * Additional tile container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Additional container classes
   */
  additionalContainerClasses: PropTypes.string,
  /**
   * Additional tile classes
   */
  additionalTitleClasses: PropTypes.string,
};

Tile.defaultProps = {
  title: undefined,
  icon: undefined,
  additionalClasses: '',
  additionalContainerClasses: '',
  additionalTitleClasses: '',
  link: '',
};
