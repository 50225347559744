import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Context
import { useModals } from '../../contexts/ModalContext';

// :: Lib
import { getTestProps } from '../../lib/helpers';

// :: Component
import Button from '../Button/Button';
import VariantModalForm from '../../form/VariantModalForm/VariantModalForm';
import VariantCard from '../VariantCard/VariantCard';

const VariantsField = ({ arrayHelpers, media, errors, disabled, testId }) => {
  const { t } = useTranslation();
  const modal = useModals();

  const variants = useMemo(
    () => getIn(arrayHelpers.form.values, arrayHelpers.name) || [],
    [arrayHelpers.name, arrayHelpers.form],
  );
  const initialVariants = useMemo(
    () => arrayHelpers.form.initialValues?.variants || [],
    [arrayHelpers.form.initialValues?.variants],
  );

  const variantsNames = useMemo(
    () => (variants || []).map((variant) => variant.name),
    [variants],
  );

  const onBlur = useCallback(
    () =>
      arrayHelpers.form.handleBlur({
        target: { name: arrayHelpers.name },
      }),
    [arrayHelpers.form, arrayHelpers.name],
  );

  const addNewVariant = useCallback(
    (variant) => {
      arrayHelpers.push(variant);
      onBlur();
    },
    [arrayHelpers, onBlur],
  );

  const updateVariant = useCallback(
    (newVariant, idx) => {
      arrayHelpers.replace(idx, newVariant);
    },
    [arrayHelpers],
  );

  const openVariantModal = useCallback(
    async (oldVariant, idx, errors) => {
      const newVariant = await modal({
        title: t('MediaEdit.AddVariant'),
        content: (
          <VariantModalForm
            variant={oldVariant}
            media={media}
            variantsNames={variantsNames}
            errors={errors}
            {...getTestProps(testId, 'modal', 'testId')}
          />
        ),
        size: 'xl',
        dialogAdditionalClasses: 'max-h-screen',
      });

      if (newVariant) {
        if (oldVariant) updateVariant(newVariant, idx);
        else addNewVariant(newVariant);
      }
    },
    [addNewVariant, media, modal, t, testId, updateVariant, variantsNames],
  );

  const deleteVariant = useCallback(
    async (idx) => {
      const result = await modal.delete(t('ContentForm.RemoveItem'));
      if (!result) return;
      arrayHelpers.remove(idx);
    },
    [arrayHelpers, modal, t],
  );

  return (
    <>
      <Button
        type="button"
        buttonSize="sm"
        onClick={() => openVariantModal()}
        disabled={disabled}
        {...getTestProps(testId, 'add', 'testId')}
      >
        {t('MediaEdit.AddVariant')}
      </Button>
      <div
        className={twMerge(
          'gap-2 sm:gap-4 grid grid-cols-[repeat(auto-fill,_minmax(14rem,_1fr))]',
          'md:grid-cols-[repeat(auto-fill,_minmax(16rem,_1fr))]',
          variants.length > 0 && 'mt-4',
        )}
      >
        {variants.map((variant, idx) => {
          const itemErrors = errors?.[idx];
          const isNew = !initialVariants.find(
            (oldVairant) => oldVairant.name === variant.name,
          );

          return (
            <VariantCard
              key={variant.name}
              variant={variant}
              media={media}
              onEdit={
                isNew ? () => openVariantModal(variant, idx, itemErrors) : null
              }
              onDelete={() => deleteVariant(idx)}
              errors={itemErrors}
              isNew={isNew}
              disabled={disabled}
              {...getTestProps(testId, variant.name, 'testId')}
            />
          );
        })}
      </div>
    </>
  );
};

export default VariantsField;

VariantsField.propTypes = {
  /**
   * Array helpers from formik array field
   */
  arrayHelpers: PropTypes.object.isRequired,
  /**
   * User data
   */
  media: PropTypes.object.isRequired,
  /**
   * Errors for varaiants
   */
  errors: PropTypes.array,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Page test id
   */
  testId: PropTypes.string,
};

VariantsField.defaultProps = {
  testId: '',
};
