import { useMemo, useReducer } from 'react';
import FlotiqPlugins from '../lib/flotiq-plugins/flotiqPluginsRegistry';

const usePluginResults = (eventName, EventClass, params, pluginId = null) => {
  const [counter, addToCounter] = useReducer((count) => {
    return (count || 0) + 1;
  });

  const results = useMemo(
    () => {
      const event = new EventClass({
        ...params,
        rerender: addToCounter,
      });

      return (
        (pluginId
          ? FlotiqPlugins.runScoped(eventName, pluginId, event)
          : FlotiqPlugins.run(eventName, event)) || []
      ).filter((r) => !!r);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [EventClass, eventName, counter, ...Object.values(params)],
  );

  return results;
};

export default usePluginResults;
