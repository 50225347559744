import { IFlotiqPluginEvent } from './IFlotiqPluginEvent';

/**
 * An event that is fired when an object form is submitted (after the API call is triggered).
 * It will be skipped if client side validation fails.
 * No result is expected.
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.form::after-submit"
 * @type {FormAfterSubmitEvent}
 */

/**
 * @class
 * @memberof FlotiqPlugins.Events
 * @name FormAfterSubmitEvent
 *
 * @property {boolean} success If the form submission was successful
 * @property {object} contentObject New content object data returned from API.
 *                    If the form submission fails, the data will return the values
 *                    with which the submission was triggered.
 * @property {object} errors Errors returned after submitting the form.
 */
export class FormAfterSubmitEvent extends IFlotiqPluginEvent {
  success;
  contentObject;
  errors;
}
