import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';

// :: Component
import Tooltip from '../../Tooltip/Tooltip';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

const TutorialBeacon = forwardRef(({ title, onClick, index, testId }, ref) => {
  const beaconComponent = (
    <div
      className="flex w-8 h-8 relative"
      onClick={onClick}
      ref={ref}
      {...getTestProps(testId, `container-${index}`)}
    >
      <div
        className={twMerge(
          'w-8 h-8 rounded-full bg-[#f3b5375c]',
          'animate-pulse border-solid border-2 border-orange',
        )}
      />
      <div
        className={twMerge(
          'absolute top-2 right-2',
          'w-4 h-4 rounded-full bg-orange',
        )}
      />
    </div>
  );

  return title ? (
    <Tooltip
      tooltip={title}
      tooltipPlacement="leftCenter"
      phoneTooltipPlacement="leftCenter"
      {...getTestProps(testId, `tooltip-${index}`, 'testId')}
    >
      {beaconComponent}
    </Tooltip>
  ) : (
    beaconComponent
  );
});

TutorialBeacon.propTypes = {
  /**
   * Component test id
   */
  testId: PropTypes.string,
  /**
   * Component on click event
   */
  onClick: PropTypes.func,
  /**
   * Component title
   */
  title: PropTypes.string,
  /**
   * Component index
   */
  index: PropTypes.number,
};

TutorialBeacon.defaultProps = {
  title: '',
  index: 0,
  testId: '',
  onClick: undefined,
};

export default TutorialBeacon;
