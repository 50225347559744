import { useEffect, useState } from 'react';

const useOnce = (callback) => {
  const [called, setCalled] = useState(false);
  useEffect(() => {
    setCalled(true);
  }, []);
  useEffect(() => {
    if (called) {
      callback();
    }
  }, [called, callback]);
};

export default useOnce;
