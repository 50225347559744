import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import { getTestProps } from '../../../../../lib/helpers';

const SettingsButton = ({ name, icon, onClick, styles, isActive, testId }) => {
  const [active, setActive] = useState(isActive);

  return (
    <div
      className={twMerge(
        styles.settingsButton,
        active && styles.settingsButtonActive,
      )}
      onClick={() => {
        onClick(name);
        setActive((prevState) => !prevState);
      }}
      {...getTestProps(testId, `image-${name}-tune`)}
    >
      {icon}
    </div>
  );
};

const FlotiqImagePluginSettings = ({
  settings,
  toggleTune,
  api,
  tuneData,
  block,
  testId,
}) => {
  const handleOnClick = useCallback(
    (name) => {
      toggleTune(name);
      block.dispatchChange();
    },
    [toggleTune, block],
  );

  return (
    <div
      className="flex space-x-2"
      {...getTestProps(testId, 'image-tune-container')}
    >
      {settings.map((element) => {
        return (
          <SettingsButton
            {...element}
            key={element.name}
            onClick={handleOnClick}
            styles={api.styles}
            isActive={tuneData?.[element.name]}
            testId={testId}
          />
        );
      })}
    </div>
  );
};

export default FlotiqImagePluginSettings;

FlotiqImagePluginSettings.propTypes = {
  /**
   *  available tunes settings
   */
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
    }),
  ).isRequired,
  /**
   * toggle image tune callback
   */
  toggleTune: PropTypes.func.isRequired,
  /**
   * Editor js api object
   */
  api: PropTypes.object.isRequired,
  /**
   * Selected tune data
   */
  tuneData: PropTypes.shape({
    withBorder: PropTypes.bool,
    withBackground: PropTypes.bool,
    stretched: PropTypes.bool,
  }),
  /**
   * Editor js block object
   */
  block: PropTypes.object.isRequired,
  /**
   * test id for image plugin settings
   */
  testId: PropTypes.string,
};

FlotiqImagePluginSettings.defaultProps = {
  tuneData: {},
  testId: '',
};
