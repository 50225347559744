import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Avatar from '../Avatar/Avatar';
import DateContainer from '../DateContainer/DateContainer';
import Panel from '../Panel/Panel';
import Loader from '../Loader/Loader';
import { capitalizeText, getTestProps } from '../../lib/helpers';
import StatusPill from '../StatusPill/StatusPill';

const VersionPill = ({ workflowState }) => {
  const { t, i18n } = useTranslation();

  return (
    <StatusPill
      containerClasses={twMerge(
        'h-[20px] py-0 px-[10px] rounded-md inline-flex items-center justify-center w-max',
        'text-xs font-normal',
        'xs:order-last xl:order-none 2xl:order-last',
      )}
      color={workflowState}
      status={
        i18n.exists(`ObjectStatus.VersionStatus.${workflowState}`)
          ? t(`ObjectStatus.VersionStatus.${workflowState}`)
          : capitalizeText(workflowState)
      }
    />
  );
};

const ContentObjectVersions = ({
  versions,
  loading,
  testId,
  selectVersionCallback,
  additionalClasses,
}) => {
  const { t } = useTranslation();

  return (
    <Panel
      title={t('ContentForm.Versions')}
      isCollapsable={false}
      additionalContainerClasses={twMerge('py-5 px-4', additionalClasses)}
      additionalTitleClasses="text-lg"
      additionalChildrenClasses={'pt-2'}
      open
      {...getTestProps(testId, `co-versions-container`, 'testId')}
    >
      {loading && (
        <Loader
          size="tiny"
          type="spinner-grid"
          additionalClasses="absolute top-[20px] left-1/2"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      )}
      <div
        className={twMerge(
          'flex flex-col space-y-4 max-h-[340px] overflow-y-overlay scrollbar-sm',
          loading && 'opacity-20',
        )}
      >
        {versions?.map((version) => (
          <div
            key={version.id}
            onClick={() => selectVersionCallback(version.version)}
            {...getTestProps(testId, `version-select-option-${version.id}`)}
            className={twMerge(
              'inline-flex items-center justify-between mr-0.5',
              'cursor-pointer group',
            )}
          >
            <div className="inline-flex items-end space-x-3 w-full">
              <Avatar
                userInitials={
                  version.editor
                    ? version.editor?.firstName[0] + version.editor?.lastName[0]
                    : ''
                }
                additionalClasses="shrink-0 !cursor-pointer text-lg my-2.5"
                testId={testId}
              />
              <div
                className={twMerge(
                  'flex flex-col xs:flex-row xl:flex-col 2xl:flex-row justify-start',
                  'items-start xs:items-center xl:items-start 2xl:items-center',
                  '2xl:items-center gap-3 2xl:gap-6 py-2 px-3 w-full',
                  'group-hover:bg-slate-50 dark:group-hover:bg-gray-900 group-hover:rounded-md ',
                )}
              >
                <VersionPill workflowState={version.internal.workflowState} />

                <div className="flex flex-col">
                  <DateContainer
                    date={version.internal.updatedAt}
                    dateFormat={'L, LT'}
                    isBold={false}
                    additionalClasses={'text-slate-400'}
                    testId={testId}
                  />

                  <p
                    className="font-bold dark:text-white"
                    {...getTestProps(testId, 'editor')}
                  >
                    {version.editor
                      ? `${version.editor?.firstName} ${version.editor?.lastName}`
                      : `(${t('Global.DeletedUser')})`}
                  </p>
                  {version.apiKeyName && (
                    <p
                      className="dark:text-white"
                      {...getTestProps(testId, 'api-key-name')}
                    >
                      {version.apiKeyName}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Panel>
  );
};

export default ContentObjectVersions;

ContentObjectVersions.propTypes = {
  /**
   * Content object versions array
   */
  versions: PropTypes.array.isRequired,
  /**
   * Test id for information container
   */
  testId: PropTypes.string,
  /**
   *  Callback for version onclick
   */
  selectVersionCallback: PropTypes.func,
  /**
   *  Loader
   */
  loading: PropTypes.bool,
  /**
   * Additional css classes
   */
  additionalClasses: PropTypes.string,
};

ContentObjectVersions.defaultProps = {
  loading: false,
  versions: [],
  testId: '',
  selectVersionCallback: /* istanbul ignore next */ () => null,
};
