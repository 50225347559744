import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { LeaveIcon } from '../../../images/shapes';
import { useCallback } from 'react';
import TopbarActionButton from './base/TopbarActionButton';

const SaveAndLeaveButton = ({ form, navigateOnSave, ...props }) => {
  const { t } = useTranslation();

  const onClick = useCallback(() => {
    if (navigateOnSave) navigateOnSave.current = true;
  }, [navigateOnSave]);

  return (
    <TopbarActionButton
      iconImage={<LeaveIcon className="w-3.5" />}
      label={t('Global.SaveAndLeave')}
      onClick={onClick}
      additionalIconClasses="ml-1 w-3.5"
      {...(form ? { form, type: 'submit' } : {})}
      {...props}
    />
  );
};

export default SaveAndLeaveButton;

SaveAndLeaveButton.propTypes = {
  /**
   * Form id, if not provided form submission should be handled by onClick property
   */
  form: PropTypes.string,
  /**
   * Reference to navigate on save property
   */
  navigateOnSave: PropTypes.any,
};

SaveAndLeaveButton.defaultProps = {};
