import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { PlusCircleOutlineIcon } from '../../images/shapes';
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';
import { getTestProps } from '../../lib/helpers';
import useSelectedSpace from '../../hooks/useSelectedSpace';

const CustomUIPluginForm = ({ onSubmit, disabled, allowedHosts, testId }) => {
  const { t } = useTranslation();
  const { space } = useSelectedSpace();

  const inputHelpText = useMemo(() => {
    if (allowedHosts.includes('*')) return null;
    return (
      <Trans i18nKey="Plugins.AllowedHostsTooltip">
        {{ hosts: allowedHosts.join(', ') }}
        <a
          className="underline cursor-pointer"
          href={`/space/upgrade/${space}`}
          target={'_blank'}
          rel="noreferrer"
        >
          tier
        </a>
      </Trans>
    );
  }, [allowedHosts, space]);

  return (
    <Formik initialValues={{ url: '' }} onSubmit={onSubmit}>
      {(formik) => (
        <form
          id={'custom-ui-plugin-form'}
          className="pb-3"
          onSubmit={formik.handleSubmit}
          noValidate={true}
        >
          <div className="flex items-end gap-3">
            <Input
              name="url"
              type={'text'}
              additionalClasses={'mt-2'}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
              label={t('Plugins.CustomPlugin')}
              placeholder={t('Plugins.CustomPluginPlaceholder')}
              {...getTestProps(testId, 'url', 'testId')}
            />

            <Button
              type="submit"
              form="custom-ui-plugin-form"
              buttonColor="borderlessBlue"
              disabled={disabled}
              additionalClasses={'h-fit p-2 mb-2'}
              noPaddings
              {...getTestProps(testId, 'add-custom-plugin', 'testId')}
            >
              <PlusCircleOutlineIcon className="min-w-5 w-5" />
            </Button>
          </div>
          {inputHelpText && (
            <div className="text-sm text-slate-600 dark:text-gray-200 mt-1">
              {inputHelpText}
            </div>
          )}
        </form>
      )}
    </Formik>
  );
};

export default CustomUIPluginForm;

CustomUIPluginForm.propTypes = {
  /**
   * On sumbit callback
   */
  onSubmit: PropTypes.func.isRequired,
  /**
   * If form is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Allowed host names
   */
  allowedHosts: PropTypes.arrayOf(PropTypes.string),
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

CustomUIPluginForm.defaultProps = {
  disabled: false,
  allowedHosts: ['localhost'],
  testId: '',
};
