import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-hot-toast';

// :: Context
import { useModals } from '../../contexts/ModalContext';
import UserContext from '../../contexts/UserContext';
import DirtyHandlerContext from '../../contexts/DirtyHandlerContext';

// :: Api
import { postUserRole } from '../../lib/flotiq-client';
import {
  ResponseError,
  checkResponseStatus,
} from '../../lib/flotiq-client/response-errors';
import { getCtdsFromQuery } from '../../lib/flotiq-client/api-helpers';

// :: Hooks
import { useContentTypes, useUserRole } from '../../hooks/api';
import useApiErrorsToast from '../../hooks/api/useApiErrorsToast';
import useToken from '../../hooks/useToken';
import useFirstLoading from '../../hooks/useFirstLoading';
import { useGridNavigate } from '../../components/DataGrid/useGridFilters';
import useSelectedSpace from '../../hooks/useSelectedSpace';

// :: Images
import { WarningIcon } from '../../images/shapes';

// :: Components
import Loader from '../../components/Loader/Loader';
import Heading from '../../components/Heading/Heading';
import UserRoleForm from '../../form/UserRoleForm/UserRoleForm';
import LinkButton from '../../components/LinkButton/LinkButton';
import CancelButton from '../../components/Topbar/buttons/CancelButton';
import SaveButton from '../../components/Topbar/buttons/SaveButton';
import TopbarActionMenu from '../../components/Topbar/buttons/base/TopbarActionMenu';
import DuplicateButton from '../../components/Topbar/buttons/DuplicateButton';
import SaveAndLeaveButton from '../../components/Topbar/buttons/SaveAndLeaveButtons';
import DeleteButton from '../../components/Topbar/buttons/DeleteButton';
import TopbarBreadcrumbs from '../../components/Topbar/breadcrumbs/TopbarBreadcrumbs';

// :: Layout
import PageLayout, {
  predefinedLayoutClasses,
} from '../../layout/PageLayout/PageLayout';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const AddUserRole = ({ duplicate, testId }) => {
  const { t } = useTranslation();
  const modal = useModals();
  const jwt = useToken();
  const navigate = useNavigate();
  const navigateOnSave = useRef();
  const { space, buildUrlWithSpace } = useSelectedSpace();
  const { id } = useParams();
  const { isAdmin, planLimits } = useContext(UserContext);
  const { setDirty } = useContext(DirtyHandlerContext);

  const { navigateGrid, gridLink } = useGridNavigate(
    'user-roles',
    buildUrlWithSpace('user-roles'),
  );

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const {
    entity: role,
    isLoading: roleIsLoading,
    updateEntity: putRole,
    deleteEntity: deleteRole,
    errors,
    status,
  } = useUserRole(id);

  const ctdParams = useMemo(
    () => ({
      limit: 1000,
      order_by: 'label',
      order_direction: 'asc',
      page: 1,
    }),
    [],
  );

  const {
    data: contentTypes,
    isLoading: isLoadingCT,
    errors: ctdErrors,
  } = useContentTypes(ctdParams);

  const firstLoading = useFirstLoading(
    (!roleIsLoading || !id) && !isLoadingCT,
    id,
  );

  useApiErrorsToast(errors);
  useApiErrorsToast(ctdErrors);

  const handleDeleteRole = useCallback(async () => {
    modal.deleting('delete-modal');
    try {
      const { body, status } = await deleteRole();
      checkResponseStatus(body, status);
      toast.success(t('UserRoles.Deleted'));

      setDirty(false);
      navigateGrid();
    } catch (error) {
      if (!(error instanceof ResponseError)) {
        toast.error(t('Form.CommunicationErrorMessage'));
      } else {
        toast.error(
          error.message ? error.message : t('UserRoles.DeletingError'),
        );
      }
    }
  }, [modal, deleteRole, t, setDirty, navigateGrid]);

  const deleteRoleModal = useCallback(async () => {
    setIsDeleting(true);
    await modal.delete(t('UserRoles.ConfirmDelete'), 'delete-modal', () =>
      handleDeleteRole(),
    );
    setIsDeleting(false);
  }, [handleDeleteRole, modal, t]);

  const pageTitle = useMemo(() => {
    if (duplicate) return 'Duplicate';
    else if (id) return 'Edit';
    return 'Add';
  }, [duplicate, id]);

  const saveNewRole = useCallback(
    async (values) => {
      setIsSaving(true);
      try {
        const { body, status } = await postUserRole(jwt, space, {
          name: values.name,
          description: values.description,
          permissions: values.permissions.map((permission) => ({
            type: permission.type,
            contentTypeDefinition: {
              id: permission.contentTypeDefinition?.id || '',
            },
            canRead: permission.canRead,
            canCreate: permission.canCreate,
            canUpdate: permission.canUpdate,
            canDelete: permission.canDelete,
          })),
        });
        setIsSaving(false);
        checkResponseStatus(body, status);
        toast.success(t('UserRoles.Added', { roleName: body.name }));
        navigate(buildUrlWithSpace(`user-roles/edit/${body.id}`));
        return [body, {}, false];
      } catch (error) {
        setIsSaving(false);

        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          return [values, {}, true];
        }

        toast.error(error.message ? error.message : t('UserRoles.AddingError'));
        return [values, error.errors, true];
      }
    },
    [jwt, navigate, t, space, buildUrlWithSpace],
  );

  const updateRole = useCallback(
    async (values) => {
      setIsSaving(true);
      try {
        const { status, body } = await putRole(values);
        setIsSaving(false);
        checkResponseStatus(body, status);
        toast.success(t('UserRoles.Updated', { roleName: body.name }));
        return [body, {}, false];
      } catch (error) {
        if (!(error instanceof ResponseError)) {
          toast.error(t('Form.CommunicationErrorMessage'));
          setIsSaving(false);
          return [values, {}, true];
        }
        toast.error(
          error.message
            ? t('ContentForm.Errors.TryAgain')
            : t('UserRoles.UpdatingError'),
        );
        setIsSaving(false);
        return [values, error.errors, true];
      }
    },
    [putRole, t],
  );

  const filterCtd = useCallback(
    async (query, _, setIsLoading) => {
      setIsLoading(true);
      const newCtds = await getCtdsFromQuery(
        jwt,
        space,
        { ...ctdParams, label: query },
        t,
      );
      setIsLoading(false);
      return newCtds
        .filter(
          (ctd) =>
            !ctd.internal ||
            ctd.name === '_media' ||
            ctd.name === '_tag' ||
            ctd.name === '_webhooks' ||
            ctd.name === '_plugin_settings',
        )
        .map((ctd) => ({ label: ctd.label, value: ctd.id }));
    },
    [jwt, ctdParams, t, space],
  );

  const showForm = useMemo(
    () => isAdmin && !firstLoading && (role || !id),
    [isAdmin, firstLoading, role, id],
  );

  const noData = useMemo(() => {
    if (showForm) return null;
    if (firstLoading) {
      return (
        <Loader
          size="small"
          type="spinner-grid"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      );
    }
    if (!isAdmin) {
      toast.error(t('Global.NoAccess'));
    }
    return (
      <Heading
        level={2}
        additionalClasses={twMerge(
          'text-3xl md:text-4xl leading-8 dark:text-white',
        )}
      >
        <div
          className="flex flex-col items-center justify-center text-center"
          {...getTestProps(testId, 'empty-data')}
        >
          <WarningIcon
            className="text-red w-14 md:w-20 mb-3"
            title={t('UserRoles.CouldntFetch')}
          />
          {status === 404 || !isAdmin
            ? t('Global.NoAccess')
            : t('UserRoles.CouldntFetch')}
        </div>
      </Heading>
    );
  }, [showForm, firstLoading, isAdmin, testId, t, status]);

  const contentTypesOptions = useMemo(() => {
    if (!contentTypes) return;

    const options = contentTypes
      .filter(
        (ctd) =>
          !ctd.internal ||
          ctd.name === '_media' ||
          ctd.name === '_tag' ||
          ctd.name === '_plugin_settings' ||
          ctd.name === '_webhooks',
      )
      .map((ctd) => {
        return {
          value: ctd.id,
          label: ctd.label,
        };
      });

    return options;
  }, [contentTypes]);

  return (
    <PageLayout
      page="userRoles"
      title={t(`UserRoles.${pageTitle}`, { roleName: role?.name })}
      buttonsDisabled={isSaving || isDeleting}
      breadcrumbs={
        <TopbarBreadcrumbs
          parentTitle={t('Global.UserRoles')}
          parentLink={gridLink}
        />
      }
      buttons={
        <>
          <CancelButton link={gridLink} />
          {isAdmin && (
            <>
              <SaveButton
                form="role-form"
                isLoading={isSaving}
                navigateOnSave={navigateOnSave}
                disabled={!planLimits?.user_roles_enabled}
              />
              <TopbarActionMenu>
                {id && !duplicate && (
                  <DuplicateButton
                    link={buildUrlWithSpace(`user-roles/duplicate/${id}`)}
                    disabled={!planLimits?.user_roles_enabled}
                  />
                )}
                <SaveAndLeaveButton
                  form="role-form"
                  navigateOnSave={navigateOnSave}
                  disabled={!planLimits?.user_roles_enabled}
                />
                {id && !duplicate && <DeleteButton onClick={deleteRoleModal} />}
              </TopbarActionMenu>
            </>
          )}
        </>
      }
      testId={testId}
    >
      {showForm ? (
        <div className={predefinedLayoutClasses.withSidebar}>
          <div className={predefinedLayoutClasses.leftColumnWhite}>
            <UserRoleForm
              key={pageTitle}
              role={role}
              onSave={id && !duplicate ? updateRole : saveNewRole}
              ctdsOptions={contentTypesOptions}
              ctdsFilterCallback={filterCtd}
              disabled={
                isSaving || isDeleting || !planLimits?.user_roles_enabled
              }
              navigateOnSave={navigateOnSave}
              testId={testId}
            />
          </div>
          <div className={predefinedLayoutClasses.rightColumn}>
            {!planLimits?.user_roles_enabled && (
              <div
                className="rounded-lg p-5 w-full bg-white dark:bg-slate-950 dark:text-white"
                {...getTestProps(testId, 'upgrade-plan-info')}
              >
                <div
                  className={
                    'w-full flex flex-wrap gap-x-2 gap-y-1 items-center justify-between ' +
                    'mr-2 font-bold text-base dark:text-white mb-2'
                  }
                >
                  {t('UserRoles.CustomRoles')}
                  {planLimits?.price !== -1 && (
                    <LinkButton
                      buttonSize="xs"
                      link={`/space/upgrade/${space}`}
                    >
                      {t('Global.UpgradePlan')}
                    </LinkButton>
                  )}
                </div>
                <p className="text-red font-bold mb-3">
                  {t('UserRoles.Disabled')}
                </p>
                {t('UserRoles.CustomRolesUpgrade')}
              </div>
            )}
            <div className="rounded-lg p-5 w-full bg-white dark:bg-slate-950 dark:text-white">
              <p className="font-bold text-red mb-3">{t('Global.Warning')}</p>
              {t('UserRoles.Information')}
            </div>
          </div>
        </div>
      ) : (
        <div className={predefinedLayoutClasses.whiteBox}>{noData}</div>
      )}
    </PageLayout>
  );
};

export default AddUserRole;

AddUserRole.propTypes = {
  /**
   * If user role is duplicating
   */
  duplicate: PropTypes.bool,
  /**
   * Test id for page
   */
  testId: PropTypes.string,
};

AddUserRole.defaultProps = {
  duplicate: false,
  testId: '',
};
