import { createContext } from 'react';
import PropTypes from 'prop-types';

export const ListPropertiesContext = createContext({});

ListPropertiesContext.propTypes = {
  value: PropTypes.shape({
    /**
     * Parent list field name
     */
    highListName: PropTypes.string,
    /**
     * If is in nested list
     */
    isNestedList: PropTypes.bool,
  }).isRequired,
};

export default ListPropertiesContext;
