import { useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import useLocalStorageState from 'use-local-storage-state';

// :: Components
import Loader from '../../components/Loader/Loader';

// :: Lib
import { getAccountActivation } from '../../lib/flotiq-client';
import { checkResponseStatus } from '../../lib/flotiq-client/response-errors';
import { getTestProps } from '../../lib/helpers';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

const AccountActivation = ({ testId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get('activationToken');
  const requestRef = useRef();
  const [darkMode] = useDarkMode();
  const [, , { removeItem: removeUser }] = useLocalStorageState('cms.user');

  const fetchActivation = useCallback(
    async (tokenParam) => {
      if (requestRef.current) return;
      requestRef.current = true;
      try {
        const { body, status } = await getAccountActivation(tokenParam);
        checkResponseStatus(body, status);
        navigate('/login?activated=true');
      } catch (e) {
        navigate('/login?activated=false');
      }
    },
    [navigate],
  );

  useEffect(() => {
    removeUser();
  }, [removeUser]);

  useEffect(() => {
    if (tokenParam) {
      fetchActivation(tokenParam);
    }
  }, [tokenParam, fetchActivation]);

  return (
    <>
      <Helmet>
        <title>{t('Global.RegisterToFlotiq')}</title>
      </Helmet>
      <div
        className={twMerge(
          'w-full h-screen flex items-center justify-center',
          darkMode && 'dark:bg-gray-900',
        )}
      >
        <Loader
          size="big"
          type="spinner-grid"
          {...getTestProps(testId, 'loader', 'testId')}
        />
      </div>
    </>
  );
};

AccountActivation.propTypes = {
  /**
   * Test id for account activation page
   */
  testId: PropTypes.string,
};
AccountActivation.defaultProps = {
  testId: '',
};

export default AccountActivation;
