import { twMerge } from 'tailwind-merge';
import PropTypes from 'prop-types';
import Button from '../../../Button/Button';
import LinkButton from '../../../LinkButton/LinkButton';
import Tooltip from '../../../Tooltip/Tooltip';
import { useContext } from 'react';
import { TopbarContext } from '../../Topbar';
import { getTestProps } from '../../../../lib/helpers';

const TopbarButton = ({
  label,
  disabled,
  tooltip,
  tooltipPlacement,
  phoneTooltipPlacement,
  additionalClasses,
  additionalTooltipClasses,
  ...props
}) => {
  const ButtonComponent = props.link ? LinkButton : Button;

  const { buttonsDisabled, testId } = useContext(TopbarContext);
  const isDisabled = disabled || buttonsDisabled;

  const buttonComponent = (
    <ButtonComponent
      buttonSize="xs"
      additionalClasses={twMerge('text-base 2xl:text-base', additionalClasses)}
      additionalChildrenClasses={
        'max-w-32 xs:max-w-56 md:max-w-32 lg:max-w-56 2xl:max-w-[450px]'
      }
      title={label?.length > 90 ? label : ''}
      disabled={isDisabled}
      iconPosition="start"
      {...getTestProps(testId, `${label}`, 'testId')}
      {...props}
    >
      <div className="whitespace-nowrap truncate">{label}</div>
    </ButtonComponent>
  );

  return tooltip ? (
    <Tooltip
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
      phoneTooltipPlacement={phoneTooltipPlacement}
      additionalTooltipClasses={additionalTooltipClasses}
      {...getTestProps(testId, `${label}`, 'testId')}
      {...props}
    >
      {buttonComponent}
    </Tooltip>
  ) : (
    buttonComponent
  );
};

export default TopbarButton;

TopbarButton.propTypes = {
  /**
   * Button label
   */
  label: PropTypes.node,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
};

TopbarButton.defaultProps = {
  disabled: false,
};
