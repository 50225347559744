import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Component
import Heading from '../Heading/Heading';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import { createContext, useMemo } from 'react';

export const TopbarContext = createContext({
  buttonsDisabled: false,
  pageTitle: '',
});

const UtilityToolbar = ({ children }) => (
  <div
    className={twMerge(
      'flex items-center justify-center lg:justify-end',
      'pt-2 pb-safe-or-2 lg:py-0 pl-4 2xl:pl-6 space-x-3',
      'fixed lg:relative bottom-0 left-0',
      'w-full lg:w-auto border-t border-gray dark:border-zinc-800 lg:border-none',
      'bg-white dark:bg-slate-950 lg:bg-transparent lg:dark:bg-transparent',
    )}
  >
    {children}
  </div>
);

const StatusUtilityToolbar = ({ children }) => (
  <div
    className={twMerge(
      'my-auto lg:border-r px-2 lg:px-4 2xl:px-6 shrink-0 w-fit',
      'fixed xl:static bottom-12 lg:top-14 lg:bottom-auto right-0',
    )}
  >
    {children}
  </div>
);

const Topbar = ({
  buttonsDisabled,
  topbarLogo,
  topbarHeading,
  buttons,
  status,
  additionalClasses,
  breadcrumbs,
  testId,
}) => {
  const topbarContext = useMemo(
    () => ({ pageTitle: topbarHeading, buttonsDisabled, testId }),
    [buttonsDisabled, testId, topbarHeading],
  );

  return (
    <TopbarContext.Provider value={topbarContext}>
      <header
        className={twMerge(
          'flex items-center border-b border-gray dark:border-none sm:border-none bg-white dark:bg-slate-950',
          additionalClasses,
        )}
        {...getTestProps(testId)}
      >
        <div className="h-full inline-flex sm:hidden items-center pl-3 sm:pl-none">
          {topbarLogo}
        </div>
        <div
          className="inline-flex h-14 px-3 lg:px-7 justify-between items-center
      border-b-none sm:border-b sm:border-gray sm:dark:border-slate-950 grow min-w-0 max-w-full"
        >
          <div className="flex justify-between min-w-0 w-full">
            <div className="min-w-0 w-full">
              <Heading
                level={4}
                additionalClasses={twMerge(
                  'text-lg sm:text-2xl w-fit m-0 p-0 leading-6 truncate dark:text-white',
                  'max-w-[calc(100vw-94px)] sm:max-w-[calc(100vw-114px)] lg:max-w-[calc(100vw-400px)]',
                  'lg:max-w-full',
                )}
                title={topbarHeading.length > 30 ? topbarHeading : ''}
              >
                {topbarHeading}
              </Heading>
              {breadcrumbs}
            </div>
            {status && <StatusUtilityToolbar>{status}</StatusUtilityToolbar>}
          </div>
          <UtilityToolbar>{buttons}</UtilityToolbar>
        </div>
      </header>
    </TopbarContext.Provider>
  );
};

Topbar.propTypes = {
  /**
   * Topbar logo
   */
  topbarLogo: PropTypes.any,
  /**
   * Header for this section
   */
  topbarHeading: PropTypes.string,
  /**
   * Buttons passed to topbar
   */
  buttons: PropTypes.node,
  /**
   * Topbar additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Breadcrumbs
   */
  breadcrumbs: PropTypes.node,
  /**
   * If topbar buttons are disabled
   */
  buttonsDisabled: PropTypes.bool,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

Topbar.defaultProps = {
  topbarLogo: '',
  topbarHeading: '',
  buttons: [],
  additionalClasses: '',
  breadcrumbs: [],
  buttonsDisabled: false,
  testId: '',
};

export default Topbar;
