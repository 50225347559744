import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

// :: Components
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import { PasswordValidationBox } from '../PasswordValidationBox/PasswordValidationBox';

// :: Icons
import { EyeIcon, EyeCrossedIcon, MagnifierIcon } from '../../images/shapes';

// :: Helpers
import { getTestProps } from '../../lib/helpers';

const Input = ({
  value,
  type,
  required,
  onChange,
  onBlur,
  name,
  label,
  inputSize,
  error,
  disabled,
  helpText,
  showPasswordInfo,
  additionalClasses,
  additionalInputClasses,
  additionalInputLabelClasses,
  additionalInputErrorClasses,
  testId,
  isSearchIcon,
  refs,
  autoFocus,
  visualSeparateSlash,
  notSensitiveToPasswordManager,
  updateCurrent,
  ...props
}) => {
  const ref = useRef(null);
  const [currentValue, setCurrentValue] = useState(value);
  const [currentType, setCurrentType] = useState(
    notSensitiveToPasswordManager ? 'text' : type,
  );
  const [isFocused, setIsFocused] = useState(false);
  const [isNotSensitiveToPasswordManager, setIsNotSensitiveToPasswordManager] =
    useState(notSensitiveToPasswordManager);

  const apiKeyInputStyle = useMemo(() => {
    if (isNotSensitiveToPasswordManager) {
      return {
        textSecurity: 'disc',
        WebkitTextSecurity: 'disc',
        MozTextSecurity: 'disc',
      };
    }
    return {};
  }, [isNotSensitiveToPasswordManager]);

  useEffect(() => {
    if (currentType !== 'password' && type !== 'password') {
      setCurrentType(type);
    }
    setIsNotSensitiveToPasswordManager(notSensitiveToPasswordManager);
  }, [currentType, type, notSensitiveToPasswordManager]);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
    }
  }, [autoFocus]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    if (updateCurrent && value !== currentValue) setCurrentValue(value);
  }, [currentValue, value, updateCurrent]);

  const handleChange = useCallback(
    (e) => {
      setCurrentValue(e.target.value);
      onChange?.(e);
    },
    [onChange],
  );

  const handlePasswordVisibility = useCallback(() => {
    if (notSensitiveToPasswordManager) {
      if (isNotSensitiveToPasswordManager) {
        setIsNotSensitiveToPasswordManager(false);
      } else {
        setIsNotSensitiveToPasswordManager(true);
      }
      return;
    }

    if (currentType === 'password') {
      setCurrentType('text');
    } else {
      setCurrentType('password');
    }
  }, [
    notSensitiveToPasswordManager,
    currentType,
    isNotSensitiveToPasswordManager,
  ]);

  const handleBlur = useCallback(
    (e) => {
      setIsFocused(false);
      if (onBlur) onBlur(e);
    },
    [onBlur],
  );

  const handleSeparateVisualSlash = useCallback(() => {
    if (
      typeof currentValue === 'string' &&
      currentValue?.indexOf('/') > -1 &&
      currentValue?.indexOf('/') !== 0 &&
      currentValue?.indexOf('/') === currentValue.lastIndexOf('/')
    ) {
      const folderName = currentValue.split('/')?.[0];
      return (
        <div
          className={twMerge(
            'absolute z-10 pl-2 left-1 bottom-1 flex justify-start items-center',
            'overflow-hidden -mt-[10px] ',
            'bg-white dark:bg-slate-950 pointer-events-none max-w-full',
            disabled && 'bg-gray',
          )}
          style={{ width: 'calc(100% - 10px)', height: 'calc(100% - 10px)' }}
          {...getTestProps(testId, 'separate-visual-slash-container')}
        >
          <span
            className={twMerge(
              'bg-blue-300 text-white py-[6px] px-[12px]',
              'rounded-[6px] text-gray-700 whitespace-nowrap',
              'overflow-hidden text-ellipsis',
              folderName.length > 20 && 'min-w-[100px]',
            )}
            {...getTestProps(testId, 'separate-visual-slash-folder-name')}
          >
            {folderName}
          </span>
          <span className="mx-1 dark:text-white">/</span>
          <span
            className="whitespace-nowrap dark:text-white"
            {...getTestProps(testId, 'separate-visual-slash-rest-name')}
          >
            {currentValue.split('/')?.[1]}
          </span>
        </div>
      );
    }
  }, [currentValue, disabled, testId]);

  return (
    <div
      className={twMerge(
        'w-full',
        'flex',
        'flex-col',
        'relative',
        additionalClasses,
      )}
      style={{ width: inputSize }}
      {...getTestProps(testId, 'container')}
    >
      {label && (
        <label
          className={twMerge(
            'text-sm text-slate-400 dark:text-gray-200 mb-1',
            additionalInputLabelClasses,
          )}
          {...getTestProps(testId, 'label')}
        >
          {label}
          {required && <RequiredTemplate />}
        </label>
      )}
      {!label && required && (
        <RequiredTemplate absolute={true} isIcon={type === 'password'} />
      )}
      {(type === 'password' || notSensitiveToPasswordManager) && (
        <>
          {currentType === 'password' || isNotSensitiveToPasswordManager ? (
            <EyeCrossedIcon
              onClick={handlePasswordVisibility}
              className={twMerge(
                'w-5 cursor-pointer',
                'absolute right-5 z-10 text-slate-400/80 dark:text-gray-200',
                label ? 'top-[33px]' : 'top-[14px]',
              )}
              {...getTestProps(testId, 'password-toggle')}
            />
          ) : (
            <EyeIcon
              onClick={handlePasswordVisibility}
              className={twMerge(
                'w-5 cursor-pointer',
                'absolute right-5 z-10 text-slate-400/80 dark:text-gray-200',
                label ? 'top-[33px]' : 'top-[14px]',
              )}
              {...getTestProps(testId, 'password-toggle-visible')}
            />
          )}
        </>
      )}
      <div className="relative w-full">
        {type === 'search' && isSearchIcon && (
          <MagnifierIcon className="absolute top-3 left-4 z-[1] w-4 2xl:w-5 h-5 2xl:h-6 text-blue" />
        )}
        {visualSeparateSlash && !isFocused && handleSeparateVisualSlash()}
        <input
          className={twMerge(
            'text-base px-4 relative',
            'focus:outline-none focus:border-blue',
            'border-slate-200 dark:border-slate-700 border rounded-lg',
            'placeholder:text-slate-400 placeholder:font-light',
            'text-ellipsis text-indigo-950 dark:text-white',
            'py-1 h-12 w-full dark:bg-transparent',
            handleSeparateVisualSlash() && 'dark:bg-slate-950',
            disabled && 'bg-gray dark:bg-gray-900',
            required && !label && 'pr-5',
            type === 'password' && 'pr-8',
            type === 'search' && isSearchIcon && 'pl-12',
            error ? 'border-red' : '',
            additionalInputClasses,
          )}
          value={currentValue}
          type={currentType}
          name={name}
          style={apiKeyInputStyle || {}}
          onChange={handleChange}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
          required={required}
          disabled={disabled}
          ref={refs || ref}
          {...getTestProps(testId, 'input')}
          {...props}
        />
      </div>
      {type === 'password' && showPasswordInfo && isFocused && (
        <PasswordValidationBox password={currentValue} testId={testId} />
      )}
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={error}
        additionalErrorClasses={additionalInputErrorClasses}
        testId={testId}
      />
    </div>
  );
};

export default Input;

Input.propTypes = {
  /**
   * Input label
   */
  label: PropTypes.node,
  /**
   * Input value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Input name
   */
  name: PropTypes.string,
  /**
   * Input type
   */
  type: PropTypes.oneOf([
    'password',
    'text',
    'date',
    'time',
    'search',
    'number',
    'datetime-local',
    'email',
  ]),
  /**
   * Input if required we add "*" near label or inside input if there is no label.
   */
  required: PropTypes.bool,
  /**
   * Input size of the input
   */
  inputSize: PropTypes.string,
  /**
   * Input text that will inform about error
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * Input on change handler
   */
  onChange: PropTypes.func,
  /**
   * Input on blur handler
   */
  onBlur: PropTypes.func,
  /**
   * Input if disabled
   */
  disabled: PropTypes.bool,
  /**
   * Help text under input
   */
  helpText: PropTypes.any,
  /**
   * If information about password validation should be shown
   */
  showPasswordInfo: PropTypes.bool,
  /**
   * Input additional classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Input additional input classes
   */
  additionalInputClasses: PropTypes.string,
  /**
   * Input additional label classes
   */
  additionalInputLabelClasses: PropTypes.string,
  /**
   * Input additional error classes
   */
  additionalInputErrorClasses: PropTypes.string,
  /**
   * Checkbox test id
   */
  testId: PropTypes.string,
  /**
   * Checkbox on type search if we want to show icon
   */
  isSearchIcon: PropTypes.bool,
  /**
   * Input password-like not sensitive to password managers
   */
  notSensitiveToPasswordManager: PropTypes.bool,
  /**
   * Input get visual separate slash preview when value include slash "/"
   */
  visualSeparateSlash: PropTypes.bool,
  /**
   * If current value should be updated if is different than passed value
   */
  updateCurrent: PropTypes.bool,
};

Input.defaultProps = {
  label: '',
  value: '',
  type: 'text',
  required: false,
  inputSize: '',
  error: '',
  name: '',
  onChange: undefined,
  disabled: false,
  helpText: '',
  showPasswordInfo: false,
  additionalClasses: '',
  additionalInputClasses: '',
  additionalInputLabelClasses: '',
  additionalInputErrorClasses: '',
  testId: '',
  isSearchIcon: true,
  notSensitiveToPasswordManager: false,
  visualSeparateSlash: false,
  updateCurrent: false,
};
