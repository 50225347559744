import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { Link, useSearchParams } from 'react-router-dom';
import useLocalStorageState from 'use-local-storage-state';

// :: Component
import Button from '../../components/Button/Button';
import Heading from '../../components/Heading/Heading';

// :: Lib
import {
  authButtonsData,
  generateRedirectUrl,
  getTestProps,
} from '../../lib/helpers';

// :: Images
import { FlotiqLogo } from '../../images/shapes';

const Authentication = ({
  headerText,
  paragraphText,
  loginLayout,
  logoLink,
  children,
  additionalLogo,
  additionalClasses,
  testId,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [, , { removeItem: removeUser }] = useLocalStorageState('cms.user');

  const connectWith = useCallback(
    (url) => {
      removeUser();

      const searchParmasString = searchParams.toString();
      const loginUrl =
        '/login' + (searchParmasString ? `?${searchParmasString}` : '');

      window.location.replace(
        `${url}?redirectUri=${generateRedirectUrl(loginUrl, true)}${
          searchParams.has('plan') ? `&plan=${searchParams.get('plan')}` : ''
        }`,
      );
    },
    [removeUser, searchParams],
  );

  return (
    <>
      <div
        className={twMerge(
          'authentication flex justify-center w-screen items-center dark:bg-gray-900',
          additionalClasses,
        )}
        {...getTestProps(testId, 'container')}
      >
        <div className="w-full md:w-4/5 md:max-w-[800px] flex flex-col items-center ">
          <div className="inline-flex items-center justify-center">
            <Link
              to={logoLink || 'https://flotiq.com/'}
              className="cursor-pointer"
              rel="noreferrer"
              {...getTestProps(testId, 'flotiq-logo-link')}
            >
              <FlotiqLogo className={'h-9 m-6'} />
            </Link>
            {additionalLogo}
          </div>

          <Heading
            level={1}
            alignment="center"
            additionalClasses="dark:text-white"
          >
            {headerText}
          </Heading>

          <p
            className={
              'pb-2.5 mt-6 mb-6 max-w-[50%] text-center dark:text-white'
            }
          >
            {paragraphText}
          </p>

          {loginLayout && (
            <div
              className={twMerge(
                'w-full h-64 ',
                'flex flex-col md:flex-row',
                'items-center md:items-start',
              )}
            >
              <div className="w-4/5 md:w-2/5">
                {authButtonsData.map((auth) => (
                  <div className="mb-6 flex justify-center" key={auth.name}>
                    <Button
                      iconImage={auth.logo}
                      iconPosition={'start'}
                      buttonColor={'grayBordered'}
                      onClick={() => connectWith(auth.url)}
                      additionalClasses="min-w-[300px] justify-center"
                    >
                      {t('Global.ContinueWith')} {auth.name}
                    </Button>
                  </div>
                ))}
              </div>
              <div className="w-4/5 md:w-1/5 h-full flex flex-row md:flex-col items-center">
                <div className="w-[40%] md:w-[1px] h-[1px] md:h-[40%] bg-slate-400/80 select-none" />
                <span className="w-[20%] md:h-[20%] flex items-center justify-center dark:text-slate-400">
                  {t('Global.Or')}
                </span>
                <div className="w-[40%] md:w-[1px] h-[1px] md:h-[40%] bg-slate-400/80 select-none" />
              </div>

              <div className="w-4/5 md:w-2/5 mt-6 md:mt-0">{children}</div>
            </div>
          )}

          {!loginLayout && <div className={'w-full'}>{children}</div>}
        </div>
      </div>
    </>
  );
};

export default Authentication;

Authentication.propTypes = {
  /**
   * Header text
   */
  headerText: PropTypes.any,
  /**
   * Paragraph text
   */
  paragraphText: PropTypes.any,
  /**
   * Login layout
   */
  loginLayout: PropTypes.any,
  /**
   * Children
   */
  children: PropTypes.any,
  /**
   * Additional element next to logo
   */
  additionalLogo: PropTypes.node,
  /**
   * Link to redirect on click
   */
  logoLink: PropTypes.string,
  /**
   * Authentication additional classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Authentication test id
   */
  testId: PropTypes.string,
};

Authentication.defaultProps = {
  headerText: '',
  paragraphText: '',
  loginLayout: null,
  children: null,
  additionalLogo: null,
  additionalClasses: '',
  logoLink: '',
  testId: '',
};
