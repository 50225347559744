import { FormRenderFieldEvent } from './FormRenderFieldEvent';

/**
 * An event that is fired when a field is rendered in the object form.
 * No result is expected, but event may modify the config object that is then passed to the field.
 * This event is fired for every field in the form except hidden ones.
 *
 * If additionalElements are added to the configuration, additional elements will be rendered in the field component
 *
 * @memberof FlotiqPlugins.Events
 * @event "flotiq.form.field::config"
 * @type {FormConfigFieldEvent}
 */

/**
 * @class
 * @extends FormRenderFieldEvent
 * @memberof FlotiqPlugins.Events
 * @name FormConfigFieldEvent
 *
 * @property {object} config Field configuration
 */
export class FormConfigFieldEvent extends FormRenderFieldEvent {
  config;
}
