import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { useModals } from '../../contexts/ModalContext';

import GlobalSearchModal from './GlobalSearchModal';

const GlobalSearch = ({ testId }) => {
  const { t } = useTranslation();
  const modal = useModals();
  useEffect(() => {
    const searchKeyHandler = (e) => {
      if (e.key !== '/' || e.ctrlKey || e.metaKey || e.shiftKey) return;
      if (/^(?:input|textarea|select|button)$/i.test(e.target.tagName)) return;
      if (e.target.isContentEditable) return;

      e.preventDefault();

      modal({
        id: 'global-search',
        content: <GlobalSearchModal testId={testId} />,
        buttons: [{ label: t('Global.Close'), result: true, key: 'Close' }],
        size: 'xl',
        dialogAdditionalClasses: 'self-start mt-5 max-h-[calc(100vh-2.5rem)]',
      });
    };

    document.addEventListener('keydown', searchKeyHandler);
    return () => document.removeEventListener('keydown', searchKeyHandler);
  }, [modal, t, testId]);

  return null;
};

GlobalSearch.propTypes = {
  /**
   * Test id for button
   */
  testId: PropTypes.string,
};
GlobalSearch.defaultProps = {
  testId: '',
};

export default GlobalSearch;
