import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';
import { twMerge } from 'tailwind-merge';

// :: Component
import Button from '../Button/Button';

const colorClasses = {
  blue: 'focus:text-white active:text-white',
  blueBordered:
    'focus:text-blue focus:hover:text-white active:text-blue active:hover:text-white',
};

const LinkButton = ({
  link,
  children,
  onClick,
  disabled,
  additionalClasses,
  ...props
}) => {
  const onClickCallback = useCallback(
    (e) => {
      e.stopPropagation();

      if (disabled) {
        e.preventDefault();
        return;
      }
      onClick?.();
    },
    [disabled, onClick],
  );

  return (
    <Button
      as={Link}
      to={link}
      onClick={onClickCallback}
      disabled={disabled}
      type={null}
      additionalClasses={twMerge(
        'focus:no-underline dark:text-white active:no-underline w-fit',
        colorClasses[props.buttonColor || 'blue'] ||
          'focus:text-indigo-950 active:text-indigo-950',
        props.buttonColor === 'borderless' ? '' : 'hover:no-underline',
        additionalClasses,
      )}
      {...props}
    >
      {children}
    </Button>
  );
};

export default LinkButton;

LinkButton.propTypes = {
  /**
   * Link to navigate on click
   */
  link: PropTypes.string,
  /**
   * On click callback
   */
  onClick: PropTypes.func,
  /**
   * If button is disabled
   */
  disabled: PropTypes.bool,
};

LinkButton.defaultProps = {
  link: '',
  disabled: false,
};
