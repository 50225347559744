import Joyride from 'react-joyride';

// :: Hooks
import useDarkMode from '../../hooks/useDarkMode';

// :: Inner Components
import TutorialTooltip from './TutorialTooltip/TutorialTooltip';
import TutorialBeacon from './TutorialBeaceon/TutorialBeacon';

const Tutorial = (props) => {
  const [darkMode] = useDarkMode();

  return (
    <Joyride
      tooltipComponent={TutorialTooltip}
      beaconComponent={TutorialBeacon}
      continuous={true}
      showProgress={true}
      showSkipButton={true}
      hideCloseButton={false}
      disableOverlayClose={false}
      styles={{
        options: {
          arrowColor: darkMode ? 'rgb(51, 65, 85)' : '#FFFFFF',
          overlayColor: 'rgb(0 0 0 / 46%)',
          zIndex: 1000,
        },
      }}
      scrollOffset={70}
      {...props}
    />
  );
};

export default Tutorial;
