import { useCallback, useId, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as yup from 'yup';
import { twMerge } from 'tailwind-merge';

// :: Components
import Input from '../../components/Input/Input';

// :: Lib
import { getTestProps } from '../../lib/helpers';
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import { DeleteIcon } from '../../images/shapes';
import HelpErrorTextsTemplate from '../../components/HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import Loader from '../../components/Loader/Loader';
import DirtyHandler from '../../components/DirtyHandler/DirtyHandler';

const getContentTypesArray = (contentTypeDefinitions) =>
  (contentTypeDefinitions || '').split(',').filter((value) => !!value);

const PluginForm = ({
  plugin,
  onSave,
  ctdsOptions,
  ctdsAreLoading,
  filterCtds,
  onDelete,
  additionalDropdownClasses,
  testId,
}) => {
  const formId = useId();
  const { t } = useTranslation();
  const [isSaving, setIsSaving] = useState(false);
  const isNew = !plugin.id;

  const hideCtds = useMemo(
    () => !Object.hasOwn(plugin, 'contentTypeDefinitions'),
    [plugin],
  );

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        contentTypeDefinitions: yup.array().of(yup.string()),
        settings: yup.array().of(
          yup.object().shape({
            key: yup.string(),
            label: yup.string(),
            value: yup.string(),
          }),
        ),
      }),
    [],
  );

  const ctds = useMemo(
    () => getContentTypesArray(plugin.contentTypeDefinitions),
    [plugin.contentTypeDefinitions],
  );

  const handleReset = useCallback(
    (formik) => {
      if (isNew) {
        onDelete();
        return;
      }
      formik.resetForm({
        values: {
          ...plugin,
          ...(!hideCtds ? { contentTypeDefinitions: ctds } : {}),
        },
      });
    },
    [ctds, hideCtds, isNew, onDelete, plugin],
  );

  const handleSubmit = useCallback(
    async (values, formik) => {
      setIsSaving(true);
      const [[newPlugin, errors], hasErrors] = await onSave(values);
      formik.setStatus({ ...formik.status, errors });
      if (!hasErrors)
        formik.resetForm({
          values: {
            ...newPlugin,
            ...(!hideCtds
              ? {
                  contentTypeDefinitions: getContentTypesArray(
                    newPlugin.contentTypeDefinitions,
                  ),
                }
              : {}),
          },
        });
      setIsSaving(false);
    },
    [hideCtds, onSave],
  );

  const handleDelete = useCallback(async () => {
    await onDelete(plugin.id, plugin.name, plugin.type);
  }, [onDelete, plugin.id, plugin.name, plugin.type]);

  return (
    <Formik
      initialValues={{
        ...plugin,
        ...(!hideCtds ? { contentTypeDefinitions: ctds } : {}),
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {(formik) => (
        <form
          id={formId}
          className="w-full"
          onSubmit={formik.handleSubmit}
          noValidate
          {...getTestProps(testId)}
        >
          <div
            className={twMerge(
              'rounded-lg border bg-gray-50 dark:bg-slate-950 flex-col items-center',
              isNew ? 'border-lime' : 'dark:border-none',
            )}
          >
            <div className="flex w-full justify-between p-3 md:p-5 border-b dark:border-slate-800">
              <div
                className="text-lg font-bold dark:text-white"
                {...getTestProps(testId, 'name')}
              >
                {plugin.name}
              </div>
              {!isNew && (
                <Button
                  iconImage={
                    <DeleteIcon className="h-3.5 text-purple-700 dark:text-gray-200 opacity-50 hover:opacity-100" />
                  }
                  buttonColor="borderless"
                  additionalClasses="w-fit"
                  onClick={handleDelete}
                  disabled={isSaving}
                  noPaddings
                  {...getTestProps(testId, 'delete', 'testId')}
                />
              )}
            </div>
            <div className="bg-white dark:bg-slate-950 space-y-4 p-3 md:p-5">
              {(plugin.settings || []).map((setting, idx) => (
                <div key={setting.key} className="flex flex-col w-full gap-2">
                  <Input
                    name={`settings[${idx}].value`}
                    value={formik.values.settings[idx].value}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    label={setting.label}
                    disabled={isSaving}
                    error={
                      formik.status?.errors?.settings?.[idx] ||
                      formik.errors?.settings?.[idx]
                    }
                    {...getTestProps(testId, setting.key, 'testId')}
                  />
                </div>
              ))}
              {!hideCtds && (
                <div className="mb-2 relative w-full">
                  <Dropdown
                    label={t('Global.Search.ContentTypesHeader')}
                    options={ctdsOptions}
                    isDataLoading={ctdsAreLoading}
                    value={formik.values.contentTypeDefinitions}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="contentTypeDefinitions"
                    filterCallback={filterCtds}
                    debounceTime={150}
                    additionalDropdownClasses={additionalDropdownClasses}
                    disabled={isSaving}
                    error={
                      formik.status?.errors?.contentTypeDefinitions ||
                      formik.errors?.contentTypeDefinitions
                    }
                    nullable
                    multiple
                    {...getTestProps(testId, 'ctds', 'testId')}
                  />
                </div>
              )}
            </div>
            <div
              className="flex bg-white dark:bg-slate-950 justify-between rounded-b-xl border-t
              dark:border-slate-800 p-3 md:p-5"
            >
              <div className="space-y-2">
                <Button
                  buttonSize="sm"
                  type="submit"
                  form={formId}
                  disabled={isSaving}
                  iconImage={
                    isSaving ? <Loader size="small" type="spinner-grid" /> : ''
                  }
                  {...getTestProps(testId, 'save', 'testId')}
                >
                  {t('Global.SaveChanges')}
                </Button>
                <HelpErrorTextsTemplate error={formik.status?.errors?.['']} />
              </div>
              {(formik.dirty || isNew) && (
                <Button
                  buttonSize="sm"
                  buttonColor="grayBordered"
                  onClick={() => handleReset(formik)}
                  disabled={isSaving}
                  {...getTestProps(testId, 'cancel', 'testId')}
                >
                  {t('Global.Cancel')}
                </Button>
              )}
            </div>
          </div>
          <DirtyHandler />
        </form>
      )}
    </Formik>
  );
};

export default PluginForm;

PluginForm.propTypes = {
  /**
   * Plugin item
   */
  plugin: PropTypes.shape({
    id: PropTypes.string,
    contentTypeDefinitions: PropTypes.string,
    name: PropTypes.string.isRequired,
    settings: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  /**
   * On save callback
   */
  onSave: PropTypes.func.isRequired,
  /**
   * On delete callback
   */
  onDelete: PropTypes.func.isRequired,
  /**
   * Content types data
   */
  ctdsOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  /**
   * If content types data are loading
   */
  ctdsAreLoading: PropTypes.bool,
  /**
   * Callback for filtering content types
   */
  filterCtds: PropTypes.func,
  /**
   * Additional dropdown classes
   */
  additionalDropdownClasses: PropTypes.string,
  /**
   * Test id for layout
   */
  testId: PropTypes.string,
};

PluginForm.defaultProps = {
  ctdsOptions: [],
  ctdsAreLoading: false,
  filterCtds: /* istanbul ignore next */ () => null,
  additionalDropdownClasses: '',
  testId: '',
};
