import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

// :: Components
import Panel from '../../components/Panel/Panel';

// :: Lib
import { getTestProps } from '../../lib/helpers';

const PackagesGrid = ({
  title,
  helpPopup,
  packages,
  additionalClasses,
  isOpen,
  isCollapsable,
  testId,
  id,
}) => {
  return (
    <Panel
      helpText={helpPopup}
      open={isOpen}
      isCollapsable={isCollapsable}
      title={title}
      additionalContainerClasses="py-4 px-4"
      additionalChildrenClasses="!h-full"
      id={id}
      {...getTestProps(testId, 'package-grid-panel', 'testId')}
    >
      <div
        className={twMerge(
          `grid grid-cols-[repeat(auto-fit,_minmax(4rem,_1fr))] gap-4`,
          additionalClasses,
        )}
        {...getTestProps(testId, 'package-grid')}
      >
        {packages.length > 0 &&
          packages.map((element) => (
            <Link
              key={element.fileName}
              className={twMerge(
                'rounded-lg bg-gray-50 dark:bg-gray-900 border border-gray-150 dark:border-gray-900',
                'py-2 m-0 hover:border hover:border-gray',
                'flex items-center justify-center w-full h-auto',
              )}
              to={element.downloadUrl}
              target="_blank"
              rel="noreferrer"
              {...getTestProps(testId, `${element.fileName}-link`)}
            >
              {element.icon}
            </Link>
          ))}
      </div>
    </Panel>
  );
};

export default PackagesGrid;

PackagesGrid.propTypes = {
  /**
   * Title for panel component
   */
  title: PropTypes.string,
  /**
   * Title for panel component
   */
  helpPopup: PropTypes.node,
  /**
   * List of packages and its
   */
  packages: PropTypes.arrayOf(
    PropTypes.shape({
      fileName: PropTypes.string,
      icon: PropTypes.node,
      downloadUrl: PropTypes.string,
    }),
  ),
  /**
   * PackagesGrid additional CSS classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Component test id
   */
  testId: PropTypes.string,
  /**
   * Packages Grid id
   */
  id: PropTypes.string,
};

PackagesGrid.defaultProps = {
  additionalClasses: '',
  testId: '',
  isOpen: false,
  isCollapsable: true,
  id: '',
};
