/* eslint-disable max-len */

export const DEFINED_TYPES = {
  blogpost: {
    name: 'blogpost',
    label: 'Blog Post',
    ctd: {
      name: 'blogpost',
      label: 'Blog Post',
      schemaDefinition: {
        type: 'object',
        allOf: [
          {
            $ref: '#/components/schemas/AbstractContentTypeSchemaDefinition',
          },
          {
            type: 'object',
            properties: {
              slug: {
                type: 'string',
                pattern: '^[a-zA-Z0-9-_]*$',
                minLength: 1,
              },
              title: {
                type: 'string',
                minLength: 1,
              },
              content: {
                type: 'object',
                minLength: 1,
                properties: {
                  time: {
                    type: 'number',
                  },
                  blocks: {
                    type: 'array',
                    items: {
                      type: 'object',
                      properties: {
                        id: {
                          type: 'string',
                        },
                        data: {
                          type: 'object',
                          properties: {
                            text: {
                              type: 'string',
                            },
                          },
                          additionalProperties: true,
                        },
                        type: {
                          type: 'string',
                        },
                      },
                    },
                  },
                  version: {
                    type: 'string',
                  },
                },
                additionalProperties: false,
              },
              excerpt: {
                type: 'string',
                minLength: 1,
              },
              headerImage: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/DataSource',
                },
                minItems: 0,
              },
            },
          },
        ],
        required: ['title', 'slug', 'excerpt', 'content'],
        additionalProperties: false,
      },
      metaDefinition: {
        order: ['title', 'slug', 'excerpt', 'content', 'headerImage'],
        propertiesConfig: {
          slug: {
            label: 'Slug',
            unique: true,
            helpText: 'Slug can only have alphanumerical characters, - and _',
            inputType: 'text',
          },
          title: {
            label: 'Title',
            unique: false,
            helpText: '',
            inputType: 'text',
            isTitlePart: true,
          },
          content: {
            label: 'Content',
            unique: false,
            helpText: '',
            inputType: 'block',
          },
          excerpt: {
            label: 'Excerpt',
            unique: false,
            helpText: '',
            inputType: 'textarea',
          },
          headerImage: {
            label: 'Header image',
            unique: false,
            helpText: '',
            inputType: 'datasource',
            validation: {
              relationContenttype: '_media',
            },
          },
        },
      },
    },
  },
  event: {
    name: 'event',
    label: 'Event',
    ctd: {
      name: 'event',
      label: 'Event',
      schemaDefinition: {
        type: 'object',
        allOf: [
          {
            $ref: '#/components/schemas/AbstractContentTypeSchemaDefinition',
          },
          {
            type: 'object',
            properties: {
              date: {
                type: 'string',
                pattern:
                  '^$|^([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))T?(([0-1]?[0-9]|2[0-3]):[0-5][0-9])?(:[0-5][0-9])?(\\.[0-9]{3})?(Z|([\\+-]\\d{2}(:\\d{2})?))?$',
                minLength: 1,
              },
              name: {
                type: 'string',
                minLength: 1,
              },
              slug: {
                type: 'string',
                minLength: 1,
              },
              image: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/DataSource',
                },
                minItems: 0,
              },
              price: {
                type: 'string',
              },
              address: {
                type: 'string',
                minLength: 1,
              },
              excerpt: {
                type: 'string',
              },
              gallery: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/DataSource',
                },
                minItems: 0,
              },
              description: {
                type: 'string',
              },
            },
          },
        ],
        required: ['name', 'slug', 'address', 'date'],
        additionalProperties: false,
      },
      metaDefinition: {
        order: [
          'name',
          'slug',
          'image',
          'address',
          'date',
          'price',
          'description',
          'excerpt',
          'gallery',
        ],
        propertiesConfig: {
          date: {
            label: 'Date',
            unique: false,
            helpText: '',
            showTime: true,
            inputType: 'dateTime',
          },
          name: {
            label: 'Name',
            unique: false,
            helpText: '',
            inputType: 'text',
            isTitlePart: true,
          },
          slug: {
            label: 'Slug',
            unique: true,
            helpText: '',
            inputType: 'text',
          },
          image: {
            label: 'Event featured image',
            unique: false,
            helpText: '',
            inputType: 'datasource',
            validation: {
              relationContenttype: '_media',
            },
          },
          price: {
            label: 'Ticket price',
            unique: false,
            helpText: '',
            inputType: 'textarea',
          },
          address: {
            label: 'Address',
            unique: false,
            helpText: '',
            inputType: 'textarea',
          },
          excerpt: {
            label: 'Description excerpt',
            unique: false,
            helpText: '',
            inputType: 'textarea',
          },
          gallery: {
            label: 'Gallery',
            unique: false,
            helpText: '',
            inputType: 'datasource',
            validation: {
              relationMultiple: true,
              relationContenttype: '_media',
            },
          },
          description: {
            label: 'Description',
            unique: false,
            helpText: '',
            inputType: 'richtext',
          },
        },
      },
    },
  },
  product: {
    name: 'product',
    label: 'Product',
    ctd: {
      name: 'product',
      label: 'Product',
      schemaDefinition: {
        type: 'object',
        allOf: [
          {
            $ref: '#/components/schemas/AbstractContentTypeSchemaDefinition',
          },
          {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                minLength: 1,
              },
              slug: {
                type: 'string',
                pattern: '^[a-zA-Z0-9-_]*$',
                minLength: 1,
              },
              price: {
                type: 'number',
                minLength: 1,
              },
              description: {
                type: 'string',
              },
              productImage: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/DataSource',
                },
                minItems: 0,
              },
              productGallery: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/DataSource',
                },
                minItems: 0,
              },
            },
          },
        ],
        required: ['name', 'slug', 'price'],
        additionalProperties: false,
      },
      metaDefinition: {
        order: [
          'name',
          'slug',
          'price',
          'description',
          'productImage',
          'productGallery',
        ],
        propertiesConfig: {
          name: {
            label: 'Name',
            unique: true,
            helpText: '',
            inputType: 'text',
            isTitlePart: true,
          },
          slug: {
            label: 'Slug',
            unique: true,
            helpText: 'Slug can only have alphanumerical characters, - and _',
            inputType: 'text',
          },
          price: {
            label: 'Price',
            unique: false,
            helpText: '',
            inputType: 'number',
          },
          description: {
            label: 'Description',
            unique: false,
            helpText: '',
            inputType: 'richtext',
          },
          productImage: {
            label: 'Product image',
            unique: false,
            helpText: '',
            inputType: 'datasource',
            validation: {
              relationContenttype: '_media',
            },
          },
          productGallery: {
            label: 'Product gallery',
            unique: false,
            helpText: '',
            inputType: 'datasource',
            validation: {
              relationMultiple: true,
              relationContenttype: '_media',
            },
          },
        },
      },
    },
  },
  project: {
    name: 'project',
    label: 'Project',
    ctd: {
      name: 'project',
      label: 'Project',
      schemaDefinition: {
        type: 'object',
        allOf: [
          {
            $ref: '#/components/schemas/AbstractContentTypeSchemaDefinition',
          },
          {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                minLength: 1,
              },
              slug: {
                type: 'string',
                pattern: '^[a-zA-Z0-9-_]*$',
                minLength: 1,
              },
              gallery: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/DataSource',
                },
                minItems: 0,
              },
              description: {
                type: 'string',
              },
              headerImage: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/DataSource',
                },
                minItems: 0,
              },
              gallery_name: {
                type: 'string',
              },
              gallery_description: {
                type: 'string',
              },
            },
          },
        ],
        required: ['name', 'slug'],
        additionalProperties: false,
      },
      metaDefinition: {
        order: [
          'name',
          'slug',
          'description',
          'headerImage',
          'gallery_name',
          'gallery_description',
          'gallery',
        ],
        propertiesConfig: {
          name: {
            label: 'Name',
            unique: true,
            helpText: '',
            inputType: 'text',
            isTitlePart: true,
          },
          slug: {
            label: 'Slug',
            unique: true,
            helpText: '',
            inputType: 'text',
          },
          gallery: {
            label: 'Gallery',
            unique: false,
            helpText: '',
            inputType: 'datasource',
            validation: {
              relationMultiple: true,
              relationContenttype: '_media',
            },
          },
          description: {
            label: 'Description',
            unique: false,
            helpText: '',
            inputType: 'richtext',
          },
          headerImage: {
            label: 'Header image',
            unique: false,
            helpText: '',
            inputType: 'datasource',
            validation: {
              relationContenttype: '_media',
            },
          },
          gallery_name: {
            label: 'Gallery name',
            unique: false,
            helpText: '',
            inputType: 'text',
          },
          gallery_description: {
            label: 'Gallery description',
            unique: false,
            helpText: '',
            inputType: 'richtext',
          },
        },
      },
    },
  },
  recipe: {
    name: 'recipe',
    label: 'Recipe',
    ctd: {
      name: 'recipe',
      label: 'Recipe',
      schemaDefinition: {
        type: 'object',
        allOf: [
          {
            $ref: '#/components/schemas/AbstractContentTypeSchemaDefinition',
          },
          {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                minLength: 1,
              },
              slug: {
                type: 'string',
                pattern: '^[a-zA-Z0-9-_]*$',
                minLength: 1,
              },
              image: {
                type: 'array',
                items: {
                  $ref: '#/components/schemas/DataSource',
                },
                minItems: 0,
              },
              steps: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    step: {
                      type: 'string',
                    },
                    image: {
                      type: 'array',
                      items: {
                        $ref: '#/components/schemas/DataSource',
                      },
                      minItems: 0,
                    },
                  },
                },
              },
              servings: {
                type: 'number',
              },
              cookingTime: {
                type: 'string',
              },
              description: {
                type: 'string',
              },
              ingredients: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    unit: {
                      type: 'string',
                    },
                    amount: {
                      type: 'number',
                    },
                    product: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        ],
        required: ['name', 'slug'],
        additionalProperties: false,
      },
      metaDefinition: {
        order: [
          'name',
          'slug',
          'image',
          'description',
          'ingredients',
          'steps',
          'cookingTime',
          'servings',
        ],
        propertiesConfig: {
          name: {
            label: 'Name',
            unique: false,
            helpText: '',
            inputType: 'text',
            isTitlePart: true,
          },
          slug: {
            label: 'Slug',
            unique: true,
            helpText: 'Slug can only have alphanumerical characters, - and _',
            inputType: 'text',
          },
          image: {
            label: 'Image',
            unique: false,
            helpText: '',
            inputType: 'datasource',
            validation: {
              relationContenttype: '_media',
            },
          },
          steps: {
            label: 'Steps',
            items: {
              order: ['image', 'step'],
              propertiesConfig: {
                step: {
                  label: 'Step',
                  unique: false,
                  helpText: '',
                  inputType: 'textarea',
                },
                image: {
                  label: 'Image',
                  unique: false,
                  helpText: '',
                  inputType: 'datasource',
                  validation: {
                    relationContenttype: '_media',
                  },
                },
              },
            },
            unique: false,
            helpText: '',
            inputType: 'object',
          },
          servings: {
            label: 'Servings',
            unique: false,
            helpText: '',
            inputType: 'number',
          },
          cookingTime: {
            label: 'Cooking time',
            unique: false,
            helpText: '',
            inputType: 'text',
          },
          description: {
            label: 'Description',
            unique: false,
            helpText: '',
            inputType: 'richtext',
          },
          ingredients: {
            label: 'Ingredients',
            items: {
              order: ['amount', 'unit', 'product'],
              propertiesConfig: {
                unit: {
                  label: 'Unit',
                  unique: false,
                  options: [
                    '',
                    'g',
                    'kg',
                    'ml',
                    'pcs',
                    'tablespoon',
                    'teaspoon',
                    'ounce',
                    'pound',
                    'cup',
                    'clove',
                    'pinch',
                  ],
                  helpText: '',
                  inputType: 'select',
                },
                amount: {
                  label: 'Amount',
                  unique: false,
                  helpText: '',
                  inputType: 'number',
                },
                product: {
                  label: 'Product',
                  unique: false,
                  helpText: '',
                  inputType: 'text',
                },
              },
            },
            unique: false,
            helpText: '',
            inputType: 'object',
          },
        },
      },
    },
  },
};
