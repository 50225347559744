import { useMemo, useState } from 'react';
import { useContentTypes, useSearch } from '.';

export const useBacklinks = (id, contentTypeName, duplicate = false) => {
  const [backlinksPage, setBacklinksPage] = useState(1);

  const backlinkSearchParams = useMemo(
    () => [
      {
        q: `"/api/v1/content/${contentTypeName}/${id}"`,
        limit: 5,
        page: backlinksPage,
      },
      {
        pause: duplicate || !id,
      },
    ],
    [contentTypeName, duplicate, id, backlinksPage],
  );

  const [backlinks, backlinkPagination] = useSearch(...backlinkSearchParams);

  const ctdByNamesParams = useMemo(
    () => [
      {
        names: backlinks?.map(({ item }) => item?.internal?.contentType),
      },
      { pause: !backlinks?.length },
    ],
    [backlinks],
  );

  const [ctdsByName] = useContentTypes(...ctdByNamesParams);

  const backlinkContentTypes = useMemo(
    () =>
      ctdsByName.reduce((ctds, ctd) => {
        ctds[ctd.name] = ctd;
        return ctds;
      }, {}),
    [ctdsByName],
  );

  return {
    backlinks,
    backlinkContentTypes,
    backlinkPagination,
    setBacklinksPage,
  };
};
