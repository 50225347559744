import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from '../Avatar/Avatar';
import { CloseIcon } from '../../images/shapes';
import { getTestProps } from '../../lib/helpers';

const ImpersonateCard = ({ userInitials, email, onClose, testId }) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        `fixed top-2 left-1/2 -translate-x-1/2 z-50 w-fit bg-white dark:bg-gray-900 shadow-xl rounded-lg ` +
        `border border-red overflow-hidden`
      }
      {...getTestProps(testId)}
    >
      <div className="p-2">
        <div className="flex items-center gap-3">
          <Avatar userInitials={userInitials} testId={testId} />
          <div>
            <p className="text-sm dark:text-white">
              {t('Global.Impersonating')}
            </p>
            <p
              className="text-base text-black dark:text-white font-semibold"
              {...getTestProps(testId, 'email')}
            >
              {email}
            </p>
          </div>
          <div className="ml-4 flex-shrink-0 flex self-start">
            <button
              type="button"
              className="inline-flex text-black focus:outline-none"
              onClick={onClose}
              {...getTestProps(testId, 'close')}
            >
              <CloseIcon className="w-3 dark:text-white" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpersonateCard;

ImpersonateCard.propTypes = {
  /**
   * User initials
   */
  userInitials: PropTypes.string,
  /**
   * User email
   */
  email: PropTypes.string,
  /**
   * Callback to be called on closing the card
   */
  onClose: PropTypes.func,
  /**
   * Component test id
   */
  testId: PropTypes.string,
};

ImpersonateCard.defaultProps = {
  userInitials: '',
  email: '',
  onClose: /* istanbul ignore next */ () => true,
  testId: '',
};
