import { createContext } from 'react';
import PropTypes from 'prop-types';

export const SidebarContext = createContext({});

SidebarContext.propTypes = {
  value: PropTypes.shape({
    /**
     * Callback for reloading content type definitions
     */
    roladCtd: PropTypes.func,
  }).isRequired,
};

export default SidebarContext;
