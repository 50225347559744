import { forwardRef, useCallback, useRef } from 'react';
import { Popover, Whisper, Button, Dropdown } from 'rsuite';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

// :: Lib
import { getTestProps } from '../../../lib/helpers';

// :: Images
import { EllipsisIcon } from '../../../images/shapes';

const PopoverItem = ({ link, children }) => {
  const containerClasses = 'px-4 py-1 lg:py-2 w-full flex';
  if (link)
    return (
      <Link
        className={twMerge(
          containerClasses,
          'focus:no-underline active:no-underline hover:no-underline',
          'focus:text-indigo-950 active:text-indigo-950 hover:text-indigo-950 dark:hover:text-white',
        )}
        to={link}
      >
        {children}
      </Link>
    );
  return <span className={containerClasses}>{children}</span>;
};

const MenuPopover = forwardRef(
  (
    { menuItems, rowData, handleItemOnClick, ignoreIcons, testId, ...rest },
    ref,
  ) => (
    <Popover ref={ref} {...rest} full>
      <Dropdown.Menu
        className="bg-white dark:bg-gray-900 py-0 shadow-xl dark:shadow-none border border-solid border-gray-50
        dark:border-gray-900 space-y-1"
      >
        {menuItems.map((item, index) => (
          <Dropdown.Item
            key={item.id || item.label || index}
            onClick={() => handleItemOnClick(item, rowData)}
            className={twMerge(
              'flex items-center hover:text-indigo-950 text-base lg:text-lg font-medium',
              'hover:bg-blue-300/20 focus-visible:!bg-none focus-visible:!text-lime p-0',
              'dark:text-white',
            )}
            eventKey={item.label}
            disabled={item.disabled}
            {...getTestProps(testId, `${index}-item`)}
          >
            <PopoverItem link={item.link}>
              {!ignoreIcons && item.icon}
              <span className="leading-none">{item.label}</span>
            </PopoverItem>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Popover>
  ),
);

const DataGridActionMenu = ({ menuItems, rowData, ignoreIcons, testId }) => {
  const ref = useRef();
  const handleItemOnClick = useCallback((item, rowData) => {
    // Case: On click when defined
    if (item?.onClick) {
      item.onClick(rowData);
    }
    // Case: Close action modal
    ref.current.close();
  }, []);

  return (
    <Whisper
      placement="autoVerticalStart"
      controlId="control-id-with-dropdown"
      trigger="click"
      ref={ref}
      speaker={
        <MenuPopover
          menuItems={menuItems}
          rowData={rowData}
          handleItemOnClick={handleItemOnClick}
          ignoreIcons={ignoreIcons}
          testId={testId}
        />
      }
    >
      <Button
        className={twMerge(
          'group flex justify-center items-center p-0 ',
          'focus:outline-none dark:focus:bg-gray-700 dark:active:bg-gray-700 transition duration-250 ease-in-out',
          'leading-none rounded-full font-medium w-7 2xl:w-8 h-7 2xl:h-8 dark:hover:bg-gray-700',
        )}
        {...getTestProps(testId, 'button')}
      >
        <EllipsisIcon className="w-4 2xl:w-5 h-4 2xl:h-5 text-slate-400 group-hover:text-blue" />
      </Button>
    </Whisper>
  );
};

DataGridActionMenu.propTypes = {
  /**
   * Action menu items
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func,
      icon: PropTypes.node,
      disabled: PropTypes.bool,
    }),
  ).isRequired,
  /**
   * If icons in menu should be hidden
   */
  ignoreIcons: PropTypes.bool,
  /**
   * Action menu test id
   */
  testId: PropTypes.string,
};

DataGridActionMenu.defaultProps = {
  ignoreIcons: false,
  testId: '',
};

export default DataGridActionMenu;
