import { useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import EditorJS from '@editorjs/editorjs';
import Header from 'editorjs-header-with-anchor';
import Quote from '@editorjs/quote';
import Warning from '@editorjs/warning';
import Delimiter from '@editorjs/delimiter';
import Code from '@editorjs/code';
import Table from '@editorjs/table';
import Paragraph from '@editorjs/paragraph';
import NestedList from '@editorjs/nested-list';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import YoutubeEmbed from './customTools/YoutubeEmded/YoutubeEmded';
import FlotiqImagePlugin from './customTools/FlotiqImagePlugin/FlotiqImagePlugin';
import { twMerge } from 'tailwind-merge';
import HelpErrorTextsTemplate from '../HelpErrorTextsTemplate/HelpErrorTextsTemplate';
import RequiredTemplate from '../RequiredTemplate/RequiredTemplate';
import { getTestProps } from '../../lib/helpers';
import { useModals } from '../../contexts/ModalContext';
import LinkObjectContentModal from '../RelationField/LinkObjectContentModal/LinkObjectContentModal';
import { useTranslation } from 'react-i18next';
import FileButton from '../FileButton/FileButton';

const findTools = (toolsList, editorJsTools) => {
  if (!toolsList) return editorJsTools;
  const list = {
    paragraph: editorJsTools.paragraph,
    alignmentTuneTool: editorJsTools.alignmentTuneTool,
  };
  toolsList.forEach((tool) => {
    if (editorJsTools[tool]) {
      list[tool] = editorJsTools[tool];
    }
  });
  return list;
};

const parseData = (value) => {
  if (value?.blocks?.length) {
    return {
      ...value,
      blocks: value.blocks.map((block) => {
        if (Array.isArray(block.data)) return { ...block, data: {} };
        return block;
      }),
    };
  }
  return value;
};

const BlockInput = ({
  value,
  onChange,
  onBlur,
  toolsList,
  label,
  required,
  disabled,
  helpText,
  error,
  onMediaUpload,
  additionalClasses,
  additionalErrorClasses,
  testId,
}) => {
  const editor = useRef(null);
  const holder = useRef();
  const modal = useModals();
  const { t } = useTranslation();

  const openMediaModal = useCallback(async () => {
    return await modal({
      title: (
        <div className="flex flex-wrap justify-between gap-1">
          <div className="text-3xl">{t('Global.MediaLibrary')}</div>
          <FileButton
            onUpload={onMediaUpload}
            multiple
            additionalClasses="w-fit"
            testId={testId}
          />
        </div>
      ),
      content: (
        <LinkObjectContentModal
          relationType={'_media'}
          onMediaUpload={onMediaUpload}
          returnContentObject
        />
      ),
      size: '2xl',
      dialogAdditionalClasses: 'h-[calc(100vh-32px)]',
    });
  }, [modal, t, onMediaUpload, testId]);

  const editorJsTools = useMemo(
    () => ({
      paragraph: {
        class: Paragraph,
        inlineToolbar: true,
        tunes: ['alignmentTuneTool'],
      },
      alignmentTuneTool: {
        class: AlignmentTuneTool,
        config: {
          blocks: {
            header: 'center',
          },
        },
      },
      table: { class: Table, inlineToolbar: true },
      list: {
        class: NestedList,
        inlineToolbar: true,
      },
      warning: { class: Warning, inlineToolbar: true },
      code: Code,
      header: {
        class: Header,
        inlineToolbar: true,
        tunes: ['alignmentTuneTool'],
      },
      quote: { class: Quote, inlineToolbar: true },
      delimiter: Delimiter,
      youtubeEmbed: YoutubeEmbed,
      image: {
        class: FlotiqImagePlugin,
        inlineToolbar: true,
        config: {
          openModal: openMediaModal,
        },
      },
    }),
    [openMediaModal],
  );

  const handleSave = useCallback(async () => {
    if (disabled) return;
    const savedData = await editor.current.save();
    onChange(savedData);
  }, [disabled, onChange]);

  useEffect(() => {
    if (
      editor.current?.destroy &&
      editor.current?.configuration &&
      editor.current.configuration.readOnly !== disabled
    ) {
      editor.current.destroy();
    }
    if (editor.current && Object.keys(editor.current).length) return;
    editor.current = new EditorJS({
      data: parseData(value),
      readOnly: disabled,
      holder: holder.current,
      tools: findTools(toolsList, editorJsTools),
      onChange: handleSave,
      logLevel: 'ERROR',
    });
  }, [value, disabled, toolsList, handleSave, openMediaModal, editorJsTools]);

  useEffect(() => {
    if (disabled && (!value || Object.keys(value).length === 0))
      onChange({ time: Date.now(), blocks: [], version: EditorJS.version });
  }, [disabled, onChange, value]);

  useEffect(() => {
    if (editor.current && !value.blocks) {
      editor.current.isReady.then(() => handleSave());
    }
  }, [handleSave, value]);

  useEffect(() => {
    if (!editor.current) return;
    if (editor.current.destroy) {
      editor.current.destroy();
    }
  }, [toolsList]);

  useEffect(() => {
    return () => {
      if (!editor.current) return;
      if (editor.current.destroy) editor.current.destroy();
    };
  }, []);

  const parsedError = useMemo(() => {
    if (error?.blocks) {
      const newErrors = { '': error.blocks, ...error };
      delete newErrors.blocks;
      return newErrors;
    }
    return error;
  }, [error]);

  const handleBlur = useCallback(
    (e) => {
      // Due to the align tool does not trigger the change event,
      // we need to trigger on change on blurring
      handleSave();
      onBlur(e);
    },
    [handleSave, onBlur],
  );

  return (
    <div className={twMerge('relative', additionalClasses)} onBlur={handleBlur}>
      {label && (
        <label
          className="block text-sm text-slate-400 dark:text-gray-200 mb-1"
          {...getTestProps(testId, 'label')}
        >
          {label}
          {required && <RequiredTemplate />}
        </label>
      )}
      {!label && required && (
        <RequiredTemplate absolute additionalClasses="-right-3 top-2" />
      )}
      <div
        className={twMerge(
          'border border-slate-200 dark:bg-transparent dark:border-slate-800 rounded-lg w-full dark:text-white',
          disabled ? 'bg-gray cursor-not-allowed' : 'bg-white',
        )}
      >
        <div ref={holder} />
      </div>
      <HelpErrorTextsTemplate
        helpText={helpText}
        error={parsedError}
        additionalErrorClasses={additionalErrorClasses}
        testId={testId}
      />
    </div>
  );
};

export default BlockInput;

BlockInput.propTypes = {
  /**
   * Blocks value
   */
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  /**
   * On value change callback
   */
  onChange: PropTypes.func,
  /**
   * On blur callback
   */
  onBlur: PropTypes.func,
  /**
   * List of tools names
   */
  toolsList: PropTypes.arrayOf(PropTypes.string),
  /**
   * Label above block input
   */
  label: PropTypes.node,
  /**
   * If field is required
   */
  required: PropTypes.bool,
  /**
   * If value is read only
   */
  disabled: PropTypes.bool,
  /**
   * Help text under block input
   */
  helpText: PropTypes.string,
  /**
   * Text under block input that will inform about error
   */
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.object,
  ]),
  /**
   * On media upload callback
   */
  onMediaUpload: PropTypes.func,
  /**
   * Additional container classes
   */
  additionalClasses: PropTypes.string,
  /**
   * Additional error container classes
   */
  additionalErrorClasses: PropTypes.string,
  /**
   * Input test id
   */
  testId: PropTypes.string,
};

BlockInput.defaultProps = {
  value: {},
  onChange: /* istanbul ignore next */ () => {},
  onBlur: /* istanbul ignore next */ () => {},
  disabled: false,
  toolsList: null,
  label: '',
  required: false,
  helpText: '',
  error: '',
  onMediaUpload: /* istanbul ignore next */ () => {},
  additionalClasses: '',
  additionalErrorClasses: '',
  testId: '',
};
